import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Emergency from '../assets/images/emergency_home.png'
import { useNavigate } from 'react-router-dom';

import ScreenView from "../util/screenView";
import EncryptionDecryption from '../util/encrption';

const defaultTheme = createTheme();

export default function Instruction(){
    const Navigate = useNavigate();
    const [decrytedString, setDecryptedString] = useState(null);
    const [studentData, setStudentData] = useState(null);

    const handleClick = () =>{
        if(studentData !== null && Object.keys(studentData).length > 0){
            if(studentData.DOB !== '' && studentData.MobileNo !== '' && studentData.UserName !== ''){
                Navigate('/course-selection')
            }else{
                Navigate('/login')
            }
        }else{
            Navigate('/login')
        }
    }

    useEffect(() => {
        console.log('hit') 
        var EncryptedURL = window.location.href;
        var DecryptedUrl = EncryptionDecryption(EncryptedURL);

        console.log('DecryptedUrl in file', DecryptedUrl)
        if(DecryptedUrl === undefined){
            Navigate('/login')
        }else{
            setDecryptedString(DecryptedUrl);
        }

    },[])
    console.log('decrytedString', decrytedString)
    useEffect(() => {
        if(decrytedString !== null && decrytedString !== undefined){
            // Split the query string by '&' to get key-value pairs
            const pairs = decrytedString.split('&');

            // Initialize an empty object to hold the JSON
            const jsonObject = {};

            // Iterate over each pair
            pairs.forEach(pair => {
                // Split each pair by '=' to separate keys and values
                const [key, value] = pair.split('=');
                // Add the key-value pair to the jsonObject
                jsonObject[key] = value;
            });
            setStudentData(jsonObject);

            // if(Object.keys(jsonObject).length !== 0){
            //     Navigate('/login')
            // }
        }
        // else{
        //     Navigate('/login')
        // }
        
    }, [decrytedString])

    useEffect(() => {
        if(studentData !== null){
            sessionStorage.setItem("name",studentData.UserName);
            sessionStorage.setItem("mobileNo",studentData.MobileNo);
            sessionStorage.setItem("dateOfBirth",studentData.DOB);
            sessionStorage.setItem("univID",studentData.UnvId);
            sessionStorage.setItem("loggedInUser",'student');
            sessionStorage.setItem("CategoryId",parseInt(studentData.CategoryId));
            sessionStorage.setItem("CourseId",parseInt(studentData.CourseId));
            sessionStorage.setItem("source", "digiversity")

            // Remove local storage
            // localStorage.removeItem('enquiryId');
            // localStorage.removeItem('lastActiveSection');
        }
        // else{
        //     Navigate('/login')
        // }
        
    },[studentData])


    console.log('studentData', studentData)

    return(
        <ScreenView>
            {mobileView => (
                <ThemeProvider theme={defaultTheme}>
                    <CssBaseline />
                    <Grid container component="main" sx={{ height: '100vh', bgcolor: '#384ac133' }}>
                        <Box sx={{my: 12, mx: mobileView? 5 : 20,  borderRadius: '10px', direction: 'row', justifyContent: 'center', alignItems:'center', background: '#FFF'}}>
                            <Box sx={{
                                backgroundImage: `url(${Emergency})`,
                                backgroundSize: 'contain', // Adjust the background size as needed
                                backgroundPosition: 'center', // Align the background image to the center
                                backgroundRepeat: 'no-repeat', // Prevent the image from repeating
                                width: '10%',
                                height: '10vh', // Adjust the height as needed
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '3%',
                                marginBottom: '3%',
                                marginLeft: '45%'
                            }}></Box>
                            <Typography variant="h6">
                                Disclaimer
                            </Typography>
                            <Typography sx={{mx: mobileView? 5 : 10, my: mobileView ? 3 : 5}}>
                                The showcased Work Integrated Degree Programs on Digivarsity are curated based on current available information provided by participating employers and universities.

                                Digivarsity does not collect any fee as a part of the application process. Any payments made by students as part of admission process are directly collected by the university via the university platform.
                            </Typography>
                            <Button style={{width: mobileView? '70%' : '30%', marginTop: '5%'}} className='primary-btn' variant="contained" onClick={handleClick}>
                                Continue to Register
                            </Button>
                        </Box>
                    </Grid>
                </ThemeProvider>
            )}
        </ScreenView>
    )
}