import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import login from '../assets/images/login.png';
import '../assets/styles/global.css';
import LoginBlock from '../component/loginContainer'


const defaultTheme = createTheme();

export default function SignIn() {

    return (
        <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={6}
                sx={{
                backgroundImage: `url(${login})`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                  t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',

                }}
            />
            <LoginBlock display={'flex'} justifyContent={"center"} alignItems={"center"}/>
            
           
        </Grid> 
        </ThemeProvider>
    );
}