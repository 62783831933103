import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import { Box, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Navigate, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';

import Header from '../component/header';
import { CheckListForm, Enquiry, Form } from '../util/apiUrl';
import CallAPI from '../util/apiAction';
import '../assets/styles/global.css';
import ScreenView from '../util/screenView';


export default function PreRequisites(){
    const Navigate = useNavigate();
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false);
    const scrollableDivRef = useRef(null);
    const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
    const [formData, setFormData] = useState(null);
    const [enquiryId, setEnquiryId] = useState(null);

    var UnvId = sessionStorage.getItem("univID");
    var mobileNo = sessionStorage.getItem("mobileNo");
    var dateOfBirth = sessionStorage.getItem("dateOfBirth");
    var course = sessionStorage.getItem('CourseId')
    var category = sessionStorage.getItem('CategoryId')
    var lastActive = localStorage.getItem('lastActiveSection')
    var enquiry = localStorage.getItem('enquiryId')
    var RegisterName = sessionStorage.getItem("name"); 

    // var CourseId = 0; 
    const location = useLocation();
    // const { lastActiveSection, CategoryId, CourseId,  userName, name } = location.state;


    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data,loading,error } = await CallAPI(CheckListForm, 'GET', { UnvId:UnvId, CourseId:course }); 
                setData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
        
    }, []);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const { data,loading,error } = await CallAPI(Form, 'POST', { 
    //                 "unvId": UnvId,
    //                 "categoryId": parseInt(category),
    //                 "courseId": parseInt(course),
    //                 // "enquiryId": 0,
    //                 "mobileNo": mobileNo,
    //                 "dob": dateOfBirth,
    //                 "name": RegisterName
    //             }
    //             ); 
    //             setFormData(data);
    //             setEnquiryId(data.enquiryId)
    //             //  setFormData(data)
    //             //  setEnquiryId(data.enquiryId)
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     }

    //     fetchData();
    // },[])

    const handleProceed = () => {
        var boxes = document.querySelectorAll('.check');
        let anyUnchecked = false;
    
        boxes.forEach(box => {
            if (!box.checked) {
                anyUnchecked = true;
            }
        });
    
        if (anyUnchecked) {
            setOpen(true);
        } else {
            setOpen(false);
            Navigate('/admission-form')
            // Navigate("/admission-form", { state: { lastActiveSection: 0, CategoryId: category, CourseId: course,  enquiryId: enquiry, name: RegisterName } });
        }
    };
    

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    const checkHeight = (event) => {
        var doc = document.getElementById('scrollableDiv');
        var height = doc.style.height
        if(height === '300px'){
            setIsCheckboxEnabled(true);
        };
        
    }

    const handleScroll = () => {
        var block = document.getElementById('scrollableDiv');
        var checkboxes = document.getElementsByClassName('check');

        if (!block) {
            setTimeout(handleScroll, 100); // try again after 100ms
            return;
        }
        // Check if the content is scrollable
        if (block.scrollHeight > block.clientHeight) {
            // Check if the user has scrolled to the bottom
            if (Math.ceil(block.scrollTop + block.clientHeight) >= block.scrollHeight) {
                for (let checkbox of checkboxes) {
                    checkbox.disabled = false; // Enable checkboxes if at the bottom
                }
            } else {
                for (let checkbox of checkboxes) {
                    checkbox.disabled = true; // Disable checkboxes if not at the bottom
                }
            }
        } else {
            for (let checkbox of checkboxes) {
                checkbox.disabled = false; // Enable checkboxes if content fits
            }
        }
    }
    
    // Initial check to handle the case where the block is not scrollable from the beginning
    useLayoutEffect(() => {
        handleScroll();
    }, []);


    return(
        <>
        <Header/>
        {/* <Grid container component="main">
            <Grid item>
                {data !== null ? 
                    <Box>
                        {data[0].CheckListForAdmission} 
                    </Box>                
                : null}
            </Grid>
        </Grid> */}
        <ScreenView>
            {mobileView => (
        <div style={{padding: mobileView? '2% 10%' : '0% 20%'}}>
            <div>
                {data !== null ?
                    <div style={{
                            width: '100%', 
                            height:'50%', 
                            marginTop: '5%', 
                            boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.25)', 
                            borderRadius: '10px'
                        }}>
                        <div 
                            style={{
                                textAlign: 'left', 
                                height:'20%', 
                                background:'#972524', 
                                color:'#fff', 
                                padding: mobileView ? '14px 5px 14px 20px' : '14px 5px 14px 40px',
                                fontWeight: 600,
                                fontSize: mobileView ? '18px' : '24px',
                                borderTopLeftRadius: '10px', 
                                borderTopRightRadius: '10px'
                                
                            }}
                        >
                            PRE-REQUISITES
                        </div>
                        {/* onScroll={handleScroll} onTouchMove={handleScroll} */}
                        <div id="scrollableDiv" onScroll={handleScroll} onTouchMove={handleScroll} style={{textAlign: 'left', padding: '1%', height: '300px', overflowY: 'auto'}}>
                            <div dangerouslySetInnerHTML={{ __html: data[0].CheckListForAdmission }}></div>
                        </div>
                        <hr/>
                        {/* <div style={{textAlign: 'left', color:'red', padding: '5px 5px 5px 40px'}}>*All checkboxes are mandatory</div> */}
                        <div style={{display: mobileView ? 'block' : 'flex', padding: '1%', flexWrap: mobileView ? 'wrap' : 'nowrap'}}>
                            <div style={{textAlign: 'left', width: '60%', padding: '6px 5px 11px 40px'}}>
                                {/* disabled={!isCheckboxEnabled}  */}
                                <input class='check' id='check' type='checkbox' /> 
                                I have read all the instructions and I agree 
                            </div>
                            <div style={{textAlign: 'right', width: '40%'}}>
                                <button class="primary-save-btn"
                                    id='continue'
                                    style={{
                                        color: '#fff',
                                        borderColor: '#D4273C',
                                        // marginTop: '30px',
                                        marginLeft: '10%',
                                        padding: '1%',
                                        marginBottom: '10px',
                                        fontFamily: "Open Sauce Sans",
                                        fontSize: '17px',
                                    }}
                                    onClick={handleProceed}
                                    >
                                        Continue to Application Form
                                </button>
                                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                    <Alert
                                        onClose={handleClose}
                                        severity="error"
                                        variant="filled"
                                        sx={{ width: '100%' }}
                                    >
                                        Please select checkbox
                                    </Alert>
                                </Snackbar>
                            </div>
                            
                        </div>
                            
                        </div>
                    
                
                    // <div style={{ textAlign: 'left' }}>
                    //     <div dangerouslySetInnerHTML={{ __html: data[0].CheckListForAdmission }}></div>
                    //     <table align='center' border={0} cellPadding={4} style={{width: '95%'}}>
                    //         <tbody>
                    //             {data[0].CheckListForAdmission.split('<tr>\r\n\t\t\t').map((point, index) => {
                    //                 // const text = point.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
                    //                 const text = point.replace(/<(?!br\s*\/?)[^>]+>/g, ''); 
                    //                 // |\/?&nbsp; 
                    //                 if (text.trim()) { // Check if the trimmed text is not empty
                    //                     return (
                    //                         <div key={index}>
                    //                             <tr>
                    //                                 <td colSpan={3} htmlFor={`checkbox_${index}`} style={{ padding: '5px' }} dangerouslySetInnerHTML={{__html: text}}></td>
                    //                                 <td colSpan={1}><input class="check" type="checkbox" id={`checkbox_${index}`} /></td>
                    //                             </tr>
                    //                         </div>
                    //                     );
                    //                 }
                    //                 return null; // Skip rendering if the text is empty
                    //             })}
                    //         </tbody>
                    //     </table>
                    // </div>
                : null}
                {/* {data !== null ?
                    <div style={{ textAlign: 'left' }}>
                        {data[0].CheckListForAdmission.split('<tr').replace('>', '').map((point, index) => (
                            <div key={index}>
                                <label htmlFor={`checkbox_${index}`} style={{ marginLeft: '5px' }} dangerouslySetInnerHTML={{__html: point}}></label>
                                {point.trim() && ( 
                                    <input type="checkbox" id={`checkbox_${index}`} />
                                )}
                                
                            </div>
                        ))}
                    </div>
                : null} */}
                {/* {data !== null ? 
                    <div style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: data[0].CheckListForAdmission}} >
                    </div>                
                    {data[0].CheckListForAdmission.split('<tr').map((point, index) => {
                        <input type="checkbox" />
                    })}
                : null} */}
                {/* {data !== null && (
                    <div style={{ textAlign: 'left' }}>
                        <div dangerouslySetInnerHTML={{ __html: data[0].CheckListForAdmission }}></div>
                        <table align='center' border={0} cellPadding={4} style={{width: '95%'}}>
                            <tbody>
                                {data[0].CheckListForAdmission.split('</tr>').map((point, index) => (
                                    <div key={index}>
                                        <tr>
                                            <td dangerouslySetInnerHTML={{ __html: point }}></td>
                                            <td><input type="checkbox" /></td>
                                        </tr>
                                    </div>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )} */}
            </div>
            
        </div>
        )}
        </ScreenView>
        </>
    )
}