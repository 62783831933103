
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './pages/Login';
import Instruction from './component/instruction';
import JobSection from './component/jobSelection.js';
import Form from './pages/form.js';
import AdmissionForm from './pages/admissionForm.js';
import CourseSelectionPage from './pages/courseSelectionPage.js';
import PreRequisites from './pages/preRequisites.jsx';
import Preview from './pages/preview.js';

function App() {
  return (
    <div className="App" style={{overflowX: 'hidden'}}>

      <BrowserRouter>
      <Routes>
          <Route path="/" element ={<Login/>}/>
          <Route path="/disclaimer" element ={<Instruction/>}/>
          {/* <Route path="/" element={<Login />} index /> */}
          <Route path="/login" element ={<Login/>}/>
          <Route path="/job" element={<JobSection/>}/> 
          <Route path="/form" element ={<Form/>}/>
          <Route path="/course-selection" element={<CourseSelectionPage/>} />
          <Route path="/admission-form" element ={<AdmissionForm/>} />
          <Route path="/admission-form-preview" element ={<AdmissionForm/>} />
          <Route path="/pre-requisites" element={<PreRequisites />} />
          <Route path="/preview" element={<Preview />} />
        </Routes>
        {/* <Routes>
          <Route path={process.env.REACT_APP_INSTRUCTION_PAGE_ROUTE} element={<Instruction />} />
          <Route path={process.env.REACT_APP_LOGIN_PAGE_ROUTE} element={<Login />} />
          <Route path={process.env.REACT_APP_JOB_SECTION_ROUTE} element={<JobSection />} />
          <Route path={process.env.REACT_APP_FORM_ROUTE} element={<Form />} />
          <Route path={process.env.REACT_APP_COURSE_SELECTION_PAGE_ROUTE} element={<CourseSelectionPage />} />
          <Route path={process.env.REACT_APP_ADMISSION_FORM_ROUTE} element={<AdmissionForm />} />
          <Route path={process.env.REACT_APP_ADMISSION_FORM_PREVIEW_ROUTE} element={<AdmissionForm />} />
          <Route path={process.env.REACT_APP_PRE_REQUISITES_ROUTE} element={<PreRequisites />} />
          <Route path={process.env.REACT_APP_PREVIEW_ROUTE} element={<Preview />} />
        </Routes> */}
      </BrowserRouter>

    </div>
  );
}

export default App;
