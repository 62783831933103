import React from 'react';
import * as CryptoJS from 'crypto-js';

const deriveKey = (key) => {
    return CryptoJS.SHA256(key);
};

const decryptData = (base64Encrypted) => {
    var secretKey = 'LURNAPP$SGLURN@G02020';
    const key = deriveKey(secretKey);

  
    const bytes = CryptoJS.AES.decrypt(base64Encrypted, key, {
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.ECB,
      keySize: 256 / 32
    });

    return bytes.toString(CryptoJS.enc.Utf8);
};

const EncryptionDecryption = (url) => {
    var EncryptedURL = url;
    console.log('EncryptedURL', EncryptedURL)
    const urlData = new URL(EncryptedURL).searchParams.get('data');

    if(urlData !== null){
        const finalUrlData = urlData.replace(/ /g, '+')
        console.log('dt:', finalUrlData)

        if (finalUrlData !== null) {
          try {
            const decrypted = decryptData(finalUrlData);
            console.log('decrypted', decrypted);
            if (decrypted.length > 0) {
                // setDecryptedString(decrypted);
                console.log('Decrypted string:', decrypted);
                return decrypted;
            } else {
              console.log('Decryption error: No output from decryption');
            }
          } catch (e) {
            console.log('Decryption error:', e.message);
          }
        } else {
          console.log('URL parameter "data" is null or undefined');
        }
    }
}

export default EncryptionDecryption;