import React, { useState, useEffect } from 'react';

const MobileViewDetector = ({ children }) => {
    const [state, setState] = useState({
        mobileView: false,
    });

    useEffect(() => {
        const setResponsiveness = () => {
            setState(prevState => ({
                ...prevState,
                mobileView: window.innerWidth < 900
            }));
        };

        setResponsiveness();
        window.addEventListener("resize", setResponsiveness);

        return () => {
            window.removeEventListener("resize", setResponsiveness);
        };
    }, []);

    return children(state.mobileView);
};

export default MobileViewDetector;
