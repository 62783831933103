import React, {useState, useEffect, useRef, createContext, useContext, forwardRef, useCallback} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {dynamicForm} from '../configuration/fieldSetting';
import { createState } from 'state-pool';
// import { last } from './state';
import ModalComponent from './ModalComponent';
// import { data } from '../configuration/fieldSetting';
import { FormControl, InputLabel, Input, FormHelperText, Box, Typography, Grid, Link, TextareaAutosize, Tooltip, IconButton, InputAdornment, Modal, OutlinedInput  } from '@mui/material';
import { TextField, Checkbox, FormControlLabel, Select, MenuItem, Button, Paper, Radio, RadioGroup, FormGroup, Snackbar, SnackbarContent, Alert, Stack, CircularProgress } from '@mui/material'; 
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import Textarea from '@mui/joy/Textarea';
import OtpInput from 'react-otp-input';
// date imports
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from "dayjs";

// Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

// import { TableVirtuoso } from 'react-virtuoso';

// Internal imports
import {getAccessToken, fetchAccessToken } from '../component/getToken';
import { FormDetail } from "../util/apiUrl";
import {Form, saveField, CountryList, StateList, CityList, DocumentSave, annexture, formPreview, enterAadhaar, enterOtp, debGenerateToken, debIdDetails} from "../util/apiUrl";
import CallAPI from '../util/apiAction';
import attachment from '../assets/images/attachment.png';
import Preview from "./preview";
import ScreenView from "../util/screenView";
// import '../assets/styles/global.css';
import '../assets/styles/form.css';
import pdfIcon from "../assets/images/pdfIcon.png"
import docIcon from "../assets/images/docIcon.png"
import errorIcon from "../assets/images/error.png"
import RefreshIcon from '@mui/icons-material/Refresh';
import JobSection from "./jobSelection";
import Loader from "./linearProgress";
import { renderToFile } from "@react-pdf/renderer";
import appSetting from "../configuration/appsettings";


const errors = []; // Define or receive errors object
export const newContext = createContext("");
export const last = createState('');
export const globalInstersted = createState('');
export const globalCurrentSection = createState('');

export default function FormSection(props){
    let apiRootUrl = `${appSetting.ApiServerURL[appSetting.environment]}`;

    // const id = props.sectionId
    const location = useLocation();
    const Navigate = useNavigate();
    
    // const { CourseId, CategoryId, lastActiveSection, enquiryId, name } = location.state;

    const univId = sessionStorage.getItem('univID')
    const dateOfBirth = sessionStorage.getItem('dateOfBirth')
    const mobileNo = sessionStorage.getItem('mobileNo')

    // useState
    const [data, setData] = useState(null);
    const [admissionFormId, setAdmissionFormId] = useState(null);
    const [showPreview, setShowPreview] = useState(props.showPreview);
    const [preview, setPreview] = useState(newContext)
    const [id, setId] = useState(props.activeSection);
    const [previewToken, setPreviewToken] = useState(props.previewToken);
    const [selectedDate, setSelectedDate] = useState(null);
    const {activeId, setActiveId} = useContext(newContext);
    // const { setCurrentSection } = useContext(newContext);
    const [lastSaveSection, setLastSaveSection] = last.useState();
    const [currentSection, setCurrentSection] = globalCurrentSection.useState();
    const year = dayjs().subtract(15, 'year');
    const [inputValue, setInputValue] = useState({});
    const [subjectValue, setSubjectValue] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});
    const [latestField, setLatestField] = useState({});
    const [checked, setChecked] = useState(false);
    
    const [listOfFieldsArray, setListOfFieldsArray] = useState([]);
    const [listOfFields, setListOfFields] = useState({})
    const [dynamicApiData, setDynamicApiData] = useState(null);
    const [saveApiResponse, setSaveApiResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState({});
    const [successMessage, setSuccessMessage] = useState(null);
    const [debToken, setDebToken] = useState(props.debTokenData)
    const [isDebVerified, setIsDebVerified] = useState(false)
    // const [debId, setDebId] = useState(null)
    const [isRequired, setIsRequired] = useState({});
    const [maxSubjectSequence, setMaxSubjectSequence] = useState(null)
    const [editSection, setEditSection] = useState(null)
    // const [jobDataLength, setJobDataLength] = useState(null)
    const [alignFieldList, setAlignFieldList] = useState([])

    // Table usestate
    const [tableInputValue, setTableInputValue] = useState([]);
    const [tableRowSequence, setTableRowSequence] = useState(null);
    const [tableValue, setTableValue] = useState({});
    const [row, setRow] = useState(0)
    const [deleteFlag, setDeleteFlag] = useState(0);
    const [workMandatoryFlag, setWorkMandatoryFlag] = useState(0);
    const [tableRow, setTableRow] = useState(1);
    const [editFlag, setEditFlag] = useState(0);
    const [deleteSequenceFlag, setDeleteSequenceFlag] = useState(0);

    // University Flag usestate
    const [kycFlag, setKycFlag] = useState(0);
    const [fullNameFlag, setFullNameFlag] = useState(0);
    const [examMandatoryFlag, setExamMandatory] = useState(0);
    const [debEnable, setDebEnable] = useState(0);

    // Annexture usestate
    const [annextureFlag, setAnnextureFlag] = useState(false)       // set annexture view flag to false
    const [annextureData, setAnnextureData] = useState(null); 
    const [Insterested, setInterested] = globalInstersted.useState();
    const [link, setLink] = useState(null);

    // Modal usestate
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [titleMsg, setTitleMsg] = useState(null);
    const [content, setContent] = useState(null);
    const [modalResponse, setModalResponse] = useState(false);
    const [modalContentIdentity, setModalContentIdentity] = useState(null)
    const [modalResponseCallback, setModalResponseCallback] = useState(null);

    // document usestate
    const [file, setFile] = useState({});
    const [fileDataURL, setFileDataURL] = useState({});
    const [fileName, setFileName] = useState(null);
    const [extension, setExtension] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [blob, setblob] = useState(null);
    const [base64String, setBase64String] = useState(null);

    // dropdown usestate
    const [apiDropdownData, setApiDropdownData] = useState({});
    const [modifiedApiDropdownData, setModifiedApiDropdownData] = useState({});
    const [countryList, setCountryList] = useState(null);
    const [stateList, setStateList] = useState(null);
    const [cityList, setCityList] = useState(null);
    const [permanentCountryList, setPermanentCountryList] = useState(null);
    const [permanentStateList, setPermanentStateList] = useState(null);
    const [permanentCityList, setPermanentCityList] = useState(null);
    const [selStateList, setSelStateList] = useState(null)
    const [selPermanentStateList, setSelPermanentStateList] = useState(null)
    const [selCityList, setSelCityList] = useState(null)
    const [selPermanentCityList, setSelPermanentCityList] = useState(null)

    // Aadhaar usestate
    const [otp, setOtp] = useState('')
    const [isAadhaarVerified, setIsAadhaarVerified] = useState(false)
    const [aadhaarData, setAadhaarData] = useState(null)

    const [listOfSections, setListOfSections] = useState({})
    const inputRef = useRef();

    const Registerdob = sessionStorage.getItem("dateOfBirth");
    var RegisterMobile = sessionStorage.getItem("mobileNo");   
    var RegisterName = sessionStorage.getItem("name"); 
    var course = sessionStorage.getItem('CourseId')
    var category = sessionStorage.getItem('CategoryId')
    var lastActive = sessionStorage.getItem('lastActiveSection')
    var enquiry = sessionStorage.getItem('enquiryId')
    const loggedInUser = sessionStorage.getItem("loggedInUser");

    const [lastActiveSection, setLastActiveSec] = useState(null)
    const [CourseId, setCourseId] = useState(null)
    const [CategoryId, setCategoryId] = useState(null)
    // const [userName, setUserName] = useState(null)
    const [name, setName] = useState(null)
    const [enquiryLocation, setEnquiryLocation] = useState(null)
    const [enquiryId, setEnquiryId] = useState(0)

    let setDebId = 0
    let jobDataLength = 0

    // Set Intrested in and working flag
    useEffect(() => {
        if(data !== null && (loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))){
            // Set Interested in flag
            let sec = data.sections.find(section => section.sectionId === 7 && section.isActive === 1)
        
            if(sec){
                let fields = sec.fieldsList
                if(fields.length !== 0){
                    let intField = fields.find(f => f.fieldId === 52)
                    setInterested(intField.value)
                }
            }
            

            // set workMandatory Flag
            let personalSec = data.sections.find(section => section.sectionId === 2)
            let personalField = personalSec.fieldsList
            let workingStatus = personalField.find(field=> field.dbColumnName === 'WorkingStatus')
            if(workingStatus !== undefined){
                if(workingStatus.value === 'Employed'){
                    setWorkMandatoryFlag(1)
                }
            }
        }
    }, [data])

    useEffect(() => {
        if(props.debTokenData !== null){
            setDebToken(props.debTokenData)
        }
    }, [props])

    useEffect(() => {
        setLastActiveSec(parseInt(lastActive))
        setCourseId(parseInt(course))
        if(enquiry !== null){
            setEnquiryId(parseInt(enquiry))
        }else{
            setEnquiryId(0)
        }
        
        if(category !== null){
            setCategoryId(parseInt(category))
        }
        setName(RegisterName)
    },[sessionStorage.length > 0]) 

    useEffect(() => {
        if(location.state !== null){
            const { lastActiveSection, CategoryId, CourseId, enquiryId, name } = location.state;
            setLastActiveSec(lastActiveSection)
            setCourseId(CourseId)
            setEnquiryId(enquiryId)
            // setEnquiryLocation(enquiryId)
            setCategoryId(CategoryId)
            // setUserName(userName)
            setName(name)
        }else{
            let CatId = sessionStorage.getItem("CategoryId");
            setCategoryId(parseInt(CatId))
        }
    },[location.state])

    // on change of date
    const handleDateChange = (date, name, target, isMultipleFields) => {
        const d = dayjs(date)
        var t = new Date( date );
        let isError = false;
        let errorMessage = '';

        setTextError((prevTextError) => ({
            ...prevTextError,
            [name]: { isError, errorMessage },
        }));

        var formattedDate = dayjs(t).format("YYYY-MM-DD");
        // setSelectedDate(date);

        setSelectedDate(prevState => ({
            ...prevState,
            [name]: date
        }));

        setInputValue((prevInputValue) => ({
            ...prevInputValue,
            [name]: formattedDate
        }));

        setTableValue((prevInputValue) => ({
            ...prevInputValue,
            [name]: formattedDate
        }));

        if (formattedDate !== '') {
            if(isMultipleFields === 1){
                setListOfFields((prevListOfFields) => ({
                    ...prevListOfFields,
                    'sectionId': target.sectionId,
                    'fieldId': target.fieldId,
                    'sequenceId': tableRowSequence[target.sectionId],
                    'value': formattedDate,
                    "imageExtension": "",
                    "imageSize": "",
                    'fieldTypeId': target?.fieldTypeId,
                    "fieldRefType": "",
                    "fieldRefTypeId": 0,
                    "isDelete" : false,
                }));
            }else{
                setListOfFields((prevListOfFields) => ({
                    ...prevListOfFields,
                    'sectionId': target.sectionId,
                    'fieldId': target.fieldId,
                    'sequenceId': target.sequence,
                    'value': formattedDate,
                    "imageExtension": "",
                    "imageSize": "",
                    'fieldTypeId': target?.fieldTypeId,
                    "fieldRefType": "",
                    "fieldRefTypeId": 0,
                    "isDelete" : false,
                }));
            }
           
        }
            
    };

    useEffect(() => {
        if(props.activeSection !== null && !isNaN(props.activeSection) && props.activeSection !== 0){
            setId(parseInt(props.activeSection))
        }
    }, [props.activeSection]);
    

    // API to fetch formData
    useEffect(() => {
        const fetchData = async () => {
          try {
           const { data,loading,error } = await CallAPI(Form, 'POST', { 
                "unvId": univId,
                "categoryId": CategoryId,
                "courseId": CourseId,
                "enquiryId": enquiryId,
                "mobileNo": mobileNo,
                "dob": dateOfBirth,
                "name": name
            }
          ); 
            setData(data);
            setAdmissionFormId(data.admissionFormId)
            if(data.lastActiveSection === 0){
                let first = data.sections.find(section => section.sequence === 1 && section.parentSectionId === 0  && section.isActive === 1)
                setLastSaveSection(first.sectionId)
            }else{
                setLastSaveSection(data.lastActiveSection)
            }
            setTimeout(() => {
                setLoading(false); // Set loading state to false after a short delay
              }, 1000);
          } catch (error) {
            console.error('Error fetching data:', error);
            setTimeout(() => {
                setLoading(false); // Set loading state to false after a short delay
              }, 500);
          }
        };

        const parameters = [lastActiveSection, CourseId, CategoryId, enquiryId, name];

        if((loggedInUser === 'student' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) &&
            parameters.every(param => param !== null)){
            setLoading(true);
            fetchData();
        }
        
    }, [lastActiveSection, CourseId, CategoryId, enquiryId, name, loggedInUser]);


    // API for preview
    useEffect(() => {
        const fetchPreviewData = async () => {
            try {
             const { data,loading,error } = await CallAPI(formPreview, 'POST', { 
                    "unvId": univId,
                    "categoryId": String(CategoryId),
                    "previewToken": props.previewToken   
                }
            );
            if(data.length !== 0){ 
              setData(data);
              let firstSection = data.sections.find(section => section.sequence === 1 && section.parentSectionId === 0 && section.isActive === 1)
              setId(firstSection.sectionId)
            }
            } catch (error) {
              console.error('Error fetching data:', error);
            }
        };

        if((loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit') && CategoryId !== null && CategoryId !== NaN && props.previewToken !== ''){
            fetchPreviewData();
        }
    }, [CategoryId, loggedInUser, props.previewToken])


    // API for get Annexture
    useEffect(() => {
        const fetchData = async () => {
          try {
           const { data,loading,error } = await CallAPI(annexture, 'POST', { 
                "enquiryId": enquiry,
            }
          ); 
            setAnnextureData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        if((loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))){
            fetchData();
        }
        
    }, []);


    // API for debId token
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const { data,loading,error } = await CallAPI(debGenerateToken, 'POST', { 
    //                   "username" : "DigivUnv", 
    //                   "password" : "63a326d8-3943-4f27-b2f1-83770255282c"
    //              }
    //            ); 
    //            setDebToken(data.token)
                 
    //            } catch (error) {
    //              console.error('Error fetching data:', error);
                 
    //         }
    //     };

    //     if(loggedInUser === 'student' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')){
    //         fetchData();
    //     }
    // }, [])

    // useEffect(() => {
    //     if (data !== null) {
    //       data.sections.forEach((section) => {
    //         const imageFields = [];
    //         const otherFields = [];
      
    //         section.fieldsList.forEach((field) => {
    //           if (field.fieldType === 'Image') {
    //             imageFields.push(field.sequence);
    //           } else {
    //             otherFields.push(field.sequence);
    //           }
    //         });
      
    //         setAlignFieldList((prevFieldList) => ({
    //           ...prevFieldList,
    //           [section.sectionId]: {
    //             images: imageFields,
    //             otherFields: otherFields,
    //           },
    //         }));
    //       });
    //     }
    // }, [data]);

    // console.log('alignFieldList', alignFieldList)

    // const fileInput = useRef(null);
    // const [filename, setFilename] = useState("");

    // const handleFile = (event) => {
    //     const selectedFile = event.target.files[0];
    //     if (selectedFile) {
    //         setFilename(selectedFile.name);
    //     }
    // }

    const [imageUrl, setImage] = useState("");

    // to set image
    const handleImageChange = (name, sectionId, fieldId, rest, event) => {
        if(isAadhaarVerified === false){
        const selectedFile = event.target.files[0];
        let fileName = selectedFile.name
        let fileExtension = fileName.split('.').pop().toLowerCase()
        let type = selectedFile.type;
        let size = selectedFile.size;
        let validate = rest?.fieldValidations
        let validTypes = '';
        let validMaxSize = 0;
        let validMinSize = 0;
        let validMaxSizeUnit = '';
        let validMinSizeUnit = '';

        let isError = false;
        let errorMessage = '';

        if(selectedFile.type === 'image/png'){
            type = '.png'
        }else if(selectedFile.type === 'image/jpg'){
            type = '.jpg'
        }else if(selectedFile.type === 'image/jpeg'){
            type = '.jpeg'
        }

        validate.forEach(element => {
            switch (element.property) {
                case 'FileType':
                    validTypes = element.value.split(/,\s*|\./).filter(Boolean);
                    break;
                case 'MaxSize':
                    validMaxSize = parseFloat(element.value);
                    break;
                case 'MinSize':
                    validMinSize = parseFloat(element.value);
                    break;
                case 'MaxSizeUnit':
                    validMaxSizeUnit = element.value;
                    break;
                case 'MinSizeUnit':
                    validMinSizeUnit = element.value;
                    break;
                default:
                    break;
            }
        });

        const sizeUnits = {
            KB: 1024,
            MB: 1024 * 1024,
            GB: 1024*1024*1024
        };

        const minSize = validMinSize * (sizeUnits[validMinSizeUnit] || 1);      // Minimun Valid size 
        const maxSize = validMaxSize * (sizeUnits[validMaxSizeUnit] || 1);      // Maximum Valid size 



        // if(rest?.validationType === 'Image'){
            if((validTypes.includes(fileExtension)) && size >= minSize && size <= maxSize){
                setValidationError((prevState) => ({
                    ...prevState,
                    [rest?.fieldName] : 'Valid'
                }))

                // setFile(URL.createObjectURL(event.target.files[0]));
                setFile((prevState) => ({
                    ...prevState,
                    [rest?.fieldName]: URL.createObjectURL(event.target.files[0])
                }))
                const reader = new FileReader();
                    reader.onloadend = () => {
                    var baseString = reader.result.replace("data:", "").replace(/^.+,/, "");
                    setBase64String(baseString)

                    setInputValue((prevInputValue) => ({
                        ...prevInputValue,
                        [name]: baseString
                    }));
            
                    setListOfFields((prevListOfFields) => ({
                        ...prevListOfFields,
                        'sectionId': sectionId,
                        'fieldId': fieldId,
                        'sequenceId': 0,
                        'value': baseString,
                        'imageExtension': type,
                        'imageSize': size.toString(),
                        'fieldTypeId': rest?.fieldTypeId,
                        "fieldRefType": "",
                        "fieldRefTypeId": 0,
                        "isDelete" : false,
                    }))
                };
                reader.readAsDataURL(selectedFile);
                isError = false;
                errorMessage = '';
            }else{
                setValidationError((prevState) => ({
                    ...prevState,
                    [rest?.fieldName] : 'Invalid'
                }))

                if(!validTypes.includes(fileExtension)){
                    isError = true;
                    errorMessage = `Invalid filetype: ${fileExtension}`;
                }else if(size > maxSize){
                    isError = true;
                    errorMessage = `Maximum size is ${validMaxSize} ${validMaxSizeUnit}`;
                }else if(size < minSize){
                    isError = true;
                    errorMessage = `Minimum size is ${validMinSize} ${validMinSizeUnit}`;
                }else{
                    isError = true;
                    errorMessage = `Format: ${validTypes} (min size: ${validMinSize} ${validMinSizeUnit}, max size: ${validMaxSize} ${validMaxSizeUnit})`;
                }

                
            }
        // }
        // if (selectedFile) {
        //     setFile(URL.createObjectURL(event.target.files[0]));
        //     isError = false;
        //     errorMessage = '';
            
        //     // const reader = new FileReader();
        //     // reader.onload = () => {
        //     //     setImageUrl(reader.result);
        //     // };
        //     // reader.readAsDataURL(selectedFile);
        // }

        setTextError((prevTextError) => ({
            ...prevTextError,
            [name]: { isError, errorMessage },
        }));
    }
 
    };
    
    const handleFile = (fieldName, sectionId, fieldId, validationType, sequenceId, rest, event) => {
        const selectedFile = event.target.files[0];
        let fileName = selectedFile.name
        let fileExtension = fileName.split('.').pop()
        let fileSize = selectedFile.size
        let validate = rest?.fieldValidations
        let validTypes = '';
        let validMaxSize = 0;
        let validMinSize = 0;
        let validMaxSizeUnit = '';
        let validMinSizeUnit = '';

        // Validation properties
        validate.forEach(element => {
            switch (element.property) {
                case 'FileType':
                    validTypes = element.value.split(/,\s*|\./).filter(Boolean);
                    break;
                case 'MaxSize':
                    validMaxSize = parseFloat(element.value);
                    break;
                case 'MinSize':
                    validMinSize = parseFloat(element.value);
                    break;
                case 'MaxSizeUnit':
                    validMaxSizeUnit = element.value;
                    break;
                case 'MinSizeUnit':
                    validMinSizeUnit = element.value;
                    break;
                default:
                    break;
            }
        });

        const sizeUnits = {
            KB: 1000,
            MB: 1000000,
            GB: 1000000000
        };

        const minSize = validMinSize * (sizeUnits[validMinSizeUnit] || 1);      // Minimun Valid size 
        const maxSize = validMaxSize * (sizeUnits[validMaxSizeUnit] || 1);      // Maximum Valid size 

        let blobUrl = URL.createObjectURL(selectedFile)
        if((validTypes.includes(fileExtension)) && fileSize >= minSize && fileSize <= maxSize){

            setValidationError((prevState) => ({
                ...prevState,
                [fieldName] : 'Valid'
            }))

            const reader = new FileReader();
            reader.onload = async(e) => {
                setFile((prevState) => ({
                    ...prevState,
                    [fieldName]: selectedFile
                }))
                setblob((prevState) => ({
                    ...prevState,
                    [fieldName]: blobUrl
                }))
                setFileDataURL((prevState) => ({
                    ...prevState,
                    [fieldName]: e.target.result
                }));
                setFileName((prevState) => ({
                    ...prevState,
                    [fieldName]: selectedFile.name
                }))
                setExtension((prevState) => ({
                    ...prevState,
                    [fieldName]: selectedFile.type
                }))

                
            }
            reader.readAsDataURL(selectedFile);

            setInputValue((prevInputValue) => ({
                ...prevInputValue,
                [fieldName]: selectedFile
            }));
            
            if(inputValue[fieldName] !== null){
                const formData = new FormData();
                formData.append("formFile", selectedFile);

                // API to save document
                const fetchData = async() => {
                    const accessToken = await getAccessToken();
                    setButtonLoading((prevButton) => ({
                        [fieldName] : true,
                        'isDelete': false
                    }))
                    try {
                        const formData = new FormData();
                        formData.append("UnvId", parseInt(univId));
                        formData.append("EnquiryId", enquiryId);
                        formData.append("AdmissionFormId", admissionFormId);
                        formData.append("SectionId", sectionId);
                        formData.append("FieldId", fieldId);
                        formData.append("SequenceId", rest?.sequence);
                        formData.append("DocumentId", rest?.fieldDataSource);
                        formData.append("DocumentformFile", selectedFile);
                        formData.append("IsDelete", false);

                        const response = await fetch(DocumentSave, {
                            method: 'POST',
                            body: formData,
                            headers : {
                                'Authorization': `Bearer ${accessToken}`,
                            }
                        });
                        const data = await response.json();

                        // API of Enquiry
                        // If response ok API call to fetch latest data 
                        if (response.ok) {
                            try {
                                const { data,loading,error } = await CallAPI(Form, 'POST', { 
                                    "unvId": univId,
                                    "categoryId": CategoryId,
                                    "courseId": CourseId,
                                    "enquiryId": enquiryId,
                                    "mobileNo": mobileNo,
                                    "dob": dateOfBirth,
                                    "name": name
                                }); 
                                setData(data);
                                setLastSaveSection(data.lastActiveSection)
                            } catch (error) {
                                console.error('Error fetching data:', error);
                            }
                        }else{
                            throw new Error(data.message || 'Error fetching data');
                        }
                        setButtonLoading((prevButton) => ({
                            [fieldName] : false,
                            'isDelete': false
                        }))
                    }catch (error) {
                        console.error('Error fetching data:', error);
                    }
                }

                if(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')){
                    fetchData()
                }
                
            }

        }else{
            setValidationError((prevState) => ({
                ...prevState,
                [fieldName] : 'Invalid'
            }))
        }

    };

    // To Preview Document
    const documentPreview = (fieldName, value) => {
        if(value !== null || value !== ""){
        let extension = value.split('.').pop()      // Fetch the extension from url
        setIsModalOpen(true);
        setTitleMsg("");
        if(extension === 'pdf'){
            setContent(<iframe src={value} width={700} height={500} frameBorder="0"></iframe>)
        }else if(extension === 'doc' || extension === 'docx'){
            const encodedUrl = encodeURIComponent(value);
            const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
            setContent(<iframe src={googleDocsViewerUrl} width={700} height={500} frameBorder="0"></iframe>)
        }else if(extension === 'png' || extension === 'jpg' || extension === 'jpeg'){
            setContent(<iframe src={value} width={800} height={500} frameBorder="0"></iframe>)
        }
        setModalContentIdentity('documentPreview')
        }
    };

    // To remove uploaded document
    const removeUploadedFile = (fieldName, rest, value) => {

        let extension = value.split('.').pop() 
        let parts = value.split('.')
        let dummyFile = new File([extension], extension, { type: 'text/plain' });

        const fetchData = async() => {
            setButtonLoading((prevButton) =>({
                [fieldName]: true,
                'isDelete' : true
            }))
            const accessToken = await getAccessToken();

            try {
                const formData = new FormData();
                formData.append("UnvId", parseInt(univId));
                formData.append("EnquiryId", enquiryId);
                formData.append("SectionId", rest?.sectionId);
                formData.append("FieldId", rest?.fieldId);
                formData.append("AdmissionFormId", admissionFormId);
                formData.append("SequenceId", rest?.sequence);
                formData.append("DocumentId", rest?.fieldDataSource);
                formData.append("DocumentformFile", dummyFile);
                formData.append("IsDelete", true);

                const response = await fetch(DocumentSave, {
                    method: 'POST',
                    body: formData,
                    headers : {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (response.ok) {
                    try {
                        const { data,loading,error } = await CallAPI(Form, 'POST', { 
                            "unvId": univId,
                            "categoryId": CategoryId,
                            "courseId": CourseId,
                            "enquiryId": enquiryId,
                            "mobileNo": mobileNo,
                            "dob": dateOfBirth,
                            "name": name
                        }); 
                        setData(data);
                        setLastSaveSection(data.lastActiveSection)
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                }else{
                    throw new Error(data.message || 'Error fetching data');
                }

            }catch(error){
                console.log(error)
            }
            setButtonLoading((prevButton) =>({
                [fieldName]: false,
                'isDelete': false
            }))
            
        }
        if((loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))){
            fetchData();
        }
        
        setFile((prevState) => ({
            ...prevState,
            [fieldName]: null
        }))
        setFileName((prevState) => ({
            ...prevState,
            [fieldName]: null
        }))
        setExtension((prevState) => ({
            ...prevState,
            [fieldName]: null
        }))
        setValidationError((prevState) => ({
            ...prevState,
            [fieldName] : null
        }))
    }


    // Api to fetch country list
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const { data, loading, error } = await CallAPI(CountryList, 'GET', {});
    //             setCountryList(data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };

    //     fetchData();
    // }, [])

    // Api to fetch state list
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const { data, loading, error } = await CallAPI(StateList, 'GET', {'countryId' : 0});
    //             setStateList(data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };

    //     fetchData();
    // }, [])

    // Api to fetch city list
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const { data, loading, error } = await CallAPI(CityList, 'GET', {'stateId' : 0});
    //             setCityList(data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };

    //     fetchData();
    // }, [])

    const handleDropdownChange = (name, value, target) => {
        setInputValue((prevInputValue) => ({
            ...prevInputValue,
            [name]: value.toString()
        }));

        console.log(name, value, target.dbColumnName)

        const handleFilter = (dbColumnName, fieldName, dependentFieldName) => {
            let sec = data.sections.find(section => section.sectionId === target.sectionId)
            let field = sec.fieldsList.find(field => field.dbColumnName.toLowerCase() === fieldName)
            
            if(field){
                const filteredStates = apiDropdownData[field.fieldName].filter(state => state.valueId === value.toString());
                console.log('field.fieldName', field.fieldName)
                setModifiedApiDropdownData((prevModifiedApiData) => ({
                ...prevModifiedApiData,
                [field.fieldName]: filteredStates
                }));
                
                // If the filtered states array is empty, reset the dependent field to an empty array
                if (filteredStates.length === 0 && dependentFieldName) {
                    let dependentField = sec.fieldsList.find(field => field.dbColumnName.toLowerCase() === dependentFieldName)
                    console.log('dependentField.fieldName', dependentField.fieldName)
                    setModifiedApiDropdownData((prevModifiedApiData) => ({
                        ...prevModifiedApiData,
                        [dependentField.fieldName]: []
                    }));

                    setListOfFieldsArray((prevList) => ([
                        ...prevList,
                        {
                            'sectionId': dependentField.sectionId,
                            'fieldId': dependentField.fieldId,
                            'sequenceId': 0,
                            'value': '',
                            "imageExtension": "",
                            "imageSize": "",
                            "fieldRefType": "",
                            "fieldRefTypeId": 0,
                            "isDelete": false,
                        },
                        {
                            'sectionId': field.sectionId,
                            'fieldId': field.fieldId,
                            'sequenceId': 0,
                            'value': '',
                            "imageExtension": "",
                            "imageSize": "",
                            "fieldRefType": "",
                            "fieldRefTypeId": 0,
                            "isDelete": false,
                        }
                    ]));
                }



            //   setListOfFields((prevListOfFields) => ({
            //     ...prevListOfFields,
            //     'sectionId': dependentField.sectionId,
            //     'fieldId': dependentField.fieldId,
            //     'sequenceId': 0,
            //     'value': '',
            //     "imageExtension": "",
            //     "imageSize": "",
            //     "fieldRefType": "",
            //     "fieldRefTypeId": 0,
            //     "isDelete" : false,
            //     }))

            //     setListOfFields((prevListOfFields) => ({
            //     ...prevListOfFields,
            //     'sectionId': field.sectionId,
            //     'fieldId': field.fieldId,
            //     'sequenceId': 0,
            //     'value': '',
            //     "imageExtension": "",
            //     "imageSize": "",
            //     "fieldRefType": "",
            //     "fieldRefTypeId": 0,
            //     "isDelete" : false,
            //     }))
            }
        }

        setListOfFieldsArray((prevList) => ([
            ...prevList,
            {
                'sectionId': target.sectionId,
                'fieldId': target.fieldId,
                'sequenceId': 0,
                'value': value.toString(),
                "imageExtension": "",
                "imageSize": "",
                "fieldRefType": "",
                "fieldRefTypeId": 0,
                "isDelete": false,
            }
        ]));

        // setListOfFields((prevListOfFields) => ({
        //     ...prevListOfFields,
        //     'sectionId': target.sectionId,
        //     'fieldId': target.fieldId,
        //     'sequenceId': 0,
        //     'value': value.toString(),
        //     "imageExtension": "",
        //     "imageSize": "",
        //     "fieldRefType": "",
        //     "fieldRefTypeId": 0,
        //     "isDelete" : false,
        // }))
        
        let isError = false
        let errorMessage = ''

        setTextError((prevTextError) => ({
            ...prevTextError,
            [name]: { isError, errorMessage },
        }));

        // const handleFilter = (dbColumnName, fieldName) => {
        //     let sec = data.sections.find(section => section.sectionId === target.sectionId)
        //     let field = sec.fieldsList.find(field => field.dbColumnName.toLowerCase() === fieldName)

        //     const filteredStates = apiDropdownData[field.fieldName].filter(state => state.valueId === value.toString());
        //     setApiDropdownData((prevApiData) => ({
        //       ...prevApiData,
        //       [field.fieldName]: filteredStates
        //     }));
        // }

        switch (target.dbColumnName.toLowerCase()) {
            case 'countryid':
              handleFilter('countryid', 'stateid', 'cityid');
              break;
            case 'pcountryid':
              handleFilter('pcountryid', 'pstateid', 'pcityid');
              break;
            case 'stateid':
              handleFilter('stateid', 'districtid');
              handleFilter('stateid', 'cityid');
              break;
            case 'pstateid':
                handleFilter('stateid', 'pdistrictid');
                handleFilter('pstateid', 'pcityid');
                break;
            default:
              // handle other cases or do nothing
        }

        // if (target.dbColumnName.toLowerCase() === 'countryid') {
        //     let sec = data.sections.find(section => section.sectionId === target.sectionId)
        //     let field = sec.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'stateid')
        //     console.log(apiDropdownData[field.fieldName])
        //     const filteredStates = apiDropdownData[field.fieldName].filter(state => state.valueId === value.toString());
        //     setApiDropdownData((prevApiData) => ({
        //       ...prevApiData,
        //       [field.fieldName]: filteredStates
        //     }));
        // }

    }

    // on change of country
    // const handleDropdownChange = (name, value, target) => {
    //     setInputValue((prevInputValue) => ({
    //         ...prevInputValue,
    //         [name]: value.toString()
    //     }));

    //     let sec = data.sections.find(section => section.sectionId === target.sectionId)
    //     let stateField = ''
    //     let cityField = ''

    //     if(target.dbColumnName.toLowerCase() === 'pcountryid'){
    //         cityField = sec.fieldsList.find(f => f.dbColumnName.toLowerCase() === 'pcityid')
    //     }else{
    //         cityField = sec.fieldsList.find(f => f.dbColumnName.toLowerCase() === 'cityid')
    //     }

    //     if(target.dbColumnName.toLowerCase() === 'pcountryid'){
    //         stateField = sec.fieldsList.find(f => f.dbColumnName.toLowerCase() === 'pstateid')
    //     }else{
    //         stateField = sec.fieldsList.find(f => f.dbColumnName.toLowerCase() === 'stateid')
    //     }
        

    //     if(target.dbColumnName.toLowerCase() === 'pcountryid'){
    //         const countryKey = countryList.find(country => country.key === value);
    //         const states = stateList.filter(state => state.countryId === (countryKey ? countryKey.key : null));
    //         setSelPermanentStateList(states)
    //         setSelPermanentCityList([])
    //         setInputValue((prevInputValue) => ({
    //             ...prevInputValue,
    //             [cityField.fieldName]: '',
    //             [stateField.fieldName]: ''
    //         }));
    //     }else{
    //         const countryKey = countryList.find(country => country.key === value);
    //         const states = stateList.filter(state => state.countryId === (countryKey ? countryKey.key : null));
    //         setSelStateList(states)
    //         setSelCityList([])
    //         setInputValue((prevInputValue) => ({
    //             ...prevInputValue,
    //             [cityField.fieldName]: '',
    //             [stateField.fieldName]: ''
    //         }));

            
    //     }
    //     let list = [
    //         {
    //         'sectionId': cityField.sectionId ,
    //         'fieldId': cityField.fieldId ,
    //         'sequenceId': 0,
    //         'value': '',
    //         "imageExtension": "",
    //         "imageSize": "",
    //         "fieldRefType": "",
    //         "fieldRefTypeId": 0,
    //         "isDelete" : false,
    //         },
    //         {
    //             'sectionId': stateField.sectionId ,
    //             'fieldId': stateField.fieldId ,
    //             'sequenceId': 0,
    //             'value': '',
    //             "imageExtension": "",
    //             "imageSize": "",
    //             "fieldRefType": "",
    //             "fieldRefTypeId": 0,
    //             "isDelete" : false,
    //             }
    //     ]

    //     setListOfFieldsArray((prevList) => ([
    //         ...prevList,
    //         ...list
    //     ]))

    //     setListOfFields((prevListOfFields) => ({
    //         ...prevListOfFields,
    //         'sectionId': target.sectionId,
    //         'fieldId': target.fieldId,
    //         'sequenceId': 0,
    //         'value': value.toString(),
    //         "imageExtension": "",
    //         "imageSize": "",
    //         "fieldRefType": "",
    //         "fieldRefTypeId": 0,
    //         "isDelete" : false,
    //     }))
        
    //     let isError = false
    //     let errorMessage = ''

    //     setTextError((prevTextError) => ({
    //         ...prevTextError,
    //         [name]: { isError, errorMessage },
    //     }));

    // }

    const handleCityDropdown = (name, value, target) => {
        setInputValue((prevInputValue) => ({
            ...prevInputValue,
            [name]: value.toString()
        }));

        if(target.dbColumnName.toLowerCase() === 'pstateid'){
            const pStateKey = selPermanentStateList.find(state => state.key === value);
            const pCity = cityList.filter(city => city.stateId === (pStateKey ? pStateKey.key : null));
            setSelPermanentCityList(pCity)
        }else{
            const stateKey = selStateList.find(state => state.key === value);
            const city = cityList.filter(city => city.stateId === (stateKey ? stateKey.key : null));
            setSelCityList(city)
        }

        setListOfFields((prevListOfFields) => ({
            ...prevListOfFields,
            'sectionId': target.sectionId,
            'fieldId': target.fieldId,
            'sequenceId': 0,
            'value': value.toString(),
            "imageExtension": "",
            "imageSize": "",
            'fieldTypeId': target?.fieldTypeId,
            "fieldRefType": "",
            "fieldRefTypeId": 0,
            "isDelete" : false,
        }))

        let isError = false
        let errorMessage = ''

        setTextError((prevTextError) => ({
            ...prevTextError,
            [name]: { isError, errorMessage },
        }));
    }

    const handleCity = (name, value, target) => {
        let isError = false
        let errorMessage = ''

        if(value === ""){
            setTextError((prevTextError) => ({
                ...prevTextError,
                [name]: { isError, errorMessage },
            }));
        }

        setInputValue((prevInputValue) => ({
            ...prevInputValue,
            [name]: value.toString()
        }));

        setListOfFields((prevListOfFields) => ({
            ...prevListOfFields,
            'sectionId': target.sectionId,
            'fieldId': target.fieldId,
            'sequenceId': 0,
            'value': value.toString(),
            "imageExtension": "",
            "imageSize": "",
            'fieldTypeId': target?.fieldTypeId,
            "fieldRefType": "",
            "fieldRefTypeId": 0,
            "isDelete" : false,
        }))

    }

    const handlePermanentCityDropdown = (name, value) => {
        setInputValue((prevInputValue) => ({
            ...prevInputValue,
            [name]: value
        }));

        const stateKey = permanentStateList.find(state => state.value === value);
        const city = cityList.filter(city => city.stateId === (stateKey ? stateKey.key : null));
        setPermanentCityList(city)
    }

    // To load country, state, city dropdown on load
    // useEffect(() => {
    //     if(data !== null && stateList !== null && countryList !== null && cityList !== null){ 
    //         let sec = data.sections.find(section => section.sectionId === 3)
    //         var sectionList = ''
    //         var field = ''

    //         if (sec) {
    //             const hasLocationField = sec.fieldsList.some((element) => {
    //               return element.dbColumnName.includes('StateId') || element.dbColumnName.includes('CountryId') || element.dbColumnName.includes('CityId');
    //             });
              
    //             if (!hasLocationField) {
    //               let sec9 = data.sections.find(section => section.sectionId === 9);
    //               let sec10 = data.sections.find(section => section.sectionId === 10);
              
    //               if (sec9 && sec10) {
    //                 sec = sec9;
    //                 field = [...sec9.fieldsList, ...sec10.fieldsList];
    //               } else if (sec9) {
    //                 sec = sec9;
    //                 field = sec9.fieldsList;
    //               } else if (sec10) {
    //                 sec = sec10;
    //                 field = sec10.fieldsList;
    //               }
    //             } else {
    //               field = sec.fieldsList;
    //             }
    //           } else {
    //             let sec9 = data.sections.find(section => section.sectionId === 9);
    //             let sec10 = data.sections.find(section => section.sectionId === 10);
              
    //             if (sec9 && sec10) {
    //               sec = sec9;
    //               field = [...sec9.fieldsList, ...sec10.fieldsList];
    //             } else if (sec9) {
    //               sec = sec9;
    //               field = sec9.fieldsList;
    //             } else if (sec10) {
    //               sec = sec10;
    //               field = sec10.fieldsList;
    //             }
    //           }

    //         // if (sec) {
    //         //     const hasLocationField = sec.fieldsList.some((element) => {
    //         //       if(element.dbColumnName.includes('StateId') || element.dbColumnName.includes('CountryId') || element.dbColumnName.includes('CityId')){
    //         //         field = sec.fieldsList
    //         //       };
    //         //     });
          
    //         //     if (!hasLocationField) {
    //         //       sec = data.sections.find(section => section.sectionId === 9)
    //         //       field = sec.fieldsList
    //         //     }
    //         // } else {
    //         //     sec = data.sections.find(section => section.sectionId === 9)
    //         //     field = sec.fieldsList
    //         // }
            

    //         field.forEach((f) => {
    //             // f.forEach((element) => {
    //                 if(f.dbColumnName === 'CountryId'){
    //                     let cid = f.value
    //                     const states = stateList.filter(state => state.countryId === parseInt(cid));
    //                     setSelStateList(states);
                        
    //                 }if(f.dbColumnName === 'StateId'){
    //                     let sid = f.value
    //                     const city = cityList.filter(city => city.stateId === parseInt(sid));
    //                     setSelCityList(city)
    //                 }if(f.dbColumnName === 'PCountryId'){
    //                     let pcid = f.value
    //                     const pstates = stateList.filter(state => state.countryId === parseInt(pcid));
    //                     setSelPermanentStateList(pstates)
    //                 }if(f.dbColumnName === 'PStateId'){
    //                     let psid = f.value
    //                     const pcity = cityList.filter(city => city.stateId === parseInt(psid));
    //                     setSelPermanentCityList(pcity)
    //                 }
    //             // })
                
    //         })
    //     }
    // },[data, stateList, countryList])

    // Set university flags
    useEffect(() => {
        if(data !== null && (loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))){
            let univ = data.universityFlags.find(univFlag => univFlag.Key === 'AadhaarKYCEnabled')
            let fullname = data.universityFlags.find(univFlag => univFlag.Key === 'FullNameApplicable')
            let exam = data.universityFlags.find(examFlag => examFlag.Key === 'ExamDetailMandatory')
            let debEnable = data.universityFlags.find(debFlag => debFlag.Key === 'DEBEnabled')
            setKycFlag(univ.Value)
            setFullNameFlag(fullname.Value)
            setExamMandatory(exam.Value)
            setDebEnable(debEnable.value)
        }

    }, [data])

    
    let uuId = ''
    let aadhaarOtp = ''
    let aadhaarMessage = ''
    const verifyAadhaar = async () => {
        if(isAadhaarVerified === false){
            // setLoading(true)
            // setButtonLoading(true)
            let sec = data.sections.find(section => section.sectionId === 2)
            let field = sec.fieldsList.find(f => f.dbColumnName === 'AadharNo')

            setButtonLoading((prevButton) => ({
                [field.fieldName] : true,
                'isDelete': false
            }))
            let addhar = inputValue[field.fieldName]
            if(aadhaarData !== undefined || aadhaarData !== null || aadhaarData !== ''){
                aadhaarNumber = aadhaarData
            } else if(addhar !== undefined || addhar !== ''){
                aadhaarNumber = addhar
            } 
        
            try{
                const response = await fetch(enterAadhaar, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ "aadhaar": aadhaarNumber}),
                });
                if(response.ok){
                    const kycResponse = await response.json();
                    // setLoading(false)
                    setButtonLoading((prevButton) => ({
                        [field.fieldName] : true,
                        'isDelete': false
                    }))
                    if(kycResponse.response_status.code === 0 && kycResponse.response_status.status === 'SUCCESS'){
                        uuId = kycResponse.response_data.uuId

                        aadhaarModel(false)
                    }else if(kycResponse.response_status.status === 'FAIL'){
                        let isError = true
                        let errorMessage = kycResponse.response_status.message
                        await updateTextError(field.fieldName, { isError, errorMessage });
                    }
                }
            }catch(error){
                console.log(error)
            }
        }
    }

    const aadhaarModel = (buttonDisabled) =>{
        setIsModalOpen(true)
        setTitleMsg('Aadhaar Verification')
        setContent(
            <Box sx={{mt:1}}>
                {/* <hr/> */}
                <Typography
                    sx={{
                        color: '#5aa729',
                        fontFamily: 'Open Sauce Sans',
                        fontSize: '16px'
                    }}
                >
                    A One-Time password has been sent to your registered mobile number
                </Typography>
                <Box sx={{mb:5}}>
                    <Box sx={{mt:5}}></Box>
                    <InputLabel sx={{mt: 3}}>Enter OTP</InputLabel>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <TextField
                            className="formInputField"
                            sx={{width: '80%'}}
                            onChange={(e) => handleOtp(e.target.value)}
                            InputProps={{
                                sx: {
                                '& input': {
                                    textAlign: 'center',
                                    // Add other custom styles here
                                },
                                },
                            }}
                        />
                        <RefreshIcon 
                            sx={{ color: '#185ABD', mt: 1, ml: 1, mr: 1 }} 
                            onClick={resendOtp}
                        />
                    </Box>
                    <Typography
                        sx={{
                            color: 'red',
                            fontFamily: 'Open Sauce Sans',
                            fontSize: '16px'
                        }}
                    >{aadhaarMessage}</Typography>
                </Box>
                <Button sx={{
                    textAlign: 'center', 
                    // background: '#5aa729',
                    color: '#fff',
                    marginTop:  '3%',
                    marginBottom: '2%',
                    '&:hover': {
                        background: '#538a30',
                    },
                    textTransform: 'none',
                    mt:5,
                    fontFamily: 'Open Sauce Sans',
                    background: 'linear-gradient(119.54deg, #000046 0, #1cb5e0 100%)',
                    '&:hover': {
                        background: 'linear-gradient(119.54deg, #000046 0, #1cb5e0 100%)', 
                    }
                }}
                onClick={() => {
                    varifyOtp();
                    aadhaarModel(true);
                }}
                disabled={buttonDisabled}
                >
                    {loading ? 
                    <CircularProgress
                        size={24}
                        sx={{
                        color: '#fff',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        }}
                    /> : 'Verify'}
                </Button>
            </Box> 
        )
        setModalContentIdentity('Verification')
    }

    // Set aadhaar verification otp
    const handleOtp = (otpVal) => {
        setOtp(parseInt(otpVal));
        aadhaarOtp = otpVal
    }

    // Resend otp for aadhaar verification
    const resendOtp = async() => {
        setOtp('')
        try{
            const response = await fetch('https://uatapi.teamleaseedtech.com/api/kyc/v2/enteraadhaar', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "aadhaar": aadhaarNumber}),
            });
            if(response.ok){
                const refreshResponse = await response.json();
                if(refreshResponse.response_status.status === 'SUCCESS'){
                    console.log('otp resend')
                }else if(refreshResponse.response_status.status === 'FAIL'){
                    aadhaarMessage = refreshResponse.response_status.message
                    return aadhaarMessage;
                }
            }
        }catch(error){
            console.log(error)
        }
    }

    const varifyOtp = async () => {
        // setLoading(true)
        setButtonLoading(true)
        try{
            const response = await fetch(enterOtp, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "uuid": uuId, "otp": aadhaarOtp}),
            });
            if(response.ok){
                const verifyResponse = await response.json();
                setLoading(false);
                setButtonLoading(false)
                if(verifyResponse.response_status.status === 'SUCCESS'){
                    let sec = data.sections.find(section => section.sectionId === 2)
                    let field = sec.fieldsList.find(f => f.dbColumnName === 'AadharNo')

                    setIsModalOpen(false)
                    setIsAadhaarVerified(true)
                    prefillAadhaarData(verifyResponse.response_data)

                    let isError = false
                    let errorMessage = ''

                    await updateTextError(field.fieldName, { isError, errorMessage });

                }else if(verifyResponse.response_status.status === 'FAIL'){
                    setIsAadhaarVerified(false)
                    aadhaarMessage = verifyResponse.response_status.message

                    setIsModalOpen(true)
                    setTitleMsg('Aadhaar Verification')
                    setContent(
                        <Box sx={{mt:1}}>
                            {/* <hr/> */}
                            <Typography
                                sx={{
                                    color: '#5aa729',
                                    fontFamily: 'Open Sauce Sans',
                                    fontSize: '16px'
                                }}
                            >
                                A One-Time password has been sent to your registered mobile number
                            </Typography>
                            <Box sx={{mb:5}}>
                                <Box sx={{mt:5}}></Box>
                                <InputLabel sx={{mt: 3}}>Enter OTP</InputLabel>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    <TextField
                                        className="formInputField"
                                        sx={{width: '80%'}}
                                        onChange={(e) => handleOtp(e.target.value)}
                                        InputProps={{
                                            sx: {
                                            '& input': {
                                                textAlign: 'center',
                                                // Add other custom styles here
                                            },
                                            },
                                        }}
                                    />
                                    <RefreshIcon 
                                        sx={{ color: '#185ABD', mt: 1, ml: 1, mr: 1 }} 
                                        onClick={resendOtp}
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        color: 'red',
                                        fontFamily: 'Open Sauce Sans',
                                        fontSize: '16px'
                                    }}
                                >{aadhaarMessage}</Typography>
                            </Box>
                            <Button sx={{
                                textAlign: 'center', 
                                // background: '#5aa729',
                                color: '#fff',
                                marginTop:  '3%',
                                marginBottom: '2%',
                                '&:hover': {
                                    background: '#538a30',
                                },
                                textTransform: 'none',
                                mt:5,
                                fontFamily: 'Open Sauce Sans',
                                background: 'linear-gradient(119.54deg, #000046 0, #1cb5e0 100%)',
                            }}
                            onClick={varifyOtp}
                            >Verify</Button>
                        </Box> 
                    )
                    setModalContentIdentity('Verification')
                }
                
            }
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() =>{
        if(data !== null && (loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))){
            let sec = data.sections.find(section => section.sectionId === 2)
            let aadhaarField = sec.fieldsList.find(f => f.dbColumnName.toLowerCase() === 'aadharno')
            let debField = sec.fieldsList.find(f => f.dbColumnName.toLowerCase() === 'debid')

            if(aadhaarField !== undefined && aadhaarField.value !== ''){
                setIsAadhaarVerified(true)
            } if(debField !== undefined && debField.value !== ''){
                setIsDebVerified(true)
            }
        }
    },[data])

    const prefillAadhaarData = (aadhaarData1) => {
        // fetch feilds of personal detail section
        let section = data.sections.find(section => section.sectionId === 2);
        
        var dobField = section.fieldsList.find(field => field.dbColumnName === 'DOB')
        var genderField = section.fieldsList.find(field => field.dbColumnName === 'Gender')
        var photoField = section.fieldsList.find(field => field.dbColumnName === 'Photo')

        //  fetch fields of corresponding detail section
        let sec = data.sections.find(s=>s.sectionId === 9)
        let cityField = sec.fieldsList.find(f=>f.dbColumnName === 'CityId')
        let stateField = sec.fieldsList.find(f=>f.dbColumnName === 'StateId')
        let countryField = sec.fieldsList.find(f=>f.dbColumnName === 'CountryId')
        let pincodeField = sec.fieldsList.find(f=>f.dbColumnName === 'Pincode')
        let address1Field = sec.fieldsList.find(f=>f.dbColumnName === 'AddressLine1')
        let address2Field = sec.fieldsList.find(f=>f.dbColumnName === 'AddressLine2')

        

        // Convert base64 to file
        let base64String = aadhaarData1.doc_face;
        const mimeType = 'image/jpeg'; // adjust the mime type according to your image type
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: mimeType });
        setFile(URL.createObjectURL(file));
        let imageFileType = file.type; 
        let imageFileSize = file.size;


        let address = aadhaarData1.address.split(",").splice(-4)        // fetch city, state, country and pincode
        let addressLine = aadhaarData1.address.split(",").splice(0,2)   // fetch adress line1 and address line2

        const countryKey = countryList.find(country => country.value.toLowerCase() === address[2].toLowerCase()).key;
        const statek = stateList.find(state => state.value.toLowerCase() === address[1].toLowerCase()).key;
        const cityk = cityList.find(city => city.value.toLowerCase() === address[0].toLowerCase()).key;

        // Set state list
        const states = stateList.filter(state => state.countryId === (countryKey ? countryKey.key : null));
        setSelStateList(states)

        // Set city list
        const city = cityList.filter(city => city.stateId === (statek ? statek.key : null));
        setSelCityList(city)

        // Format dob
        let [day, month, year] = aadhaarData1.dob.split("/");
        let formattedDob = `${year}-${month}-${day}`;

        // set input values
        setInputValue((prevInputValue) => ({
            ...prevInputValue,
            // [firstField?.fieldName]: firstName !== null && firstName !== undefined ? firstName : "",
            // [middleField?.fieldName]: middleName !== null && middleName !== undefined ? middleName : "",
            // [lastField?.fieldName]: lastName !== null && lastName !== undefined ? lastName : "",
            [dobField?.fieldName]: formattedDob,
            [genderField?.fieldName]: aadhaarData1.gender,
            [photoField?.fieldName]: aadhaarData1.doc_face,
            [cityField?.fieldName]: cityk.toString(),
            [stateField?.fieldName]: statek.toString(),
            [countryField?.fieldName]: countryKey.toString(),
            [pincodeField?.fieldName]: address[3],
            [address1Field?.fieldName]: addressLine[0],
            [address2Field?.fieldName]: addressLine[1]
        }))

        // set into list to save
        let fieldsToSave = [
            // { 'sectionId': 2, fieldId: firstField?.fieldId, 'sequenceId': 0, value: firstName !== null && firstName !== undefined ? firstName : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            // { 'sectionId': 2, fieldId: middleField?.fieldId, 'sequenceId': 0, value: middleName !== null && middleName !== undefined ? middleName : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            // { 'sectionId': 2, fieldId: lastField?.fieldId, 'sequenceId': 0, value: lastName !== null && lastName !== undefined ? lastName : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            { 'sectionId': 2, fieldId: dobField?.fieldId, 'sequenceId': 0, value: formattedDob !== null && formattedDob !==undefined ? formattedDob : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            { 'sectionId': 2, fieldId: genderField?.fieldId, 'sequenceId': 0, value: aadhaarData1.gender !== null && aadhaarData1.gender !== undefined ? aadhaarData1.gender : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            { 'sectionId': 2, fieldId: photoField?.fieldId, 'sequenceId': 0, value: aadhaarData1.doc_face !== null && aadhaarData1.doc_face !== undefined ? aadhaarData1.doc_face : "", "imageExtension": imageFileType, "imageSize": imageFileSize.toString(), "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            
            { 'sectionId': 9, fieldId: cityField?.fieldId, 'sequenceId': 0, value: cityk !== null && cityk !== undefined ? cityk.toString() : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            { 'sectionId': 9, fieldId: stateField?.fieldId, 'sequenceId': 0, value: statek !== null && statek !== undefined ? statek.toString() : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            { 'sectionId': 9, fieldId: countryField?.fieldId, 'sequenceId': 0, value: countryKey !== null && countryKey !== undefined ? countryKey.toString() : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            { 'sectionId': 9, fieldId: pincodeField?.fieldId, 'sequenceId': 0, value: address[3] !== null && address[3] !== undefined ? address[3] : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            { 'sectionId': 9, fieldId: address1Field?.fieldId, 'sequenceId': 0, value: addressLine[0] !== null && addressLine[0] !== undefined ? addressLine[0] : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            { 'sectionId': 9, fieldId: address2Field?.fieldId, 'sequenceId': 0, value: addressLine[1] !== null && addressLine[1] !== undefined ? addressLine[1] : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
        ]
        
        setListOfFieldsArray(fieldsToSave);

        if(fullNameFlag === 0){
            var firstField = section.fieldsList.find(field => field.dbColumnName === 'Firstname')
            var middleField = section.fieldsList.find(field => field.dbColumnName === 'Middlename')
            var lastField = section.fieldsList.find(field => field.dbColumnName === 'Lastname')

            var nameParts = aadhaarData1.name.split(" ");
        
            // Access the parts of the name
            if(nameParts.length === 3){
                var firstName = nameParts[0];
                var middleName = nameParts[1];
                var lastName = nameParts[2];
            }

            // set input values
            setInputValue((prevInputValue) => ({
                ...prevInputValue,
                [firstField?.fieldName]: firstName !== null && firstName !== undefined ? firstName : "",
                [middleField?.fieldName]: middleName !== null && middleName !== undefined ? middleName : "",
                [lastField?.fieldName]: lastName !== null && lastName !== undefined ? lastName : "",
            }))

            let fieldsToSave = [
                { 'sectionId': 2, fieldId: firstField?.fieldId, 'sequenceId': 0, value: firstName !== null && firstName !== undefined ? firstName : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
                { 'sectionId': 2, fieldId: middleField?.fieldId, 'sequenceId': 0, value: middleName !== null && middleName !== undefined ? middleName : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
                { 'sectionId': 2, fieldId: lastField?.fieldId, 'sequenceId': 0, value: lastName !== null && lastName !== undefined ? lastName : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            ]   
            
            setListOfFieldsArray((prevList) => [...prevList, ...fieldsToSave]);
        } else if(fullNameFlag === 1){
            var firstField = section.fieldsList.find(field => field.dbColumnName === 'Firstname')

            setInputValue((prevInputValue) => ({
                ...prevInputValue,
                [firstField?.fieldName]: aadhaarData1.name !== null && aadhaarData1.name !== undefined ? aadhaarData1.name : "",
            }))

            let fieldsToSave = [
                { 'sectionId': 2, fieldId: firstField?.fieldId, 'sequenceId': 0, value: aadhaarData1.name !== null && aadhaarData1.name !== undefined ? aadhaarData1.name : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
            ]   
            
            setListOfFieldsArray((prevList) => [...prevList, ...fieldsToSave]);
        }

    }

    const verifyDebId = async (sectionId) => {
        let sec = data.sections.find(section => section.sectionId === sectionId)
        let field = sec.fieldsList.find(f => f.dbColumnName.toLowerCase() === 'debid')

        let deb = inputValue[field.fieldName]
        let debNumber = 0
        let isError = false;
        let errorMessage = '';

        if(setDebId !== undefined || setDebId !== null || setDebId !== ''){
            debNumber = setDebId
        } else if(deb !== undefined || deb !== ''){
            debNumber = setDebId
        }

        try{
            const response = await fetch(debIdDetails, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${debToken}`,
                },
                body: JSON.stringify({ "DEBId": debNumber, "UnvId": parseInt(univId)}),
            });
            if(response.ok){
                const debResponse = await response.json();
                if(debResponse.isSuccess === true){
                    let details = debResponse.resource[0]

                    let section = data.sections.find(sec => sec.sectionId === 2)

                    var dobField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'dob')
                    var genderField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'gender')
                    var mobileField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'mobileno')
                    var emailFeild = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'emailid' )

                    let [day, month, year] = details.dob.split("/");
                    let formattedDob = `${year}-${month}-${day}`;
                    let gender = ''

                    if(details.gender === 'Male'){
                        gender = 'M'
                    }else if(details.gender === 'Female'){
                        gender = 'F'
                    }else if(details.gender === 'Transgender'){
                        gender = 'T'
                    }else if(details.gender === 'Others'){
                        gender = 'O'
                    }

                    setInputValue((prevInputValue) => ({
                        ...prevInputValue,
                        [dobField?.fieldName]: formattedDob,
                        [genderField?.fieldName]: gender,
                        [mobileField?.fieldName] : details.mobile,
                        [emailFeild?.fieldName]: details.email,
                    }))

                    let fieldsToSave = [
                        { 'sectionId': 2, fieldId: dobField?.fieldId, 'sequenceId': 0, value: formattedDob !== null && formattedDob !==undefined ? formattedDob : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
                        { 'sectionId': 2, fieldId: genderField?.fieldId, 'sequenceId': 0, value: gender !== null && gender !== undefined ? gender : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
                        { 'sectionId': 2, fieldId: mobileField?.fieldId, 'sequenceId': 0, value: details.mobile !== null && details.mobile !== undefined ? details.mobile : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
                        { 'sectionId': 2, fieldId: emailFeild?.fieldId, 'sequenceId': 0, value: details.email !== null && details.email !== undefined ? details.email : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
                    ]            

                    setListOfFieldsArray(fieldsToSave);

                    if(fullNameFlag === 0){
                        var firstField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'firstname')
                        var middleField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'middlename')
                        var lastField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'lastname')
                        
                        var nameParts = details.stdname.split(" ");
        
                        // Access the parts of the name
                        if(nameParts.length === 3){
                            var firstName = nameParts[0];
                            var middleName = nameParts[1];
                            var lastName = nameParts[2];
                        }

                        // set input values
                        setInputValue((prevInputValue) => ({
                            ...prevInputValue,
                            [firstField?.fieldName]: firstName !== null && firstName !== undefined ? firstName : "",
                            [middleField?.fieldName]: middleName !== null && middleName !== undefined ? middleName : "",
                            [lastField?.fieldName]: lastName !== null && lastName !== undefined ? lastName : "",
                        }))

                        let fieldsToSave = [
                            { 'sectionId': 2, fieldId: firstField?.fieldId, 'sequenceId': 0, value: firstName !== null && firstName !== undefined ? firstName : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
                            { 'sectionId': 2, fieldId: middleField?.fieldId, 'sequenceId': 0, value: middleName !== null && middleName !== undefined ? middleName : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
                            { 'sectionId': 2, fieldId: lastField?.fieldId, 'sequenceId': 0, value: lastName !== null && lastName !== undefined ? lastName : "", "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
                        ]   
                        
                        setListOfFieldsArray((prevList) => [...prevList, ...fieldsToSave]);
                    } else if(fullNameFlag === 1){
                        var firstField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'firstname')

                        setInputValue((prevInputValue) => ({
                            ...prevInputValue,
                            [firstField?.fieldName]: details.stdname,
                        }))

                        let fieldsToSave = [
                            { 'sectionId': 2, fieldId: firstField?.fieldId, 'sequenceId': 0, value: details.stdname, "imageExtension": "", "imageSize": "", "fieldRefType": "", "fieldRefTypeId": 0, "isDelete" : false,},
                        ]   
                        
                        setListOfFieldsArray((prevList) => [...prevList, ...fieldsToSave]);
                    }

                    setIsDebVerified(true)
                
                }else if(debResponse.isSuccess === false){
                    isError = true
                    errorMessage = debResponse.message

                    await updateTextError(field.fieldName, { isError, errorMessage });
                }
            }else{
                console.log(response)
            }
        }  catch(error){
            console.log(error)
        }  
        // finally{
        //     setIsDebVerified(true)
        // }
    }

    const handleCheckboxChange = (fieldName, target, event) => {
        setChecked((prevChecked) => ({
            ...prevChecked,
            [fieldName]: event.target.checked
        }));

        if(target.fieldRefType === 'SubjectId'){
            if(event.target.checked){
                // Add to list
                setListOfFieldsArray((prevList) => [
                    ...prevList,
                    {
                        'sectionId': target.sectionId,
                        'fieldId': target.fieldId,
                        'sequenceId': 0,
                        'value': target.fieldRefTypeId.toString(),
                        "imageExtension": "",
                        "imageSize": "",
                        "fieldRefType": target.fieldRefType,
                        "fieldRefTypeId": target.fieldRefTypeId,
                        "isDelete" : false,
                    }
                ]);
            } else if(event.target.checked === false && target.value !== '' ){
                setListOfFieldsArray((prevList) => [
                    ...prevList,
                    {
                        'sectionId': target.sectionId,
                        'fieldId': target.fieldId,
                        'sequenceId': 0,
                        'value': '',
                        "imageExtension": "",
                        "imageSize": "",
                        "fieldRefType": target.fieldRefType,
                        "fieldRefTypeId": target.fieldRefTypeId,
                        "isDelete" : false,
                    }
                ]);
            } else {
                // Remove from list
                setListOfFieldsArray((prevList) => prevList.filter(field => field.fieldId !== target.fieldId));
            }
        }

    }
      
    const handleCheckListChange = (field, key, value, event) => {
        const { checked } = event.target;
      
        setCheckedItems((prevCheckedItems) => ({
          ...prevCheckedItems,
          [field.fieldName]: {
            ...prevCheckedItems[field.fieldName],
            [key]: checked,
          },
        }));

        setLatestField({ field, key });
      
    };

    useEffect(() => {
        const { field, key } = latestField;
        if (!field || !key) return; // add a guard clause to avoid undefined errors

        const checklist = checkedItems[field.fieldName];

        if (checklist) {
            const checkedKeys = Object.keys(checklist).filter((k) => checklist[k]);

            if (checkedKeys.length > 0) {
            // If the checkbox is checked, add the key to listOfFieldsArray
            const existingFieldIndex = listOfFieldsArray.findIndex(
                (item) =>
                item.sectionId === field.sectionId &&
                item.fieldId === field.fieldId &&
                item.sequenceId === 0
            );

            if (existingFieldIndex !== -1) {
                const existingField = listOfFieldsArray[existingFieldIndex];
                existingField.value = [...existingField.value.split(","), key].join(",");
                setListOfFieldsArray([...listOfFieldsArray]);
            } else {
                // Add the field to listOfFieldsArray if it doesn't exist
                let fieldsToSave = [
                {
                    sectionId: field.sectionId,
                    fieldId: field.fieldId,
                    sequenceId: 0,
                    value: key,
                    imageExtension: "",
                    imageSize: "",
                    fieldRefType: "",
                    fieldRefTypeId: 0,
                    isDelete: false,
                },
                ];

                setListOfFieldsArray((prevList) => [...prevList, ...fieldsToSave]);
            }
            } else {
            // If the checkbox is unchecked, remove the key from listOfFieldsArray
            const existingFieldIndex = listOfFieldsArray.findIndex(
                (item) =>
                item.sectionId === field.sectionId &&
                item.fieldId === field.fieldId &&
                item.sequenceId === 0
            );

            if (existingFieldIndex !== -1) {
                const existingField = listOfFieldsArray[existingFieldIndex];
                const newValue = existingField.value
                .split(",")
                .filter((item) => item !== key)
                .join(",");
                existingField.value = newValue;
                setListOfFieldsArray([...listOfFieldsArray]);
            }
            }
        }
    }, [checkedItems,  latestField]);

    const inputRefs = {};
    const textareaRef = useRef(null);

    const dataLoadedRef = useRef(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    // set dropdown list
    useEffect(() => {
        if (dataLoadedRef.current) return;
        if (data === null) return;

        if(data !== null ){
            data.sections.forEach(section => {
                section.fieldsList.forEach(field => {
                    if(field.fieldDataSourceType === 'API'){
                        if(field.fieldDataSource !== ''){
                        CallAPI(`${apiRootUrl}${field.fieldDataSource}`, 'GET', {'ChildDataId' : 0,}).then(({ data, loading, error }) => {
                            setApiDropdownData(prevApiData => ({
                                ...prevApiData,
                                [field.fieldName]: data
                            }))

                            setModifiedApiDropdownData(prevApiData => ({
                                ...prevApiData,
                                [field.fieldName]: data
                            }))

                            const fieldsdbNames = ['stateid', 'pstateid', 'districtid', 'pdistrictid', 'cityid', 'pcityid'];
                            let allFieldsHaveValue = true;

                            fieldsdbNames.forEach((fieldName) => {
                            if (field.dbColumnName.toLowerCase() === fieldName && field.value === '') {
                                    allFieldsHaveValue = false;
                                }
                            });

                            if(!allFieldsHaveValue){
                                setModifiedApiDropdownData(prevApiData => ({
                                    ...prevApiData,
                                    [field.fieldName]: data
                                }))
                            }
                            // setIsDataLoaded(true)
                            // else{
                            //     if(field.dbColumnName.toLowerCase() === 'stateid'){
                            //         let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'countryid')
                                    
                            //         if(dependencyField.value !== '' && dependencyField.value !== undefined){
                            //             let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)
                                        
                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: filteredlist
                            //             }))
                            //         }else{
                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: []
                            //             }))
                            //         }
                            //     }
                            //     if(field.dbColumnName.toLowerCase() === 'pstateid'){
                            //         let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'pcountryid')

                            //         if(dependencyField.value !== '' && dependencyField.value !== undefined){
                            //             let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)

                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: filteredlist
                            //             }))
                            //         }else{
                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: []
                            //             }))
                            //         }
                            //     }
                            //     if(field.dbColumnName.toLowerCase() === 'districtid'){
                            //         let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'stateid')
                            //         console.log('dependencyField', dependencyField)
                            //         if(dependencyField.value !== '' && dependencyField !== undefined){
                            //             console.log(apiDropdownData)
                            //             console.log(apiDropdownData[field.fieldName])
                            //             console.log(apiDropdownData[field.fieldName].filter(state => state.valueId === '25'))
                            //             let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)
                            //             console.log(filteredlist)
                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: filteredlist
                            //             }))
                            //         }else{
                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: []
                            //             }))
                            //         }
                            //     }
                            //     if(field.dbColumnName.toLowerCase() === 'pdistrictid'){
                            //         let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'pstateid')

                            //         if(dependencyField.value !== '' && dependencyField !== undefined){
                            //             let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)

                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: filteredlist
                            //             }))
                            //         }else{
                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: []
                            //             }))
                            //         }
                            //     }
                            //     if(field.dbColumnName.toLowerCase() === 'cityid'){
                            //         let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'stateid')

                            //         if(dependencyField.value !== '' && dependencyField !== undefined){
                            //             let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)

                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: filteredlist
                            //             }))
                            //         }else{
                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: []
                            //             }))
                            //         }
                            //     }
                            //     if(field.dbColumnName.toLowerCase() === 'pcityid'){
                            //         let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'pstateid')

                            //         if(dependencyField.value !== '' && dependencyField !== undefined){
                            //             let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)

                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: filteredlist
                            //             }))
                            //         }else{
                            //             setModifiedApiDropdownData(prevApiData => ({
                            //                 ...prevApiData,
                            //                 [field.fieldName]: []
                            //             }))
                            //         }
                            //     }
                            // }
                           
                        }).catch(error => {
                            console.error('Error fetching data:', error);
                        });
                        }
                    }
                })
            });
        }
    },[data])

    useEffect(() => {
        if(apiDropdownData){
            if(data !== null ){
                data.sections.forEach(section => {
                    section.fieldsList.forEach(field => {
                        if(field.dbColumnName.toLowerCase() === 'stateid'){
                            let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'countryid')
                            
                            if(dependencyField !== undefined && dependencyField.value !== '' ){
                                if(apiDropdownData[field.fieldName]){
                                    let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)
                                    
                                    setModifiedApiDropdownData(prevApiData => ({
                                        ...prevApiData,
                                        [field.fieldName]: filteredlist
                                    }))
                                }
                            }else if(dependencyField !== undefined){
                                setModifiedApiDropdownData(prevApiData => ({
                                    ...prevApiData,
                                    [field.fieldName]: []
                                }))
                            }
                        }
                        if(field.dbColumnName.toLowerCase() === 'pstateid'){
                            let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'pcountryid')

                            if(dependencyField !== undefined && dependencyField.value !== ''){
                                if(apiDropdownData[field.fieldName]){
                                    let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)

                                    setModifiedApiDropdownData(prevApiData => ({
                                        ...prevApiData,
                                        [field.fieldName]: filteredlist
                                    }))
                                }
                            }else if(dependencyField !== undefined){
                                setModifiedApiDropdownData(prevApiData => ({
                                    ...prevApiData,
                                    [field.fieldName]: []
                                }))
                            }
                        }
                        if(field.dbColumnName.toLowerCase() === 'districtid'){
                            let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'stateid')

                            if(dependencyField.value !== '' && dependencyField !== undefined){
                                if(apiDropdownData[field.fieldName]){
                                    let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)
                                    
                                    setModifiedApiDropdownData(prevApiData => ({
                                        ...prevApiData,
                                        [field.fieldName]: filteredlist
                                    }))
                                }
                            }else{
                                setModifiedApiDropdownData(prevApiData => ({
                                    ...prevApiData,
                                    [field.fieldName]: []
                                }))
                            }
                        }
                        if(field.dbColumnName.toLowerCase() === 'pdistrictid'){
                            let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'pstateid')

                            if(dependencyField.value !== '' && dependencyField !== undefined){
                                if(apiDropdownData[field.fieldName]){
                                    let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)

                                    setModifiedApiDropdownData(prevApiData => ({
                                        ...prevApiData,
                                        [field.fieldName]: filteredlist
                                    }))
                                }
                            }else{
                                setModifiedApiDropdownData(prevApiData => ({
                                    ...prevApiData,
                                    [field.fieldName]: []
                                }))
                            }
                        }
                        if(field.dbColumnName.toLowerCase() === 'cityid'){
                            let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'stateid')

                            if(dependencyField.value !== '' && dependencyField !== undefined){
                                if(apiDropdownData[field.fieldName]){
                                    let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)

                                    setModifiedApiDropdownData(prevApiData => ({
                                        ...prevApiData,
                                        [field.fieldName]: filteredlist
                                    }))
                                }
                            }else{
                                setModifiedApiDropdownData(prevApiData => ({
                                    ...prevApiData,
                                    [field.fieldName]: []
                                }))
                            }
                        }
                        if(field.dbColumnName.toLowerCase() === 'pcityid'){
                            let dependencyField = section.fieldsList.find(field => field.dbColumnName.toLowerCase() === 'pstateid')

                            if(dependencyField.value !== '' && dependencyField !== undefined){
                                if(apiDropdownData[field.fieldName]){
                                    let filteredlist = apiDropdownData[field.fieldName].filter(state => state.valueId === dependencyField.value)

                                    setModifiedApiDropdownData(prevApiData => ({
                                        ...prevApiData,
                                        [field.fieldName]: filteredlist
                                    }))
                                }
                            }else{
                                setModifiedApiDropdownData(prevApiData => ({
                                    ...prevApiData,
                                    [field.fieldName]: []
                                }))
                            }
                        }
                    })
                })
            }
        }
    }, [apiDropdownData])


    // Customized fields
    const Input = forwardRef(({ value, sx, onBlur, onChange, type, name, isMultipleFields, ...rest }, ref) => {
        let inputDate = null
        let sec = data.sections.find(section=>section.sectionId === rest?.sectionId)
        let fields = sec.fieldsList
        const imageInputRef = useRef(null);

        switch (type) {
            case "Text":
                return (
                    <ScreenView>
                        {mobileView => (
                        <TextField
                            className={
                                isRequired[rest?.fieldName] === true ? "formInputRequired" 
                                : isRequired[rest?.fieldName] === false? 'formInputField'
                                : rest?.isRequired === 1 ? "formInputRequired" : 'formInputField'
                            }
                            id={rest?.dbColumnName}
                            sx={sx ? sx : { width: '100%', mt: mobileView ? 2 : 3, textAlign: 'left', 
                            // ...(rest?.visibleToStudent === '2' && { display: 'none' })
                            }}
                            maxLength={rest?.maxLength}
                            disabled = {(rest?.visibleToStudent === '0' && !value) || rest?.dbColumnName.toLowerCase() === 'mobileno'} 
                            // inputRef={inputRef}  
                            inputRef={(ref) => {
                                inputRefs[rest?.fieldName] = ref; // store the ref in the inputRefs object
                            }}
                            onClick={() => {
                                inputRefs[rest?.fieldName].focus(); // focus on the current input field when clicked
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Tab') {
                                    event.preventDefault(); // prevent default tab behavior
                                    const currentIndex = fields.findIndex(field => field.fieldName === rest?.fieldName);
                                    const nextIndex = (currentIndex + 1) % fields.length; // wrap around to first field if at end
                                    const nextFieldName = fields[nextIndex].fieldName; 
                                    const inputDiv = inputRefs[nextFieldName];
                                    inputRefs[nextFieldName].focus(); // focus on the next input field
                                }
                            }}
                            onBlur={(e) => {onBlur(e.target.value, e.target.value, e.target)}}
                            // rest?.sectionId === 5 || rest?.sectionId === 6 ? "": 
                            defaultValue={inputValue[rest?.fieldName] || '' || (rest?.sectionId === 5 || rest?.sectionId === 6 ? "": value)}
                            tabIndex={0}
                            inputProps={{ style: { 
                                ...(rest?.inputCase === 'U' && {textTransform: "uppercase"}), 
                                ...(rest?.inputCase === 'C' && {textTransform: "capitalize"}),
                                ...(rest?.inputCase === 'L' && {textTransform: "lowercase"})  
                            } }}
                            onChange={(e) => onChange(e.target.value, e.target)}
                            required={isRequired[rest?.fieldName]} 

                            InputProps={{
                                readOnly: ((loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' ) || 
                                    ((isAadhaarVerified === true || isDebVerified === true) && 
                                    (rest?.dbColumnName === 'Firstname' || 
                                        rest?.dbColumnName === 'Middlename' || 
                                        rest?.dbColumnName === 'Lastname'
                                    )) ||
                                    ((isDebVerified === true) &&
                                        (rest?.dbColumnName.toLowerCase() === 'emailid' ||
                                            rest?.dbColumnName.toLowerCase() === 'mobileno'
                                        ))
                                ),
                                endAdornment: (
                                <>
                                {/* && rest?.isRequired === 1 */}
                                    {Object.entries(textError).length > 0 &&
                                        Object.entries(textError).map(([name, { isError, errorMessage }]) =>
                                            isError === true && rest?.fieldName === name && (
                                                <InputAdornment key={name} position="end">
                                                    <Popup trigger={<ErrorIcon sx={{ color: 'red' }} />} on='hover' position="top center">
                                                        <Box sx={{ width: '100%' }}>{errorMessage}</Box>
                                                    </Popup>
                                                </InputAdornment>
                                            ) 
                                        )
                                    }

                                    {rest?.dbColumnName === 'AadharNo' && kycFlag === 1 &&
                                        <InputAdornment position="end">
                                            <Button 
                                                className="additional-button"
                                                disabled= {isAadhaarVerified}
                                                onMouseDown={(event) => {
                                                    event.preventDefault(); // prevent button from capturing focus
                                                    onBlur(inputRefs[rest?.fieldName].value, inputRefs[rest?.fieldName])
                                                    verifyAadhaar()
                                                }}
                                                sx={{
                                                    width:mobileView ? '108px' : '156px',
                                                    color: isAadhaarVerified ? '#fff' : '#484848',
                                                    border: isAadhaarVerified ? '0.5px solid #29BF12' : '0.5px solid #FEBF00',
                                                    background: isAadhaarVerified ? '#29BF12' : '#FEBF00',
                                                    '&:disabled': { // add a separate rule for the disabled state
                                                        color: '#fff', // set the color to #fff when the button is disabled
                                                    },
                                                    disabled: {buttonLoading}
                                                }}  
                                                // onClick={verifyAadhaar}
                                            >{isAadhaarVerified ? 'Verified' : buttonLoading[rest?.fieldName] && buttonLoading['isDelete'] === false ? 
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                    color: 'green[500]',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                    }}
                                                /> : 'Verify'}
                                            </Button>
                                        </InputAdornment>
                                    }

                                    {rest?.dbColumnName === 'DebId' && debEnable === 1 &&
                                        <InputAdornment position="end">
                                            <Button 
                                                className="additional-button"
                                                disabled= {isDebVerified}
                                                onMouseDown={(event) => {
                                                    event.preventDefault(); // prevent button from capturing focus
                                                    // handleInputChange(inputRefs[rest?.fieldName].value, inputRefs[rest?.fieldName].fieldType, inputRefs[rest?.fieldName].fieldName, inputRefs[rest?.fieldName], 0, inputRefs[rest?.fieldName].value)
                                                    onBlur(inputRefs[rest?.fieldName].value, inputRefs[rest?.fieldName])
                                                    verifyDebId(rest?.sectionId)
                                                }}
                                                sx={{
                                                    width:mobileView ? '108px' : '156px',
                                                    color: isDebVerified ? '#fff' : '#484848',
                                                    border: isDebVerified ? '0.5px solid #29BF12' : '0.5px solid #FEBF00',
                                                    background: isDebVerified ? '#29BF12' : '#FEBF00',
                                                    '&:disabled': { // add a separate rule for the disabled state
                                                        color: '#fff', // set the color to #fff when the button is disabled
                                                    },
                                                    disabled: {loading}
                                                }}  
                                                // onClick={verifyAadhaar}
                                            >{isDebVerified ? 'Verified' : loading ? 
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                    color: 'green[500]',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                    }}
                                                /> : 'Verify'}
                                            </Button>
                                        </InputAdornment>
                                    }
                                </>
                                )
                            }}
                            
                        />  
                        )}
                    </ScreenView>
                );
                break;
            case "TextArea":
                return (
                    <>
                    <Textarea minRows={2} 
                        className={
                            isRequired[rest?.fieldName] === true ? "formTextareaRequired" 
                            : isRequired[rest?.fieldName] === false? 'formTextarea'
                            : rest?.isRequired === 1 ? "formTextareaRequired" : 'formTextarea'
                        }
                        id={rest?.dbColumnName}
                        sx={sx ? sx :{ width: '100%', mt:3, textAlign: 'left' }}
                        maxLength={rest?.maxLength}
                        onBlur={(e) => onBlur(e.target.value, e.target.value)}
                        ref={textareaRef}
                        onClick={() => {
                            textareaRef.current.focus(); // focus on the current input field when clicked
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Tab') {
                                event.preventDefault(); // prevent default tab behavior
                                const currentIndex = fields.findIndex(field => field.fieldName === rest?.fieldName);
                                const nextIndex = (currentIndex + 1) % fields.length; // wrap around to first field if at end
                                const nextFieldName = fields[nextIndex].fieldName; 
                                const inputDiv = inputRefs[nextFieldName];
                                inputRefs[nextFieldName].focus(); // focus on the next input field
                            }
                        }}
                        // rest?.sectionId === 5 || rest?.sectionId === 6 ? "": 
                        defaultValue={inputValue[rest?.fieldName] || '' || (rest?.sectionId === 5 || rest?.sectionId === 6 ? "" : value)}
                        readOnly={loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' }
                        // readOnly={(loggedInUser === 'student' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit')) ? false : true}
                        required={isRequired[rest?.fieldName]}
                    />
                    {Object.entries(textError).length > 0 &&
                        Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                            isError === true &&
                            rest?.fieldName === name && (
                            <Typography className="error-message" key={name}>
                                {errorMessage}
                            </Typography>
                        )
                    ))}
                    </>
                    
                );
                break;
            case "RadioButton":
                const radio = rest.fieldDataSource
                const option = radio
                    .split("+")
                    .filter(item => item) // Remove empty items
                    .map(item => {
                        const [key, value] = item.split("=");
                        return {key, value} ;
                    });
                return(
                    <ScreenView>{mobileView => (
                    <>
                    <Typography sx={{fontFamily: 'Open Sauce Sans', fontSize: '16px', mt:2}}>{rest?.fieldName}</Typography>
                    {/* <Box sx={{ mt:0.5, border: '0.5px solid rgba(0, 0, 0, 0.26)', borderRadius: '4px', borderLeft: rest?.isRequired === 1 ? '2px solid red' : '',}}> */}
                    <RadioGroup row
                        // aria-labelledby="demo-row-radio-buttons-group-label"
                        // name="row-radio-buttons-group"
                        defaultValue={inputValue[rest?.fieldName] || '' || (rest?.sectionId === 5 || rest?.sectionId === 6 ? "":value)}
                        sx={{ 
                            mt:0.5,
                            height: 'auto' , 
                            flexFlow: 'wrap',
                            border: '0.5px solid rgba(0, 0, 0, 0.26)',
                            borderRadius: '4px',
                            borderLeft: isRequired[rest?.fieldName] === true ? '2px solid red' : isRequired[rest?.fieldName] === false? '' : rest?.isRequired === 1 ? "2px solid red" : '',
                            // padding: '0.5rem'
                            // flexWrap: 'inherit'
                        }}
                        id={rest?.dbColumnName}
                    >
                        {/* <div style={{ border: '1px solid' }}> */}
                        {option.map((selectOption, index) => (
                            <FormControlLabel
                                key={index}
                                value={selectOption.key}
                                control={ <Radio />}
                                label={selectOption.value}
                                InputLabelProps={{ shrink: false }}
                                sx={{ml: 1, mr:0, ml:0}}
                                disabled={loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' } 
                                onBlur={() => onBlur(selectOption.key, selectOption.value)}
                                inputRef={(ref) => {
                                    inputRefs[rest?.fieldName] = ref; // store the ref in the inputRefs object
                                }}
                                onClick={() => {
                                    inputRefs[rest?.fieldName].focus(); // focus on the current input field when clicked
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Tab') {
                                        event.preventDefault(); // prevent default tab behavior
                                        const currentIndex = fields.findIndex(field => field.fieldName === rest?.fieldName);
                                        const nextIndex = (currentIndex + 1) % fields.length; // wrap around to first field if at end
                                        const nextFieldName = fields[nextIndex].fieldName; 
                                        const inputDiv = inputRefs[nextFieldName];
                                        inputRefs[nextFieldName].focus(); // focus on the next input field
                                    }
                                }}
                                required={isRequired[rest?.fieldName]}
                                
                            />
                        ))}
                        {/* </div> */}
                        
                    </RadioGroup> 
                    {/* <Box sx={{ alignSelf: 'center', justifyContent: 'flex-end', display: 'flex'}}>
                    {Object.entries(textError).length > 0 && rest?.isRequired === 1 &&
                        Object.entries(textError).map(([name, { isError, errorMessage }]) =>
                            isError === true && rest?.fieldName === name && (
                                // <InputAdornment key={name} position="end">
                                    <Popup trigger={<ErrorIcon sx={{ color: 'red', }} />} on='hover' position="top center">
                                        <Box sx={{ width: '100%' }}>{errorMessage}</Box>
                                    </Popup>
                                // </InputAdornment>
                            ) 
                        )
                    }
                    </Box> */}
                    {/* </Box> */}
                    {Object.entries(textError).length > 0 &&
                        Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                            isError === true &&
                            rest?.fieldName === name && (
                                <Typography className="error-message" key={name}>
                                    {errorMessage}
                                </Typography>
                            )
                        ))
                    }
                    </>
                    )}</ScreenView>
                );
                break;
            case "DropDown":
                if(rest?.fieldDataSourceType === 'API') {
                    return (
                        <>
                        <Select
                            className={
                                isRequired[rest?.fieldName] === true ? "form-dropdown-require" 
                                : isRequired[rest?.fieldName] === false? 'form-dropdown'
                                : rest?.isRequired === 1 ? "form-dropdown-require" : 'form-dropdown'
                            }
                            id={rest?.dbColumnName}
                            defaultValue={inputValue[rest?.fieldName] || '' || value}
                            sx={{mt: 3, width: '100%'}}
                            displayEmpty
                            MenuProps={{ PaperProps: { sx: { maxHeight: '25%', minWidth: '454px !important' } } }}    // Height of dropdown
                            // onBlur={(e) => onBlur(e.target.value)}
                            onChange={(e) => handleDropdownChange(rest?.fieldName, e.target.value, rest)}
                            inputRef={(ref) => {
                                inputRefs[rest?.fieldName] = ref; // store the ref in the inputRefs object
                            }}
                            onClick={() => {
                                inputRefs[rest?.fieldName].focus(); // focus on the current input field when clicked
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Tab') {
                                    event.preventDefault(); // prevent default tab behavior
                                    const currentIndex = fields.findIndex(field => field.fieldName === rest?.fieldName);
                                    const nextIndex = (currentIndex + 1) % fields.length; // wrap around to first field if at end
                                    const nextFieldName = fields[nextIndex].fieldName; 
                                    const inputDiv = inputRefs[nextFieldName];
                                    inputRefs[nextFieldName].focus(); // focus on the next input field
                                }
                            }}
                            inputProps={{ readOnly: loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' }}
                            required={isRequired[rest?.fieldName]}
                        >
                            {modifiedApiDropdownData !== null && rest?.fieldName in modifiedApiDropdownData && (
                                modifiedApiDropdownData[rest?.fieldName].map(option => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                </MenuItem>
                                ))
                            )}
                            {/* {countryList !== null && (rest?.dbColumnName === 'CountryId' || rest?.dbColumnName === 'PCountryId') &&
                                countryList.map(item => (
                                    <MenuItem key={item.key} value={item.key}>
                                        {item.value}
                                    </MenuItem>
                                ))
                            } */}
                        </Select>
                        {Object.entries(textError).length > 0 &&
                            Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                                isError === true &&
                                rest?.fieldName === name && (
                                <Typography className="error-message" key={name}>
                                    {errorMessage}
                                </Typography>
                            )
                        ))}
                        </>
                    )
                }
                // if (rest?.fieldDataSourceType === 'API') {
                //     if(rest?.dbColumnName === 'CountryId' ){
                //         return (
                //             <>
                //             <Select
                //                 className={
                //                     isRequired[rest?.fieldName] === true ? "form-dropdown-require" 
                //                     : isRequired[rest?.fieldName] === false? 'form-dropdown'
                //                     : rest?.isRequired === 1 ? "form-dropdown-require" : 'form-dropdown'
                //                 }
                //                 id={rest?.dbColumnName}
                //                 defaultValue={inputValue[rest?.fieldName] || '' || value}
                //                 sx={{mt: 3, width: '100%'}}
                //                 displayEmpty
                //                 MenuProps={{ PaperProps: { sx: { maxHeight: '25%', minWidth: '454px !important' } } }}    // Height of dropdown
                //                 // onBlur={(e) => onBlur(e.target.value)}
                //                 onChange={(e) => handleDropdownChange(rest?.fieldName, e.target.value, rest)}
                //                 inputRef={(ref) => {
                //                     inputRefs[rest?.fieldName] = ref; // store the ref in the inputRefs object
                //                 }}
                //                 onClick={() => {
                //                     inputRefs[rest?.fieldName].focus(); // focus on the current input field when clicked
                //                 }}
                //                 onKeyDown={(event) => {
                //                     if (event.key === 'Tab') {
                //                         event.preventDefault(); // prevent default tab behavior
                //                         const currentIndex = fields.findIndex(field => field.fieldName === rest?.fieldName);
                //                         const nextIndex = (currentIndex + 1) % fields.length; // wrap around to first field if at end
                //                         const nextFieldName = fields[nextIndex].fieldName; 
                //                         const inputDiv = inputRefs[nextFieldName];
                //                         inputRefs[nextFieldName].focus(); // focus on the next input field
                //                     }
                //                 }}
                //                 inputProps={{ readOnly: loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' }}
                //                 required={isRequired[rest?.fieldName]}
                //             >
                //                 {countryList !== null && (rest?.dbColumnName === 'CountryId' || rest?.dbColumnName === 'PCountryId') &&
                //                     countryList.map(item => (
                //                         <MenuItem key={item.key} value={item.key}>
                //                             {item.value}
                //                         </MenuItem>
                //                     ))
                //                 }
                //             </Select>
                //             {Object.entries(textError).length > 0 &&
                //                 Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                //                     isError === true &&
                //                     rest?.fieldName === name && (
                //                     <Typography className="error-message" key={name}>
                //                         {errorMessage}
                //                     </Typography>
                //                 )
                //             ))}
                //             </>
                //         )
                //     }
                //     if(rest?.dbColumnName === 'PCountryId'){
                //         return (
                //             <>
                //             <Select
                //                 className={
                //                     isRequired[rest?.fieldName] === true ? "form-dropdown-require" 
                //                     : isRequired[rest?.fieldName] === false? 'form-dropdown'
                //                     : rest?.isRequired === 1 ? "form-dropdown-require" : 'form-dropdown'
                //                 }
                //                 id={rest?.dbColumnName}
                //                 defaultValue={inputValue[rest?.fieldName] || '' || value}
                //                 sx={{mt: 3, width: '100%'}}
                //                 displayEmpty
                //                 MenuProps={{ PaperProps: { sx: { maxHeight: '25%', minWidth: '454px !important' } } }}    // Height of dropdown
                //                 // onBlur={(e) => onBlur(e.target.value)}
                //                 onChange={(e) => handleDropdownChange(rest?.fieldName, e.target.value, rest)}
                //                 inputProps={{ readOnly: loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit'  }}
                //                 required={isRequired[rest?.fieldName]}
                //             >
                //                 {countryList !== null && (rest?.dbColumnName === 'CountryId' || rest?.dbColumnName === 'PCountryId') &&
                //                     countryList.map(item => (
                //                         <MenuItem key={item.key} value={item.key}>
                //                             {item.value}
                //                         </MenuItem>
                //                     ))
                //                 }
                //             </Select>
                //             {Object.entries(textError).length > 0 &&
                //                 Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                //                     isError === true &&
                //                     rest?.fieldName === name && (
                //                     <Typography className="error-message" key={name}>
                //                         {errorMessage}
                //                     </Typography>
                //                 )
                //             ))}
                //             </>
                            
                //         )
                //     }
                //     if(rest?.dbColumnName === 'StateId'){
                //         return (
                //             <>
                //             <Select
                //                 className={
                //                     isRequired[rest?.fieldName] === true ? "form-dropdown-require" 
                //                     : isRequired[rest?.fieldName] === false? 'form-dropdown'
                //                     : rest?.isRequired === 1 ? "form-dropdown-require" : 'form-dropdown'
                //                 }
                //                 id={rest?.dbColumnName}
                //                 defaultValue={inputValue[rest?.fieldName] || '' || rest?.defaultValue }
                //                 sx={{mt: 3, width: '100%'}}
                //                 displayEmpty
                //                 MenuProps={{ PaperProps: { sx: { maxHeight: '25%', minWidth: '454px !important' } } }}    // Height of dropdown
                //                 // onBlur={(e) => onBlur(e.target.value)}
                //                 onChange={(e) => handleCityDropdown(rest?.fieldName, e.target.value, rest)}
                //                 inputProps={{ readOnly: loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit'  }}
                //                 required={isRequired[rest?.fieldName]}
                //             >

                //                 {selStateList !== null && (rest?.dbColumnName === 'StateId') &&
                //                     selStateList.map(item => (
                //                         <MenuItem key={item.key} value={item.key}>
                //                             {item.value}
                //                         </MenuItem>
                //                     ))
                //                 }

                //             </Select>
                //             {Object.entries(textError).length > 0 &&
                //                 Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                //                     isError === true &&
                //                     rest?.fieldName === name && (
                //                     <Typography className="error-message" key={name}>
                //                         {errorMessage}
                //                     </Typography>
                //                 )
                //             ))}
                //             </>
                //         )
                //     }
                //     if( rest?.dbColumnName === 'PStateId'){
                //         return (
                //             <>
                //             <Select
                //                 className={
                //                     isRequired[rest?.fieldName] === true ? "form-dropdown-require" 
                //                     : isRequired[rest?.fieldName] === false? 'form-dropdown'
                //                     : rest?.isRequired === 1 ? "form-dropdown-require" : 'form-dropdown'
                //                 }
                //                 id={rest?.dbColumnName}
                //                 defaultValue={inputValue[rest?.fieldName] || '' || value}
                //                 sx={{mt: 3, width: '100%'}}
                //                 displayEmpty
                //                 MenuProps={{ PaperProps: { sx: { maxHeight: '25%', minWidth: '454px !important' } } }}    // Height of dropdown
                //                 // onBlur={(e) => onBlur(e.target.value)}
                //                 onChange={(e) => handleCityDropdown(rest?.fieldName, e.target.value, rest)}
                //                 inputProps={{ readOnly: loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit'  }}
                //                 required={isRequired[rest?.fieldName]}
                //             >

                //                 {selPermanentStateList !== null && (rest?.dbColumnName === 'PStateId') &&
                //                     selPermanentStateList.map(item => (
                //                         <MenuItem key={item.key} value={item.key}>
                //                             {item.value}
                //                         </MenuItem>
                //                     ))
                //                 }

                //             </Select>
                //             {Object.entries(textError).length > 0 &&
                //                 Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                //                     isError === true &&
                //                     rest?.fieldName === name && (
                //                     <Typography className="error-message" key={name}>
                //                         {errorMessage}
                //                     </Typography>
                //                 )
                //             ))}
                //             </>
                //         )
                //     }
                //     if(rest?.dbColumnName === 'CityId'){
                //         return (
                //             <>
                //             <Select
                //                 className={
                //                     isRequired[rest?.fieldName] === true ? "form-dropdown-require" 
                //                     : isRequired[rest?.fieldName] === false? 'form-dropdown'
                //                     : rest?.isRequired === 1 ? "form-dropdown-require" : 'form-dropdown'
                //                 }
                //                 id={rest?.dbColumnName}
                //                 defaultValue={inputValue[rest?.fieldName] || value || '' }
                //                 sx={{mt: 3, width: '100%'}}
                //                 displayEmpty
                //                 MenuProps={{ PaperProps: { sx: { maxHeight: '25%', minWidth: '454px !important' } } }}    // Height of dropdown
                //                 onBlur={(e) => onBlur(e.target.value)}
                //                 onChange={(e) => handleCity(rest?.fieldName, e.target.value, rest)}
                //                 inputProps={{ readOnly: loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit'  }}
                //                 required={isRequired[rest?.fieldName]}
                //             >

                //                 {selCityList !== null && (rest?.dbColumnName === 'CityId') &&
                //                     selCityList.map(item => (
                //                         <MenuItem key={item.key} value={item.key}>
                //                             {item.value}
                //                         </MenuItem>
                //                     ))
                //                 }

                //             </Select>
                //             {Object.entries(textError).length > 0 &&
                //                 Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                //                     isError === true &&
                //                     rest?.fieldName === name && (
                //                     <Typography className="error-message" key={name}>
                //                         {errorMessage}
                //                     </Typography>
                //                 )
                //             ))}
                //             </>
                //         )
                //     }
                //     if(rest?.dbColumnName === 'PCityId'){
                //         return (
                //             <>
                //             <Select
                //                 className={
                //                     isRequired[rest?.fieldName] === true ? "form-dropdown-require" 
                //                     : isRequired[rest?.fieldName] === false? 'form-dropdown'
                //                     : rest?.isRequired === 1 ? "form-dropdown-require" : 'form-dropdown'
                //                 }
                //                 id={rest?.dbColumnName}
                //                 defaultValue={inputValue[rest?.fieldName] || '' || value}
                //                 sx={{mt: 3, width: '100%'}}
                //                 displayEmpty
                //                 MenuProps={{ PaperProps: { sx: { maxHeight: '25%', minWidth: '454px !important' } } }}    // Height of dropdown
                //                 onBlur={(e) => onBlur(e.target.value)}
                //                 onChange={(e) => handleCity(rest?.fieldName, e.target.value, rest)}
                //                 inputProps={{ readOnly: loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit'  }}
                //                 required={isRequired[rest?.fieldName]}
                //             >

                //                 {selPermanentCityList !== null && (rest?.dbColumnName === 'PCityId') &&
                //                     selPermanentCityList.map(item => (
                //                         <MenuItem key={item.key} value={item.key}>
                //                             {item.value}
                //                         </MenuItem>
                //                     ))
                //                 }

                //             </Select>
                //             {Object.entries(textError).length > 0 &&
                //                 Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                //                     isError === true &&
                //                     rest?.fieldName === name && (
                //                     <Typography className="error-message" key={name}>
                //                         {errorMessage}
                //                     </Typography>
                //                 )
                //             ))}
                //             </>
                //         )
                //     }
                // }
                if (rest?.fieldDataSourceType === 'TXT') {
                    const options = rest.fieldDataSource
                    const keyValuePairs = options
                        .split("+")
                        .filter(item => item) // Remove empty items
                        .map(item => {
                            const [key, value] = item.split("=");
                            return {key, value} ;
                        });
                    return (
                        <ScreenView>{mobileView => (
                        <>
                        <Select
                            className={
                                isRequired[rest?.fieldName] === true ? "form-dropdown-require" 
                                : isRequired[rest?.fieldName] === false? 'form-dropdown'
                                : rest?.isRequired === 1 ? "form-dropdown-require" : 'form-dropdown'
                            }
                            id={rest?.dbColumnName}
                            defaultValue={inputValue[rest?.fieldName] || '' || (rest?.sectionId === 5 || rest?.sectionId === 6 ? "" : value)}
                            sx={{mt: mobileView ? 1 : 3, width: '100%'}}
                            displayEmpty
                            MenuProps={{ PaperProps: { sx: { maxHeight: '25%', minWidth: '454px !important' } } }}    // Height of dropdown
                            MuiMenuItem = {{
                                styleOverrides: {
                                    root: {
                                        fontFamily: "Open Sauce Sans !important",
                                    }
                                }
                            }}
                            onBlur={(e) => onBlur(e.target.value, e.target.value)}
                            inputProps={{ readOnly: loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' }}
                            required={isRequired[rest?.fieldName]}
                            // onChange={(e) => onChange(e.target.value)}
                        >
                            {keyValuePairs.map((selectOption, index) => (
                                <MenuItem key={index} value={selectOption.key}>{selectOption.value}</MenuItem>
                            ))}
                        </Select>
                        {Object.entries(textError).length > 0 &&
                            Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                                isError === true &&
                                rest?.fieldName === name && (
                                <Typography className="error-message" key={name}>
                                    {errorMessage}
                                </Typography>
                            )
                        ))}
                        </>
                        )}</ScreenView>
                    );
                }
                break;
            case "CheckBox":
                return (
                    <FormGroup>
                        <FormControlLabel sx={{mt: 2, ml: 0.1, width: '100%', 
                            border: '0.5px solid #dcd0d0',
                            borderLeft: (rest?.fieldRefType === 'SubjectId' && (rest?.isRequired === 1 || isRequired[rest?.fieldName])) ? '2px solid red' : '',
                            borderRadius: '4px' }} 
                            control={
                            <Checkbox 
                            // id="section-one-checkbox"
                                id={rest?.dbColumnName}
                                disabled={loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' } 
                                // onBlur={(e) => onBlur(e.target.checked)}
                                // onChange={(e) => handleCheckboxChange(rest?.fieldName, e)}
                                onChange={(e) => {
                                    handleCheckboxChange(rest?.fieldName, rest, e);
                                    // handleCheckListChange(rest, rest?.fieldRefTypeId, rest?.fieldRefTypeId, e);
                                    
                                    // onBlur(e.target.checked, e.target.checked);
                                    if (rest?.fieldRefType !== 'SubjectId') {
                                    onBlur(rest?.fieldRefTypeId, rest?.fieldRefTypeId);
                                    }
                                }}
                                checked={checked[rest?.fieldName] ?? (value === 'true' || (rest?.fieldRefType === 'SubjectId' && value === rest?.fieldRefTypeId.toString()))}
                                // checked={checked[rest?.fieldName] || value === 'true' || false}
                                // checked={inputValue[rest?.fieldName] || (rest?.sectionId === 5 || rest?.sectionId === 6) ? true : value}
                            />
                            }
                            label={
                                <Typography
                                    sx={{
                                    fontFamily: "Open Sauce Sans",
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    fontStyle: 'normal'
                                    }}
                                >
                                    {rest?.fieldName}&nbsp;
                                    {(rest?.fieldRefType !== 'SubjectId' && (
                                        <span>{rest?.helpText}</span>
                                    ))}
                                    {(rest?.isRequired === 1 || isRequired[rest?.fieldName]) && (
                                        <span style={{ color: "red" }}>*</span>
                                    )}
                                </Typography>
                                
                            } 
                        />  
                        {Object.entries(textError).length > 0 &&
                            Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                                isError === true &&
                                rest?.fieldName === name && (
                                    <Typography className="error-message" key={name}>
                                        {errorMessage}
                                    </Typography>
                                )
                            ))
                        }
                    </FormGroup>
                );
                break;
            case "Date":
                const dateFromDb = dayjs(value);
                inputDate = dayjs(inputValue[rest?.fieldName])

                return(
                    <ScreenView>{mobileView => (
                    <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer sx={{ mt:mobileView? 1 : 2, 
                            // ...(rest?.visibleToStudent === '2' && { display: 'none' })
                            }} components={[ 'DatePicker', 'TimePicker', 'DateTimePicker', 'DateRangePicker',]}>
                            <DemoItem sx={{mt: 0}} componentName="DatePicker" name={rest?.fieldName} valueType="date">
                                <DatePicker 
                                    className={
                                        isRequired[rest?.fieldName] === true ? "formInputRequired" 
                                        : isRequired[rest?.fieldName] === false? 'formInputField'
                                        : rest?.isRequired === 1 ? "formInputRequired" : 'formInputField'
                                    }
                                    id={rest?.dbColumnName}
                                    format='DD/MM/YYYY'
                                    key={rest?.fieldId}
                                    name={rest?.fieldName}
                                    disableFuture
                                    maxDate = {rest?.dbColumnName === 'DOB' ? year : null}
                                    defaultValue={ 
                                        inputValue[rest?.fieldName] !== null && inputValue[rest?.fieldName] !== undefined ? inputDate :
                                        rest?.sectionId === 5 || rest?.sectionId === 6 ? null : dateFromDb !== null ? dateFromDb :
                                        selectedDate !== null ? selectedDate[rest?.fieldName] :
                                        null
                                    }
                                    // (isAadhaarVerified === true && rest?.dbColumnName === 'DOB')
                                    readOnly={(loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' ) || rest?.dbColumnName === 'DOB' }
                                    onChange={(date) => handleDateChange(date, rest?.fieldName, rest, isMultipleFields)}
                                    inputRef={(ref) => {
                                        inputRefs[rest?.fieldName] = ref; // store the ref in the inputRefs object
                                    }}
                                    onClick={() => {
                                        inputRefs[rest?.fieldName].focus(); // focus on the current input field when clicked
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Tab') {
                                            event.preventDefault(); // prevent default tab behavior
                                            const currentIndex = fields.findIndex(field => field.fieldName === rest?.fieldName);
                                            const nextIndex = (currentIndex + 1) % fields.length; // wrap around to first field if at end
                                            const nextFieldName = fields[nextIndex].fieldName; 
                                            const inputDiv = inputRefs[nextFieldName];
                                            inputRefs[nextFieldName].focus(); // focus on the next input field
                                        }else if (event.key === '2' && rest?.dbColumnName === 'DOB') {
                                            // ignore the '2' key press when entering a year
                                            event.stopPropagation();
                                        }
                                    }}
                                    required={isRequired[rest?.fieldName]}
                                />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                    
                    {Object.entries(textError).length > 0 &&
                        Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                            isError === true &&
                            rest?.fieldName === name && (
                                <Typography className="error-message" key={name}>
                                    {errorMessage}
                                </Typography>
                            )
                    ))}
                </>
                )}</ScreenView>
                );
                break;
            case "File":
                let uploadedFile = file !== null ? file[rest?.fieldName] : null
                let uploadedFileName = fileName !== null && fileName !== undefined ? fileName[rest?.fieldName] : null
                let fileExtension = extension !== null && extension !== undefined ? extension[rest?.fieldName] : null
                let validationState = validationError !== null ? validationError[rest?.fieldName] : null
                let uploadedFileExtension = value !== null ? value.split('.').pop() : null

                return(
                    <ScreenView>
                        {mobileView => (
                            <Box >
                                <Box sx={{width: '100%'}} >
                                <TextField
                                    className={
                                        isRequired[rest?.fieldName] === true ? "formInputRequired" 
                                        : isRequired[rest?.fieldName] === false? 'formInputField'
                                        : rest?.isRequired === 1 ? "formInputRequired" : 'formInputField'
                                    }
                                    // className={(isRequired[rest?.fieldName] ? (isRequired[rest?.fieldName] === true ? "formInputRequired" : "formInputField") : (rest?.isActive === 1 ? "formInputRequired" : "formInputField"))}
                                    // className={(rest?.isRequired === 1 && isRequired[rest?.fieldName] === true) ? "formInputRequired" : "formInputField"}
                                    id={rest?.dbColumnName}
                                    sx={{width: mobileView ? '75%' : '80%', mt:mobileView? 1 : 3}}
                                    required={isRequired[rest?.fieldName]}
                                    InputProps={{
                                        readOnly: loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' ? false : true,
                                        endAdornment: (
                                            (buttonLoading[rest?.fieldName] && buttonLoading['isDelete'] ? 
                                                <>
                                                    <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: 'green[500]',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '95%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                    />
                                                </>:
                                                value !== null && value !== "" ? (
                                                    uploadedFileExtension === 'pdf' ? (
                                                    <img src={pdfIcon} style={{ width: '50px' }} alt="Uploaded file" />
                                                    ) : uploadedFileExtension === 'doc' || uploadedFileExtension === 'docx' ? (
                                                    <img src={docIcon} style={{ width: '50px' }} alt="Uploaded file doc" />
                                                    ) : uploadedFileExtension === 'png' || uploadedFileExtension === 'jpg' || uploadedFileExtension === 'jpeg' ? (
                                                    <img src={value} style={{ width: '30px' }} alt="Uploaded file image" />
                                                    ) : null
                                                ) 
                                                :<Button sx={{
                                                    background: '#d3d3d3', 
                                                    '&:hover': {
                                                    background: '#d3d3d3'
                                                    }}} 
                                                    variant="contained"
                                                    component="label"
                                                    
                                                >
                                                    <FilePresentIcon />
                                                </Button>
                                            )
                                        )
                                    }}
                                    placeholder={uploadedFileName !== null && uploadedFileName !== undefined ? uploadedFileName : rest?.fieldName}
                                />
                                    {value !== "" && 
                                    <>
                                    <VisibilityOutlinedIcon sx={{
                                        marginRight: '2%', 
                                        mt:3, 
                                        marginLeft: '2%', 
                                        color: '#63616a',
                                        cursor: 'pointer'
                                    }} 
                                        onClick = {() => documentPreview(rest?.fieldName, value)}
                                        // onClick={openModal}
                                    />
                                    <DeleteForeverIcon 
                                        sx={{color: '#63616a', cursor: 'pointer'}}
                                        onClick = {() => removeUploadedFile(rest?.fieldName, rest, value)}
                                        // onClick={() => setFile((prevState) => ({...prevState, [rest?.fieldName]: null}))}
                                    />
                                    </>
                                    }
                                </Box>
                                <Box sx={{display: 'flex'}}>
                                    <Typography 
                                        sx={{
                                            textAlign: 'left', width: '60%', mt:1,
                                            fontFamily: "Open Sauce Sans",
                                            fontSize: '14px',
                                            fontStyle: 'italic',
                                            color: validationState === 'Invalid' ? 'red' : 'black'
                                        }}
                                    >
                                        {/* Hint for file type and size */}
                                        {(() => {
                                            let validTypes = '';
                                            let validMinSize = '';
                                            let validMinSizeUnit = '';
                                            let validMaxSize = '';
                                            let validMaxSizeUnit = '';

                                            rest?.fieldValidations.forEach(element => {
                                                switch (element.property) {
                                                    case 'FileType':
                                                        validTypes = element.value;
                                                        break;
                                                    case 'MinSize':
                                                        validMinSize = element.value;
                                                        break;
                                                    case 'MinSizeUnit':
                                                        validMinSizeUnit = element.value;
                                                        break;
                                                    case 'MaxSize':
                                                        validMaxSize = element.value;
                                                        break;
                                                    case 'MaxSizeUnit':
                                                        validMaxSizeUnit = element.value;
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            });

                                            return `Format: ${validTypes} (min size: ${validMinSize} ${validMinSizeUnit}, max size: ${validMaxSize} ${validMaxSizeUnit})`;
                                        })()}
                                        {/* Format: .pdf, .doc, .dox (min size:{rest?.fieldValidations[0].property === 'MinSize' ? rest?.fieldValidations[0].value : 0} max size: 1 Mb) */}
                                    </Typography>
                                    <Button sx={{
                                        mt: 1,
                                        mr: mobileView ? 8 : 10,
                                        borderColor: buttonLoading[rest?.fieldName] && buttonLoading['isDelete'] === false ? '#fff' : '#FEBF00',
                                        color: '#484848',
                                        textTransform: 'none',
                                        height: '30%',
                                        '&:hover': {
                                            borderColor: '#FEBF00',
                                            background:'#fff'
                                        },
                                    }}
                                        variant="outlined"
                                        component="label"
                                        
                                    > 
                                    {buttonLoading[rest?.fieldName] && buttonLoading['isDelete'] === false ? 
                                    <>
                                        <CircularProgress
                                        size={24}
                                        sx={{
                                            color: 'green[500]',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                        />
                                    </>:
                                        <>
                                            <UploadOutlinedIcon />
                                            Upload
                                        </>
                                    }
                                        {(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit' )) && 
                                            <input type="file" style={{ display: 'none', width: '100%' }} onChange={(e) => handleFile(rest?.fieldName, rest?.sectionId, rest?.fieldId, rest?.validationType, rest?.sequenceId, rest, e)} />
                                        }
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </ScreenView>
                );
            case 'Image':
                return(
                    <ScreenView>
                        {mobileView => (
                            <Grid item>
                            <Box
                                sx={{
                                display: sec.sectionId === 2 ? 'block' : 'inline-block',
                                }}
                            >
                                <FormControl
                                sx={{
                                    width: mobileView ? '100%' : '100%',
                                    textAlign: 'left',
                                    ...(rest?.visibleToStudent === '2' || rest?.fieldType === 'HiddenField'
                                    ? { display: 'none' }
                                    : {}),
                                }}
                                >
                                {/* rest of your code */}
                                <Box
                                    sx={{
                                    flexDirection: mobileView ? 'column' : 'column',
                                    width: mobileView ? '100%' : '100%',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                    mt:2
                                    }}
                                >
                                    <Box
                                    sx={{
                                        display: mobileView ? 'block' : 'flex',
                                        alignItems: 'center',
                                        ...(rest?.visibleToStudent === '2' ? { display: 'none' } : {}),
                                    }}
                                    >
                                    <Typography sx={{
                                        fontFamily: "Open Sauce Sans",
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                    }}>{rest?.fieldName}
                                        { (rest?.isRequired === 1 || isRequired[rest?.fieldName]) && (
                                            <span style={{ color: '#FF0000' }}>*</span>
                                        )}
                                    </Typography>
                                    </Box>
                                    <Box sx={{display: 'flex'}}>
                                        <Box
                                            height={150}
                                            width={200}
                                            my={1}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={4}
                                            p={2}
                                            sx={{borderRadius: '10px', border: '0.5px solid #972524', cursor: 'pointer' }}
                                            // onClick={() => {(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) && isAadhaarVerified === false && document.getElementById(rest?.dbColumnName).click()}}
                                            onClick={() => {
                                                // Check if the conditions are met to trigger the file input
                                                if (
                                                    (loggedInUser  !== 'admin' || (loggedInUser  === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) &&
                                                    isAadhaarVerified === false
                                                ) {
                                                    // Trigger the hidden file input
                                                    imageInputRef.current.click();
                                                    // document.getElementById(field?.dbColumnName).click();
                                                }
                                            }}
                                        >
                                            {file && Object.keys(file).length !== 0 && file[rest?.fieldName] ? (
                                                <>
                                                    <img id={rest?.dbColumnName} src={file[rest?.fieldName]} style={{ width: '200px', height: '150px', borderRadius: '10px' }} alt="Uploaded file" />
                                                    {(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) && isAadhaarVerified === false &&
                                                        <input id={rest?.dbColumnName} ref={imageInputRef} type="file" style={{ display: 'none' }} onChange={(e) => handleImageChange(rest?.fieldName, rest?.sectionId, rest?.fieldId, rest, e)} onBlur={handleInputChange} />
                                                    }
                                                </>
                                                
                                                // (Object.keys(file).length !== 0 && (file[rest?.fieldName] && (file[rest?.fieldName] === undefined || file[rest?.fieldName] === null))) || (value !== null && value !== "") ?
                                            ) : (file && Object.keys(file).length !== 0 && file[rest?.fieldName]) || (value !== null && value !== "") ? (
                                                <>
                                                    <img id={rest?.dbColumnName} src={value} style={{ width: '200px', height: '150px', borderRadius: '10px' }} alt="Uploaded file" />
                                                    {(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) &&
                                                        <input id={rest?.dbColumnName} ref={imageInputRef} type="file" style={{ display: 'none' }} onChange={(e) => handleImageChange(rest?.fieldName, rest?.sectionId, rest?.fieldId, rest, e)} onBlur={handleInputChange} />
                                                    }
                                                </>
                                                // (Object.keys(file).length === 0 || (file[rest?.fieldName] === undefined || file[rest?.fieldName] === null)) && (value === null || value === "")?     
                                            ) : (!file || Object.keys(file).length === 0) || (file || Object.keys(file).length !== 0 && !file[rest?.fieldName]) && (value === null || value === "") ? (
                                                    <Box>
                                                        <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
                                                            <UploadOutlinedIcon />
                                                            <span>Browse from device</span>
                                                        </Box>
                                                        {(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) && isAadhaarVerified === false &&
                                                            <input id={rest?.dbColumnName} ref={imageInputRef} type="file" style={{ display: 'none' }} onChange={(e) => handleImageChange(rest?.fieldName, rest?.sectionId, rest?.fieldId, rest, e)} onBlur={handleInputChange} />
                                                        }
                                                    </Box>
                                                ) : (value !== null && value !== "") ? (
                                                    <>
                                                    <img id={rest?.dbColumnName} src={value} style={{ width: '200px', height: '150px', borderRadius: '10px' }} alt="Uploaded file" />
                                                        {(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) &&
                                                            <input id={rest?.dbColumnName} ref={imageInputRef} type="file" style={{ display: 'none' }} onChange={(e) => handleImageChange(rest?.fieldName, rest?.sectionId, rest?.fieldId, rest, e)} onBlur={handleInputChange} />
                                                        }
                                                    </>
                                                ) : null
                                            }

                                        </Box>
                                        {file && file[rest?.fieldName] && isAadhaarVerified === false && (
                                            <DeleteForeverIcon 
                                                sx={{ cursor: 'pointer', alignSelf: 'center', ml: 2 }} 
                                                onClick={() => setFile((prevState) => {
                                                    const { [rest?.fieldName]: _, ...newFile } = prevState; // Destructure to exclude the field
                                                    return newFile; // Return the new object without the specified field
                                                })}
                                            />
                                            // <DeleteForeverIcon 
                                            //     sx={{ cursor: 'pointer', alignSelf: 'center', ml: 2 }} 
                                            //     onClick={() => setFile((prevState) => ({
                                            //         ...prevState,
                                            //         [rest?.fieldName]: null
                                            //     }))}
                                            // />
                                        )}
                                        {/* {file && file[rest?.fieldName] && isAadhaarVerified === false && <DeleteForeverIcon sx={{ cursor: 'pointer', alignSelf: 'center', ml: 2 }} 
                                        // onClick={() => setFile(null)}
                                        />
                                        } */}
                                        
                                    </Box>
                                    {Object.entries(textError).length > 0 &&
                                        Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                                            isError === true &&
                                            rest?.fieldName === name && (
                                            <Typography className="error-message" key={name}>
                                                {errorMessage}
                                            </Typography>
                                        )
                                    ))}
                                    <Typography 
                                        sx={{
                                            textAlign: 'left', width: '100%', 
                                            fontFamily: "Open Sauce Sans",
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            color: 'black'
                                        }}
                                    >
                                        Format: {rest.fieldValidations.map((acc)=>{
                                            if (acc.property === 'FileType') {
                                                return acc.value.replace(/\,/g, ', ');
                                            } else if (acc.property === 'MinSize') {
                                                const minSizeUnit = rest.fieldValidations.find(v => v.property === 'MinSizeUnit').value;
                                                return ` , Min Size: ${acc.value}${minSizeUnit}`;
                                            } else if (acc.property === 'MaxSize') {
                                                const maxSizeUnit = rest.fieldValidations.find(v => v.property === 'MaxSizeUnit').value;
                                                return ` , Max Size: ${acc.value}${maxSizeUnit}`;
                                            }
                                            return '';
                                        }).filter(Boolean).join('')}
                                    </Typography>
                                </Box>
                                </FormControl>
                            </Box>
                            </Grid>
                        )}
                    </ScreenView>
                );
                break;
            case 'Label':
                return(
                    <TextField
                        className={
                            isRequired[rest?.fieldName] === true ? "formInputRequired" 
                            : isRequired[rest?.fieldName] === false? 'formInputField'
                            : rest?.isRequired === 1 ? "formInputRequired" : 'formInputField'
                        }
                            id={rest?.dbColumnName}
                            sx={sx ? sx : { width: '100%', mt: 3, textAlign: 'left', 
                            ...(rest?.visibleToStudent === '2' && { display: 'none' })
                            }}
                            InputProps={{
                                readOnly: true
                            }}
                            disabled = {rest?.visibleToStudent === '0' && !value} 
                            defaultValue={rest?.fieldName}
                            tabIndex={0}
                        />  
                )
            break;
            case 'CheckList':
                let checklist = rest.fieldDataSource
                const checks = checklist
                .split("+")
                .filter(item => item) // Remove empty items
                .map(item => {
                        const [key, value] = item.split("=");
                        return {key, value} ;
                    });

                let items = subjectValue.map(item => Object.keys(item)[0])
                let x = value.split(', ');
                
                return(
                    <ScreenView>
                        {mobileView => (  
                            (rest?.sectionId === 13 ? (
                            <Box sx={{ display: mobileView ? 'block' : 'flex', width: mobileView ? '120%' : '171%', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {checks.map((selectOption, index) => (
                            <Box sx={{ marginLeft: mobileView ? '0%' : index % 2 === 1 ? '33%' : '0' }}>        
                                <TextField
                                    InputProps={{
                                        sx: {
                                            paddingLeft: mobileView ? '3px' : '14px'
                                        },
                                        readOnly: true,
                                        startAdornment: (
                                            <FormGroup>
                                                <FormControlLabel
                                                    key={index}
                                                    value={selectOption.key}
                                                    control={ <Checkbox sx={{padding: '0px'}}/>}
                                                    disabled={loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' }
                                                    // checked={value}
                                                    checked={
                                                        subjectValue.some(item => item[selectOption.key] === selectOption.value) 
                                                        // ||x.includes(selectOption.key)
                                                    }
                                                    
                                                    onChange={(event) => {
                                                        const isChecked = event.target.checked;
                                                        if (isChecked) {                    // To check
                                                                setSubjectValue(prevSubjectValue => ([
                                                                ...prevSubjectValue,
                                                                { [selectOption.key]: selectOption.value, sequence: rest?.sequence, fieldRefTypeId: rest?.fieldRefTypeId, fieldRefType: rest?.fieldRefType, isDelete: false }
                                                            ]));
                                                        onBlur(selectOption.key, event.target)
                                                        } else {                            // To uncheck
                                                            setSubjectValue(prevSubjectValue =>
                                                                prevSubjectValue.filter(item => !(item[selectOption.key] === selectOption.value))
                                                            );
                                                        }
                                                    }}
                                                    inputRef={(ref) => {
                                                        inputRefs[rest?.fieldName] = ref; // store the ref in the inputRefs object
                                                    }}
                                                    onClick={() => {
                                                        inputRefs[rest?.fieldName].focus(); // focus on the current input field when clicked
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Tab') {
                                                            event.preventDefault(); // prevent default tab behavior
                                                            const currentIndex = fields.findIndex(field => field.fieldName === rest?.fieldName);
                                                            const nextIndex = (currentIndex + 1) % fields.length; // wrap around to first field if at end
                                                            const nextFieldName = fields[nextIndex].fieldName; 
                                                            const inputDiv = inputRefs[nextFieldName];
                                                            inputRefs[nextFieldName].focus(); // focus on the next input field
                                                        }
                                                    }}
                                                    InputLabelProps={{ shrink: false }}
                                                    sx={{ml: 0}}
                                                    
                                                />
                                            </FormGroup>
                                        )
                                    }}
                                    className={
                                        isRequired[rest?.fieldName] === true ? "formInputRequired" 
                                        : isRequired[rest?.fieldName] === false? 'formInputField'
                                        : rest?.isRequired === 1 ? "formInputRequired" : 'formInputField'
                                    }
                                    id={rest?.dbColumnName}
                                    sx={sx ? sx : { width: '176%', mt: 3, textAlign: 'left', paddingLeft: '0px',
                                    ...(rest?.visibleToStudent === '2' && { display: 'none' })
                                    }}
                                    disabled = {rest?.visibleToStudent === '0' && !value} 
                                    defaultValue={selectOption.value}
                                    tabIndex={0}
                                />  
                            </Box>
                            ))}
                            </Box>)
                            :
                            (
                            <>
                            <RadioGroup row
                                // aria-labelledby="demo-row-radio-buttons-group-label"
                                // name="row-radio-buttons-group"
                                defaultValue={inputValue[rest?.fieldName] || '' || (rest?.sectionId === 5 || rest?.sectionId === 6 ? "":value)}
                                sx={{ 
                                    mt:2,
                                    height: 'auto' , 
                                    flexFlow: 'wrap',
                                    border: '0.5px solid rgba(0, 0, 0, 0.26)',
                                    borderRadius: '4px',
                                    borderLeft: (rest?.isRequired === 1 || isRequired[rest?.fieldName]) ? '2px solid red' : '',
                                    // padding: '0.5rem'
                                    // flexWrap: 'inherit'
                                }}
                                id={rest?.dbColumnName}
                            >
                                {/* <div style={{ border: '1px solid' }}> */}
                                {checks.map((selectOption, index) => (
                                    <FormControlLabel
                                        key={index}
                                        value={selectOption.key}
                                        control={ <Checkbox />}
                                        label={selectOption.value}
                                        InputLabelProps={{ shrink: false }}
                                        sx={{ml: 1, mr:0, ml:0}}
                                        disabled={loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit'}
                                        // checked={checkedItems[item.name]}
                                        checked={checkedItems[rest?.fieldName] && checkedItems[rest?.fieldName][selectOption.key]}
                                        onChange={(e) => {
                                            handleCheckListChange(rest, selectOption.key, selectOption.value, e);
                                            // onBlur(selectOption.key, selectOption.key);
                                        }} 
                                        inputRef={(ref) => {
                                            inputRefs[rest?.fieldName] = ref; // store the ref in the inputRefs object
                                        }}
                                        onClick={() => {
                                            inputRefs[rest?.fieldName].focus(); // focus on the current input field when clicked
                                        }}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Tab') {
                                                event.preventDefault(); // prevent default tab behavior
                                                const currentIndex = fields.findIndex(field => field.fieldName === rest?.fieldName);
                                                const nextIndex = (currentIndex + 1) % fields.length; // wrap around to first field if at end
                                                const nextFieldName = fields[nextIndex].fieldName; 
                                                const inputDiv = inputRefs[nextFieldName];
                                                inputRefs[nextFieldName].focus(); // focus on the next input field
                                            }
                                        }}
                                        required={isRequired[rest?.fieldName]}
                                        
                                    />
                                ))}
                                {/* </div> */}
                                
                            </RadioGroup> 
                            {Object.entries(textError).length > 0 &&
                                Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                                    isError === true &&
                                    rest?.fieldName === name && (
                                        <Typography className="error-message" key={name}>
                                            {errorMessage}
                                        </Typography>
                                    )
                                ))
                            }
                            </>
                            )
                            )
                        )}
                    </ScreenView>
                )
            break;
            case 'Paragraph':
                return (
                    <div
                        style={{
                            padding: 16,
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            width: '113%',
                            minHeight: 100,
                            marginTop: '21px'
                        }}
                        
                    >
                        <div dangerouslySetInnerHTML={{ __html: rest?.fieldDataSource }}></div>
                    </div>
                  );
                break;
            default:
                return null;

        }

    });

    function decodeHtmlEntities(str) {
        return str.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
     
    }

    // Usestate to add existing value in subjectValue list to edit
    useEffect(()=>{
        if (data !== null) {
            let sec = data.sections.find(sec => sec.sectionId === 13)

            if(sec !== undefined){
                let fields = sec.fieldsList;

                fields.map((element) =>{
                    let checklist = element.fieldDataSource

                    const checks = checklist
                    .split("+")
                    .filter(item => item) // Remove empty items
                    .map(item => {
                            const [key, value] = item.split("=");
                            return {key, value} ;
                        });

                    let savedValues = element.value 
                    let x = savedValues.split(', ');
                    const existingChecks = x.map(val => {
                        const check = checks.find(check => check.key === val);
                        if (check) {
                            return {[check.key]: check.value, sequence: element?.sequence, fieldRefTypeId: element?.fieldRefTypeId, fieldRefType: element?.fieldRefType, isDelete: false};
                        }
                        return null;
                        }).filter(Boolean);
                    if(id === 11){
                        setSubjectValue(prevSubjectValue => [...prevSubjectValue,...existingChecks]);
                        // setSubjectValue(existingChecks)
                    }
                })
            }
        }
    },[data, id])

    useEffect(() => {
        if (data !== null) {
            let sec = data.sections.find(section => section.sectionId === id && id !== 13)
            if(sec){
                let fields = sec.fieldsList
                if (fields) {
                    const checklistFields = fields.filter(element => element.fieldType === 'CheckList' && element.value !== '')
                    checklistFields.forEach(element => {
                    let checkedValue = element.value.replace(/\s+/g, '').split(','); // remove whitespace and split on commas
            
                    checkedValue.forEach(check => {
                        setCheckedItems((prevCheckedItems) => ({
                        ...prevCheckedItems,
                        [element.fieldName]: {
                            ...prevCheckedItems[element.fieldName],
                            [check]: true,
                        },
                        }));
                    })
            
                    const fieldToSave = {
                        sectionId: element.sectionId,
                        fieldId: element.fieldId,
                        sequenceId: 0,
                        value: element.value,
                        imageExtension: "",
                        imageSize: "",
                        fieldRefType: "",
                        fieldRefTypeId: 0,
                        isDelete: false,
                    };
            
                    setListOfFieldsArray((prevList) => [...prevList, fieldToSave]);
                    })
                }
            }
        }
      }, [data, id])

    const [textError, setTextError] = useState({})
    const [tableList, setTableList] = useState({})

    // Validation function
    const handleInputChange = async (value, type, name, target, isMultipleFields, displayValue) => {
        let isError = false;
        let errorMessage = '';

        target?.fieldValidations.forEach(element => {
            switch (element.property) {
                case 'InputType':
                    if(element.value === 'Numbers only'){
                        const numberRegex = /^[0-9]*$/;
                        if(!numberRegex.test(value)){
                            isError = true;
                            errorMessage = (
                                <ul>
                                    <li>Only numbers are allowed</li>
                                </ul>
                            );
                        };
                    }

                    if(element.value === 'Email'){
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if(!emailRegex.test(value)){
                            isError = true;
                            errorMessage = 'Please enter email address in format: yourname@example.com';
                        };
                    }

                    if(element.value === 'Alphanumeric'){
                        let AlphanumericRegex = /^[a-zA-Z0-9_ ]*$/;
                        if(!AlphanumericRegex.test(value)){
                            isError = true;
                            errorMessage = (
                                <ul>
                                    <li>Allowed alphabets are (a-z or A-Z)</li>
                                    <li>Allowed numbers are (0-9)</li>
                                </ul>
                            );
                        };
                    }

                    if(element.value === 'Alphanumeric (With special characters)'){
                        let AlphanumericSpecialRegex =  /^[a-zA-Z0-9-,.:()_@+; ]*$/;
                        if(!AlphanumericSpecialRegex.test(value)){
                            isError = true;
                            errorMessage = (
                                <ul>
                                    <li>Allowed alphabets are (a-z or A-Z)</li>
                                    <li>Allowed numbers are (0-9)</li>
                                    <li>Allowed special characters are (@ , . ( ) / _ - : + ;) </li>
                                </ul>
                            );
                        };
                    }

                    if(element.value === 'Text only (With special characters)'){
                        let textSpecialRegex =/^[a-zA-Z-+' ]*$/;
                        if(!textSpecialRegex.test(value)){
                            isError = true;
                            errorMessage = (
                                <ul>
                                    <li>Allowed alphabets are (a-z or A-Z)</li>
                                    <li>Allowed special characters are (' - +)</li>
                                </ul>
                            );
                        };
                    }

                    if(element.value === 'Text only'){
                        let textSpecialRegex =/^[a-zA-Z ]*$/;
                        if(!textSpecialRegex.test(value)){
                            isError = true;
                            errorMessage = (
                                <ul>
                                    <li>Allowed alphabets are (a-z or A-Z)</li>
                                </ul>
                            );
                        };
                    }

                    break;
                case 'MinLength':
                    if(value.length < element.value){
                        isError = true;
                        errorMessage = (
                            <ul>
                                <li>Minimum length is {element.value}</li>
                            </ul>
                        );
                    }
                    break;
                case 'MaxLength':
                    if(value.length > element.value){
                        isError = true;
                        errorMessage = (
                            <ul>
                                <li>Maximum length is {element.value}</li>
                            </ul>
                        );
                    }
                    break;
                default:
                    break;
            }
        });

        setTextError((prevTextError) => ({
            ...prevTextError,
            [name]: { isError, errorMessage },
        }));
        setInputValue((prevInputValue) => ({
            ...prevInputValue,
            [name]: ''
        }));


        if (isError === false) {
            setInputValue((prevInputValue) => ({
                ...prevInputValue,
                [name]: value
            }));

            {isMultipleFields === 1 &&
                setTableValue((prevTableValue) => ({
                    ...prevTableValue,
                    [name]: displayValue
                }))
            }

            // Set Insterested in value of annexture to display job section
            if(target?.fieldId === 52){
                setInterested(value)
                try {
                    const { data,loading,error } = await CallAPI(annexture, 'POST', { 
                            "enquiryId": enquiryId,
                        }
                    ); 
                    setAnnextureData(data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            console.log('deleteSequenceFlag', deleteSequenceFlag, tableInputValue[id] ? tableInputValue[id].length + 1 : 'not present', tableRowSequence[target.sectionId])
            // Set list of fields for save api
            {isMultipleFields === 1 ?
                setListOfFields((prevListOfFields) => ({
                    ...prevListOfFields,
                    'sectionId': target.sectionId,
                    'fieldId': target.fieldId,
                    'sequenceId':deleteSequenceFlag === 1 && tableInputValue[id] && editFlag === 0 ? tableInputValue[id].length + 1 : tableRowSequence[target.sectionId],
                    // 'sequenceId':deleteSequenceFlag === 1 ? tableRowSequence[target.sectionId]-1 : tableRowSequence[target.sectionId],
                    'value': value.toString(),
                    "imageExtension": "",
                    "imageSize": "",
                    'fieldTypeId': target?.fieldTypeId,
                    "fieldRefType": target.fieldRefType,
                    "fieldRefTypeId": target.fieldRefTypeId,
                    "isDelete" : false,
                }))
                :
                (value !== '' && target.sectionId !== 13 && isMultipleFields === 0 &&
                    setListOfFields((prevListOfFields) => ({
                        ...prevListOfFields,
                        'sectionId': target.sectionId,
                        'fieldId': target.fieldId,
                        'sequenceId': 0,
                        'value': value.toString(),
                        "imageExtension": "",
                        "imageSize": "",
                        'fieldTypeId': target?.fieldTypeId,
                        "fieldRefType": target.fieldRefType,
                        "fieldRefTypeId": target.fieldRefTypeId,
                        "isDelete" : false,
                    }))
                    // :
                    // (target.sectionId === 13 && target.fieldRefType === 'SubjectId') &&
                    // setListOfFields((prevListOfFields) => ({
                    //     ...prevListOfFields,
                    //     'sectionId': target.sectionId,
                    //     'fieldId': target.fieldId,
                    //     'sequenceId': 0,
                    //     'value': value.toString(),
                    //     "imageExtension": "",
                    //     "imageSize": "",
                    //     "fieldRefType": target.fieldRefType,
                    //     "fieldRefTypeId": target.fieldRefTypeId,
                    //     "isDelete" : false,
                    // }))
                )
            }

            
        }
        
        
    }


    // To save subject section
    useEffect(() => {
        if (subjectValue.length > 0) {
            const groupedFields = subjectValue.reduce((acc, item) => {
                const key = item.fieldRefTypeId;
                const valueKey = Object.keys(item)[0].toString(); // Ensure the key is a string
    
                if (!acc[key]) {
                    acc[key] = {
                        'sectionId': 13,
                        'fieldId': 73,
                        'sequenceId': 0,
                        'value': valueKey, // Set the first value as a string
                        "imageExtension": "",
                        "imageSize": "",
                        "fieldRefType": item.fieldRefType,
                        "fieldRefTypeId": item.fieldRefTypeId,
                        "isDelete" : false,
                    };
                } else {
                    if (typeof acc[key].value === 'string') {
                        acc[key].value = [acc[key].value]; // Convert the value to an array of strings
                    }
                    acc[key].value.push(valueKey);
                }
                return acc;
            }, {});
    
            setListOfFieldsArray(Object.values(groupedFields).map(field => ({
                ...field,
                value: Array.isArray(field.value) ? field.value.join(', ') : field.value // Join the values with a comma
            })));
        }
    }, [subjectValue]);
    
    // Set list of fields into array to pass save api
    useEffect(() => {
        // Add the updated listOfFields to listOfFieldsArray after each update
        if (Object.keys(listOfFields).length !== 0) {
            setListOfFieldsArray(prevList => [...prevList, listOfFields]);
        } 

        // Set dependent field mandatory based on selection
        if(data !== null && Object.keys(listOfFields).length > 0){
            const dependentField = data.fieldDepedancyList.find((item) => parseInt(item.fieldId, 10) === listOfFields.fieldId);

            if (dependentField) {
                dependentField.fieldDepedancies.forEach((dep) => {
                  const dependentFieldValue = dep.value;
                  const dependentSectionId = dep.sectionId;
                  const dependentType = dep.dependancyType;
                  const dependentFieldId = dep.fieldId;
                  const dependentFieldRefId = dep.fieldRefId;
            
                  let sec = data.sections.find((section) => section.sectionId === parseInt(dependentSectionId));
                  let fields = sec.fieldsList.find((f) => f.fieldId === parseInt(dependentFieldId));
                  if(dependentFieldId === '54' && dependentFieldRefId !== ''){
                    fields = sec.fieldsList.find((f) => f.fieldDataSource === dependentFieldRefId)
                  }
                  if (listOfFields.value === dependentFieldValue && dependentType === '2') {
                    setIsRequired((prevRequired) => ({
                      ...prevRequired,
                      [fields.fieldName]: true,
                    }));
                  } else if(listOfFields.value === dependentFieldValue && dependentType === '4') {
                    setIsRequired((prevRequired) => ({
                      ...prevRequired,
                      [fields.fieldName]: false,
                    }));
                  } 
                  else{
                    setIsRequired((prevRequired) => {
                        const newRequired = { ...prevRequired };
                        delete newRequired[fields.fieldName];
                        return newRequired;
                    });
                  }
                });
            }
        }

        // Check conditions before calling fieldWiseSave
        if (loggedInUser === 'student' && id !== 5 && id !== 6 && id !== 11) {
            fieldWiseSave();
        }
        // if (!(listOfFields.fieldId === 23 && kycFlag === 1) && id !== 5 && id !== 6 && id !== 11) {
        //     fieldWiseSave();
        // }
    }, [listOfFields, data, kycFlag, id]);
    

    // Field wise save
    const fieldWiseSave = async() => {

        if(listOfFieldsArray[0] !== undefined){
            let isMultipleFields = listOfFieldsArray[0].sequenceId

            const requestModel =  {
                "enquiryId": enquiryId,
                "admissionFormId": admissionFormId,
                "userId": loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit' ? parseInt(sessionStorage.getItem('userId')) : 0,
                "listOfSection": [
                    {
                    "sectionId": id,
                    "isMultiple": isMultipleFields !== 0 ? 1 : 0,
                    "listOfFields": ((id === 3 || id === 11) ? listOfFieldsArray.filter(item => item.sectionId !== 5 && item.sectionId !== 6) : listOfFieldsArray)
                    }
                ]
            }

            try{
                const { statusCode,loading,error } = await CallAPI(saveField, 'POST', { requestModel })
                setSaveApiResponse(statusCode);
				}catch(error){
                console.log(error);
            }
        }
        
    }
    
    let aadhaarNumber = ''
    const handleChange = (value, name, rest) => {
        if(rest?.dbColumnName === 'AadharNo'){
            aadhaarNumber = value
            setAadhaarData(value)
        }
        else if(rest?.dbColumnName.toLowerCase() === 'debid'){
            setDebId = value
        }
    }
    
    // To set error and error message
    const updateTextError = async (fieldName, { isError, errorMessage }) => {
        setTextError(prevTextError => ({
            ...prevTextError,
            [fieldName]: { isError, errorMessage },
        }));
    };

    // To set field value empty for section 5 and 6
    useEffect(()=>{
        if( data !== null && tableRowSequence !== null){
            var sec = data.sections.find(section => section.sectionId === id);
            
            if((id===5 || id === 6) && editFlag === 0){
                const updatedFields = sec.fieldsList
                    .filter(field => field.multipleSequence === 0 || field.multipleSequence === 1 )
                    .map(field => ({
                        sectionId: sec.sectionId,
                        fieldId: field.fieldId,
                        // sequenceId: tableRowSequence[id], // Assuming this should be dynamically set based on your logic
                        sequenceId: tableInputValue[id] ? tableInputValue[id].length + 1 : tableRowSequence[id], // Assuming this should be dynamically set based on your logic
                        value: '',
                        imageExtension: "",
                        imageSize: "",
                        fieldRefType: "", 
                        fieldRefTypeId: 0,
                        isDelete : false,
                }));
                // setListOfFieldsArray(prevList => [...prevList, ...updatedFields])
                setListOfFieldsArray(updatedFields);
            }
            

        }
    }, [id, data, tableRowSequence])

    const [SnackbarOpen, setSnackbarOpen] = useState(false);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
    };

    // save and proceed for form preview
    const handleNextAdmin = (flag, isMultipleFields, fieldList, sequence, sectionId) => {
        let nextId = id + 1;
        let nextSequence = 1

        if(sectionId === 11){
            let sec = data.sections.find(section =>section.sectionId === sectionId)
            nextSequence = sec.sequence + 1
        }else{
            nextSequence = sequence + 1;
        }
        setLastSaveSection(id)

        if(nextSequence !== null){
            var nextSection = data.sections.find(section => section.sequence === nextSequence);
            // let lastSection = tempData.sections.pop();
            // let lastSectionId = lastSection.sectionId;
            if(nextSection === undefined){
                return;
                // Navigate('/preview', { state: { lastActiveSection: lastActiveSection, CategoryId: CategoryId, CourseId:CourseId, enquiryId: enquiryId, name: name } })
            }  

            if( nextSection.isActive === 1 && nextSection.parentSectionId === 0 && nextSection.sectionId !== 14){
                setId(nextSection.sectionId)
                setCurrentSection(nextSection.sectionId)  //  Set active section id
            } else if(nextSection.sectionId === 14){
                setId(nextSection.sectionId)
                setCurrentSection(nextSection.sectionId)  //  Set active section id
            }
            else{
                let nextSec = data.sections.find(n => n.sequence === nextSection.sequence + 1);

                while (nextSec !== undefined && nextSec.isActive === 0) {
                    let currentSequence = nextSec.sequence + 1;
                    nextSec = data.sections.find(y => y.sequence === currentSequence);
                }

                if(nextSec){
                    setId(nextSec.sectionId)
                    setCurrentSection(nextSec.sectionId)
                }else {
                    console.log('in final else')
                    // Navigate('/preview', { state: { lastActiveSection: lastActiveSection, CategoryId: CategoryId, CourseId:CourseId, enquiryId: enquiryId, name: name } })
                }
            }    
        }

        window.scrollTo(0, 0);
    }
const [errorLabel, setErrorLabel] = useState(false);

    useEffect(() => {
        if(data !== null){
            let dependancyList =  data.fieldDepedancyList

            dependancyList.forEach((element) =>{
                let sec = data.sections.find(section => section.sectionId === parseInt(element.sectionId))
                let field = sec.fieldsList.find(f => f.fieldId === parseInt(element.fieldId))

                if(!inputValue[field.fieldName] && field.value !== ''){
                    element.fieldDepedancies.forEach((dependent) => {
                        let dependentSec = data.sections.find(sec => sec.sectionId === parseInt(dependent.sectionId))
                        let dependentField = dependentSec.fieldsList.find(f => f.fieldId === parseInt(dependent.fieldId))
                        if(dependent.fieldId === '54'){
                            dependentField = dependentSec.fieldsList.find(f => f.fieldDataSource === dependent.fieldRefId)
                        }
                        if(field.value === dependent.value && dependent.dependancyType === '2'){
                            setIsRequired((prevRequired) => ({
                                ...prevRequired,
                                [dependentField.fieldName]: true,
                            }));
                            
                        }else if(field.value === dependent.value && dependent.dependancyType === '4'){
                            setIsRequired((prevRequired) => ({
                                ...prevRequired,
                                [dependentField.fieldName]: false,
                            }));
                        }
                    })

                }
            })
        }
    },[data])

    // Save and Proceed
    const handleContinue = async (flag, isMultipleFields, fieldList, sequence, sectionId) => {
        const nextId = id + 1;
        let nextSequence = sequence + 1;

        if(sectionId === 11 || sectionId === 3){
            let sec = data.sections.find(section => section.sectionId === sectionId)
            nextSequence = sec.sequence + 1
        }

        let isError = false;
        let errorMessage = '';
        let proceedFlag = true;

        if(id === 2){
            if(kycFlag === 1 && isAadhaarVerified === false){
                let sec = data.sections.find(section => section.sectionId === id)
                let field = sec.fieldsList.find(f => f.dbColumnName === 'AadharNo')
                
                if(field !== undefined){
                    isError = true
                    proceedFlag = false
                    errorMessage = 'Aadhaar number verification is mandatory'

                    await updateTextError(field.fieldName, { isError, errorMessage });
                }
            }
        }

        // Validation for any required field is empty in address section
        if(id === 3){
            data.sections.forEach(sec => {
                if(sec.parentSectionId === 3){
                    let fieldList = sec.fieldsList
                    fieldList.forEach( async element => {                          
                        if((isRequired[element.fieldName] ? (isRequired[element.fieldName] === true ) : element.isRequired === 1 )  && element.isActive === 1 && (element.value === null || element.value === "")){
                            if ((element.dbColumnName.toLowerCase() === 'stateid' && modifiedApiDropdownData[element.fieldName].length === 0) ) {
                                console.log('')
                            }else if(element.dbColumnName.toLowerCase() === 'cityid' && modifiedApiDropdownData[element.fieldName].length === 0){
                                console.log('')
                            }else if(element.dbColumnName.toLowerCase() === 'pstateid' && modifiedApiDropdownData[element.fieldName].length === 0){
                                console.log('')
                            }else if(element.dbColumnName.toLowerCase() === 'pcityid' && modifiedApiDropdownData[element.fieldName].length === 0){
                                console.log('')
                            }else if(element.dbColumnName.toLowerCase() === 'districtid' && modifiedApiDropdownData[element.fieldName].length === 0){
                                console.log('')
                            }else if(element.dbColumnName.toLowerCase() === 'pdistrictid' && modifiedApiDropdownData[element.fieldName].length === 0){
                                console.log('')
                            }
                            else{
                                if(inputValue[element.fieldName] === undefined || inputValue[element.fieldName] === '' || Object.keys(inputValue).length === 0){
                                    isError = true;
                                    proceedFlag = false;        //If error exist set flag to false
                                    errorMessage = `${element.fieldName} is required`
                                    await updateTextError(element.fieldName, { isError, errorMessage });
                                }
                            
                            }
                        }
                    });
                }
                
            })
            
        }

        if(id === 11){
            data.sections.forEach(async sec => {
                if(sec.parentSectionId === 11 && sec.sectionId === 13){
                    let fieldList = sec.fieldsList
                    let updatedListOfFieldsArray = subjectValue.filter(field => field.sequence === 1);

                    for (const fieldObject of fieldList) {

                        // if (fieldObject.value && fieldObject.value !== '') {
                        //     continue; // Skip validation if value is not an empty string
                        // }

                        // Parse fieldDataSource to extract sequence IDs
                        const sequenceIds = fieldObject.fieldDataSource.split('+').map(entry => entry.split('=')[0]);
                      
                        // Filter subjectValue array based on sequence IDs
                        let updatedListOfFieldsArray = subjectValue.filter(field =>
                          sequenceIds.includes(Object.keys(field)[0])
                        );
                      
                        // Extract validation rules
                        const validations = fieldObject.fieldValidations.reduce((acc, validation) => {
                          acc[validation.property] = parseInt(validation.value, 10);
                          return acc;
                        }, {});
                      
                        // Validate MinSelections
                        let isValidMin = true;
                        if (validations.MinSelections !== undefined) {
                          isValidMin = updatedListOfFieldsArray.length >= validations.MinSelections;
                        }
                      
                        // Validate MaxSelections
                        let isValidMax = true;
                        if (validations.MaxSelections !== undefined) {
                          isValidMax = updatedListOfFieldsArray.length <= validations.MaxSelections;
                        }

                        if (!isValidMin) {
                            isError = true;
                            errorMessage = `Select at least ${validations.MinSelections} subjects`;
                            await updateTextError(fieldObject.fieldName, { isError, errorMessage });
                        } else if (!isValidMax) {
                            isError = true;
                            errorMessage = `Select a maximum of ${validations.MaxSelections} subjects`;
                            await updateTextError(fieldObject.fieldName, { isError, errorMessage });
                        }
                      };
                }
                
            })
            
        }

        // Add blank value if value is not available
        if (id === 5 || id === 6) {
            var sec = data.sections.find(section => section.sectionId === id);
            let fieldList = sec.fieldsList
            let hasFilledAtLeastOneField = false;
            let hasError = false;
            
            if (!sec) return;
        
            // Remove all fields with sequenceId 0
            let updatedListOfFieldsArray = listOfFieldsArray.filter(field => field.sequenceId !== 0);
            if(id === 5){
                if((tableInputValue[5] === undefined || tableInputValue[5].length === 0) && id === 5 && examMandatoryFlag === 1){
                    if(isError === false){
                        fieldList.forEach( async element => {
                            if((isRequired[element.fieldName] ? (isRequired[element.fieldName] === true ) : element.isRequired === 1 ) && element.isActive === 1 && (element.value === null || element.value === "" || element.multipleSequence === 1)){
                                if(inputValue[element.fieldName] === undefined || inputValue[element.fieldName] === '' || Object.keys(inputValue).length === 0){
                                    isError = true;
                                    proceedFlag = false;        //If error exist set flag to false
                                    errorMessage = `${element.fieldName} is required`
                                    await updateTextError(element.fieldName, { isError, errorMessage });
                                }
                                
                            }
                            
                            
                        });

                        
                    }
                }else{
                    let emptyFieldsCount = 0;

                    fieldList.forEach(element => {
                        if ((isRequired[element.fieldName] ? (isRequired[element.fieldName] === true) : element.isRequired === 1) && element.isActive === 1) {
                          if (inputValue[element.fieldName] === undefined || inputValue[element.fieldName] === '') {
                            emptyFieldsCount++;
                          } 
                        }
                    });

                    if (Object.keys(inputValue).length === 0) {
                    // skip if all fields are empty
                    proceedFlag = true
                    // return;
                    }else{
                        fieldList.forEach(element => {
                        if ((isRequired[element.fieldName] ? (isRequired[element.fieldName] === true) : element.isRequired === 1) && element.isActive === 1) {
                            if (inputValue[element.fieldName] === undefined || inputValue[element.fieldName] === '') {
                            emptyFieldsCount++;
                            if (element.value === null || element.value === "" || element.multipleSequence === 1) {
                                isError = true;
                                errorMessage = `${element.fieldName} is required`;
                                updateTextError(element.fieldName, { isError, errorMessage });
                            }
                            } else{
                            hasFilledAtLeastOneField = true;
                            }
                        }
                    })
                    }
                    
                }
            }else if(id === 6){
                if((tableInputValue[6] === undefined || tableInputValue[6].length === 0) && id === 6 && workMandatoryFlag === 1){
                    if(isError === false){
                        fieldList.forEach( async element => {
                            if((isRequired[element.fieldName] ? (isRequired[element.fieldName] === true ) : element.isRequired === 1 ) && element.isActive === 1 && (element.value === null || element.value === "" || element.multipleSequence === 1)){
                                if(inputValue[element.fieldName] === undefined || inputValue[element.fieldName] === '' || Object.keys(inputValue).length === 0){
                                    isError = true;
                                    proceedFlag = false;        //If error exist set flag to false
                                    errorMessage = `${element.fieldName} is required`
                                    await updateTextError(element.fieldName, { isError, errorMessage });
                                }
                                
                            }
                            
                            
                        });
                    }
                }else{
                    let emptyFieldsCount = 0;

                    fieldList.forEach(element => {
                        if ((isRequired[element.fieldName] ? (isRequired[element.fieldName] === true) : element.isRequired === 1) && element.isActive === 1) {
                          if (inputValue[element.fieldName] === undefined || inputValue[element.fieldName] === '') {
                            emptyFieldsCount++;
                          } 
                        }
                    });
                    if (Object.keys(inputValue).length === 0) {
                        // skip if all fields are empty
                        proceedFlag = true
                    }else{
                        fieldList.forEach(element => {
                        if ((isRequired[element.fieldName] ? (isRequired[element.fieldName] === true) : element.isRequired === 1) && element.isActive === 1) {
                            if (inputValue[element.fieldName] === undefined || inputValue[element.fieldName] === '') {
                            emptyFieldsCount++;
                            if (element.value === null || element.value === "" || element.multipleSequence === 1) {
                                isError = true;
                                errorMessage = `${element.fieldName} is required`;
                                updateTextError(element.fieldName, { isError, errorMessage });
                            }
                            } else{
                            hasFilledAtLeastOneField = true;
                            }
                        }
                    })
                    }
                }
            }else if(Object.keys(tableInputValue).length !== 0 && Object.keys(tableInputValue).includes(String(id)) === false){
                if(isError === false){
                    fieldList.forEach( async element => {
                        if((element.isRequired === 1 || isRequired[element.fieldName]) && element.isActive === 1 && (element.value === null || element.value === "" || element.multipleSequence === 1)){
                            if(inputValue[element.fieldName] === undefined || inputValue[element.fieldName] === '' || Object.keys(inputValue).length === 0){
                                isError = true;
                                proceedFlag = false;        //If error exist set flag to false
                                errorMessage = `${element.fieldName} is required`
                                await updateTextError(element.fieldName, { isError, errorMessage });
                            }
                            
                        }
                        
                        
                    });
                }
            }else{
                // Collect unique sequence IDs from the updated list
                const sequenceIds = [...new Set(updatedListOfFieldsArray.map(field => field.sequenceId))];

                const updatedFields = fieldList.map(field => ({
                    sectionId: field.sectionId,
                    fieldId: field.fieldId,
                    sequenceId: tableRowSequence[field.sectionId],
                    value: inputValue[field.fieldName] || '',
                    imageExtension: "",
                    imageSize: "",
                    fieldRefTypeId: 0,
                    fieldRefType: "",
                    isDelete : false,
                }));
                // sequenceIds.forEach(sequenceId => {
                //     const fieldsForSequence = updatedListOfFieldsArray.filter(field => field.sequenceId === sequenceId);
            
                //     // Create a template for all fields in the section
                //     const template = sec.fieldsList.reduce((acc, field) => {
                //         acc[field.fieldName] = '';
                //         return acc;
                //     }, {});
            
                //     // Fill in existing values for this sequence
                //     const completeInputValue = { ...template };
                //     console.log('completeInputValue: ', completeInputValue)
                //     console.log('fieldsForSequence: ', fieldsForSequence)
                //     fieldsForSequence.forEach(field => {
                //         const fieldDef = sec.fieldsList.find(f => f.fieldId === field.fieldId);
                //         if (fieldDef) {
                //             completeInputValue[fieldDef.fieldName] = field.value;
                //         }
                //     });
            
                //     // Convert the complete input value object into the required format for `setListOfFieldsArray`
                //     const updatedFields = sec.fieldsList.map(field => ({
                //         sectionId: sec.sectionId,
                //         fieldId: field.fieldId,
                //         sequenceId: sequenceId,
                //         value: completeInputValue[field.fieldName] || '',
                //         imageExtension: "",
                //         imageSize: ""
                //     }));
            
                //     // Update the array with the complete set of fields for the current sequence
                //     updatedListOfFieldsArray = [
                //         ...updatedListOfFieldsArray.filter(field => field.sequenceId !== sequenceId),
                //         ...updatedFields
                //     ];
                // });

                // Update state with the new list
                setListOfFieldsArray([]);
                setListOfFieldsArray(updatedListOfFieldsArray);
            }
        
            
        }

        if (id === 7){
            if(annextureFlag === false){
                proceedFlag = false;
                setSnackbarOpen(true);
            }
        }
        if (id === 14) {
            let sec = data.sections.find(section => section.sectionId === id);
            
            if (sec && sec.fieldsList) {
                let field = sec.fieldsList.find(f => f.fieldId === 74); // Adjust the fieldId if necessary
        
                if (field && field.fieldValidations) {
                    const validations = field.fieldValidations.reduce((acc, validation) => {
                        acc[validation.property] = parseInt(validation.value, 10);
                        return acc;
                    }, {});
        
                    // Validate MinSelections
                    let isValidMin = true;
                    if (validations.MinSelections !== undefined) {
                        isValidMin = listOfFieldsArray.length >= validations.MinSelections;
                    }
        
                    // Validate MaxSelections
                    let isValidMax = true;
                    if (validations.MaxSelections !== undefined) {
                        isValidMax = listOfFieldsArray.length <= validations.MaxSelections;
                    }
        
                    if (!isValidMin) {
                        isError = true;
                        errorMessage = `Select at least ${validations.MinSelections} opportunity`;
                        setSnackbarOpen(true);
                        await updateTextError(field.fieldName, { isError, errorMessage });
                    } else if (!isValidMax) {
                        isError = true;
                        errorMessage = `Select a maximum of ${validations.MaxSelections} opportunity`;
                        setSnackbarOpen(true);
                        await updateTextError(field.fieldName, { isError, errorMessage });
                    }
                } else {
                    console.log('fieldValidations is undefined for field:', field);
                }
            } else {
                console.log('sec or sec.fieldsList is undefined');
            }
        }

        // Validation for any required field is empty
        fieldList.forEach( async element => {
            if(id !== 5 && id !== 6 && id !== 3){
                if(
                    (isRequired[element.fieldName] && isRequired[element.fieldName] === true) || 
                    (isRequired[element.fieldName] === undefined && element.isRequired === 1)
                ){
                    if(element.isActive === 1 && (element.value === null || element.value === "") && element.fieldType !== 'CheckList'){
                        if( element.fieldType === 'File'){
                            setValidationError((prevState) => ({
                                ...prevState,
                                [element.fieldName] : 'Invalid'
                            }))
                        }
                        if(inputValue[element.fieldName] === undefined || inputValue[element.fieldName] === '' || Object.keys(inputValue).length === 0){
                            isError = true;
                            proceedFlag = false;        //If error exist set flag to false
                            errorMessage = `${element.fieldName} is required`
                            await updateTextError(element.fieldName, { isError, errorMessage });
                        }
                        
                    }
                }

                if((isRequired[element.fieldName] ? (isRequired[element.fieldName] === true ) : element.isRequired === 1 )  && element.isActive === 1 && element.fieldType === 'CheckList'){
                    await Promise.all(element.fieldValidations.map(async x => {
                        const trueCount = Object.values(checkedItems[element.fieldName]).filter(value => value).length;
                        switch (x.property) {
                        case 'MinSelections':
                          if (trueCount < parseInt(x.value)) {
                            isError = true;
                            proceedFlag = false;
                            errorMessage = `Please select atleast ${x.value} options`;
                            await updateTextError(element.fieldName, { isError, errorMessage });
                          }else{
                            isError = false;
                            proceedFlag = true;
                            errorMessage = '';
                            await updateTextError(element.fieldName, { isError, errorMessage });
                          }
                          break;
                        case 'MaxSelections':
                          if (trueCount > parseInt(x.value)) {
                            isError = true;
                            proceedFlag = false;
                            errorMessage = `You can only select up to ${x.value} options`;
                            await updateTextError(element.fieldName, { isError, errorMessage });
                          }else{
                            isError = false;
                            proceedFlag = true;
                            errorMessage = '';
                            await updateTextError(element.fieldName, { isError, errorMessage });
                          }
                          break;
                      }
                    }));
                }

                if((isRequired[element.fieldName] ? (isRequired[element.fieldName] === true ) : element.isRequired === 1 ) && element.isActive === 1 && element.fieldType === 'CheckBox'){
                    const elementId = document.getElementById(element?.dbColumnName);
                    if(elementId){
                        if(elementId.checked === false){
                            isError = true;
                            proceedFlag = false; 
                            errorMessage = `${element.fieldName} is required`
                            // alert("Stop")
                            setErrorLabel(true);
                            await updateTextError(element.fieldName, { isError, errorMessage });
                        }
                    }
                }
            }
            
        });

        let filteredSections = data.sections.filter(section => section.parentSectionId === 0 && section.isActive === 1);
        let sortedSections = filteredSections.sort((a, b) => b.sequence - a.sequence);
        let lastSection = sortedSections[0];

        if(sortedSections[0].sectionId === 14 && Insterested === '1'){
            lastSection = sortedSections[0]
        }else if(sortedSections[0].sectionId === 14 && Insterested !== '1'){
            lastSection = sortedSections[1]
        }

        // if(id !== lastSection.sectionId){
            let requireFlag = 0
            let renderToSection = ''
            if(sectionId === lastSection.sectionId){
                data.sections.forEach((sec) => {
                    if (sec.isMultipleFields === 1) {
                    sec.fieldsList = sec.fieldsList.filter(field => 
                        field.multipleSequence === Math.min(...sec.fieldsList.filter(f => f.isActive === 1).map(f => f.multipleSequence))
                    );
                    }
                    sec.fieldsList.forEach((element) => {
                    if (((isRequired[element.fieldName] && isRequired[element.fieldName] === true) || 
                        (isRequired[element.fieldName] === undefined && element.isRequired === 1)) && (element.value === '') && id !== element.sectionId) {
                        renderToSection = element
                        requireFlag = 1
                        return; 
                    }
                    })
                })
                
            }


            let renderSection = data.sections.find(section => section.sectionId === renderToSection.sectionId)
            setEditSection(renderSection)

            if(requireFlag === 1){
                setIsModalOpen(true)
                setTitleMsg('')
                setContent(`Please complete all required fields in the ${renderSection.sectionName}. You will be redirected to the incomplete section`)
                setModalContentIdentity('confirmation')
                return;
            }
        

        // If error doesn't exist save and proceed
        if(proceedFlag === true && inputValue !== null && isError === false){
            setLoading(true)
            saveFields(id, isMultipleFields, nextSequence, nextId, flag);
        }
    }

    // save field and section wise with model
    const saveFields = async(id, isMultipleFields, nextSequence, nextId, flag) => {
        // console.log(listOfFieldsArray.filter(item => item.sectionId === id))
        const requestModel =  {
            "enquiryId": enquiryId,
            "userId": loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit' ? parseInt(sessionStorage.getItem('userId')) : 0,
            "admissionFormId": admissionFormId,
            "listOfSection": [
                {
                "sectionId": id,
                "isMultiple": isMultipleFields,
                "listOfFields": id === 4 ? [] : ((id === 3 || id === 11) ? listOfFieldsArray.filter(item => item.sectionId !== 5 && item.sectionId !== 6) : (listOfFieldsArray.filter(item => item.sectionId === id)))
                }
            ]
        }
        // Section wise Save API
        try{
            const { statusCode,loading,error } = await CallAPI(saveField, 'POST', { requestModel })
            setSaveApiResponse(statusCode);

            if(statusCode === 200){
                setLastSaveSection(id)
                setInputValue({})
                setTableValue({})
                setFile(null)

                try {
                    const { data,loading,error } = await CallAPI(Form, 'POST', { 
                         "unvId": univId,
                         "categoryId": CategoryId,
                         "courseId": CourseId,
                         "enquiryId": enquiryId,
                         "mobileNo": mobileNo,
                         "dob": dateOfBirth,
                         "name": name
                     }
                   ); 
                     setData(data);
                     setLastActiveSec(data.lastActiveSection)
                     sessionStorage.setItem('lastActiveSection', data.lastActiveSection)
                     setTimeout(() => {
                        setLoading(false); // Set loading state to false after a short delay
                      }, 1000);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setTimeout(() => {
                        setLoading(false); // Set loading state to false after a short delay
                      }, 5000);
                }
                    if(nextSequence !== null){
                        let currentSec = data.sections.find(sec => sec.sectionId === id)        // For section name in save message 
                        var nextSection = data.sections.find(section => section.sequence === nextSequence && section.parentSectionId === 0);
                        console.log(nextSection)
                        // let lastSection = tempData.sections.pop();
                        // let lastSectionId = lastSection.sectionId;
                        if(nextSection === undefined){
                            Navigate('/preview', { state: { lastActiveSection: lastActiveSection, CategoryId: CategoryId, CourseId:CourseId, enquiryId: enquiryId, name: name } })
                        }  
                        if( nextSection.isActive === 1 && nextSection.parentSectionId === 0 && nextSection.sectionId !== 14){
                            setId(nextSection.sectionId)
                            setCurrentSection(nextSection.sectionId)  //  Set active section id
                            setActiveId(nextSection.sectionId)
                            setSnackbarOpen(true);
                            setSuccessMessage(
                                <Box sx={{display: 'flex'}}>
                                    <CheckCircleIcon sx={{color: '#17AA00', 
                                        '& .MuiSvgIcon-root': {
                                            fill: '#FFFFFF', // white tick
                                        },
                                    }}/>
                                    <Typography>&nbsp;your {currentSec.sectionName} have been successfully saved.</Typography>
                                </Box>
                            )
                        } else if(nextSection.sectionId === 14 && Insterested === '1'){
                            setId(nextSection.sectionId)
                            setCurrentSection(nextSection.sectionId)  //  Set active section id
                        }
                        else{
                            let nextSec = data.sections.find(n => n.sequence === nextSection.sequence + 1);

                            while (nextSec !== undefined && nextSec.isActive === 0) {
                                let currentSequence = nextSec.sequence + 1;
                                nextSec = data.sections.find(y => y.sequence === currentSequence);
                            }

                            if(nextSec && nextSec.sectionId !== 14){
                                setId(nextSec.sectionId)
                                setCurrentSection(nextSec.sectionId)
                            }else if(nextSec && nextSec.sectionId === 14 && Insterested === '1'){
                                setId(nextSec.sectionId)
                                setCurrentSection(nextSec.sectionId)
                            }else {
                                Navigate('/preview', { state: { lastActiveSection: lastActiveSection, CategoryId: CategoryId, CourseId:CourseId, enquiryId: enquiryId, name: name } })
                            }
                        }    
                    }else{
                        // // Set the section based on sectionId from navbar
                        setId(nextId);
                        setCurrentSection(nextId)
                    }
                    
                    if(flag === 1){
                        setCurrentSection(nextId);
                    }
    
                    window.scrollTo(0, 0);      //Scroll on top of the page on save and continue
                     // }
            }
        }catch(error){
            console.log(error);
        }
    }


    // Add filled values into table
    const addData = (sectionId) => {
        let sec = data.sections.find(section => section.sectionId === sectionId);
        let fieldList = sec.fieldsList
        let isError = false;
        let errorMessage = '';
        let proceedFlag = true;

        if(isError === false){
            fieldList.forEach( async element => {
                if((element.isRequired === 1 || isRequired[element.fieldName]) && element.isActive === 1 && (element.value === null || element.value === "" || element.multipleSequence !== 1)){
                    if(inputValue[element.fieldName] === undefined || inputValue[element.fieldName] === '' || Object.keys(inputValue).length === 0){
                        isError = true;
                        proceedFlag = false;        //If error exist set flag to false
                        errorMessage = `${element.fieldName} is required`
                        if((loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))){
                            await updateTextError(element.fieldName, { isError, errorMessage });
                        }
                    }
                    
                }
                
                
            });
        }

        if(Object.keys(listOfFields).length > 0 && proceedFlag === true && isError === false){           // If all inputs are not null then add values in table
            const template = sec.fieldsList.reduce((acc, field) => {
                acc[field.fieldName] = "";
                return acc;
            }, {});
            // Merge the template with the inputValue
            const completeInputValue = { ...template, ...tableValue };

            if(editFlag === 1){
                replaceRecord(sectionId, tableRowSequence[sectionId]-1)
            }
    
            setTableInputValue((prevInputValue) => ({
                ...prevInputValue,
                [sectionId]: [...(prevInputValue[sectionId] || []), completeInputValue]
            }));
    
            const completeListOfFields = { ...template, ...listOfFields };

            setListOfFields((prevListOfFields) => ({
                ...prevListOfFields, completeListOfFields
            }));

            console.log('completeListOfFields in add', completeListOfFields)

            setInputValue({})               // After adding inputs to table set input fields empty
            setSelectedDate(null)           // set selected date to null
            setTableValue({})
            console.log('editFlag', editFlag)
            if(editFlag === 0){
                console.log('in 2 tableInputValue[sectionId].length + 2', tableInputValue[sectionId] ? tableInputValue[sectionId].length + 2 : 'not present')
                setTableRowSequence((prevTableRowSequence) => ({
                    ...prevTableRowSequence,
                    [sectionId]: tableInputValue[sectionId] ? tableInputValue[sectionId].length + 2 : 2
                    // [sectionId]: tableRowSequence[sectionId] + 1,
                }));
                fieldWiseSave()
            }else{
                console.log('in add else', tableInputValue[sectionId].length)
                const lengthOfArray = tableInputValue[sectionId].length;
                console.log('in 3 lengthofarray+1', lengthOfArray+1)
                setTableRowSequence((prevTableRowSequence) => ({
                    ...prevTableRowSequence,
                    [sectionId]: lengthOfArray+1,
                }));
                
                fieldWiseSave()
            }
            
            setEditFlag(0)
            
        }
        isError = false;
        proceedFlag = true;
        
    }


    // For multiple record section - if value is fetched from db add into table
    useEffect(() => {
        if (data !== null) {
            const newTableInputValue = {};
            data.sections.forEach((section) => {
                if (section.sectionId === 5 || section.sectionId === 6) {
                    const sortedFields = section.fieldsList
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => a.sequence - b.sequence); // Assuming 'sequence' is the correct property name
        
                    sortedFields.forEach((field) => {
                        // Check if the field value is non-null
                        if (field.value !== null && field.value !== undefined && field.value !== "") {
                            // Initialize the sectionData array if it doesn't exist
                            if (!newTableInputValue[section.sectionId]) {
                                newTableInputValue[section.sectionId] = [];
                            }
    
                            // Ensure the array has enough elements for the given sequence
                            while (newTableInputValue[section.sectionId].length < field.multipleSequence) {
                                newTableInputValue[section.sectionId].push({});
                            }
    
                            if(field.fieldType === 'RadioButton' || field.fieldType === 'DropDown'){
                                // Add field data to the appropriate sequence-based entry
                                newTableInputValue[section.sectionId][field.multipleSequence - 1][field.fieldName] = field.valueLabel;
                            }else{
                                // Add field data to the appropriate sequence-based entry
                                newTableInputValue[section.sectionId][field.multipleSequence - 1][field.fieldName] = field.value;
                            }
                            
                        }                     
                        
                    });
                    const lengthOfArray = newTableInputValue[section.sectionId] ? newTableInputValue[section.sectionId].length : 0;
                    setTableRowSequence((prevTableRowSequence) => ({
                        ...prevTableRowSequence,
                        [section.sectionId]: lengthOfArray + 1,
                    }));
    
                }
            });
            setTableInputValue(newTableInputValue);

        }
    }, [data]);
    

    // on edit add table data into inputFeilds
    const editRecord = (record, sectionId, index) => {
        let sec = data.sections.find(section => section.sectionId === sectionId)
        let fieldList = sec.fieldsList.filter(field => field.multipleSequence === Math.min(...sec.fieldsList.filter(field => field.isActive === 1).map(field => field.multipleSequence)));
        let newListOfFieldsArray = []

        fieldList.forEach(field => {
            if(field.fieldType === 'RadioButton' || field.fieldType === 'DropDown'){
                if(record[field.fieldName] !== ''){
                    console.log(field.fieldDataSource)
                    const dataSourceArray = field.fieldDataSource.split('+');
                    const keyValuePairs = dataSourceArray.map(item => {
                        const [key, value] = item.split('=');
                        return { key, value };
                    });
                    const matchingKey = keyValuePairs.find(pair => pair.value === record[field.fieldName])?.key;

                    newListOfFieldsArray.push({
                        'sectionId': sectionId,
                        'fieldId': field.fieldId,
                        'sequenceId': index + 1,
                        'value': matchingKey || "",  // Ensure to get the correct value from element
                        "imageExtension": "",
                        "imageSize": "",
                        'fieldTypeId': field.fieldTypeId,
                        "fieldRefType": "", 
                        "fieldRefTypeId": 0,
                        "isDelete" : false,
                    });

                    setListOfFields((prevListOfFields) => ({
                        ...prevListOfFields,
                        'sectionId': sectionId,
                        'fieldId': field.fieldId,
                        'sequenceId': index + 1,
                        'value': matchingKey || "",  // Ensure to get the correct value from element
                        "imageExtension": "",
                        "imageSize": "",
                        'fieldTypeId': field.fieldTypeId,
                        "fieldRefType": "", 
                        "fieldRefTypeId": 0,
                        "isDelete" : false,
                    }))
                }
                
            }else{
                newListOfFieldsArray.push({
                    'sectionId': sectionId,
                    'fieldId': field.fieldId,
                    'sequenceId': index + 1,
                    'value': record[field.fieldName] || "",  // Ensure to get the correct value from element
                    "imageExtension": "",
                    "imageSize": "",
                    'fieldTypeId': field.fieldTypeId,
                    "fieldRefType": "", 
                    "fieldRefTypeId": 0,
                    "isDelete" : false,
                });

                setListOfFields((prevListOfFields) => ({
                    ...prevListOfFields,
                    'sectionId': sectionId,
                    'fieldId': field.fieldId,
                    'sequenceId': index + 1,
                    'value': record[field.fieldName] || "",  // Ensure to get the correct value from element
                    "imageExtension": "",
                    "imageSize": "",
                    'fieldTypeId': field.fieldTypeId,
                    "fieldRefType": "", 
                    "fieldRefTypeId": 0,
                    "isDelete" : false,
                }))
            }

            // newListOfFieldsArray.push({
            //     'sectionId': sectionId,
            //     'fieldId': field.fieldId,
            //     'sequenceId': index + 1,
            //     'value': record[field.fieldName] || "",  // Ensure to get the correct value from element
            //     "imageExtension": "",
            //     "imageSize": "",
            //     "fieldRefType": "", 
            //     "fieldRefTypeId": 0,
            //     "isDelete" : false,
            // });

            // setListOfFields((prevListOfFields) => ({
            //     ...prevListOfFields,
            //     'sectionId': sectionId,
            //     'fieldId': field.fieldId,
            //     'sequenceId': index + 1,
            //     'value': record[field.fieldName] || "",  // Ensure to get the correct value from element
            //     "imageExtension": "",
            //     "imageSize": "",
            //     "fieldRefType": "", 
            //     "fieldRefTypeId": 0,
            //     "isDelete" : false,
            // }))

            if(field.fieldType === 'RadioButton' || field.fieldType === 'DropDown'){
                let dataSource = field.fieldDataSource
                const map = dataSource.split('+').reduce((acc, pair) => {
                    const [key, value] = pair.split('=');
                    acc[value] = key;
                    return acc;
                  }, {});

                  setInputValue((prevRecord) => ({
                    ...prevRecord,
                    [field.fieldName]: map[record[field.fieldName]]
                }));
            }else{
                setInputValue((prevRecord) => ({
                    ...prevRecord,
                    [field.fieldName]: record[field.fieldName] !== undefined ? record[field.fieldName] : ''
                }));
            }
        });

        setTableValue((prevTableValue) => ({
            ...prevTableValue,
            ...record
        }))
        setListOfFieldsArray(prevList => [...prevList, ...newListOfFieldsArray])

        setEditFlag(1);
        console.log('in 5 index+1', index+1)
        setTableRowSequence((prevTableRowSequence) => ({
            ...prevTableRowSequence,
            [sectionId]: index + 1,
        }));

        window.scroll(0, 0);
        
    };

    const replaceRecord = async (sectionId, rowIndex) => {
        console.log(sectionId, rowIndex)
        let sec = data.sections.find(section => section.sectionId === sectionId)
        let fieldList = sec.fieldsList.filter(field => field.multipleSequence === 1);
        const modifiedArray = [...tableInputValue[sectionId]]; // Get the array for the specified sectionId
        modifiedArray.splice(rowIndex, 1); // Remove one element at the specified rowIndex

        const newModifiedListOfFieldsArray = []

        modifiedArray.forEach((element, index) => {
            fieldList.forEach(field => {
                if(field.fieldType === 'RadioButton' || field.fieldType === 'DropDown'){
                    if(element[field.fieldName] !== ''){
                        console.log(field.fieldDataSource)
                        const dataSourceArray = field.fieldDataSource.split('+');
                        const keyValuePairs = dataSourceArray.map(item => {
                            const [key, value] = item.split('=');
                            return { key, value };
                        });
                        const matchingKey = keyValuePairs.find(pair => pair.value === element[field.fieldName])?.key;

                        newModifiedListOfFieldsArray.push({
                            'sectionId': sectionId,
                            'fieldId': field.fieldId,
                            'sequenceId': index+1,
                            'value': matchingKey || "",  // Ensure to get the correct value from element
                            "imageExtension": "",
                            "imageSize": "",
                            "fieldRefType": "", 
                            "fieldRefTypeId": 0,
                            "isDelete" : false,
                        });
                    }
                    
                }else{
                    newModifiedListOfFieldsArray.push({
                        'sectionId': sectionId,
                        'fieldId': field.fieldId,
                        'sequenceId': index+1,
                        'value': element[field.fieldName] || "",  // Ensure to get the correct value from element
                        "imageExtension": "",
                        "imageSize": "",
                        "fieldRefType": "", 
                        "fieldRefTypeId": 0,
                        "isDelete" : false,
                    });
                }
                // newModifiedListOfFieldsArray.push({
                //     'sectionId': sectionId,
                //     'fieldId': field.fieldId,
                //     'sequenceId': index + 1,
                //     'value': element[field.fieldName] || "",  // Ensure to get the correct value from element
                //     "imageExtension": "",
                //     "imageSize": "",
                //     "fieldRefType": "", 
                //     "fieldRefTypeId": 0,
                //     "isDelete" : false,
                // });
            });
        });

        // console.log('newModifiedListOfFieldsArray in replace', newModifiedListOfFieldsArray)

        setTableInputValue(prevInputValue => ({
            ...prevInputValue,
            [sectionId]: modifiedArray // Update the state with the modified array for the specified sectionId
        }));
        setEditFlag(0)
        // setListOfFieldsArray(newModifiedListOfFieldsArray)
        setDeleteFlag(1);   
    
        
    };
    
    // let row = 0
    // Delete row of table based on section id
    const deleteRecord = async (sectionId, rowIndex) => {
        setIsModalOpen(true)
        setTitleMsg('Delete Confirmation')
        setContent('Are you sure you want to delete this record?')
        setModalContentIdentity('alert')
        // row = rowIndex
        setRow(rowIndex)
           
        // setEditFlag(0)
        // setListOfFieldsArray(newListOfFieldsArray)
        // setDeleteFlag(1); 
        
    };

    useEffect(() => {
        if(modalResponse === true && (id === 5 || id === 6)){
            deleteRecordContinue(id, row)
        }else if(modalResponse === true){
            let sec = data.sections.find(section => section.sectionId === editSection.sectionId)
            if(sec.parentSectionId !== 0){
                setId(sec.parentSectionId)
                setCurrentSection(sec.parentSectionId)
            }else{
                setId(editSection.sectionId)
                setCurrentSection(editSection.sectionId)
            }
        }
        setModalResponse(false)
    }, [modalResponse])

    const deleteRecordContinue = async(sectionId, rowIndex) => {
        if(modalResponse === true){
            let sec = data.sections.find(section => section.sectionId === sectionId)
            let fieldList = sec.fieldsList.filter(field => field.multipleSequence === Math.min(...sec.fieldsList.filter(field => field.isActive === 1).map(field => field.multipleSequence)))
            // let fieldList = sec.fieldsList.filter(field => field.multipleSequence === 1);
            const modifiedArray = [...tableInputValue[sectionId]]; // Get the array for the specified sectionId
            let deleteObj = modifiedArray[rowIndex]
            modifiedArray.splice(rowIndex, 1); // Remove one element at the specified rowIndex
            
            const newListOfFieldsArray = [];
            const newModifiedListOfFieldsArray = []
    
            if(editFlag === 1){
                modifiedArray.forEach((element, index) => {
                    fieldList.forEach(field => {
                        newModifiedListOfFieldsArray.push({
                            'sectionId': sectionId,
                            'fieldId': field.fieldId,
                            'sequenceId': rowIndex + 1,
                            'value': element[field.fieldName] || "",  // Ensure to get the correct value from element
                            "imageExtension": "",
                            "imageSize": "",
                            "fieldRefType": "", 
                            "fieldRefTypeId": 0,
                            "isDelete" : false,
                        });
                    });
                });
        
                setTableInputValue(prevInputValue => ({
                    ...prevInputValue,
                    [sectionId]: modifiedArray // Update the state with the modified array for the specified sectionId
                }));
                setEditFlag(0)
                setListOfFieldsArray(newModifiedListOfFieldsArray)
                setDeleteFlag(1);   
            }else{
                Object.keys(deleteObj).forEach((key, index) => {
                    const field = fieldList.find(f => f.fieldName === key);
                    if (field) {
                        newListOfFieldsArray.push({
                            'sectionId': sectionId,
                            'fieldId': field.fieldId,
                            'sequenceId': rowIndex+1, // assuming sequenceId is always 1
                            'value': deleteObj[key],
                            "imageExtension": "",
                            "imageSize": "",
                            "fieldRefType": "", 
                            "fieldRefTypeId": 0,
                            "isDelete" : true,
                        });
                    }
                });

                modifiedArray.forEach((element, index) => {
                    fieldList.forEach(field => {
                        if(field.fieldType === 'RadioButton' || field.fieldType === 'DropDown'){
                            if(element[field.fieldName] !== ''){
                                console.log(field.fieldDataSource)
                                const dataSourceArray = field.fieldDataSource.split('+');
                                const keyValuePairs = dataSourceArray.map(item => {
                                    const [key, value] = item.split('=');
                                    return { key, value };
                                });
                                const matchingKey = keyValuePairs.find(pair => pair.value === element[field.fieldName])?.key;

                                newListOfFieldsArray.push({
                                    'sectionId': sectionId,
                                    'fieldId': field.fieldId,
                                    'sequenceId': index + 1,
                                    'value': matchingKey || "",  // Ensure to get the correct value from element
                                    "imageExtension": "",
                                    "imageSize": "",
                                    "fieldRefType": "", 
                                    "fieldRefTypeId": 0,
                                    "isDelete" : false,
                                });
                            }
                            
                        }else{
                            newListOfFieldsArray.push({
                                'sectionId': sectionId,
                                'fieldId': field.fieldId,
                                'sequenceId': index + 1,
                                'value': element[field.fieldName] || "",  // Ensure to get the correct value from element
                                "imageExtension": "",
                                "imageSize": "",
                                "fieldRefType": "", 
                                "fieldRefTypeId": 0,
                                "isDelete" : false,
                            });
                        }
                        
                    });
                });
    
                const requestModel =  {
                    "enquiryId": enquiryId,
                    "userId": loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit' ? parseInt(sessionStorage.getItem('userId')) : 0,
                    "admissionFormId": admissionFormId,
                    "listOfSection": [
                        {
                        "sectionId": sectionId,
                        "isMultiple": 1,
                        "listOfFields": newListOfFieldsArray
                        }
                    ]
                }
        
                try{
                    const { statusCode,loading,error } = await CallAPI(saveField, 'POST', { requestModel })
                    setSaveApiResponse(statusCode);
                    if(statusCode === 200){
                        setTableInputValue(prevInputValue => ({
                            ...prevInputValue,
                            [sectionId]: modifiedArray // Update the state with the modified array for the specified sectionId
                        }));
                        setEditFlag(0)
                        if(newModifiedListOfFieldsArray.length === 0){
                            setDeleteSequenceFlag(1)
                            const updatedFields = fieldList
                                .filter(field => field.multipleSequence === 0 || field.multipleSequence === 1 )
                                .map(field => ({
                                    sectionId: sec.sectionId,
                                    fieldId: field.fieldId,
                                    sequenceId: tableInputValue[id].length, // Assuming this should be dynamically set based on your logic
                                    value: '',
                                    imageExtension: "",
                                    imageSize: "",
                                    fieldRefType: "", 
                                    fieldRefTypeId: 0,
                                    isDelete : false,
                            }));
                            setListOfFieldsArray(updatedFields);
                        }else{
                            setListOfFieldsArray(newListOfFieldsArray)
                        }
                        setDeleteFlag(1);           // Set the delete flag to 1 to call save function
                        setModalResponse(false);
                    }
                }
                catch(error){
                    console.log(error);
                }
            }
        }
    }

    useEffect(() => {
        if(deleteFlag === 1){
            fieldWiseSave();
            setListOfFields({})
        }
        setDeleteFlag(0)
    },[deleteFlag])

    const [sections, setSections] = useState([]);

    useEffect(() => {
		if (data !== null) {
			const updatedSections = data.sections.map(section => ({ ...section }));
			updatedSections.forEach((section) => {
                
				if (section.parentSectionId !== 0) {
                    const parentSection = updatedSections.find(parent => parent.sectionId === section.parentSectionId);
                    if (parentSection) {
                        parentSection.fieldsList = parentSection.fieldsList.concat(section.fieldsList);
                    }

                }
			});
              

            updatedSections.forEach(section => {
                if(section.sectionId === 3){
                    section.fieldsList.sort((a, b) => a.sequence - b.sequence)
                }
            })
			setSections(updatedSections);
		}
	}, [data]);

    const checkboxRef = useRef(null);
 
    // const copyAddress = () => {
    //     let corrSection = data.sections.find((section) => section.sectionId === 9)
    //     let perSection = data.sections.find((section) => section.sectionId === 10)

    //     console.log('corrSection, perSection', corrSection, perSection)

    //     const mappedFields = {};

    //     for (let i = 0; i < corrSection.fieldsList.length; i++) {
    //     if (corrSection.fieldsList[i].fieldDataSource === perSection.fieldsList[i].fieldDataSource) {
    //         mappedFields[corrSection.fieldsList[i].fieldId] = perSection.fieldsList[i].fieldId;
    //     }
    //     }

    //     console.log(mappedFields);
    // }

    // copy corresponding address same as permanent
    const copyAddress = () => {
        let checkbox = document.getElementById('check');
        let corrSection = data.sections.find((section) => section.sectionId === 9)
        let perSection = data.sections.find((section) => section.sectionId === 10)

        if (checkboxRef.current.checked === true) {
            const fieldMapping = {};

            // const fieldMapping = {
            //     11: 13,
            //     12: 14,
            //     26: 42,
            //     27: 43,
            //     28: 44,
            //     29: 45
            // };
    
            for (let i = 0; i < corrSection.fieldsList.length; i++) {
                if (corrSection.fieldsList[i].fieldDataSource === perSection.fieldsList[i].fieldDataSource) {
                    fieldMapping[corrSection.fieldsList[i].fieldId] = perSection.fieldsList[i].fieldId;
                }
            }

            const updatedValues = [...listOfFieldsArray];

            if(updatedValues.length !== 0){
                listOfFieldsArray.forEach((element) => {
                    if (fieldMapping.hasOwnProperty(element.fieldId)) {
                        const mappedFieldId = fieldMapping[element.fieldId];
                        const originalValue = element.value;
                        const sec = data.sections.find(section => section.sectionId === 10)
                        const f = sec.fieldsList.find(field => field.fieldId === mappedFieldId)

                        // Find the target element in the updatedValues array
                        const targetElement = updatedValues.find(el => el.fieldId === mappedFieldId);
                        if (targetElement) {
                            targetElement.value = originalValue;
                            
                        } else {
                            // If the target element does not exist, create a new entry for it
                            updatedValues.push({
                                // sectionId: (mappedFieldId === 13 || mappedFieldId === 14 || mappedFieldId === 42 || mappedFieldId === 43 || mappedFieldId === 44 || mappedFieldId === 45) ? 10 : element.sectionId,
                                sectionId: 10,
                                fieldId: mappedFieldId,
                                sequenceId: element.sequenceId,
                                value: originalValue,
                                imageExtension: "",
                                imageSize: "",
                                fieldRefType: "", 
                                fieldRefTypeId: 0,
                                isDelete: false,
                            });
                        }

                        // console.log('updatedValues', updatedValues)
                        // if (mappedFieldId === 42) {
                        //     const pcid = parseInt(originalValue);
                        //     const states = stateList.filter(state => state.countryId === pcid);
                        //     setSelPermanentStateList(states);
                        // } else if (mappedFieldId === 43) {
                        //     const psid = parseInt(originalValue);
                        //     const cities = cityList.filter(city => city.stateId === psid);
                        //     setSelPermanentCityList(cities);
                        // }

                        if(originalValue && f.dbColumnName.toLowerCase() === 'pcountryid'){
                            let stateField = sec.fieldsList.find(x => x.dbColumnName.toLowerCase() === 'pstateid')
                            let filteredStateList = apiDropdownData[stateField.fieldName].filter(s => s.valueId === originalValue.toString())

                            setModifiedApiDropdownData((prevModifiedApiData) => ({
                                ...prevModifiedApiData,
                                [stateField.fieldName]: filteredStateList
                            }));
                        }
                        if(originalValue && f.dbColumnName.toLowerCase() === 'pstateid'){
                            let cityField = sec.fieldsList.find(x => x.dbColumnName.toLowerCase() === 'pcityid')
                            let filteredCityList = apiDropdownData[cityField.fieldName].filter(s => s.valueId === originalValue.toString())

                            setModifiedApiDropdownData((prevModifiedApiData) => ({
                                ...prevModifiedApiData,
                                [cityField.fieldName]: filteredCityList
                            }));

                            let districtField = sec.fieldsList.find(x => x.dbColumnName.toLowerCase() === 'pdistrictid')

                            if(districtField){
                                let filteredDistrictList = apiDropdownData[districtField.fieldName].filter(s => s.valueId === originalValue.toString())

                                setModifiedApiDropdownData((prevModifiedApiData) => ({
                                    ...prevModifiedApiData,
                                    [districtField.fieldName]: filteredDistrictList
                                }));
                            }
                        }

                        setInputValue((prevInputValue) => ({
                            ...prevInputValue,
                            [f.fieldName]: originalValue
                        }));

                        setListOfFieldsArray([])
                        setListOfFieldsArray(updatedValues)

                    
                    }
                });
            }
        }
        // else {
        //     const fieldMapping = {
        //         11: 13,
        //         12: 14,
        //         26: 42,
        //         27: 43,
        //         28: 44,
        //         29: 45
        //     };

        //     if(listOfFieldsArray.length !== 0 && listOfFieldsArray[0].sectionId === 3){
        //         listOfFieldsArray.forEach((element) => {
        //             if (fieldMapping.hasOwnProperty(element.fieldId)) {
        //                 const mappedFieldId = fieldMapping[element.fieldId];

        //                 Object.values(fieldMapping).forEach((mappedFieldId) => {
        //                     const sec = data.sections.find(section => section.sectionId === 10);
        //                     const f = sec.fieldsList.find(field => field.fieldId === mappedFieldId);
        //                     setInputValue((prevInputValue) => ({
        //                         ...prevInputValue,
        //                         [f.fieldName]: ''
        //                     }));
                
        //                 });
        //             }
        //         })
        //         setSelPermanentStateList([]);
        //         setSelPermanentCityList([]);
        //     }
            
        // }
    };

    // Get geolocation in address section
    const handleLocationClick = () => {
        if((loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition);
            } else {
                alert("Geolocation is not supported by this browser.");
            }
        }
    }


    function showPosition(position) {
        let sec = data.sections.find(section => section.sectionId === 3)
        let fields;
        
        if (sec !== undefined) {
            fields = sec.fieldsList;
            let found = fields.some((field) => {
            return field.dbColumnName.includes('StateId') || field.dbColumnName.includes('CountryId') || field.dbColumnName.includes('CityId');
            });
            
            if (!found) {
            sec = data.sections.find(section => section.sectionId === 9)
            fields = sec.fieldsList;
            }
        } else {
            sec = data.sections.find(section => section.sectionId === 9)
            fields = sec.fieldsList;
        }

        fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                const city = data.city.trim().toLowerCase();
                const state = data.principalSubdivision.trim().toLowerCase();
                const country = data.countryName.trim().toLowerCase();
                const pincode = data.postcode;

                let updatedListOfFieldsArray = []

                let stateField = fields.find(field => field.dbColumnName.toLowerCase() === 'stateid')

                if(stateField){
                    let stateKey = apiDropdownData[stateField.fieldName].find(s => s.value.trim().toLowerCase() === state)
                    let dependentField = fields.find(field => field.dbColumnName.toLowerCase() === 'cityid')
                    let cityFilteredList = apiDropdownData[dependentField.fieldName].filter(c => c.valueId === stateKey.key.toString())
                    
                    setModifiedApiDropdownData(prevApiData => ({
                        ...prevApiData,
                        [dependentField.fieldName]: cityFilteredList
                    }))

                    setInputValue((prevInputValue) => ({
                        ...prevInputValue,
                        [stateField.fieldName]: stateKey.key.toString()
                    }));

                    updatedListOfFieldsArray.push({
                        'sectionId': stateField.sectionId,
                        'fieldId': stateField.fieldId,
                        'sequenceId': 0,
                        'value': stateKey.key.toString(),
                        "imageExtension": "",
                        "imageSize": "",
                        "fieldRefType": "", 
                        "fieldRefTypeId": 0,
                        "isDelete" : false,
                    });

                }

                let districtField = fields.find(field => field.dbColumnName.toLowerCase() === 'districtid') 

                if(districtField){
                    let stateKey = apiDropdownData[stateField.fieldName].find(s => s.value.trim().toLowerCase() === state)
                    let districtFilteredList = apiDropdownData[districtField.fieldName].filter(c => c.valueId === stateKey.key.toString())

                    setModifiedApiDropdownData(prevApiData => ({
                        ...prevApiData,
                        [districtField.fieldName]: districtFilteredList
                    }))

                }

                let countryField = fields.find(field => field.dbColumnName.toLowerCase() === 'countryid')

                if(countryField){
                    let countryKey = apiDropdownData[countryField.fieldName].find(c => c.value.trim().toLowerCase() === country)
                    let dependentField = fields.find(field => field.dbColumnName.toLowerCase() === 'stateid')
                    let stateFilteredList = apiDropdownData[dependentField.fieldName].filter(c => c.valueId === countryKey.key.toString())
                    
                    setModifiedApiDropdownData(prevApiData => ({
                        ...prevApiData,
                        [dependentField.fieldName]: stateFilteredList
                    }))

                    setInputValue((prevInputValue) => ({
                        ...prevInputValue,
                        [countryField.fieldName]: countryKey.key.toString()
                    }));

                    updatedListOfFieldsArray.push({
                        'sectionId': countryField.sectionId,
                        'fieldId': countryField.fieldId,
                        'sequenceId': 0,
                        'value': countryKey.key.toString(),
                        "imageExtension": "",
                        "imageSize": "",
                        "fieldRefType": "", 
                        "fieldRefTypeId": 0,
                        "isDelete" : false,
                    });


                }

                let cityField = fields.find(field => field.dbColumnName.toLowerCase() === 'cityid')

                if(cityField){
                    let cityKey = apiDropdownData[cityField.fieldName].find(ci => ci.value.trim().toLowerCase() === city)
                    setInputValue((prevInputValue) => ({
                        ...prevInputValue,
                        [cityField.fieldName]: cityKey.key.toString()
                    }));

                    updatedListOfFieldsArray.push({
                        'sectionId': cityField.sectionId,
                        'fieldId': cityField.fieldId,
                        'sequenceId': 0,
                        'value': cityKey.key.toString(),
                        "imageExtension": "",
                        "imageSize": "",
                        "fieldRefType": "", 
                        "fieldRefTypeId": 0,
                        "isDelete" : false,
                    });

                }

                

                // const countryKey = countryList.find(ct => ct.value === country.toUpperCase());
                // const states = stateList.filter(state => state.countryId === countryKey.key);
                // setSelStateList(states);

                // const stateKey = stateList.find(st => st.value === state);
                // const filteredCity = cityList.filter(city => city.stateId === (stateKey ? stateKey.key : null));
                // setSelCityList(filteredCity)

                // const cityKey = cityList.find(ct => ct.value === city);
                // let updatedListOfFieldsArray = [];

                // fields.forEach((element) => {
                //     if(element.dbColumnName === 'CityId'){

                //         setInputValue((prevInputValue) => ({
                //             ...prevInputValue,
                //             [element.fieldName]: cityKey.key.toString()
                //         }));

                //          updatedListOfFieldsArray.push({
                //             'sectionId': element.sectionId,
                //             'fieldId': element.fieldId,
                //             'sequenceId': 0,
                //             'value': cityKey.key.toString(),
                //             "imageExtension": "",
                //             "imageSize": "",
                //             "fieldRefType": "", 
                //             "fieldRefTypeId": 0,
                //             "isDelete" : false,
                //         });

                //     } if(element.dbColumnName === 'StateId'){
                //         setInputValue((prevInputValue) => ({
                //             ...prevInputValue,
                //             [element.fieldName]: stateKey.key.toString()
                //         }));

                //         updatedListOfFieldsArray.push({
                //             'sectionId': element.sectionId,
                //             'fieldId': element.fieldId,
                //             'sequenceId': 0,
                //             'value': stateKey.key.toString(),
                //             "imageExtension": "",
                //             "imageSize": "",
                //             "fieldRefType": "", 
                //             "fieldRefTypeId": 0,
                //             "isDelete" : false,
                //         });

                //     } if(element.dbColumnName === 'CountryId'){
                //         setInputValue((prevInputValue) => ({
                //             ...prevInputValue,
                //             [element.fieldName]: countryKey.key.toString()
                //         }));

                //         updatedListOfFieldsArray.push({
                //             'sectionId': element.sectionId,
                //             'fieldId': element.fieldId,
                //             'sequenceId': 0,
                //             'value': countryKey.key.toString(),
                //             "imageExtension": "",
                //             "imageSize": "",
                //             "fieldRefType": "", 
                //             "fieldRefTypeId": 0,
                //             "isDelete" : false,
                //         });
                //     } 

                // })

                if (updatedListOfFieldsArray.length > 0) {
                    setListOfFieldsArray(prevList => [...prevList, ...updatedListOfFieldsArray]);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    // const dataLoadedRef = useRef(false);
// && !dataLoadedRef.current
    // To fetch data filled during login
    useEffect(() => {
        if (data !== null && loggedInUser !== 'admin' && fullNameFlag === 0 && lastSaveSection < 2  ) {
            // dataLoadedRef.current = true;
          const section = data.sections.find(section => section.sectionId === 2);
          if (!section) return;
      
          const fields = section.fieldsList;
          let requiredFields;
          if(fullNameFlag === 1){
            requiredFields = ['firstname', 'dob', 'mobileno'];
          }else{
            requiredFields = ['firstname', 'middlename', 'lastname', 'dob', 'mobileno'];
          }
          const fieldsToSave = [];
      
          requiredFields.forEach(fieldName => {
            const field = fields.find(field => field.dbColumnName.toLowerCase() === fieldName);
            if (field) {
              let value;
              switch (fieldName) {
                case 'firstname':
                    if(fullNameFlag === 1){
                        value = RegisterName
                    }else{
                        value = RegisterName.split(' ')[0];
                    }
                    break;
                case 'middlename':
                    value = RegisterName.split(' ').length > 2 ? RegisterName.split(' ')[1] : '';
                    break;
                case 'lastname':
                    value = RegisterName.split(' ').length > 1 ? RegisterName.split(' ').pop() : '';
                    break;
                case 'dob':
                    value = Registerdob;
                    break;
                case 'mobileno':
                    value = RegisterMobile;
                    break;
                default:
                    value = '';
              }
      
              if (field.value === '') {
                setInputValue(prevInputValue => ({ ...prevInputValue, [field.fieldName]: value }));
                fieldsToSave.push({
                  sectionId: 2,
                  fieldId: field.fieldId,
                  sequenceId: 0,
                  value: value,
                  imageExtension: '',
                  imageSize: '',
                  fieldRefType: '',
                  fieldRefTypeId: 0,
                  isDelete: false,
                });
              }
            }
          });
          if (fieldsToSave.length > 0 && id === 2) {
            setListOfFieldsArray(fieldsToSave);
          }
        }
      }, [data]);

    //   set maxsequence of subject
    useEffect(() => {
        if(data !== null){
            let sec = data.sections.filter(section => section.parentSectionId === 11 && section.isActive === 1)
            if(sec){
                setMaxSubjectSequence(sec.length)
            }

            let multipleFieldSection = data.sections.filter(s => s.isMultipleFields === 1 && s.sectionId === id)
        }
    }, data)


    const handleAnnexture = () => {
        setAnnextureFlag(true)
        if(annextureData !== null && !(Insterested === null || Insterested === '') && (loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit') )){
            let url = annextureData.annexureFile.fileUrl
            // let url = 'https://d1i48f37ueygxm.cloudfront.net/Annexure/AnnexureTemplate/Annexure I.html'
            // fetch('https://uatums.digivarsity.com/Document/Data_101/Annexure%20I.html')
            fetch(url)
            .then(response => response.text())
            .then(html => {
                // Replace all placeholders with their corresponding values
                let modifiedContent = html;
                annextureData.keyValue.forEach(item => {
                    const regex = new RegExp(item.key, 'g'); // 'g' flag for global replacement
                    modifiedContent = modifiedContent.replace(regex, item.value);
                });
                // annextureData.keyValue.forEach(item => {
                //     modifiedContent = modifiedContent.replace(item.key, item.value);
                // });
                setContent(modifiedContent);
                setIsModalOpen(true);
                setTitleMsg("");
                // setContent(<div dangerouslySetInnerHTML={{ __html: modifiedContent }}></div>)
                setContent(<iframe srcDoc={modifiedContent} width={700} height={500} frameBorder="0"></iframe>)
                setModalContentIdentity('documentPreview')
            })
            .catch(error => console.error('Error fetching HTML content:', error));

            
        }else if(loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit'){
            fetch('https://uatums.digivarsity.com/Document/Data_101/Annexure%20I.html')
            .then(response => response.text())
            .then(html => {
                // Replace all placeholders with their corresponding values
                let modifiedContent = html;
                annextureData.keyValue.forEach(item => {
                    modifiedContent = modifiedContent.replace(item.key, '');
                });
                setContent(modifiedContent);

                setIsModalOpen(true);
                setTitleMsg("");
                // setContent(<div dangerouslySetInnerHTML={{ __html: modifiedContent }}></div>)
                setContent(<iframe srcDoc={modifiedContent} width={700} height={500} frameBorder="0"></iframe>)
                setModalContentIdentity('documentPreview')
            })
            .catch(error => console.error('Error fetching HTML content:', error));
        }
    }

    // Back function
    const handleBack = (sequence, sectionId) => {
        let prevSequence = 1
        if(sectionId === 11){
            let sec = data.sections.find(section => section.sectionId === sectionId)
            prevSequence = sec.sequence - 1
        }else{
            prevSequence = sequence-1
        }
        if(prevSequence === 0 && loggedInUser === 'student'){
            Navigate('/course-selection')
            setLastSaveSection(0)
        }else{
            let sec = data.sections.find(section => section.parentSectionId === 0 && section.sequence === prevSequence)
            if(sec){
                if(sec.isActive === 1 && sec.parentSectionId === 0){
                    setCurrentSection(sec.sectionId)
                    setId(sec.sectionId)
                }else{
                    let previous = data.sections.find(section => section.sequence === prevSequence-1)
                    if(previous === undefined){
                        if(loggedInUser !== 'admin'){
                            Navigate('/course-selection')
                        }else{
                            return;
                        }
                        
                        // Navigate('/course-selection')
                    }else{
                        while(previous !== undefined && previous.isActive === 0){
                            let currentSequence = previous.sequence - 1;
                            previous = data.sections.find(y => y.sequence === currentSequence);
                        }

                        setCurrentSection(previous.sectionId)
                        setId(previous.sectionId)
                    }
                }
            }
        }
        setListOfFieldsArray([])
        setInputValue({})

    }

    const [validSections, setValidSections] = useState(null)
    useEffect(() => {
        if(data !== null){
            let validSection = data.sections.filter(
                (section) => section.parentSectionId !== 0 && section.parentSectionId === 3 && section.isActive === 1
            );
            setValidSections(validSection)
        }
    },[data])

    const handleDataFromJob = (listToSave) => {
        setListOfFieldsArray(listToSave)
    }

    const handleJobDataLength = (jobLength) => {
        jobDataLength = jobLength
        // setJobDataLength(jobLength)
    }

    // Address section fields
    const SectionGrid = ({ section, index }) => {
        return (
            <ScreenView>
            {mobileView => (
                <Grid container direction="column" sx={{textAlign: 'left'}}>
                    <Typography
                    sx={{textAlign: 'left', mt:1,
                        color: '#000',
                        fontFamily: "Open Sauce Sans",
                        fontSize: '17px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '167%', 
                    }}>{section.sectionName}
                    &nbsp;&nbsp;
                    {section.sequence === 2 && (
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <input ref={checkboxRef} id='check' type='checkbox' onChange={copyAddress} />
                        <Typography sx={{ fontSize: '13px' }}>Same as Correspondence address</Typography>
                        </div>
                    )}
                    </Typography>
                    {section.fieldsList
                    .sort((a, b) => a.sequence - b.sequence)
                    .map((field, fieldIndex) => (
                        <Grid item key={fieldIndex}>
                        <Box sx={{ display: section.sectionId === 3 ? 'block' : 'display-inline',  }}>
                            <FormControl sx={{ width: mobileView ? '100%' : '100%', textAlign: 'left', 
                            ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' }: {}),
                            }}>
                            {/* rest of your code */}
                            <Box sx={{
                                direction: mobileView ? 'column' : 'column',
                                width: mobileView ? '100%' : '80%',
                                alignItems: 'left',
                                justifyContent: 'flex-start',
                            }}>
                                <Box sx={{ display: mobileView ? 'block' : 'flex', alignItems: 'center', 
                                // ...(field.visibleToStudent === '2' ? { display: 'none' }: {}) 
                                }}>
                                    {field.fieldType !== 'CheckBox' && field.fieldType !== 'RadioButton' &&
                                    <InputLabel
                                        className="formLabel"
                                        shrink={false}
                                        // sx={{ ...(field.visibleToStudent === '2' ? { display: 'none' } : {}) }}
                                        // sx={{ position: 'relative', textAlign: 'left' }}
                                    >
                                        {field.fieldName}
                                        {(field.fieldType === 'RadioButton' || field.fieldType === 'Photo' || field.fieldType === 'File') &&
                                            <span style={{ color: 'red' }}>*</span>
                                        }
                                    </InputLabel>
                                    }
                                    {field.helpText !== null && field.helpText !== '' && field.fieldType !== 'RadioButton' && ( 
                                        <Popup
                                            trigger={
                                                <InfoOutlinedIcon
                                                    sx={{
                                                        marginBottom: mobileView ? '0%' : '-6%',
                                                        marginLeft: mobileView ? '109px' : '15px',
                                                        marginTop: mobileView ? '-2%' : '0%',
                                                        position: mobileView ? 'absolute' : 'relative',
                                                        color: '#484848',
                                                        width: '4%'
                                                    }}
                                                />
                                            }
                                                on="hover" // Set the on prop to 'hover'
                                                position="right center"
                                            >
                                            <Box>{field.helpText}</Box>
                                        </Popup>
                                    )}
                                </Box>
                                <Input
                                    type={field.fieldType}
                                    defaultValue={inputValue[field.fieldName] || '' ||field.value}
                                    onBlur={(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) ? (value) => handleInputChange( 
                                        value, 
                                        field.validationType, 
                                        field.fieldName,
                                        {...field}, 
                                        section.isMultipleFields
                                    ): undefined}
                                    onChange={(value) => handleChange(value, field.fieldName, {...field})}
                                    {...field}
                                />
                            </Box>
                            </FormControl>
                        </Box>
                        </Grid>
                    ))}
                </Grid>
          )}</ScreenView>
        );
    };

    // personal detail first 3 fields
    const PersonalGrid = (section, index) => {
    return (
        <ScreenView>
        {mobileView => (
            <Grid container direction="column" sx={{ textAlign: 'left' }}>
            {section.section.fieldsList
            .sort((a, b) => a.sequence - b.sequence)
            .filter(field => {
                if(section.section.fieldsList.some(f => f.dbColumnName.toLowerCase() === 'photo' && f.sequence === 4) &&
                    !section.section.fieldsList.some(f => f.sequence === 1 && f.fieldType === 'Image')){
                    return [1, 2, 3].includes(field.sequence) && field.isActive === 1;
                  }
                  if(section.section.fieldsList.some(f => f.dbColumnName.toLowerCase() === 'photo' && [1, 2, 3].includes(f.sequence))){
                    return field.sequence !== section.section.fieldsList.find(f => f.dbColumnName.toLowerCase() === 'photo').sequence && [1, 2, 3, 4].includes(field.sequence) && field.isActive === 1;
                  }
                  if(section.section.fieldsList.some(f => f.dbColumnName.toLowerCase() === 'photo' && ![1, 2, 3, 4].includes(f.sequence))){
                    return [1, 2, 3].includes(field.sequence) && field.isActive === 1;
                  }
                  if(section.section.fieldsList.some(f => f.dbColumnName.toLowerCase() === 'photo' && f.sequence !== 1) &&
                        section.section.fieldsList.some(f => f.sequence === 1 && f.fieldType === 'Image' && f.dbColumnName.toLowerCase() !== 'photo')){
                        return [1].includes(field.sequence) && field.isActive === 1;
                    }
                  return [1, 2, 3].includes(field.sequence) && field.isActive === 1;
                // if(([1, 2, 3, 4].includes(field.sequence) && [1, 2, 3, 4].some(seq => section.section.fieldsList.find(f => f.sequence === seq && f.dbColumnName.toLowerCase() === 'photo')))){
                //     return [1, 2, 3].includes(field.sequence) && field.isActive === 1;
                // }
                // if (([1, 2, 3].includes(field.sequence) && [1, 2, 3].some(seq => section.section.fieldsList.find(f => f.sequence === seq && f.dbColumnName.toLowerCase() === 'photo')))) {
                //   return false; // skip if sequence is 1, 2, or 3 and column name is "photo"
                //     // return [1, 2, 3, 4].includes(field.sequence) && field.isActive === 1;
                // }
                // else{
                //     return [1, 2, 3].includes(field.sequence) && field.isActive === 1;
                // }
                
            })
            .map((field, fieldIndex) => (
                <Grid item key={fieldIndex}>
                <Box
                    sx={{
                    display: section.sectionId === 2 ? 'block' : 'inline-block',
                    width: '81%'
                    }}
                >
                    <FormControl
                    sx={{
                        width: mobileView ? '100%' : '100%',
                        textAlign: 'left',
                        ...(field.visibleToStudent === '2' || field.fieldType === 'HiddenField'
                        ? { display: 'none' }
                        : {}),
                    }}
                    >
                    {/* rest of your code */}
                    <Box
                        sx={{
                        flexDirection: mobileView ? 'column' : 'column',
                        width: mobileView ? '100%' : '100%', // width: mobileView ? '100%' : '225%',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        }}
                    >
                        <Box
                        sx={{
                            display: mobileView ? 'block' : 'flex',
                            alignItems: 'center',
                            ...(field.visibleToStudent === '2' ? { display: 'none' } : {}),
                        }}
                        >
                        {field.dbColumnName.toLowerCase() !== 'photo' && field.fieldType !== 'Image' && 
                        <InputLabel
                            className="formLabel"
                            shrink={false}
                            sx={{
                            ...(field.visibleToStudent === '2' ? { display: 'none' } : {}),
                            }}
                        >
                            {field.fieldName}
                            {(field.fieldType === 'RadioButton' ||
                            field.fieldType === 'Photo' ||
                            field.fieldType === 'File') && (
                            <span style={{ color: 'red' }}>*</span>
                            )}
                        </InputLabel>
                        }
                        {field.helpText !== null &&
                            field.helpText !== '' &&
                            field.fieldType !== 'RadioButton' && (
                            <Popup
                                trigger={
                                <InfoOutlinedIcon
                                    sx={{
                                    marginBottom: mobileView ? '0%' : '-6%',
                                    marginLeft: mobileView ? '109px' : '15px',
                                    marginTop: mobileView ? '-2%' : '0%',
                                    position: mobileView ? 'absolute' : 'relative',
                                    color: '#484848',
                                    width: '4%',
                                    }}
                                />
                                }
                                on="hover"
                                position="right center"
                            >
                                <Box>{field.helpText}</Box>
                            </Popup>
                            )}
                        </Box>
                        <Input
                        type={field.fieldType}
                        defaultValue={inputValue[field.fieldName] || '' || field.value}
                        onBlur={(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) ? value =>
                            handleInputChange(
                            value,
                            field.validationType,
                            field.fieldName,
                            { ...field },
                            section.section.isMultipleFields
                            )
                        : undefined}
                        onChange={value => handleChange(value, field.fieldName, { ...field })}
                        {...field}
                        />
                    </Box>
                    </FormControl>
                </Box>
                </Grid>
            ))}
            </Grid>
        )}
        </ScreenView>
    );
    };

    // photo field
    const PhotoGrid = (section, index) => {
        const fileInputRef = useRef(null);
        console.log(file)
    return (
        <ScreenView>
        {mobileView => (
            <Grid container direction="column" sx={{ textAlign: 'left' }}>
            {section.section.fieldsList
            .sort((a, b) => a.dbColumnName.localeCompare(b.dbColumnName))
            .filter(field => field.dbColumnName.toLowerCase() === 'photo' && field.isActive === 1)
            .map((field, fieldIndex) => (
                // <Input
                //     type={field.fieldType}
                //     defaultValue={inputValue[field.fieldName] || '' || field.value}
                //     onBlur={(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) ? value =>
                //         handleInputChange(
                //         value,
                //         field.validationType,
                //         field.fieldName,
                //         { ...field },
                //         section.section.isMultipleFields
                //         )
                //     : undefined}
                //     onChange={value => handleImageChange(value, field.fieldName, { ...field })}
                //     {...field}
                // />
                <Grid item key={fieldIndex}>
                <Box
                    sx={{
                    display: section.sectionId === 2 ? 'block' : 'inline-block',
                    }}
                >
                    <FormControl
                    sx={{
                        width: mobileView ? '100%' : '100%',
                        textAlign: 'left',
                        ...(field.visibleToStudent === '2' || field.fieldType === 'HiddenField'
                        ? { display: 'none' }
                        : {}),
                    }}
                    >
                    {/* rest of your code */}
                    <Box
                        sx={{
                        flexDirection: mobileView ? 'column' : 'column',
                        width: mobileView ? '100%' : '225%',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        mt:2
                        }}
                    >
                        <Box
                        sx={{
                            display: mobileView ? 'block' : 'flex',
                            alignItems: 'center',
                            ...(field.visibleToStudent === '2' ? { display: 'none' } : {}),
                        }}
                        >
                        <Typography sx={{
                            fontFamily: "Open Sauce Sans",
                            fontSize: '16px',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                        }}>{field.fieldName}<span style={{color: '#FF0000'}}>*</span></Typography>
                        
                        </Box>
                        <Box sx={{display: 'flex'}}>
                            <Box
                                height={150}
                                width={200}
                                my={1}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                gap={4}
                                p={2}
                                sx={{borderRadius: '10px', border: '0.5px solid #972524', cursor: 'pointer' }}
                                onClick={() => {
                                    // Check if the conditions are met to trigger the file input
                                    if (
                                        (loggedInUser  !== 'admin' || (loggedInUser  === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) &&
                                        isAadhaarVerified === false
                                    ) {
                                        // Trigger the hidden file input
                                        fileInputRef.current.click();
                                        // document.getElementById(field?.dbColumnName).click();
                                    }
                                }}
                            >
                                {/* file && file[field?.fieldName] && (file[field?.fieldName] !== undefined || file[field?.fieldName] !== null) ?  */}
                                {file && Object.keys(file).length !== 0 && file[field?.fieldName] ? (
                                    <>
                                        <img id={field.dbColumnName} src={file[field?.fieldName]} style={{ width: '200px', height: '150px', borderRadius: '10px' }} alt="Uploaded file" />
                                        {(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) && isAadhaarVerified === false &&
                                            <input id={field?.dbColumnName} ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={(e) => handleImageChange(field?.fieldName, field?.sectionId, field?.fieldId, field, e)} onBlur={handleInputChange} />
                                        }
                                    </>
                                    // (file && (file[field?.fieldName] === undefined || file[field?.fieldName] === null)) && (field?.value !== null && field?.value !== "") ? 
                                ) : (file && Object.keys(file).length !== 0 && !file[field?.fieldName]) || (field?.value !== null && field?.value !== "") ? (
                                    <>
                                        <img id={field.dbColumnName} src={field?.value} style={{ width: '200px', height: '150px', borderRadius: '10px' }} alt="Uploaded file" />
                                        {(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) &&
                                            <input id={field?.dbColumnName} ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={(e) => handleImageChange(field?.fieldName, field?.sectionId, field?.fieldId, field, e)} onBlur={handleInputChange} />
                                        }
                                    </>
                                    // file && (file[field?.fieldName] === undefined || file[field?.fieldName] === null) && (field.value === null || field.value === "") ?
                                ) : (!file || Object.keys(file).length === 0) || (file || Object.keys(file).length !== 0 && !file[field?.fieldName]) && (field?.value === null || field?.value === "") ? (
                                        <Box>
                                            <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
                                                <UploadOutlinedIcon />
                                                <span>Browse from device</span>
                                            </Box>
                                            {(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) && isAadhaarVerified === false &&
                                                <input id={field?.dbColumnName} ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={(e) => handleImageChange(field?.fieldName, field?.sectionId, field?.fieldId, field, e)} onBlur={handleInputChange} />
                                            }
                                        </Box>
                                    ) : (field?.value !== null && field?.value !== "") ? (
                                        <>
                                        <img id={field.dbColumnName} src={field?.value} style={{ width: '200px', height: '150px', borderRadius: '10px' }} alt="Uploaded file" />
                                            {(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) &&
                                                <input id={field?.dbColumnName} ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={(e) => handleImageChange(field?.fieldName, field?.sectionId, field?.fieldId, field, e)} onBlur={handleInputChange} />
                                            }
                                        </>
                                    ) : null
                                }
                                
                            </Box>
                            {file && file[field?.fieldName] && isAadhaarVerified === false && (
                                <DeleteForeverIcon 
                                    sx={{ cursor: 'pointer', alignSelf: 'center', ml: 2 }} 
                                    onClick={() => setFile((prevState) => {
                                        const { [field?.fieldName]: _, ...newFile } = prevState; // Destructure to exclude the field
                                        return newFile; // Return the new object without the specified field
                                    })}
                                />
                                // <DeleteForeverIcon 
                                //     sx={{ cursor: 'pointer', alignSelf: 'center', ml: 2 }} 
                                //     onClick={() => setFile((prevState) => ({
                                //         ...prevState,
                                //         [field?.fieldName]: null
                                //     }))}
                                // />
                            )}
                            
                        </Box>
                        {Object.entries(textError).length > 0 &&
                            Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                                isError === true &&
                                field?.fieldName === name && (
                                <Typography className="error-message" key={name}>
                                    {errorMessage}
                                </Typography>
                            )
                        ))}
                        <Typography 
                            sx={{
                                textAlign: 'left', width: '100%', 
                                fontFamily: "Open Sauce Sans",
                                fontSize: '14px',
                                fontStyle: 'normal',
                                color: 'black'
                            }}
                        >
                            Format: {field.fieldValidations.map((acc)=>{
                                if (acc.property === 'FileType') {
                                    return acc.value.replace(/\,/g, ', ');
                                } else if (acc.property === 'MinSize') {
                                    const minSizeUnit = field.fieldValidations.find(v => v.property === 'MinSizeUnit').value;
                                    return ` , Min Size: ${acc.value}${minSizeUnit}`;
                                } else if (acc.property === 'MaxSize') {
                                    const maxSizeUnit = field.fieldValidations.find(v => v.property === 'MaxSizeUnit').value;
                                    return ` , Max Size: ${acc.value}${maxSizeUnit}`;
                                }
                                return '';
                            }).filter(Boolean).join('')}
                        </Typography>
                    </Box>
                    </FormControl>
                </Box>
                </Grid>
            ))}
            </Grid>
        )}
        </ScreenView>
    );
    };


    return(
        <>
        <ScreenView>
            {mobileView => (
            <Box className="container">
                <Box>
                    {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box> */}
                    <Grid container component="main">
                        <Grid item xs={12}>
                            {successMessage !== null &&
                            <Snackbar
                                className="sucess-popup"
                                open={SnackbarOpen}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                onClose={() => {
                                    handleSnackbarClose();
                                    setSuccessMessage(null); // reset successMessage to null
                                }}
                                sx={{
                                    top: mobileView ? '-90px !important' : '-62px'
                                }}
                                message={successMessage}
                                key={'top center'}
                            />
                            }
                            <Typography className="title" sx={{textAlign: 'left', ml:0, fontWeight: 600}}  variant="h6">
                                {/* {name} */}
                                {(data === null || loading === true) ? <Loader/> : data !== null &&
                                    data.sections.map((section) =>
                                        section.sectionId === id && section.isActive === 1 ? 
                                        <>
                                            <span key={section.sectionId}>{section.sectionName}</span> 
                                            {section.sectionId === 3 &&
                                            <Box
                                                sx={{width: mobileView ? '90%' : '560px',
                                                    height: '50px',
                                                    flexShrink: '0',
                                                    borderRadius: '8px',
                                                    border: '1px solid #FEBF00',
                                                    background: '#ffffffb3',
                                                    boxShadow: '0px 4px 4px 0px #00000040',
                                                    display: 'flex',
                                                    alignItems: 'center',  // Align items vertically center
                                                    padding: '0 8px' ,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#000',
                                                        fontFamily: "Open Sauce Sans",
                                                        fontSize: mobileView ? '13px' : '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '500',
                                                        lineHeight: '167%', /* 26.72px */
                                                        textAlign: 'left',
                                                        flexGrow: 1
                                                    }}
                                                >
                                                    Save time. Auto fill your current location.
                                                </Typography>
                                                <Button
                                                    sx={{width: '85px',
                                                        height: '43px',
                                                        flexShrink: '0',
                                                        borderRadius: '8px',
                                                        background: '#FEBF00',
                                                        color: '#fff',
                                                        marginLeft: 'auto',
                                                        textTransform: 'none',
                                                        '&:hover': {
                                                            background: '#FEBF00',
                                                        },
                                                    }}
                                                    id='auto_fill_btn'
                                                    onClick={handleLocationClick}
                                                >
                                                    Auto fill
                                                </Button>
                                            </Box>
                                            }
                                        </>
                                        : null
                                    )
                                }
                            </Typography>
                        </Grid>
                    
                    </Grid>

                    {/*  1st 3 fields and photo field  */}
                    <React.Fragment>
                        {data !== null && loading === false && data.sections.length > 0 ? (
                            data.sections.find((section) => section.sectionId === id) ? (
                            data.sections.find((section) => section.sectionId === 2 && section.isActive === 1 && !mobileView && section.fieldsList.some((field) => field.dbColumnName.toLowerCase() === 'photo')) ? (
                                <Grid container spacing={2} mt={2}>
                                <Grid item xs={5}>
                                    {data.sections
                                    .filter((section) => section.sectionId === id && section.sectionId === 2 && section.isActive === 1 && !mobileView)
                                    .map((section, index) => (
                                        <PersonalGrid section={section} index={index} key={section.sectionId}/>
                                    ))}
                                </Grid>
                                <Grid item xs={5}>
                                    {data.sections
                                    .filter((section) => section.sectionId === id && section.sectionId === 2 && section.isActive === 1 && !mobileView)
                                    .map((section, index) => (
                                        <PhotoGrid section={section} index={index} key={section.sectionId}/>
                                    ))}
                                </Grid>
                                </Grid>
                            ) : null
                            ) : null
                        ) : null}
                    </React.Fragment>

                    {/* <React.Fragment>
                        {data !== null && loading === false && data.sections.length > 0 ? (
                            data.sections.find((section) => section.sectionId === id) ? (
                                <Grid container spacing={2} mt={2}>
                                    <Grid item xs={5}>
                                        {data.sections
                                        .filter((section) => section.sectionId === id && section.sectionId === 2 && section.isActive === 1 && !mobileView)
                                        .map((section, index) => (
                                            <PersonalGrid section={section} index={index} key={section.sectionId}/>
                                        ))}
                                    </Grid>
                                    {/* <Grid item xs={5}>
                                        {data.sections
                                            .filter((section) => section.sectionId === id && section.sectionId === 2 && section.isActive === 1 && !mobileView)
                                            .some((section) => section.fieldsList.find((field) => field.dbColumnName.toLowerCase() === 'photo')) ? (
                                            data.sections
                                                .filter((section) => section.sectionId === id && section.sectionId === 2 && section.isActive === 1 && !mobileView)
                                                .map((section, index) => (
                                                <PhotoGrid section={section} index={index} key={section.sectionId}/>
                                                ))
                                            ) : (
                                            <PersonalGrid section={data.sections.find((section) => section.sectionId === id)} index={0} key={data.sections.find((section) => section.sectionId === id).sectionId}/>
                                        )}
                                    </Grid> */}
                                    {/* <Grid item xs={5}>
                                        {data.sections
                                        .filter((section) => section.sectionId === id && section.sectionId === 2 && section.isActive === 1 && !mobileView)
                                        .map((section, index) => (
                                            <PhotoGrid section={section} index={index} key={section.sectionId}/>
                                        ))}
                                    </Grid>
                                </Grid>
                            ):null
                        ): null}
                    </React.Fragment> */}
                    
                    <React.Fragment>
                        {(data !== null && loading === false) ?
                        data.sections.map((section) => (
                            section.parentSectionId === 0 && section.sectionId === id && section.isActive === 1 ? (
                                <Grid container component="main">
                                    <Grid item xs={10}>
                                    <section key={section.sectionId} style={{textAlign: 'left'}}>
                                        {section.isMultipleFields === 0 && section.fieldsList
                                            .filter(field => field.isActive === 1 && field.dbColumnName.toLowerCase() !== 'photo') // Add this condition to filter active fields
                                            .sort((a, b) => a.sequence - b.sequence)
                                            .map((field, index) => (
                                            <>
                                                {/* // Other fields */}
                                                { !mobileView 
                                                    && (!( section.sectionId === 2 && section.fieldsList.some(f => f.dbColumnName.toLowerCase() === 'photo' && f.fieldType === 'Image')
                                                    ? [1, 2, 3].includes(field.sequence) || 
                                                    ([1, 2, 3, 4].includes(field.sequence) && [1, 2, 3, 4].some(seq => section.fieldsList.find(f => f.sequence === seq && f.dbColumnName.toLowerCase() === 'photo' && f.fieldType === 'Image')))
                                                    : false
                                                    ) || (section.sectionId === 2 
                                                        && section.fieldsList.some(f => f.sequence === 1 && f.fieldType === 'Image')
                                                        && section.fieldsList.some(f => f.dbColumnName.toLowerCase() === 'photo')
                                                        ? field.sequence !== 1 && field.dbColumnName.toLowerCase() !== 'photo'
                                                        : false
                                                    ))
                                                    // && !( section.sectionId === 2 &&
                                                    // [1, 2, 3].includes(field.sequence) ||
                                                    // ([1, 2, 3, 4].includes(field.sequence) && [1, 2, 3, 4].some(seq => section.fieldsList.find(f => f.sequence === seq && f.dbColumnName.toLowerCase() === 'photo' && f.fieldType === 'Image')))
                                                    // ) 
                                                    ?
                                                    <FormControl sx={{ width: mobileView ? '100%' : field.fieldType !== 'Paragraph' ? '50%' : '100%', textAlign: 'left',
                                                        ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' }: {}),
                                                        }}
                                                    >
                                                        <Box sx={{
                                                            direction: mobileView ? 'column' : 'row',
                                                            width: mobileView ? '100%' : '80%',
                                                            alignItems: 'left',
                                                            justifyContent: 'flex-start'
                                                        }}>
                                                            <Box sx={{ display: mobileView ? 'block' : field.fieldType !== 'Paragraph' ? 'flex' : 'block', alignItems: 'center',  mt: mobileView ? 2 : 0
                                                            // ...(field.visibleToStudent === '2' ? { display: 'none' }: {}) 
                                                            }}>
                                                                {!(field.fieldType === 'RadioButton' || field.fieldType === 'CheckBox' || field.fieldType === 'Image') && field.dbColumnName.toLowerCase() !== 'photo' && field.sectionId !== 14 &&
                                                                <>
                                                                <InputLabel
                                                                    className="formLabel"
                                                                    id='1234'
                                                                    shrink={false}
                                                                    sx={{display: mobileView ? 'contents' : 'block', textWrap: 'wrap', mt:2, ml:field.fieldType === 'Paragraph' ? 1 : 0}}
                                                                    // sx={{ ...(field.visibleToStudent === '2' ? { display: 'none' } : {}) }}
                                                                    // sx={{ position: 'relative', textAlign: 'left' }}
                                                                >
                                                                    {field.fieldName}
                                                                    {(field.fieldType === 'RadioButton') &&
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    }
                                                                </InputLabel>
                                                                </>
                                                                }
                                                                {!mobileView && field.helpText !== null && field.helpText !== '' && !(field.fieldType === 'RadioButton' || field.fieldType === 'CheckBox') && field.sectionId !== 14 ? (
                                                                    <Popup
                                                                        trigger={
                                                                            <InfoOutlinedIcon
                                                                                sx={{
                                                                                    marginBottom: mobileView ? '0%' : '-6%',
                                                                                    marginLeft: mobileView ? '109px' : '15px',
                                                                                    marginTop: mobileView ? '-2%' : '0%',
                                                                                    position: mobileView ? 'absolute' : 'relative',
                                                                                    color: '#484848',
                                                                                    width: mobileView ? '5%' : '3.5%'
                                                                                }}
                                                                            />
                                                                        }
                                                                            on="hover" // Set the on prop to 'hover'
                                                                            position="right center"
                                                                        >
                                                                        <Box>{field.helpText}</Box>
                                                                    </Popup>
                                                                ): (mobileView && field.helpText !== null && field.helpText !== '' && field.fieldType !== 'RadioButton'&& field.sectionId !== 14 && (
                                                                    <span
                                                                        style={{
                                                                            color: '#000',
                                                                            fontFamily: "Open Sauce Sans",
                                                                            fontSize: '14px',
                                                                            fontWeight: 500,
                                                                            fontStyle: 'italic',
                                                                            lineHeight: '167%'
                                                                        }}  
                                                                    >&nbsp;&nbsp;({field?.helpText})</span>
                                                                    
                                                                ))}
                                                                {field.sectionId === 14 && (
                                                                    <Box sx={{fontStyle: 'italic'}}>({field.helpText})</Box>
                                                                )}
                                                            </Box>
                                                            <Input
                                                                type={field.fieldType}
                                                                name={field.fieldName}
                                                                defaultValue={inputValue[field.fieldName]  || '' || field.value}
                                                                onBlur={(value) => handleInputChange( 
                                                                    value, 
                                                                    field.validationType, 
                                                                    field.fieldName,
                                                                    {...field}, 
                                                                    section.isMultipleFields
                                                                )}
                                                                onChange={(value) => handleChange(value, field.fieldName, {...field})}
                                                                {...field}
                                                            />
                                                        </Box>
                                                        {section.sectionId === 7 &&
                                                            <Box sx={{
                                                                mt: 5
                                                            }}>
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'Open Sauce Sans',
                                                                        cursor: 'pointer',
                                                                        color: '#185ABD'
                                                                    }}
                                                                    onClick={handleAnnexture}
                                                                >
                                                                    Click here to view annexture
                                                                </Typography>
                                                                
                                                            </Box>
                                                        }
                                                        {section.sectionId === 7 &&
                                                            <Snackbar open={SnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                                                                <Alert
                                                                    onClose={handleSnackbarClose}
                                                                    severity="error"
                                                                    variant="filled"
                                                                    sx={{ width: '100%'}}
                                                                >
                                                                    Please view annexture
                                                                </Alert>
                                                            </Snackbar>
                                                        }
                                                    </FormControl>
                                                :
                                                (mobileView &&
                                                    <FormControl sx={{ width: mobileView ? '100%' : '50%', textAlign: 'left',
                                                        ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' }: {}),
                                                        }}
                                                    >
                                                        <Box sx={{
                                                            direction: mobileView ? 'column' : 'row',
                                                            width: mobileView ? '100%' : '80%',
                                                            alignItems: 'left',
                                                            justifyContent: 'flex-start'
                                                        }}>
                                                            <Box sx={{ display: mobileView ? 'block' : 'flex', alignItems: 'center',  mt: mobileView ? 2 : 0
                                                            // ...(field.visibleToStudent === '2' ? { display: 'none' }: {}) 
                                                            }}>
                                                                {field.fieldType !== 'RadioButton' && field.fieldName !== 'CheckBox' && field.dbColumnName.toLowerCase() !== 'photo' && field.sectionId !== 14 &&
                                                                <InputLabel
                                                                    className="formLabel"
                                                                    shrink={false}
                                                                    sx={{display: mobileView ? 'contents' : 'block', mt:2}}
                                                                    // sx={{ ...(field.visibleToStudent === '2' ? { display: 'none' } : {}) }}
                                                                    // sx={{ position: 'relative', textAlign: 'left' }}
                                                                >
                                                                    {field.fieldName}
                                                                    {(field.fieldType === 'RadioButton') &&
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    }
                                                                </InputLabel>
                                                                }
                                                                {!mobileView && field.helpText !== null && field.helpText !== '' && field.fieldType !== 'RadioButton'&& field.sectionId !== 14 ? (
                                                                    <Popup
                                                                        trigger={
                                                                            <InfoOutlinedIcon
                                                                                sx={{
                                                                                    marginBottom: mobileView ? '0%' : '-6%',
                                                                                    marginLeft: mobileView ? '109px' : '15px',
                                                                                    marginTop: mobileView ? '-2%' : '0%',
                                                                                    position: mobileView ? 'absolute' : 'relative',
                                                                                    color: '#484848',
                                                                                    width: mobileView ? '5%' : '3.5%'
                                                                                }}
                                                                            />
                                                                        }
                                                                            on="hover" // Set the on prop to 'hover'
                                                                            position="right center"
                                                                        >
                                                                        <Box>{field.helpText}</Box>
                                                                    </Popup>
                                                                ): (mobileView && field.helpText !== null && field.helpText !== '' && field.fieldType !== 'RadioButton'&& field.sectionId !== 14 && (
                                                                    <span
                                                                        style={{
                                                                            color: '#000',
                                                                            fontFamily: "Open Sauce Sans",
                                                                            fontSize: '14px',
                                                                            fontWeight: 500,
                                                                            fontStyle: 'italic',
                                                                            lineHeight: '167%'
                                                                        }}  
                                                                    >&nbsp;&nbsp;({field?.helpText})</span>
                                                                    
                                                                ))}
                                                                {field.sectionId === 14 && (
                                                                    <Box sx={{fontStyle: 'italic'}}>({field.helpText})</Box>
                                                                )}
                                                            </Box>
                                                            {mobileView && field.dbColumnName.toLowerCase() === 'photo' ?
                                                            <PhotoGrid section={section} index={index} key={section.sectionId}/>
                                                            :
                                                            <Input
                                                                type={field.fieldType}
                                                                name={field.fieldName}
                                                                defaultValue={inputValue[field.fieldName]  || '' || field.value}
                                                                onBlur={(value) => handleInputChange( 
                                                                    value, 
                                                                    field.validationType, 
                                                                    field.fieldName,
                                                                    {...field}, 
                                                                    section.isMultipleFields
                                                                )}
                                                                onChange={(value) => handleChange(value, field.fieldName, {...field})}
                                                                {...field}
                                                            />
                                                            }
                                                            
                                                            
                                                        </Box>
                                                        {section.sectionId === 7 &&
                                                            <Box sx={{
                                                                mt: 5
                                                            }}>
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'Open Sauce Sans',
                                                                        cursor: 'pointer',
                                                                        color: '#185ABD'
                                                                    }}
                                                                    onClick={handleAnnexture}
                                                                >
                                                                    Click here to view annexture
                                                                </Typography>
                                                                
                                                            </Box>
                                                        }
                                                        {section.sectionId === 7 &&
                                                            <Snackbar open={SnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                                                                <Alert
                                                                    onClose={handleSnackbarClose}
                                                                    severity="error"
                                                                    variant="filled"
                                                                    sx={{ width: '100%'}}
                                                                >
                                                                    Please view annexture
                                                                </Alert>
                                                            </Snackbar>
                                                        }
                                                    </FormControl>
                                                )
                                                }
                                                {/* } */}
                                                
                                            </>
                                            
                                        ))}
                                        {section.isMultipleFields === 1 && section.fieldsList
                                            .filter(field => field.isActive === 1)
                                            .filter(field => field.multipleSequence === Math.min(...section.fieldsList.filter(field => field.isActive === 1).map(field => field.multipleSequence)))
                                            .sort((a, b) => a.sequence - b.sequence)
                                            .map((field, index) => (
                                            <>
                                                <FormControl sx={{ width: mobileView ? '100%' : '50%', textAlign: 'left',
                                                    ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' }: {}),
                                                    }}
                                                >
                                                    <Box sx={{
                                                        direction: mobileView ? 'column' : 'row',
                                                        width: mobileView ? '100%' : '80%',
                                                        alignItems: 'left',
                                                        justifyContent: 'flex-start'
                                                    }}>
                                                        <Box sx={{ display: mobileView ? 'block' : 'flex', alignItems: 'center', 
                                                        // ...(field.visibleToStudent === '2' ? { display: 'none' }: {}) 
                                                        }}>
                                                           {!(field.fieldType === 'RadioButton' || field.fieldType === 'CheckBox') &&
                                                                <InputLabel
                                                                    className="formLabel"
                                                                    shrink={false}
                                                                >
                                                                    {field.fieldName}
                                                                    {(field.fieldType === 'Photo' || field.fieldType === 'File') &&
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                    }
                                                                </InputLabel>
                                                            }
                                                            {field.helpText !== null && field.helpText !== '' && field.fieldType !== 'RadioButton' && (
                                                                <Popup
                                                                    trigger={
                                                                        <InfoOutlinedIcon
                                                                            sx={{
                                                                                marginBottom: mobileView ? '0%' : '-6%',
                                                                                marginLeft: mobileView ? '109px' : '15px',
                                                                                marginTop: mobileView ? '-2%' : '0%',
                                                                                position: mobileView ? 'absolute' : 'relative',
                                                                                color: '#484848',
                                                                                width: '4%'
                                                                            }}
                                                                        />
                                                                    }
                                                                        on="hover" // Set the on prop to 'hover'
                                                                        position="right center"
                                                                    >
                                                                    <Box>{field.helpText}</Box>
                                                                </Popup>
                                                            )}
                                                        </Box>
                                                        <Input
                                                            type={field.fieldType}
                                                            defaultValue={inputValue[field.fieldName] || '' ||field.value}
                                                            onBlur={(value, displayValue) => handleInputChange( 
                                                                value, 
                                                                field.validationType, 
                                                                field.fieldName,
                                                                {...field}, 
                                                                section.isMultipleFields,
                                                                displayValue,
                                                            )}
                                                            onChange={(value) => handleChange(value, field.fieldName, {...field})}
                                                            isMultipleFields = {section.isMultipleFields}
                                                            {...field}
                                                        />
                                                        
                                                        
                                                    </Box>
                                                    
                                                </FormControl>
                                            </>
                                        ))}
                                        {section.sectionId === 14 && Insterested === '1' && (loggedInUser !== 'admin' || loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit') ? section.fieldsList
                                        .map((field, index) => (
                                            <>
                                            <JobSection enquiry={enquiryId} fields={section.fieldsList} sendDataToParent={handleDataFromJob} sendDataLength={handleJobDataLength}/>
                                            {Object.entries(textError).map(([name, { isError, errorMessage }]) =>
                                                isError === true && field?.fieldName === name && (
                                                <Snackbar open={SnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                                                    <Alert
                                                        onClose={handleSnackbarClose}
                                                        severity="error"
                                                        variant="filled"
                                                        sx={{ width: '100%'}}
                                                    >
                                                        {errorMessage}
                                                    </Alert>
                                                </Snackbar>
                                            ))}
                                            
                                            </>
                                        )) : (section.sectionId === 14 && loggedInUser === 'admin' && <JobSection enquiry={enquiryId} fields={section.fieldsList} sendDataToParent={handleDataFromJob} sendDataLength={handleJobDataLength}/>)
                                        }
                                        
                                    </section>
                                    
                                    </Grid>

                                    {/* If isMultipleField section */}
                                    {section.isMultipleFields === 1 &&
                                        <Box xs={12} sx={{ width: '100%', textAlign: 'left'}}>
                                        
                                            <>
                                            <Button sx={{
                                                    borderRadius: '8px',
                                                    background: '#FEBF00',
                                                    boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.25)',
                                                    color: '#000',
                                                    '&:hover': {
                                                        background: '#FEBF00',
                                                    },
                                                    textTransform: 'none'
                                                }} 
                                                className="add-more-btn" 
                                                id='add_more_btn'
                                                variant="outlined" 
                                                onClick={() => addData(section.sectionId)}
                                                >
                                                + Add {section.sectionName.toLowerCase()}
                                            </Button>
                                            
                                            <TableContainer sx={{marginTop: '2%'}} component={Paper} elevation={4}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow sx={{background: '#538a30', }}>
                                                            {section.fieldsList
                                                                // .filter(field => field.multipleSequence === 0 || field.multipleSequence === 1 )
                                                                // .filter(field => field.isActive === 1 && field.fieldType !== 'Paragraph')
                                                                .filter(field => field.multipleSequence === Math.min(...section.fieldsList.filter(field => field.isActive === 1).map(field => field.multipleSequence)))
                                                                .sort((a, b) => a.sequence - b.sequence)
                                                                .map((field, index) => (
                                                                    <TableCell key={index} sx={{ color: '#fff' }}>{field.fieldName}</TableCell>
                                                                ))}
                                                            <TableCell sx={{ color: '#fff' }}>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tableInputValue[section.sectionId] && tableInputValue[section.sectionId].map((row, rowIndex) => (
                                                            <TableRow key={rowIndex}>
                                                                {section.fieldsList
                                                                // .filter(field => field.multipleSequence === 1 ? field.multipleSequence === 1 : field.multipleSequence === 0)
                                                                .filter(field => field.multipleSequence === Math.min(...section.fieldsList.filter(field => field.isActive === 1).map(field => field.multipleSequence)))
                                                                .slice()
                                                                .sort((a, b) => a.sequence - b.sequence)
                                                                .sort((a, b) => a.multipleSequence - b.multipleSequence)
                                                                .map((field, cellIndex) => (
                                                                    <TableCell>
                                                                        {field.fieldType === 'CheckBox' ?
                                                                            <Checkbox readOnly={true} 
                                                                            onClick={(e) => e.preventDefault()}
                                                                            style={{ pointerEvents: 'none' }} 
                                                                            checked={row[field.fieldName]} />  
                                                                        : 
                                                                            <span>{row[field.fieldName]}</span>
                                                                        }
                                                                    </TableCell>
                                                                    
                                                                ))}
                                                                <TableCell>
                                                                    <BorderColorOutlinedIcon
                                                                        id='edit_btn'
                                                                        onClick={() => editRecord(row, section.sectionId, rowIndex)}
                                                                    />&emsp;
                                                                    <DeleteForeverIcon sx={{color: 'red'}}
                                                                        id='delete_btn'
                                                                        onClick={() => deleteRecord(section.sectionId, rowIndex)}
                                                                        // onClick={() => deleteRecord(rowIndex)}
                                                                    />
                                                                </TableCell>
                                                                

                                                            </TableRow>
                                                        ))}
                                                    {/* {Object.keys(tableInputValue).length !== 0 && 
                                                        Object.entries(tableInputValue).map(([name, value], rowIndex) => (
                                                        <TableRow
                                                        name={name}
                                                        key={rowIndex}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                        {section.fieldsList
                                                        .sort((a, b) => a.sequence - b.sequence)
                                                        .map((field, cellIndex) => (
                                                            <TableCell key={cellIndex} component="th" scope="row">
                                                                {tableInputValue?.[field.fieldName]}
                                                            </TableCell>
                                                        ))}
                                                        </TableRow>
                                                    ))} */}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            </>
                                        
                                        </Box>
                                    }
                                    {/* save and back button */}
                                    {section.sectionId === 3 || section.sectionId === 11 ? "" : (<Grid item xs={9}>
                                        <Box sx={{
                                            my: mobileView ? 7 : 10,
                                            textAlign: 'left',
                                            marginBottom: '10%',
                                            display: mobileView ? 'block' : (section.sectionId !== 3 ? 'flex' : (section.sectionId === 3 && section.fieldsList.length > 0 ? 'flex' : 'none')),
                                            // display: mobileView ? 'block' : section.sectionId !== 3 ? 'flex' : section.sectionId === 3 && section.fieldsList.length <= 0 ? 'flex' : 'none',
                                            justifyContent: mobileView ? 'center' : 'space-between',
                                            // alignItems: !mobileView ?? 'flex-start'
                                        }}>
                                            <Button sx={{
                                                border: '1px solid #185ABD',
                                                color: '#185ABD',
                                                background: '#FFF',
                                                '&:hover': {
                                                    border: '1px solid #185ABD',
                                                },
                                                textTransform: 'none'
                                            }} 
                                            className="secondary-save-btn" 
                                            id='back_btn'
                                            variant="outlined"
                                            onClick={() => handleBack(section.sequence, section.sectionId)}
                                            >
                                                Back 
                                            </Button>
                                            <Button sx={{
                                                background: '#185ABD',
                                                my: mobileView ?? 5,
                                                marginLeft: !mobileView ?? '110%',
                                                // marginLeft: '100%',
                                                '&:hover': {
                                                    background: '#185ABD',
                                                },
                                                textTransform: 'none'
                                            }} 
                                            className="primary-save-btn"
                                            id='save_btn' 
                                            variant="contained" 
                                            onClick={() => ((loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) ? 
                                                handleContinue(mobileView ? 1 : 0, section.isMultipleFields, section.fieldsList, section.sequence, section.sectionId):
                                                handleNextAdmin(mobileView ? 1 : 0, section.isMultipleFields, section.fieldsList, section.sequence, section.sectionId)
                                            )}>
                                                {(loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit') ? 'Proceed' : 'Save & Continue'}
                                            </Button>
                                        </Box>
                                    </Grid>)}

                                    {/* To display alert popup on success */}
                                    {isModalOpen && 
                                        <ModalComponent 
                                        open={isModalOpen} 
                                        onClose={() => setIsModalOpen(false)} 
                                        title={titleMsg} 
                                        content={content} 
                                        contentIdentity={modalContentIdentity} 
                                        modalResponse={() => {
                                            setModalResponse(true);
                                            if (modalResponseCallback) {
                                              modalResponseCallback();
                                            }
                                          }}
                                        />
                                    }
                                    
                                </Grid>
                            ):

                            // Address section for mobile view & subject

                            <Grid container component="main">
                                {(mobileView && section.parentSectionId !== 0 && section.parentSectionId === id && section.parentSectionId === 3 && section.isActive === 1 && (
                                    <Grid item xs={10}>
                                            <Typography 
                                                sx={{textAlign: 'left', mt:2,
                                                    color: '#000',
                                                    fontFamily: "Open Sauce Sans",
                                                    fontSize: '15px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 600,
                                                    lineHeight: '167%', 
                                                }}>{section.sectionName}
                                                &nbsp;&nbsp;
                                                <div style={{ display: section.sequence === 2 ? 'inline-flex' : 'none', alignItems: 'center' }}>
                                                    <input id='check' type='checkbox' onChange={copyAddress}/>
                                                    <Typography sx={{ fontSize: '13px' }}>Same as Correspondence address</Typography>
                                                </div>
                                            </Typography>
                                            <section style={{textAlign: 'left'}}>
                                                {section.isMultipleFields === 0 && section.fieldsList
                                                .sort((a, b) => a.sequence - b.sequence)
                                                .map((field, index) => (
                                                    <>
                                                        <FormControl sx={{ width: mobileView ? '100%' : '50%', textAlign: 'left',
                                                                ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' }: {}),
                                                                }}
                                                            >
                                                                <Box sx={{
                                                                    direction: mobileView ? 'column' : 'column',
                                                                    width: mobileView ? '100%' : '80%',
                                                                    alignItems: 'left',
                                                                    justifyContent: 'flex-start'
                                                                }}>
                                                                    <Box sx={{ display: mobileView ? 'block' : 'flex', alignItems: 'center', 
                                                                    // ...(field.visibleToStudent === '2' ? { display: 'none' }: {}) 
                                                                    }}>
                                                                        {field.fieldName !== 'CheckBox' &&
                                                                        <InputLabel
                                                                            className="formLabel"
                                                                            shrink={false}
                                                                            // sx={{ ...(field.visibleToStudent === '2' ? { display: 'none' } : {}) }}
                                                                            // sx={{ position: 'relative', textAlign: 'left' }}
                                                                        >
                                                                            {field.fieldName}
                                                                            {(field.fieldType === 'RadioButton' || field.fieldType === 'Photo' || field.fieldType === 'File') &&
                                                                                <span style={{ color: 'red' }}>*</span>
                                                                            }
                                                                        </InputLabel>
                                                                        }
                                                                        {field.helpText !== null && field.helpText !== '' && field.fieldType !== 'RadioButton' && (
                                                                            <Popup
                                                                                trigger={
                                                                                    <InfoOutlinedIcon
                                                                                        sx={{
                                                                                            marginBottom: mobileView ? '0%' : '-6%',
                                                                                            marginLeft: mobileView ? '109px' : '15px',
                                                                                            marginTop: mobileView ? '-2%' : '0%',
                                                                                            position: mobileView ? 'absolute' : 'relative',
                                                                                            color: '#484848',
                                                                                            width: '4%'
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                    on="hover" // Set the on prop to 'hover'
                                                                                    position="right center"
                                                                                >
                                                                                <Box>{field.helpText}</Box>
                                                                            </Popup>
                                                                        )}
                                                                    </Box>
                                                                    <Input
                                                                        type={field.fieldType}
                                                                        defaultValue={inputValue[field.fieldName] || '' ||field.value}
                                                                        onBlur={(value) => handleInputChange( 
                                                                            value, 
                                                                            field.validationType, 
                                                                            field.fieldName,
                                                                            {...field}, 
                                                                            section.isMultipleFields
                                                                        )}
                                                                        onChange={(value) => handleChange(value, field.fieldName, {...field})}
                                                                        {...field}
                                                                    />
                                                                    
                                                                    
                                                                </Box>
                                                                
                                                        </FormControl>
                                                    </>
                                                ))}
                                            </section>
                                        </Grid> 
                                    ))
                                }

                                {/* Subject Section */}
                                {!mobileView && section.parentSectionId !== 0 && section.parentSectionId === id && section.parentSectionId === 11 && section.isActive === 1 ?(
                                    <Grid container component='main'>
                                        <Grid item xs={10}>
                                            <Typography 
                                                sx={{
                                                    textAlign: 'left',
                                                    mt: 3,
                                                    color: '#000',
                                                    fontFamily: "Open Sauce Sans",
                                                    fontSize: '17px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 600,
                                                    lineHeight: '167%',
                                                    textDecoration: 'underline'
                                                    // marginTop: mobileView ? '0%' : '-93%',
                                                }}>
                                                {section.sectionName}
                                            </Typography>
                                            <section key={section.sectionId} style={{textAlign: 'left'}}>
                                                {/* compulsory subject */}
                                                {section.isMultipleFields === 0 && section.sequence === 1  && section.fieldsList 
                                                    .sort((a, b) => a.sequence - b.sequence)
                                                    .map((field, index) => (
                                                        <FormControl sx={{ width: mobileView ? '100%' : '50%', textAlign: 'left',
                                                            ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' }: {}),
                                                            }}
                                                        >
                                                            <Box sx={{
                                                                direction: mobileView ? 'column' : 'row',
                                                                width: mobileView ? '100%' : '80%',
                                                                alignItems: 'left',
                                                                justifyContent: 'flex-start'
                                                            }}>
                                                                <Input
                                                                    type={field.fieldType}
                                                                    // defaultValue={inputValue[field.fieldName] || '' ||field.value}
                                                                    {...field}
                                                                />
                                                                
                                                            </Box>
                                                            
                                                        </FormControl>
                                                ))}

                                                {/* optional subject */}
                                                {section.isMultipleFields === 0 && section.sequence === 2  && section.fieldsList 
                                                    .sort((a, b) => a.sequence - b.sequence)
                                                    .map((field, index) => (
                                                    (field.fieldRefType === 'SubjectId' ? 
                                                        <FormControl sx={{ width: mobileView ? '100%' : '50%', textAlign: 'left',
                                                            ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' }: {}),
                                                            }}
                                                        >
                                                            <Box sx={{
                                                                direction: mobileView ? 'column' : 'row',
                                                                width: mobileView ? '100%' : '80%',
                                                                alignItems: 'left',
                                                                justifyContent: 'flex-start'
                                                            }}>
                                                                <Input
                                                                    type={field.fieldType}
                                                                    defaultValue={inputValue[field.fieldName] || '' ||field.value}
                                                                    onBlur={(value) => handleInputChange( 
                                                                        value, 
                                                                        field.validationType, 
                                                                        field.fieldName,
                                                                        {...field}, 
                                                                        section.isMultipleFields
                                                                    )}
                                                                    {...field}
                                                                />
                                                                
                                                            </Box>
                                                            
                                                        </FormControl>
                                                    :
                                                    <Box>
                                                        <Box 
                                                            sx={{
                                                                color: '#000',
                                                                fontFamily: "Open Sauce Sans",
                                                                fontSize: '16px',
                                                                fontStyle: 'normal',
                                                                fontWeight: 600,
                                                                lineHeight: '167%',
                                                                mt: 3
                                                            }}  
                                                        >
                                                            {field.fieldName}
                                                            {field.helpText !== null && field.helpText !== '' &&
                                                                <span
                                                                    style={{
                                                                        color: '#000',
                                                                        fontFamily: "Open Sauce Sans",
                                                                        fontSize: '16px',
                                                                        fontWeight: 500,
                                                                        fontStyle: 'italic',
                                                                        lineHeight: '167%'
                                                                    }}  
                                                                >&nbsp;&nbsp;({field?.helpText})</span>
                                                            }
                                                        </Box>
                                                        <FormControl sx={{ width: mobileView ? '100%' : '61%', textAlign: 'left', display: 'flex',
                                                            ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' }: {}),
                                                            }}
                                                        >
                                                            <Box sx={{
                                                                direction: mobileView ? 'column' : 'row',
                                                                width: mobileView ? '100%' : '80%',
                                                                alignItems: 'left',
                                                                // display: 'inline-flex',
                                                                justifyContent: 'flex-start',
                                                                // gap: 30
                                                            }}>
                                                                <Input
                                                                    type={field.fieldType}
                                                                    defaultValue={inputValue[field.fieldName] || '' ||field.value}
                                                                    onBlur={(value) => handleInputChange( 
                                                                        value, 
                                                                        field.validationType, 
                                                                        field.fieldName,
                                                                        {...field}, 
                                                                        section.isMultipleFields
                                                                    )}
                                                                    {...field}
                                                                />
                                                                
                                                            </Box>
                                                            {Object.entries(textError).length > 0 &&
                                                                Object.entries(textError).map(([name, { isError, errorMessage }]) => (
                                                                    isError === true &&
                                                                    field?.fieldName === name && (
                                                                        <Typography className="error-message" key={name}>
                                                                            {errorMessage}
                                                                        </Typography>
                                                                    )
                                                                ))
                                                            }
                                                            
                                                        </FormControl>
                                                        
                                                    </Box>
                                                    )
                                                        
                                                ))}
                                                
                                            </section>
                                        </Grid>
                                        {section.sequence === maxSubjectSequence &&
                                            <Grid item xs={9}>
                                                <Box sx={{
                                                    my: mobileView ? 7 : 10,
                                                    textAlign: 'left',
                                                    marginBottom: '10%',
                                                    display: mobileView ? 'block' : (section.sectionId !== 3 ? 'flex' : (section.sectionId === 3 && section.fieldsList.length > 0 ? 'flex' : 'none')),
                                                    // display: mobileView ? 'block' : section.sectionId !== 3 ? 'flex' : section.sectionId === 3 && section.fieldsList.length <= 0 ? 'flex' : 'none',
                                                    justifyContent: mobileView ? 'center' : 'space-between',
                                                    // alignItems: !mobileView ?? 'flex-start'
                                                }}>
                                                    <Button sx={{
                                                        border: '1px solid #185ABD',
                                                        color: '#185ABD',
                                                        background: '#FFF',
                                                        '&:hover': {
                                                            border: '1px solid #185ABD',
                                                        },
                                                        textTransform: 'none'
                                                    }} 
                                                    className="secondary-save-btn" 
                                                    id='back_btn'
                                                    variant="outlined"
                                                    onClick={() => handleBack(section.sequence, section.parentSectionId)}
                                                    >
                                                        Back 
                                                    </Button>
                                                    <Button sx={{
                                                        background: '#185ABD',
                                                        my: mobileView ?? 5,
                                                        marginLeft: !mobileView ?? '110%',
                                                        // marginLeft: '100%',
                                                        '&:hover': {
                                                            background: '#185ABD',
                                                        },
                                                        textTransform: 'none'
                                                    }} 
                                                    className="primary-save-btn"
                                                    id='save_btn' 
                                                    variant="contained" 
                                                    onClick={() => ((loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) ? 
                                                        handleContinue(mobileView ? 1 : 0, section.isMultipleFields, section.fieldsList, section.sequence, section.parentSectionId):
                                                        handleNextAdmin(mobileView ? 1 : 0, section.isMultipleFields, section.fieldsList, section.sequence, section.parentSectionId)
                                                    )}>
                                                        {(loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' )? 'Proceed' : 'Save & Continue'}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        }
                                    </Grid>
                                ): mobileView && section.parentSectionId !== 0 && section.parentSectionId === id && section.parentSectionId === 11 && section.isActive === 1 &&(
                                    <Grid container component='main'>
                                        <Grid item xs={10}>
                                            <Typography 
                                                sx={{
                                                    textAlign: 'left',
                                                    mt: 3,
                                                    color: '#000',
                                                    fontFamily: "Open Sauce Sans",
                                                    fontSize: '15px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 600,
                                                    lineHeight: '167%',
                                                    textDecoration: 'underline'
                                                    // marginTop: mobileView ? '0%' : '-93%',
                                                }}>
                                                {section.sectionName}
                                            </Typography>
                                            <section key={section.sectionId} style={{textAlign: 'left'}}>
                                                {section.isMultipleFields === 0 && section.sequence === 1  && section.fieldsList 
                                                    // .filter(field => section.isMultipleFields === 1)
                                                    .sort((a, b) => a.sequence - b.sequence)
                                                    .map((field, index) => (
                                                        <FormControl sx={{ width: mobileView ? '105%' : '50%', textAlign: 'left',
                                                            ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' }: {}),
                                                            }}
                                                        >
                                                            <Box sx={{
                                                                direction: mobileView ? 'column' : 'row',
                                                                width: mobileView ? '100%' : '80%',
                                                                alignItems: 'left',
                                                                justifyContent: 'flex-start'
                                                            }}>
                                                                <Input
                                                                    type={field.fieldType}
                                                                    // defaultValue={inputValue[field.fieldName] || '' ||field.value}
                                                                    {...field}
                                                                />
                                                                
                                                            </Box>
                                                            
                                                        </FormControl>
                                                ))}

                                                {section.isMultipleFields === 0 && section.sequence === 2  && section.fieldsList 
                                                    // .filter(field => section.isMultipleFields === 1)
                                                    .sort((a, b) => a.sequence - b.sequence)
                                                    .map((field, index) => (
                                                    <FormControl sx={{ width: mobileView ? '100%' : '50%', textAlign: 'left',
                                                        ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' }: {}),
                                                        }}
                                                    >
                                                        <Box sx={{
                                                            direction: mobileView ? 'column' : 'row',
                                                            width: mobileView ? '50%' : '80%',
                                                            alignItems: 'left',
                                                            justifyContent: 'flex-start'
                                                        }}>
                                                            <Box sx={{ display: mobileView ? 'block' : 'flex', alignItems: 'center',  width: '185%', mt: 1
                                                            // ...(field.visibleToStudent === '2' ? { display: 'none' }: {}) 
                                                            }}>
                                                                {field.fieldType !== 'RadioButton' && field.fieldName !== 'CheckBox' && field.sectionId !== 14 &&
                                                                <InputLabel
                                                                    className="formLabel"
                                                                    shrink={false}
                                                                    sx={{display: 'inline'}}
                                                                    // sx={{ ...(field.visibleToStudent === '2' ? { display: 'none' } : {}) }}
                                                                    // sx={{ position: 'relative', textAlign: 'left' }}
                                                                >
                                                                    {field.fieldName}
                                                                    {(field.fieldType === 'RadioButton') &&
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    }
                                                                </InputLabel>
                                                                }
                                                                {field.helpText !== null && field.helpText !== '' && field.fieldType !== 'RadioButton'&& field.sectionId !== 14 && (
                                                                    <span
                                                                        style={{
                                                                            color: '#000',
                                                                            fontFamily: "Open Sauce Sans",
                                                                            fontSize: '14px',
                                                                            fontWeight: 500,
                                                                            fontStyle: 'italic',
                                                                            lineHeight: '167%'
                                                                        }}  
                                                                    >&nbsp;&nbsp;({field?.helpText})</span>
                                                                    
                                                                    // <Popup
                                                                    //     trigger={
                                                                    //         <InfoOutlinedIcon
                                                                    //             sx={{
                                                                    //                 marginBottom: mobileView ? '0%' : '-6%',
                                                                    //                 marginLeft: mobileView ? '130px' : '15px',
                                                                    //                 marginTop: mobileView ? '-2%' : '0%',
                                                                    //                 position: mobileView ? 'absolute' : 'relative',
                                                                    //                 color: '#484848',
                                                                    //                 width: mobileView ? '7%' : '3.5%'
                                                                    //             }}
                                                                    //         />
                                                                    //     }
                                                                    //         on="hover" // Set the on prop to 'hover'
                                                                    //         position="right center"
                                                                    //     >
                                                                    //     <Box>{field.helpText}</Box>
                                                                    // </Popup>
                                                                )}
                                                                {field.sectionId === 14 && (
                                                                    <Box sx={{fontStyle: 'italic', width:'100%'}}>({field.helpText})</Box>
                                                                )}
                                                            </Box>
                                                            <Input
                                                                type={field.fieldType}
                                                                name={field.fieldName}
                                                                defaultValue={inputValue[field.fieldName]  || '' || field.value}
                                                                onBlur={(value) => handleInputChange( 
                                                                    value, 
                                                                    field.validationType, 
                                                                    field.fieldName,
                                                                    {...field}, 
                                                                    section.isMultipleFields
                                                                )}
                                                                onChange={(value) => handleChange(value, field.fieldName, {...field})}
                                                                {...field}
                                                            />
                                                        </Box>
                                                    </FormControl>
                                                ))}
                                            </section>
                                        </Grid>
                                        {section.sequence === maxSubjectSequence &&
                                            <Grid item xs={9}>
                                                <Box sx={{
                                                    my: mobileView ? 7 : 10,
                                                    textAlign: 'left',
                                                    marginBottom: '10%',
                                                    display: mobileView ? 'block' : (section.sectionId !== 3 ? 'flex' : (section.sectionId === 3 && section.fieldsList.length > 0 ? 'flex' : 'none')),
                                                    // display: mobileView ? 'block' : section.sectionId !== 3 ? 'flex' : section.sectionId === 3 && section.fieldsList.length <= 0 ? 'flex' : 'none',
                                                    justifyContent: mobileView ? 'center' : 'space-between',
                                                    // alignItems: !mobileView ?? 'flex-start'
                                                }}>
                                                    <Button sx={{
                                                        border: '1px solid #185ABD',
                                                        color: '#185ABD',
                                                        background: '#FFF',
                                                        '&:hover': {
                                                            border: '1px solid #185ABD',
                                                        },
                                                        textTransform: 'none'
                                                    }} 
                                                    className="secondary-save-btn" 
                                                    id='back_btn'
                                                    variant="outlined"
                                                    onClick={() => handleBack(section.sequence, section.parentSectionId)}
                                                    >
                                                        Back 
                                                    </Button>
                                                    <Button sx={{
                                                        background: '#185ABD',
                                                        my: mobileView ?? 5,
                                                        marginLeft: !mobileView ?? '110%',
                                                        // marginLeft: '100%',
                                                        '&:hover': {
                                                            background: '#185ABD',
                                                        },
                                                        textTransform: 'none'
                                                    }} 
                                                    className="primary-save-btn"
                                                    id='save_btn' 
                                                    variant="contained" 
                                                    onClick={() => ((loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) ? 
                                                        handleContinue(mobileView ? 1 : 0, section.isMultipleFields, section.fieldsList, section.sequence, section.parentSectionId):
                                                        handleNextAdmin(mobileView ? 1 : 0, section.isMultipleFields, section.fieldsList, section.sequence, section.parentSectionId)
                                                    )}>
                                                        {(loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' )? 'Proceed' : 'Save & Continue'}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        }
                                    </Grid>
                                ) }
                            </Grid>
                        )):null
                        }
                    </React.Fragment>

                    {/* Address section for desktop */}
                    <React.Fragment>
                        {data !== null && loading === false && data.sections.length > 0 ? (
                            <Grid container spacing={2} mt={2}>
                            <Grid item xs={5}>
                                {data.sections
                                .filter((section) => section.parentSectionId === id && section.parentSectionId === 3 && section.isActive === 1 && section.sequence === 1 && !mobileView)
                                .map((section, index) => (
                                    <SectionGrid section={section} index={index} key={section.sectionId} />
                                ))}
                            </Grid>
                            <Grid item xs={5}>
                                {data.sections
                                .filter((section) => section.parentSectionId === id && section.parentSectionId === 3 && section.isActive === 1 && section.sequence === 2 && !mobileView)
                                .map((section, index) => (
                                    <SectionGrid section={section} index={index} key={section.sectionId} />
                                ))}
                            </Grid>
                            <Grid item xs={9}>
                                {data.sections
                                .filter((section) => section.parentSectionId === id && section.parentSectionId === 3 && section.isActive === 1 && section.sequence === 1 && !mobileView)
                                .map((section, index) => (
                                <Box sx={{
                                    my: mobileView ? 7 : 10,
                                    textAlign: 'left',
                                    marginBottom: '10%',
                                    display: mobileView ? 'block' :'flex',
                                    justifyContent: mobileView ? 'center' : 'space-between',
                                    // alignItems: !mobileView ?? 'flex-start'
                                }}>
                                    <Button sx={{
                                        border: '1px solid #185ABD',
                                        color: '#185ABD',
                                        background: '#FFF',
                                        '&:hover': {
                                            border: '1px solid #185ABD',
                                        },
                                        textTransform: 'none'
                                    }} 
                                    className="secondary-save-btn" 
                                    id='back_btn'
                                    variant="outlined"
                                    onClick={() => handleBack(3, section.parentSectionId)}
                                    >
                                        Back
                                    </Button>
                                    <Button sx={{
                                        background: '#185ABD',
                                        my: mobileView ?? 5,
                                        marginLeft: !mobileView ?? '110%',
                                        // marginLeft: '100%',
                                        '&:hover': {
                                            background: '#185ABD',
                                        },
                                        textTransform: 'none'
                                    }} 
                                    className="primary-save-btn"
                                    id='save_btn' 
                                    variant="contained" 
                                    onClick={() => ((loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) ? 
                                        handleContinue(mobileView ? 1 : 0, section.isMultipleFields, section.fieldsList, 3, section.parentSectionId):
                                        handleNextAdmin(mobileView ? 1 : 0, section.isMultipleFields, section.fieldsList, 3, section.parentSectionId)
                                    )}>
                                        {(loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit') ? 'Proceed' : 'Save & Continue'}
                                    </Button>
                                </Box>
                                ))}
                            </Grid>
                            </Grid>
                        ) : null}
                    </React.Fragment>
                    
                </Box>
            </Box>
            )}
        </ScreenView>
        {/* )} */}
        </>

    );
}