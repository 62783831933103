import {REFRESH_TOKEN, REFRESH_ADMIN_TOKEN} from "../util/apiUrl"
// getToken.js
// import { globalActionType } from "../pages/admissionForm";
// const [actiontype, setActionType] = globalActionType.useState()
let accessTokenPromise = null;
let accessToken = '';

const fetchAccessToken = async () => {
  try {

    var dateOfBirth = sessionStorage.getItem("dateOfBirth");
    var mobileNo = sessionStorage.getItem("mobileNo");
    var univID = sessionStorage.getItem("univID");
    var actionType = sessionStorage.getItem("actionType");

    if(univID !== null && (actionType === 'Preview' || actionType === 'Edit')){
      var url = REFRESH_ADMIN_TOKEN
      var bodyData = JSON.stringify({
          "univId": univID,
          "userId": 1,
          "roleId": 1,
          "menuName": "Create Configuration"
      })
    }else{
      var url = REFRESH_TOKEN
      var bodyData = JSON.stringify({
        dateOfBirth: dateOfBirth,
        mobileNo: mobileNo,
        univID: univID,
      })
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: bodyData,
    });

    if (!response.ok) {
      throw new Error('Failed to fetch access token');
    }

    const data = await response.json();
    accessToken = data.data.token;

    const expires = new Date();
    expires.setTime(expires.getTime() + (data.expires_in * 1000)); // Convert expires_in to milliseconds
    document.cookie = `access_token=${accessToken};expires=${expires.toUTCString()};path=/`;

    return accessToken;
  } catch (error) {
    console.error('Error fetching access token:', error);
    throw error;
  }
};

const getAccessTokenFromCookie = () => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith('access_token='))
    ?.split('=')[1];

  return cookieValue || '';
};

const getAccessToken = async () => {
  // let accessToken = getAccessTokenFromCookie();

  // if (!accessToken) {
    accessToken = await fetchAccessToken();
  // }

  return accessToken;
};

export { fetchAccessToken, getAccessToken };