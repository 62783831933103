import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as CryptoJS from 'crypto-js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactDOMServer from 'react-dom/server';

import { FormControl, Box, Typography, Grid, InputAdornment, Input, CircularProgress } from '@mui/material';
import { TextField, Button, Paper } from '@mui/material'; 
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import { globalInstersted } from './formSection';
import { Form, FinalDocumentSave, CountryList, StateList, CityList, annexture, jobList } from '../util/apiUrl';
import CallAPI from '../util/apiAction';
import ScreenView from "../util/screenView";
import ModalComponent from './ModalComponent';
import pdfIcon from "../assets/images/pdfIcon.png"
import docIcon from "../assets/images/docIcon.png"
import '../assets/styles/preview.css'
import Header from './header';
import Loader from './linearProgress';
import { Interests } from '@mui/icons-material';
import { hover } from '@testing-library/user-event/dist/hover';

export default function PreviewComponent(props){
    const [data, setData] = useState(null)
    const componentRef = useRef();
    const location = useLocation();
    const Navigate = useNavigate();
 

    // Fetch values from session
    const [actionTypeHook, setActionTypeHook] = useState("");
    const univId = sessionStorage.getItem('univID')
    const dateOfBirth = sessionStorage.getItem('dateOfBirth')
    const mobileNo = sessionStorage.getItem('mobileNo')
    const name = sessionStorage.getItem('name')
    const admissionId = sessionStorage.getItem('admissionId')
    const loggedInUser = sessionStorage.getItem('loggedInUser')
    const admissionStage = sessionStorage.getItem('admissionStage')

    const [renderTo, setRenderTo] = useState(null)
    const [dataFromSection, setDataFromSection] = useState(true);
    const [inputValue, setInputValue] = useState({});
    const [tableInputValue, setTableInputValue] = useState([])
    const [paymentUrl, setPaymentUrl] = useState(null);
    const [sections, setSections] = useState([]);
    const [saveResponse, setSaveResponse] = useState(null);
    const [decrytedString, setDecryptedString] = useState(null);
    const [adminData, setAdminData] = useState(null);
    const [courseId, setCourseId] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [lastActiveSection, setlastActiveSection] = useState(null);
    const [enquiryId, setenquiryId] = useState(null);
    const [sname, setname] = useState(null);
    const [validSections, setValidSections] = useState(null);
    const [subjectSection, setSubjectSection] = useState(null);
    const [Insterested, setInterested] = globalInstersted.useState();
    const [loading, setLoading] = useState(false);
    const [admissionFormId, setAdmissionFormId] = useState(null)
    // const [renderFlag, setRenderFlag] = useState(0);
    const [annextureFlag, setAnnextureFlag] = useState(false) 
    const [annextureData, setAnnextureData] = useState(null)
    const [buttonLoading, setButtonLoading] = useState(null)

    // dropdown usestate
    const [countryList, setCountryList] = useState(null);
    const [stateList, setStateList] = useState(null);
    const [cityList, setCityList] = useState(null);
    const [opportunity, setOpportunity] = useState(null)

    // Modal usestate
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [titleMsg, setTitleMsg] = useState(null);
    const [content, setContent] = useState(null);
    const [modalResponse, setModalResponse] = useState(false);
    const [modalContentIdentity, setModalContentIdentity] = useState(null)
    const [modalResponseCallback, setModalResponseCallback] = useState(null);

    useEffect(() => {
        console.log('course in preview', sessionStorage.getItem('CourseId'))
        setCourseId(sessionStorage.getItem('CourseId'))
        setCategoryId(sessionStorage.getItem('CategoryId'))
        setenquiryId(sessionStorage.getItem('enquiryId'))
        setname(sessionStorage.getItem('name'));
    }, [sessionStorage.length > 0])

    useEffect(() => {
        if (location.state !== null) {
            console.log('in location state')
            const { CourseId, CategoryId, lastActiveSection, enquiryId } = location.state;
            setCourseId(CourseId);
            setCategoryId(CategoryId);
            setlastActiveSection(lastActiveSection);
            setenquiryId(enquiryId);
            // setname(name);
        } else if(sessionStorage.length > 0) {
            console.log('in location state else')
            setCourseId(sessionStorage.getItem('CourseId'));
            setCategoryId(sessionStorage.getItem('CategoryId'));
            setlastActiveSection(null);
            setenquiryId(sessionStorage.getItem('enquiryId'));
            setname(null);
        }
    }, [location.state])

    // API for job list
    useEffect(() => {
        const fetchData = async () => {
          try {
            const { opportunity,loading,error } = await CallAPI(jobList, 'POST', { 
                    "courseID": adminData ? (adminData.CourseId) : sessionStorage.getItem('CourseId'),
                    "univID": adminData ? adminData.UnvId : univId,
                    "candidateID": "",
                    "candidateMobile": adminData ? adminData.MobileNo : mobileNo,
                    "candidateEmail": ""
                }
            ); 
                setOpportunity(opportunity);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
        
    }, [adminData, actionTypeHook]);

    useEffect(() => {
        const fetchData = async () => {
          try {
           const { data,loading,error } = await CallAPI(annexture, 'POST', { 
                "enquiryId": adminData ? adminData.EnquiryId : sessionStorage.getItem('enquiryId'),
            }
          ); 
            setAnnextureData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
        
    }, [adminData, actionTypeHook]);
    

    function handleDataFromSec(showFormSection){
        setDataFromSection(showFormSection)
    } 

    // API for Form (for student)
    useEffect(() => {
        const fetchData = async () => {
          try {
           // const result = await callAPI(apiUrl, 'GET', { IsActive:1 });
           const { data,loading,error } = await CallAPI(Form, 'POST', { 
                "unvId": adminData ? adminData.UnvId : univId,
                "categoryId": adminData ? parseInt(adminData.CategoryId) : categoryId,
                "courseId": adminData ? parseInt(adminData.CourseId) : courseId,
                "enquiryId": adminData ? parseInt(adminData.EnquiryId) : enquiryId, 
                "mobileNo": adminData ? adminData.MobileNo : mobileNo,
                "dob": adminData ? adminData.DOB : dateOfBirth,
                "name": adminData ? adminData.Name : name
            }
          ); 
            setData(data);
            setAdmissionFormId(data.admissionFormId)
            setTimeout(() => {
                setLoading(false); // Set loading state to false after a short delay
            }, 1000);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        if(location.state !== null){
            setLoading(true);
            fetchData();
        }
        
    }, [location.state, categoryId, courseId, enquiryId]);

    // Api to fetch country list
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data, loading, error } = await CallAPI(CountryList, 'GET', {});
                setCountryList(data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [])

    // Api to fetch state list
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data, loading, error } = await CallAPI(StateList, 'GET', {'countryId' : 0});
                setStateList(data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [])

    // Api to fetch city list
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data, loading, error } = await CallAPI(CityList, 'GET', {'stateId' : 0});
                setCityList(data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [])

    // API for form (if adminData)
    useEffect(() => {
        const fetchData = async () => {
          try {
           // const result = await callAPI(apiUrl, 'GET', { IsActive:1 });
           const { data,loading,error } = await CallAPI(Form, 'POST', { 
                "unvId": adminData ? adminData.UnvId : univId,
                "categoryId": adminData ? parseInt(adminData.CategoryId) : categoryId,
                "courseId": adminData ? parseInt(adminData.CourseId) : courseId,
                "enquiryId": adminData ? parseInt(adminData.EnquiryId) : enquiryId, 
                "mobileNo": adminData ? adminData.MobileNo : mobileNo,
                "dob": adminData ? adminData.DOB : dateOfBirth,
                "name": adminData ? adminData.Name : name
            }
          ); 
            setData(data);
            setAdmissionFormId(data.admissionFormId)
            sessionStorage.setItem("lastActiveSection",data.lastActiveSection)
            setTimeout(() => {
                setLoading(false); // Set loading state to false after a short delay
            }, 1000);
            console.log(data && data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        console.log('categoryId, courseId, enquiryId', categoryId, courseId, enquiryId)
        if(categoryId !== null && courseId !== null && enquiryId !== null){
            setLoading(true)  
            fetchData();
        }

        console.log(actionTypeHook);
        
    }, [adminData, actionTypeHook, categoryId, courseId, enquiryId]);

    console.log('adminData', adminData)

    // Set IneterestedIn field value
    useEffect(() => {
        if(data !== null){
            let sec = data.sections.find(section => section.sectionId === 7)
            let fields = sec.fieldsList

            if(fields){
                console.log(fields)
                fields.forEach((element) => {
                    if(element.fieldId === 52){
                        console.log(element.value)
                        setInterested(element.value)
                    }
                })
            }
        }
    }, [data])

    // For multiple record section - if value is fetched from db add into table
    useEffect(() => {
        if (data !== null) {
            const newTableInputValue = {};
            data.sections.forEach((section) => {
                if (section.sectionId === 5 || section.sectionId === 6) {
                    const sortedFields = section.fieldsList
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => a.sequence - b.sequence); // Assuming 'sequence' is the correct property name
        
                    sortedFields.forEach((field) => {
                        // Check if the field value is non-null
                        if (field.value !== null && field.value !== '') {
                            // Initialize the sectionData array if it doesn't exist
                            if (!newTableInputValue[section.sectionId]) {
                                newTableInputValue[section.sectionId] = [];
                            }
    
                            // Ensure the array has enough elements for the given sequence
                            while (newTableInputValue[section.sectionId].length < field.multipleSequence) {
                                newTableInputValue[section.sectionId].push({});
                            }
                            
                            if(field.fieldType === 'RadioButton' || field.fieldType === 'DropDown'){
                                // Add field data to the appropriate sequence-based entry
                                newTableInputValue[section.sectionId][field.multipleSequence - 1][field.fieldName] = field.valueLabel;
                            }else{
                                // Add field data to the appropriate sequence-based entry
                                newTableInputValue[section.sectionId][field.multipleSequence - 1][field.fieldName] = field.value;
                            }
                            
                        }
                    });
    
                }
            });
            setTableInputValue(newTableInputValue);
        }
    }, [data]);

    const actionType = sessionStorage.getItem("actionType");

    useEffect(() => {
        if(adminData !== null){
            sessionStorage.setItem("name",adminData.Name);
            sessionStorage.setItem("mobileNo",adminData.MobileNo);
            sessionStorage.setItem("dateOfBirth",adminData.DOB);
            sessionStorage.setItem("univID",adminData.UnvId);
            sessionStorage.setItem("loggedInUser",'admin');
            sessionStorage.setItem("actionType",adminData.ActionType);
            sessionStorage.setItem("CategoryId",adminData.CategoryId);
            sessionStorage.setItem("CourseId", adminData.CourseId);
            sessionStorage.setItem("enquiryId", adminData.EnquiryId);
            sessionStorage.setItem("userId", adminData.SessionUserId);
            console.log("I m setting first");
            const value = sessionStorage.getItem("actionType");
            if(value === "Edit"){
                setActionTypeHook(value);
                console.log("I found edit", actionTypeHook&&actionTypeHook);
            }
            else{
                console.log("I couldnt find edit");
 
            }
        }
    },[adminData, actionTypeHook])

    useEffect(() => {
        setActionTypeHook(actionType);
    },[actionType])

    const handleEdit = (sectionId) => {
        console.log('sectionId in preview: ', sectionId)
        setRenderTo(sectionId)

        if(adminData !== null){
            // Here lastActiveSection is used to redirect to section want to edit
            // sessionStorage.setIte("actionType", adminData.ActionType);
            Navigate('/admission-form', { state: { lastActiveSection: sectionId, CategoryId: parseInt(adminData.CategoryId), CourseId: parseInt(adminData.CourseId), enquiryId: adminData.EnquiryId, name: adminData.Name} })
            console.log('in preview: ', { lastActiveSection: sectionId, CategoryId: parseInt(adminData.CategoryId), CourseId: parseInt(adminData.CourseId), enquiryId: adminData.EnquiryId, name: adminData.Name, dob: adminData.DOB, mobileNo: adminData.MobileNo, unvId: adminData.UnvId })
        }else{
            localStorage.setItem('RenderFromPreview', 1)
            // Here lastActiveSection is used to redirect to section want to edit
            Navigate('/admission-form', { state: { lastActiveSection: sectionId, CategoryId: categoryId, CourseId:courseId, enquiryId: enquiryId, name: name } })
        }
       
    }
    

    const handleFinalSave = () => {
        let saveButton = document.getElementById('final_save_btn') 
        saveButton.disabled = true;
        setButtonLoading(true)
        const fetchData = async () => {
            try {
                const { data,statusCode,error } = await CallAPI(FinalDocumentSave, 'POST', {
                    "enquiryId": adminData !== null ? adminData.EnquiryId : enquiryId,
                    "admissionFormId": admissionFormId,
                    "userId": (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit') ? parseInt(sessionStorage.getItem('userId')) : 0,
                    "isFinalSave": 1
                }
            ); 
                setSaveResponse(data);
                setPaymentUrl(data.paymentUrl)
                
                if(statusCode === 200 && data.admissionId !== 0 && adminData === null ){
                    let button = document.getElementById('payment_btn');
                    button.style.display = 'block'
                } else if(data.paymentUrl === null || data.paymentUrl === ''){
                    let button = document.getElementById('payment_btn');
                    button.style.display = 'none'
                }

                setButtonLoading(false)
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }

    const handlePayment = () => {
        if(paymentUrl !== null){
            window.location.replace(paymentUrl);
        }
    }

    useEffect(() => {
        <Loader />
    },[])

    // useEffect(() => {
    //     setAnnextureData(props.annextureData)
    // }, [])

    useEffect(() => {
		if (data !== null) {
            // console.log("Data: ",data);
			const updatedSections = data.sections.map(section => ({ ...section }));
			updatedSections.forEach((section) => {
                
				if (section.parentSectionId !== 0) {
                    const parentSection = updatedSections.find(parent => parent.sectionId === section.parentSectionId);

                    parentSection.fieldsList.sort((a, b) => a.sequence - b.sequence);
                    if (parentSection) {
                        parentSection.fieldsList = parentSection.fieldsList.concat(section.fieldsList);
                    }

                }
			});

            updatedSections.forEach(section => {
				if (section.fieldsList && section.fieldsList.length > 0) {
					section.fieldsList.sort((a, b) => a.sequence - b.sequence);
				}
			});

			setSections(updatedSections);
            // console.log(sections&&sections);
		}
	}, [data]);

    // useEffect(() => {
    //     if(data !== null && stateList !== null && countryList !== null && cityList !== null){ 
    //         const sectionList = data.sections.filter((section) => section.parentSectionId === 3)
    //         const field = sectionList.map((sec) => sec.fieldsList)

    //         field.forEach((f) => {
    //             f.forEach((element) => {
    //                 if(element.dbColumnName === 'CountryId'){
    //                     let cid = element.value
    //                     const countryKey = countryList.find(country => country.key === parseInt(cid)).value;
    //                     setInputValue((prevInputValue) => ({
    //                         ...prevInputValue,
    //                         [element.fieldName]: countryKey
    //                     }));
                        
    //                 }if(element.dbColumnName === 'StateId'){
    //                     let sid = element.value
    //                     const stateKey = stateList.find(state => state.key === parseInt(sid)).value;
    //                     setInputValue((prevInputValue) => ({
    //                         ...prevInputValue,
    //                         [element.fieldName]: stateKey
    //                     }));
    //                 }if(element.dbColumnName === 'CityId'){
    //                     let selCity = element.value
    //                     const cityKey = cityList.find(city => city.key === parseInt(selCity)).value;
    //                     setInputValue((prevInputValue) => ({
    //                         ...prevInputValue,
    //                         [element.fieldName]: cityKey
    //                     }));
    //                 }if(element.dbColumnName === 'PCountryId'){
    //                     let pcid = element.value
    //                     const pCountryKey = countryList.find(pcountry => pcountry.key === parseInt(pcid)).value;
    //                     setInputValue((prevInputValue) => ({
    //                         ...prevInputValue,
    //                         [element.fieldName]: pCountryKey
    //                     }));
    //                 }if(element.dbColumnName === 'PStateId'){
    //                     let psid = element.value
    //                     const pStateKey = stateList.find(pstate => pstate.key === parseInt(psid)).value;
    //                     setInputValue((prevInputValue) => ({
    //                         ...prevInputValue,
    //                         [element.fieldName]: pStateKey
    //                     }));
    //                 }if(element.dbColumnName === 'PCityId'){
    //                     let pSelCity = element.value
    //                     const pCitykey = cityList.find(pcity => pcity.key === parseInt(pSelCity)).value;
    //                     setInputValue((prevInputValue) => ({
    //                         ...prevInputValue,
    //                         [element.fieldName]: pCitykey
    //                     }));
    //                 }
    //             })
                
    //         })
    //     }
    // },[data, stateList, countryList])

    const GetMD5HEX = (input) => {
        return CryptoJS.MD5(input).toString();
    }

    const deriveKey = (key) => {
        return CryptoJS.SHA256(key);
    };


    const decryptData = (base64Encrypted) => {
        var secretKey = 'LURNAPP$SGLURN@G02020';
        const key = deriveKey(secretKey);

      
        const bytes = CryptoJS.AES.decrypt(base64Encrypted, key, {
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.ECB,
          keySize: 256 / 32
        });

        return bytes.toString(CryptoJS.enc.Utf8);
      };


    useEffect(() => {
        var EncryptedURL = window.location.href;
        // var EncryptedURL = 'https://mservicedev.teamleaseedtech.com/preview?data=tI4kcH/Pdrbmg62K4ntdas4E4Kzpk8fCMboaZDvNWwCuISf4FNVJigwDAlf6GnbDemklE3y8aOcP8YeFQ4T7ioMnCAxQdqekb51XCbJoa8wcm1Mfg06hDXretkT10oRLgTfxtcno7C5zx611g8TKyv7mX74FAe5lq+6gdk9fQ9aq27FW5nMxr1nnJTQYqVmKbHXjUvO0eJ3lOcxI5JWgce1OTPAkwiYm2nc44Vna8oG65WXKNynSBotzU185nsX7r2ajgJlEm/gr2NRMG43Wfw=='
        const url = new URL(EncryptedURL).searchParams.get('data');

        if(url !== null){
            const finalUrlData = url.replace(/ /g, '+')
            console.log('dt:', finalUrlData)
    
            if (finalUrlData !== null) {
              try {
                const decrypted = decryptData(finalUrlData);
                if (decrypted.length > 0) {
                    setDecryptedString(decrypted);
                  console.log('Decrypted string:', decrypted);
                } else {
                  console.log('Decryption error: No output from decryption');
                }
              } catch (e) {
                console.log('Decryption error:', e.message);
              }
            } else {
              console.log('URL parameter "data" is null or undefined');
            }
        }
        
    }, []);

    useEffect(() => {
        if(decrytedString !== null){
            // Split the query string by '&' to get key-value pairs
            const pairs = decrytedString.split('&');

            // Initialize an empty object to hold the JSON
            const jsonObject = {};

            // Iterate over each pair
            pairs.forEach(pair => {
                // Split each pair by '=' to separate keys and values
                const [key, value] = pair.split('=');
                // Add the key-value pair to the jsonObject
                jsonObject[key] = value;
            });
            setAdminData(jsonObject);
        }
        
    }, [decrytedString])
    
    useEffect(() => {
        if(data !== null){
            
            const validSection = data.sections.filter(
                (section) => section.parentSectionId !== 0 && section.parentSectionId === 3 && section.isActive === 1
            );
            setValidSections(validSection)
        }
    },[data])

    useEffect(() => {
        if(data !== null){
            let subjectSec = data.sections.filter(
                (section) => section.parentSectionId !== 0 && section.parentSectionId === 11 && section.isActive === 1
            );
            setSubjectSection(subjectSec)
        }
    },[data])

    const documentPreview = (fieldName, value) => {
        if(!(value === null || value === "")){
        let extension = value.split('.').pop()      // Fetch the extension from url
        setIsModalOpen(true);
        setTitleMsg("");
        if(extension === 'pdf'){
            setContent(<iframe src={value} width={700} height={500} frameBorder="0"></iframe>)
        }else if(extension === 'doc' || extension === 'docx'){
            const encodedUrl = encodeURIComponent(value);
            const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
            setContent(<iframe src={googleDocsViewerUrl} width={700} height={500} frameBorder="0"></iframe>)
        }else if(extension === 'png' || extension === 'jpg' || extension === 'jpeg'){
            setContent(<iframe src={value} width={800} height={500} frameBorder="0"></iframe>)
        }
        setModalContentIdentity('documentPreview')
        }
    };

    const Input = ({type, value, ...rest }) => {
        switch(type){
            case 'Label':
                return (
                    <>
                        <TextField
                            className='preview-field'
                            defaultValue={rest?.fieldName}
                            sx={{width: '50%'}}
                            InputProps={{
                                readOnly: true,
                            }}
                            
                        />  
                    
                    </>
                );
            break;
            case 'CheckList':
                return (
                    <>
                        <TextField
                            className='preview-field'
                            defaultValue={value}
                            sx={{width: '100%'}}
                            InputProps={{
                                readOnly: true,
                            }}
                            
                        />  
                    
                    </>
                );
            break;
            default:
                return null
        }
    }

    const handleAnnexture = () => {
        setAnnextureFlag(true)
        let url = annextureData.annexureFile.fileUrl

        if(annextureData !== null && (loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))){
            // let url = 'https://uatums.digivarsity.com/Document/Data_101/Annexure%20I.html'
            fetch(url)
            .then(response => response.text())
            .then(html => {
                // Replace all placeholders with their corresponding values
                let modifiedContent = html;
                // annextureData.keyValue.forEach(item => {
                //     modifiedContent = modifiedContent.replace(item.key, item.value);
                // });
                annextureData.keyValue.forEach(item => {
                    const regex = new RegExp(item.key, 'g'); // 'g' flag for global replacement
                    modifiedContent = modifiedContent.replace(regex, item.value);
                });
                setContent(modifiedContent);

                setIsModalOpen(true);
                setTitleMsg("");
                // setContent(<div dangerouslySetInnerHTML={{ __html: modifiedContent }}></div>)
                setContent(<iframe srcDoc={modifiedContent} width={700} height={500} frameBorder="0"></iframe>)
                setModalContentIdentity('documentPreview')
            })
            .catch(error => console.error('Error fetching HTML content:', error));

            
        }else if(loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit'){
            fetch(url)
            .then(response => response.text())
            .then(html => {
                // Replace all placeholders with their corresponding values
                let modifiedContent = html;
                annextureData.keyValue.forEach(item => {
                    modifiedContent = modifiedContent.replace(item.key, '');
                });
                setContent(modifiedContent);

                setIsModalOpen(true);
                setTitleMsg("");
                // setContent(<div dangerouslySetInnerHTML={{ __html: modifiedContent }}></div>)
                setContent(<iframe srcDoc={modifiedContent} width={700} height={500} frameBorder="0"></iframe>)
                setModalContentIdentity('documentPreview')
            })
            .catch(error => console.error('Error fetching HTML content:', error));
        }
    }

    // personal detail first 3 fields
    const PersonalGrid = (section, index) => {
        return (
            <ScreenView>
            {mobileView => (
                <Grid container direction="column" sx={{ textAlign: 'left' }}>
                {section.section.fieldsList
                .sort((a, b) => a.sequence - b.sequence)
                .filter(field => {
                    if ([1, 2, 3, 4].includes(field.sequence) && field.dbColumnName.toLowerCase() === 'photo') {
                      return false; // skip if sequence is 1, 2, or 3 and column name is "photo"
                    }
                    return [1, 2, 3, 4].includes(field.sequence) && field.isActive === 1;
                })
                .map((field, fieldIndex) => (
                    <Grid item key={fieldIndex}>
                    <Box
                        sx={{
                        display: section.sectionId === 2 ? 'block' : 'inline-block',
                        width: '100%'
                        }}
                    >
                        <FormControl
                            sx={{
                                width: mobileView ? '100%' : '100%',
                                textAlign: 'left',
                                ...(field.visibleToStudent === '2' ? { display: 'none' } : {}),
                            }}
                        >
                        <Box
                            sx={{
                            flexDirection: mobileView ? 'column' : 'row',
                            width: mobileView ? '100%' : '80%',
                            alignItems: 'left',
                            justifyContent: 'flex-start',
                            mt: 2,
                            }}
                        >
                            <Box sx={{ display: field.fieldType === "HiddenField" ? 'none' : 'flex'}}>
                            <Typography className="preview-label" sx={{width: '60%'}}>{field.fieldName}:</Typography>
                            <Typography 
                            className="field-value"
                                sx={{ 
                                    ...(field?.inputCase === 'U' && {textTransform: "uppercase"}), 
                                    ...(field?.inputCase === 'C' && {textTransform: "capitalize"}),
                                    ...(field?.inputCase === 'L' && {textTransform: "lowercase"})  
                                }}
                            >{field.fieldType === 'RadioButton' || field.fieldType === 'DropDown'  ? field.valueLabel : field.value}</Typography>
                            </Box>
                        </Box>
                        </FormControl>
                         {/* <FormControl
                        sx={{
                            width: mobileView ? '100%' : '100%',
                            textAlign: 'left',
                            ...(field.visibleToStudent === '2' || field.fieldType === 'HiddenField'
                            ? { display: 'none' }
                            : {}),
                        }}
                        >
                        <Box
                            sx={{
                            flexDirection: mobileView ? 'column' : 'column',
                            width: mobileView ? '100%' : '100%', // width: mobileView ? '100%' : '225%',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            }}
                        >
                            <Box
                            sx={{
                                display: mobileView ? 'block' : 'flex',
                                alignItems: 'center',
                                ...(field.visibleToStudent === '2' ? { display: 'none' } : {}),
                            }}
                            >
                            <Box sx={{display: 'flex'}}>
                                <Typography 
                                // className='preview-label'
                                sx={{width: '100%', fontFamily: "Open Sauce Sans",
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                }}
                                >
                                    {field.fieldName}:
                                </Typography>
                                <Typography 
                                // className='field-value'
                                    sx={{ 
                                        width: '0%',
                                        fontFamily: "Open Sauce Sans",
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        ...(field?.inputCase === 'U' && {textTransform: "uppercase"}), 
                                        ...(field?.inputCase === 'C' && {textTransform: "capitalize"}),
                                        ...(field?.inputCase === 'L' && {textTransform: "lowercase"})  
                                    }}
                                >{field.value}</Typography>

                            </Box>
                            </Box>
                        </Box>
                        </FormControl>  */}
                    </Box>
                   </Grid>
                ))}
                </Grid>
            )}
            </ScreenView>
        );
    };
    
    // photo field
    const PhotoGrid = (section, index) => {
        return (
            <ScreenView>
            {mobileView => (
                <Grid container direction="column" sx={{ textAlign: 'left', marginLeft: '18%' }}>
                {section.section.fieldsList
                .sort((a, b) => a.dbColumnName.localeCompare(b.dbColumnName))
                .filter(field => field.dbColumnName.toLowerCase() === 'photo' && field.isActive === 1)
                .map((field, fieldIndex) => (
                    <Grid item key={fieldIndex}>
                    <Box>
                        <Box sx={{ display: mobileView ? 'block' : 'block', alignItems: 'center' }}>
                            <Box>
                            <Typography className='field-value'>
                                <img src={field.value} style={{ width: '180px', height: '130px', borderRadius: '10px' }} alt="Uploaded file" />
                            </Typography>
                            </Box>
                        </Box>
                    </Box>
                    </Grid>
                ))}
                </Grid>
            )}
            </ScreenView>
        );
    };

    
    let renderFlag = true       // Set a flag to avoid re-rendering of adress section
    let subjectRenderFlag = true // Set a flag to avoid re-rendering of subject section
    let personalRenderFlag = true
    return(
        <>
            <ScreenView>
                {mobileView => (
                    <>
                    <Box className="previewContainer" sx={{marginBottom: '5%', overflowX: 'hidden'}}>
                        <React.Fragment >
                            {sections !== null && loading === false ?
                            sections.map((section) => (
                                section.isActive === 1  && section.parentSectionId === 0 ? (
                                    <Box className='form-section'>
                                    <Grid container component="main" sx={{mt:5, padding: '0% 10%'}}>
                                        <Grid item xs={11} sx={{mt: 0}}>
                                            {section.sectionId !== 14 || (section.sectionId === 14 && Insterested === '1')?
                                            <Typography className="preview-title" sx={{textAlign: 'left', ml:0, fontWeight: 600}}  variant="h6">
                                                <span key={section.sectionId}>{section.sectionName}</span>
                                            </Typography>
                                            :
                                            null
                                            }

                                           
                                            <section key={section.sectionId} style={{textAlign: 'left'}}>
                                                {section.fieldsList
                                                .sort((a, b) => a.sequence - b.sequence)
                                                .map((field, index) => (
                                                    
                                                    <>
                                                        
                                                        {!mobileView && section.sectionId === 2 ? (field.sequence === 1 || field.sequence === 2 || field.sequence === 3 || field.dbColumnName === 'Photo' ) && personalRenderFlag === true? 
                                                            (
                                                                <React.Fragment>
                                                                {personalRenderFlag = false}
                                                                {data !== null && loading === false && data.sections.length > 0 ? (
                                                                    data.sections.find((section) => section.sectionId === 2) ? (
                                                                    <Grid container spacing={2} mt={2}>
                                                                    <Grid item xs={5}>
                                                                        {data.sections
                                                                        .filter((section) => section.sectionId === 2 && section.isActive === 1 && !mobileView)
                                                                        .map((section, index) => (
                                                                            // <SectionGrid section={section} index={index} key={section.sectionId} />
                                                                            <PersonalGrid section={section} index={index} key={section.sectionId}/>
                                                                        ))}
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        {data.sections
                                                                        .filter((section) => section.sectionId === 2 && section.isActive === 1 && !mobileView)
                                                                        .map((section, index) => (
                                                                            // <SectionGrid section={section} index={index} key={section.sectionId} />
                                                                            <PhotoGrid section={section} index={index} key={section.sectionId}/>
                                                                        ))}
                                                                    </Grid>
                                                                    </Grid>
                                                                    ): null
                                                                ): null}
                                                            </React.Fragment>
                                                            ): (!mobileView && !(
                                                                (section.sectionId === 2 && [1, 2, 3].includes(field.sequence)) ||
                                                                ([1, 2, 3, 4].includes(field.sequence) && [1, 2, 3, 4].some(seq => section.fieldsList.find(f => f.sequence === seq && f.dbColumnName.toLowerCase() === 'photo'))) ||
                                                                (field.dbColumnName.toLowerCase() === 'photo')
                                                              ) ?
                                                                <FormControl
                                                                        sx={{
                                                                            width: mobileView ? '100%' : '50%',
                                                                            textAlign: 'left',
                                                                            ...(field.visibleToStudent === '2' ? { display: 'none' } : {}),
                                                                            ...(field.isActive === 1 ? {} : { display: 'none' }),
                                                                        }}
                                                                    >
                                                                    <Box
                                                                        sx={{
                                                                        flexDirection: mobileView ? 'column' : 'row',
                                                                        width: mobileView ? '100%' : '80%',
                                                                        alignItems: 'left',
                                                                        justifyContent: 'flex-start',
                                                                        mt: 2,
                                                                        }}
                                                                    >
                                                                        <Box sx={{ display: field.fieldType === "HiddenField" ? 'none' : 'flex' }}>
                                                                        {field.fieldType === 'Paragraph' && field.fieldName !== '' ?
                                                                            <Typography className="preview-label" sx={{width: '35%'}}>{field.fieldName}:</Typography>
                                                                            : field.fieldType !== 'Paragraph' &&
                                                                            <Typography className="preview-label">{field.fieldName}:</Typography>
                                                                        }
                                                                        <Typography className="field-value"
                                                                            sx={{ 
                                                                                ...(field?.inputCase === 'U' && {textTransform: "uppercase"}), 
                                                                                ...(field?.inputCase === 'C' && {textTransform: "capitalize"}),
                                                                                ...(field?.inputCase === 'L' && {textTransform: "lowercase"}),
                                                                                border: field.fieldType === 'Paragraph' ? '0.5px solid #dcd0d0' : '',
                                                                                borderRadius: field.fieldType === 'Paragraph' ? '4px' : '', 
                                                                                padding: field.fieldType === 'Paragraph' ? '8px' : '',
                                                                                width: field.fieldType === 'Paragraph' ? '100%' : '50%' 
                                                                            }}
                                                                        >
                                                                            {/* {field.fieldType === 'RadioButton' || field.fieldType === 'DropDown'  ? field.valueLabel : field.value} */}
                                                                            {field.fieldType === 'RadioButton' || field.fieldType === 'DropDown' || field.fieldType === 'CheckList'  ? 
                                                                                field.valueLabel : field.fieldType === 'Paragraph' ? 
                                                                                <div dangerouslySetInnerHTML={{ __html: field?.fieldDataSource }} /> : 
                                                                                field.value}
                                                                        </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    </FormControl>
                                                                : null
                                                            )
                                                            :section.sectionId !== 4  && (section.sectionId === 3 && section.fieldsList.length !== 0) && section.sectionId !==11 && section.sectionId !==14 && section.isMultipleFields === 0 ? (
                                                                <FormControl
                                                                  sx={{
                                                                    width: mobileView ? '100%' : field.fieldType !== 'Paragraph' ? '50%' : '100%',
                                                                    textAlign: 'left',
                                                                    ...(field.visibleToStudent === '2' ? { display: 'none' } : {}),
                                                                    ...(field.isActive === 1 ? {} : { display: 'none' }),
                                                                  }}
                                                                >
                                                                  <Box
                                                                    sx={{
                                                                      flexDirection: mobileView ? 'column' : 'row',
                                                                      width: mobileView ? '100%' : '80%',
                                                                      alignItems: 'left',
                                                                      justifyContent: 'flex-start',
                                                                      mt: 2,
                                                                    }}
                                                                  >
                                                                    <Box sx={{ display: field.fieldType === "HiddenField" ? 'none' : 'flex' }}>
                                                                    {field.fieldType === 'Paragraph' && field.fieldName !== '' ?
                                                                        <Typography className="preview-label" sx={{width: '35%'}}>{field.fieldName}:</Typography>
                                                                        : field.fieldType !== 'Paragraph' &&
                                                                        <Typography className="preview-label">{field.fieldName}:</Typography>
                                                                    }
                                                                      <Typography className="field-value"
                                                                       sx={{ 
                                                                            ...(field?.inputCase === 'U' && {textTransform: "uppercase"}), 
                                                                            ...(field?.inputCase === 'C' && {textTransform: "capitalize"}),
                                                                            ...(field?.inputCase === 'L' && {textTransform: "lowercase"}),
                                                                            border: field.fieldType === 'Paragraph' ? '0.5px solid #dcd0d0' : '',
                                                                            borderRadius: field.fieldType === 'Paragraph' ? '4px' : '', 
                                                                            padding: field.fieldType === 'Paragraph' ? '8px' : '',
                                                                            width: field.fieldType === 'Paragraph' ? '100%' : '50%'   
                                                                        }}
                                                                        >{field.fieldType === 'RadioButton' || field.fieldType === 'DropDown' || field.fieldType === 'CheckList'  ? 
                                                                                field.valueLabel : field.fieldType === 'Paragraph' ? 
                                                                                <div dangerouslySetInnerHTML={{ __html: field?.fieldDataSource }} /> : 
                                                                                field.value}
                                                                        </Typography>
                                                                    </Box>
                                                                  </Box>
                                                                </FormControl>
                                                            ) : section.sectionId === 4 ? ( 
                                                                <>
                                                                <FormControl
                                                                    sx={{
                                                                        width: mobileView ? '100%' : field.fieldType !== 'Paragraph' ? '50%' : '100%',
                                                                        textAlign: 'left',
                                                                        ...(field.visibleToStudent === '2' ? { display: 'none' } : {}),
                                                                        ...(field.isActive === 1 ? {} : { display: 'none' }),
                                                                    }}
                                                                >
                                                                <Box
                                                                    sx={{
                                                                    flexDirection: mobileView ? 'column' : 'row',
                                                                    width: mobileView ? '100%' : '80%',
                                                                    alignItems: 'left',
                                                                    justifyContent: 'flex-start',
                                                                    mt: 2,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        className='previewInputField'
                                                                        sx={{ width: '120%', ml: '-2%', border: '0.5px solid #dcd0d0',  borderRadius: '4px', display: 'flex' }}
                                                                        
                                                                    >
                                                                        <Typography sx={{ 
                                                                            // fontFamily: "Open Sauce Sans ", 
                                                                            color: 'rgb(0 0 0 / 60%)',
                                                                            fontSize: '14px', width: '90%', padding: '8px',}}>{field.fieldName}</Typography>
                                                                            <Box 
                                                                                sx={{width: '10%', padding: '8px', cursor: 'pointer'}}
                                                                                onClick = {() => documentPreview(field.fieldName, field.value)}
                                                                            >
                                                                                {field.value.split('.').pop() === 'pdf' ? (
                                                                                <img src={pdfIcon} style={{ width: '50px' }} alt="Uploaded file" />
                                                                                ) : field.value.split('.').pop() === 'doc' ? (
                                                                                <img src={docIcon} style={{ width: '50px' }}  alt="DOC" />
                                                                                ) : (field.value !== "" ? 
                                                                                <img src={field.value} style={{ width: '30px' }} alt="Uploaded file image" /> 
                                                                                : null)}
                                                                            </Box>
                                                                    </Box> 
                                                                    </Box>
                                                                    </FormControl>
                                                                </>
                                                            ) : section.sectionId === 3 && renderFlag === true ? (
                                                            <>
                                                            <Grid sx={{display: 'flex'}}>
                                                                {renderFlag = false}
                                                                {validSections.map((section) => (
                                                                    <>
                                                                    {section.sectionId === 9 ?
                                                                    <Grid item xs={6}>
                                                                        {section.sectionId === 9 &&
                                                                        <Typography 
                                                                        sx={{textAlign: 'left', mt:1,
                                                                            color: '#000',
                                                                            fontFamily: "Open Sauce Sans",
                                                                            fontSize: '14px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: 600,
                                                                            lineHeight: '167%', 
                                                                        }}>{section.sectionName}</Typography>
                                                                        }
                                                                        <section style={{textAlign: 'left'}}>
                                                                            {section.isMultipleFields === 0 && section.sectionId === 9 && section.fieldsList
                                                                            .sort((a, b) => a.sequence - b.sequence)
                                                                            .map((field, index) => (
                                                                                <Box sx={{display: section.sectionId === 3 ? 'block' : 'display-inline'}}>
                                                                                    <FormControl
                                                                                    key={index}
                                                                                    sx={{
                                                                                    width: mobileView ? '100%' : field.fieldType !== 'Paragraph' ? '50%' : '100%',
                                                                                    textAlign: 'left',
                                                                                    ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' } : {}),
                                                                                    ...(field.isActive === 1 ? {} : { display: 'none' }),
                                                                                    }}
                                                                                >
                                                                                    <Box
                                                                                    sx={{
                                                                                        direction: mobileView ? 'column' : 'column',
                                                                                        width: mobileView ? '100%' : '80%',
                                                                                        alignItems: 'left',
                                                                                        justifyContent: 'flex-start',
                                                                                    }}
                                                                                    >
                                                                                    <Box
                                                                                        sx={{
                                                                                        display: mobileView ? 'block' : 'flex',
                                                                                        alignItems: 'center',
                                                                                        width: '200%',
                                                                                        mt: 2
                                                                                        }}
                                                                                    >
                                                                                        {field.fieldType === 'Paragraph' && field.fieldName !== '' ?
                                                                                            <Typography className="preview-label" sx={{width: '35%'}}>{field.fieldName}:</Typography>
                                                                                            : field.fieldType !== 'Paragraph' &&
                                                                                            <Typography className="preview-label">{field.fieldName}:</Typography>
                                                                                        }
                                                                                        <Typography className="field-value" 
                                                                                        sx={{ 
                                                                                            ...(field?.inputCase === 'U' && {textTransform: "uppercase"}), 
                                                                                            ...(field?.inputCase === 'C' && {textTransform: "capitalize"}),
                                                                                            ...(field?.inputCase === 'L' && {textTransform: "lowercase"}),
                                                                                            border: field.fieldType === 'Paragraph' ? '0.5px solid #dcd0d0' : '',
                                                                                            borderRadius: field.fieldType === 'Paragraph' ? '4px' : '', 
                                                                                            padding: field.fieldType === 'Paragraph' ? '8px' : '',
                                                                                            width: field.fieldType === 'Paragraph' ?? '100%'   
                                                                                        }}>
                                                                                            {/* {field.fieldType === 'RadioButton' || field.fieldType === 'DropDown' || field.fieldType === 'CheckList'  ? field.valueLabel : field.value} */}
                                                                                            {field.fieldType === 'RadioButton' || field.fieldType === 'DropDown' || field.fieldType === 'CheckList'  ? 
                                                                                                field.valueLabel : field.fieldType === 'Paragraph' ? 
                                                                                                <div dangerouslySetInnerHTML={{ __html: field?.fieldDataSource }} /> : 
                                                                                                field.value}
                                                                                        </Typography>
                                                                                        {/* <Typography className="field-value" sx={{width: '100%'}}>{inputValue[field.fieldName] ? inputValue[field.fieldName] : field.value }</Typography> */}
                                                                                    </Box>
                                                                                    </Box>
                                                                                </FormControl>
                                                                                </Box>
                                                                            ))}
                                                                        </section>
                                                                        
                                                                    </Grid>
                                                                    :
                                                                    <Grid item xs={6}>
                                                                        {section.sectionId === 10 &&
                                                                        <Typography 
                                                                        sx={{textAlign: 'left', mt:1,
                                                                            color: '#000',
                                                                            fontFamily: "Open Sauce Sans",
                                                                            fontSize: '14px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: 600,
                                                                            lineHeight: '167%', 
                                                                        }}>{section.sectionName}</Typography>
                                                                        }
                                                                        <section style={{textAlign: 'left'}}>
                                                                            {section.isMultipleFields === 0 && section.sectionId === 10 && section.fieldsList
                                                                            .sort((a, b) => a.sequence - b.sequence)
                                                                            .map((field, index) => (
                                                                                <Box sx={{display: section.sectionId === 3 ? 'block' : 'display-inline'}}>
                                                                                    <FormControl
                                                                                    key={index}
                                                                                    sx={{
                                                                                    width: mobileView ? '100%' : '50%',
                                                                                    textAlign: 'left',
                                                                                    ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' } : {}),
                                                                                    }}
                                                                                >
                                                                                    <Box
                                                                                    sx={{
                                                                                        direction: mobileView ? 'column' : 'column',
                                                                                        width: mobileView ? '100%' : '80%',
                                                                                        alignItems: 'left',
                                                                                        justifyContent: 'flex-start',
                                                                                    }}
                                                                                    >
                                                                                    <Box
                                                                                        sx={{
                                                                                        display: mobileView ? 'block' : 'flex',
                                                                                        alignItems: 'center',
                                                                                        width: '200%',
                                                                                        mt:2
                                                                                        }}
                                                                                    >
                                                                                        {field.fieldType === 'Paragraph' && field.fieldName !== '' ?
                                                                                            <Typography className="preview-label" sx={{width: '35%'}}>{field.fieldName}:</Typography>
                                                                                            : field.fieldType !== 'Paragraph' &&
                                                                                            <Typography className="preview-label">{field.fieldName}:</Typography>
                                                                                        }
                                                                                        <Typography className="field-value" 
                                                                                        sx={{ 
                                                                                            ...(field?.inputCase === 'U' && {textTransform: "uppercase"}), 
                                                                                            ...(field?.inputCase === 'C' && {textTransform: "capitalize"}),
                                                                                            ...(field?.inputCase === 'L' && {textTransform: "lowercase"}),
                                                                                            border: field.fieldType === 'Paragraph' ? '0.5px solid #dcd0d0' : '',
                                                                                            borderRadius: field.fieldType === 'Paragraph' ? '4px' : '', 
                                                                                            padding: field.fieldType === 'Paragraph' ? '8px' : '',
                                                                                            width: field.fieldType === 'Paragraph' ?? '100%'   
                                                                                        }}>
                                                                                            {/* {field.fieldType === 'RadioButton' || field.fieldType === 'DropDown' || field.fieldType === 'CheckList' ? field.valueLabel : field.value} */}
                                                                                            {field.fieldType === 'RadioButton' || field.fieldType === 'DropDown' || field.fieldType === 'CheckList'  ? 
                                                                                            field.valueLabel : field.fieldType === 'Paragraph' ? 
                                                                                            <div dangerouslySetInnerHTML={{ __html: field?.fieldDataSource }} /> : 
                                                                                            field.value}
                                                                                        </Typography>
                                                                                        {/* <Typography className="field-value" sx={{width: '100%'}}>{inputValue[field.fieldName] ? inputValue[field.fieldName] : field.value }</Typography> */}
                                                                                    </Box>
                                                                                    </Box>
                                                                                </FormControl>
                                                                                </Box>
                                                                            ))}
                                                                        </section>
                                                                    </Grid>
                                                                    
                                                                    }
                                                                    
                                                                </>
                                                                
                                                                )) }
                                                                
                                                            </Grid>
                                                            
                                                                </>
                                                            ): section.sectionId === 11 && subjectRenderFlag === true ? (
                                                                <>
                                                                    <Grid>
                                                                        {subjectSection.map((section) => (
                                                                            <>
                                                                            {subjectRenderFlag = false}
                                                                            {section.sectionId === 12 ?
                                                                            <>
                                                                                <Typography 
                                                                                sx={{textAlign: 'left', mt:1,
                                                                                    color: '#000',
                                                                                    fontFamily: "Open Sauce Sans",
                                                                                    fontSize: '16px',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: 600,
                                                                                    lineHeight: '167%', 
                                                                                    textDecoration: 'underline'
                                                                                }}>{section.sectionName}</Typography>
                                                                                <section style={{textAlign: 'left'}}>
                                                                                    {section.isMultipleFields === 0 && section.sectionId === 12 && section.fieldsList
                                                                                    .sort((a, b) => a.sequence - b.sequence)
                                                                                    .map((field, index) => (
                                                                                        <FormControl
                                                                                            key={index}
                                                                                            sx={{
                                                                                            width: mobileView ? '100%' : '50%',
                                                                                            textAlign: 'left',
                                                                                            ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' } : {}),
                                                                                            }}
                                                                                        >
                                                                                            <Box
                                                                                            sx={{
                                                                                                direction: mobileView ? 'column' : 'column',
                                                                                                width: mobileView ? '100%' : '97%',
                                                                                                alignItems: 'left',
                                                                                                justifyContent: 'flex-start',
                                                                                            }}
                                                                                            >
                                                                                            <Box
                                                                                                sx={{
                                                                                                display: mobileView ? 'block' : 'flex',
                                                                                                alignItems: 'center',
                                                                                                width: '200%',
                                                                                                mt: 2
                                                                                                }}
                                                                                            >
                                                                                                <Input
                                                                                                    type={field.fieldType}
                                                                                                    {...field}
                                                                                                
                                                                                                />
                                                                                                {/* <Typography className="preview-label" sx={{width: '100%'}}>{field.fieldName}</Typography> */}
                                                                                            </Box>
                                                                                            </Box>
                                                                                        </FormControl>
                                                                                    ))}
                                                                                </section>
                                                                            </>
                                                                            : 
                                                                            <Box>
                                                                                <Typography 
                                                                                sx={{textAlign: 'left', mt:3,
                                                                                    color: '#000',
                                                                                    fontFamily: "Open Sauce Sans",
                                                                                    fontSize: '16px',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: 600,
                                                                                    lineHeight: '167%', 
                                                                                    textDecoration: 'underline'
                                                                                }}>{section.sectionName}</Typography>
                                                                                    
                                                                                    <section className='subject' style={{textAlign: 'left', 
                                                                                        ...(section.fieldsList.some(field => field.fieldRefType === 'SubjectId') ? { display: 'block' } : { display: 'flex' }),
                                                                                        gap: '17px'
                                                                                    }}>
                                                                                    {section.isMultipleFields === 0 && section.sectionId === 13 && (
                                                                                        section.fieldsList.every(field => field.fieldRefType === 'SubjectId' && field.value === "") &&
                                                                                        <Typography
                                                                                            sx={{
                                                                                            fontFamily: "Open Sauce Sans",
                                                                                            fontSize: '16px',
                                                                                            fontStyle: 'normal',
                                                                                            mt:2,
                                                                                            color: 'red'
                                                                                            }}
                                                                                        >
                                                                                            No subject selected
                                                                                        </Typography>
                                                                                    )}
                                                                                    {section.isMultipleFields === 0 && section.sectionId === 13 && section.fieldsList
                                                                                    .sort((a, b) => a.sequence - b.sequence)
                                                                                    .map((field, index) => (
                                                                                    <FormControl sx={{ width: mobileView ? '100%' : field?.fieldRefType === 'SubjectId' ? '48.5%' : '61%', textAlign: 'left', display: 'inline-flex',
                                                                                        ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' }: 
                                                                                            (field.value === '' && field.fieldRefType === 'SubjectId') ? {display: 'none'} : {}),
                                                                                            marginLeft: field.sequence % 2 === 0 ? '11px' : '0px'
                                                                                        }}
                                                                                    >
                                                                                        {field.value !== '' ?
                                                                                        (<Box 
                                                                                            sx={{
                                                                                            color: '#000',
                                                                                            fontFamily: "Open Sauce Sans",
                                                                                            fontSize: '16px',
                                                                                            fontStyle: 'normal',
                                                                                            fontWeight: field.fieldRefType !== 'SubjectId' ? 600 : 'normal',
                                                                                            border: field.fieldRefType === 'SubjectId' ? '0.5px solid #dcd0d0 !important' : '',
                                                                                            borderRadius: field.fieldRefType === 'SubjectId' ? '4px !important' : '',
                                                                                            padding: field.fieldRefType === 'SubjectId' ? '8px' : '',
                                                                                            lineHeight: '167%',
                                                                                            mt: 3,
                                                                                            display: field.value === '' ? 'none' : 'block'
                                                                                            }}  
                                                                                        >
                                                                                            {field.fieldName}
                                                                                        </Box>)
                                                                                        : (section.fieldsList.every(field => field.value === "") && 
                                                                                            <Typography
                                                                                            sx={{
                                                                                                fontFamily: "Open Sauce Sans",
                                                                                                fontSize: '16px',
                                                                                                fontStyle: 'normal',
                                                                                                mt:2,
                                                                                                color: 'red'
                                                                                            }}
                                                                                            >
                                                                                            No subject selected
                                                                                            </Typography> )
                                                                                        }
                                                                                        <Box sx={{
                                                                                            direction: mobileView ? 'column' : 'row',
                                                                                            width: mobileView ? '100%' : '97%',
                                                                                            alignItems: 'left',
                                                                                            // display: 'inline-flex',
                                                                                            justifyContent: 'flex-start',
                                                                                            // gap: 30
                                                                                        }}>
                                                                                            <Box>
                                                                                                {/* <Input
                                                                                                    // key={key}
                                                                                                    type={field.fieldType}
                                                                                                    value={field.value}
                                                                                                    {...field}
                                                                                                
                                                                                                /> */}
                                                                                                {field.fieldDataSource
                                                                                                    .split("+")
                                                                                                    .filter(item => item) // Remove empty items
                                                                                                    .map(item => {
                                                                                                    const [key, keyValue] = item.split("=");
                                                                                                    const valuesArray = field.value.split(',').map(val => val.trim()); // Split and trim values in field.value

                                                                                                    if (valuesArray.includes(key)) {
                                                                                                        return (
                                                                                                            <TextField
                                                                                                                sx={{width: '100%', mt:1}}
                                                                                                                defaultValue={keyValue}
                                                                                                                InputProps={{
                                                                                                                    readOnly: true,
                                                                                                                    sx: {
                                                                                                                        height: '40px', // Set your desired height here
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                        // <Typography key={key} className="preview-label" sx={{ width: '100%' }}>
                                                                                                        //     {keyValue}
                                                                                                        // </Typography>
                                                                                                        );
                                                                                                    }
                                                                                                    return null;
                                                                                                })}
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </FormControl>
                                                                                    ))}
                                                                                    </section>
                                                                                    
                                                                                </Box>
                                                                            }
                                                                            </>
                                                                        ))}
                                                                    </Grid>
                                                                </>
                                                            ): section.sectionId === 14 && Insterested === '1' ? (
                                                                <>
                                                                    <section style={{ textAlign: 'left' }}>
                                                                    {section.isMultipleFields === 0 && section.sectionId === 14 && section.fieldsList
                                                                        .sort((a, b) => a.sequence - b.sequence)
                                                                        .map((field, index) => (
                                                                        <FormControl
                                                                            key={index}
                                                                            sx={{
                                                                            width: '100%',
                                                                            textAlign: 'left',
                                                                            ...(field.visibleToStudent === '2' || field.fieldType === "HiddenField" ? { display: 'none' } : {}),
                                                                            }}
                                                                        >
                                                                            <Grid container spacing={2} sx={{ width: '100%', alignItems: 'center' }}>
                                                                            { opportunity !== null && opportunity.map((opp, oppIndex) => {
                                                                                // Split the field.value into an array of values
                                                                                const valuesArray = field.value.split(',').map(val => val.trim());
                                                                                // Check if opp.jobId is included in the array of values
                                                                                return valuesArray.includes(opp.jobId) ? (
                                                                                <Grid item xs={6} key={oppIndex}>
                                                                                    <TextField
                                                                                    sx={{ width: '82.5%', mt: 1, mr: 2 }}
                                                                                    defaultValue={opp.jobTitle}
                                                                                    InputProps={{
                                                                                        readOnly: true,
                                                                                        sx: {
                                                                                        height: '40px', // Set your desired height here
                                                                                        }
                                                                                    }}
                                                                                    />
                                                                                </Grid>
                                                                                ) : null;
                                                                            })}
                                                                            </Grid>
                                                                        </FormControl>
                                                                        ))}
                                                                    </section>
                                                                </>
                                                            ): (section.isMultipleFields !== 1 && section.parentSectionId === 0 && section.sectionId !== 11 && section.sectionId !== 14) &&
                                                                <FormControl
                                                                    sx={{
                                                                        width: mobileView ? '100%' : field.fieldType !== 'Paragraph' ? '50%' : '100%',
                                                                        textAlign: 'left',
                                                                        ...(field.visibleToStudent === '2' ? { display: 'none' } : {}),
                                                                        ...(field.isActive === 1 ? {} : { display: 'none' }),
                                                                    }}
                                                                >
                                                                <Box
                                                                    sx={{
                                                                    flexDirection: mobileView ? 'column' : 'row',
                                                                    width: mobileView ? '100%' : '80%',
                                                                    alignItems: 'left',
                                                                    justifyContent: 'flex-start',
                                                                    mt: 2,
                                                                    }}
                                                                >
                                                                    <Box sx={{ display: field.fieldType === "HiddenField"  ? 'none' : 'flex' }}>
                                                                    {field.fieldType === 'Paragraph' && field.fieldName !== ''?
                                                                        <Typography className="preview-label" sx={{width: '35%'}}>{field.fieldName}:</Typography>
                                                                        : field.fieldType !== 'File' && field.fieldType !== 'Paragraph' &&
                                                                        <Typography className="preview-label">{field.fieldName}:</Typography>
                                                                    }
                                                                    {/* {
                                                                        field.dbColumnName.toLowerCase() === 'photo' && mobileView &&
                                                                        <img src={field.value} style={{ width: '180px', height: '130px', borderRadius: '10px' }} alt="Uploaded file" />
                                                                    } */}
                                                                    {field.fieldType === 'File' && field.isActive === 1 ?
                                                                        <Box
                                                                            className='previewInputField'
                                                                            sx={{ width: '120%', ml: '-2%', border: '0.5px solid #dcd0d0',  borderRadius: '4px', display: 'flex', cursor: 'pointer' }}
                                                                            onClick = {() => documentPreview(field.fieldName, field.value)}
                                                                        >
                                                                            <Typography sx={{ 
                                                                                // fontFamily: "Open Sauce Sans ", 
                                                                                color: 'rgb(0 0 0 / 60%)',
                                                                                fontSize: '14px', width: '90%', padding: '8px',}}>{field.fieldName}</Typography>
                                                                                <Box sx={{width: '10%', padding: '8px'}}>
                                                                                    {field.value.split('.').pop() === 'pdf' ? (
                                                                                    <img src={pdfIcon} style={{ width: '50px' }} alt="Uploaded file" />
                                                                                    ) : field.value.split('.').pop() === 'doc' ? (
                                                                                    <img src={docIcon} style={{ width: '50px' }}  alt="DOC" />
                                                                                    ) : (field.value !== "" ? 
                                                                                    <img src={field.value} style={{ width: '30px' }} alt="Uploaded file image" /> 
                                                                                    : null)}
                                                                                </Box>
                                                                        </Box> 
                                                                        :
                                                                        <Typography className="field-value"
                                                                        sx={{ 
                                                                            ...(field?.inputCase === 'U' && {textTransform: "uppercase"}), 
                                                                            ...(field?.inputCase === 'C' && {textTransform: "capitalize"}),
                                                                            ...(field?.inputCase === 'L' && {textTransform: "lowercase"}),
                                                                            border: field.fieldType === 'Paragraph' ? '0.5px solid #dcd0d0' : '',
                                                                            borderRadius: field.fieldType === 'Paragraph' ? '4px' : '', 
                                                                            padding: field.fieldType === 'Paragraph' ? '8px' : '',  
                                                                            width: field.fieldType === 'Paragraph' ? '100%' : '50%'
                                                                        }}
                                                                        >
                                                                            {field.fieldType === 'RadioButton' || field.fieldType === 'DropDown' || field.fieldType === 'CheckList'  ? 
                                                                                field.valueLabel : field.fieldType === 'Paragraph' ? 
                                                                                <div dangerouslySetInnerHTML={{ __html: field?.fieldDataSource }} /> : 
                                                                                field.dbColumnName.toLowerCase() === 'photo' && mobileView ?
                                                                                <img src={field.value} style={{ width: '180px', height: '130px', borderRadius: '10px' }} alt="Uploaded file" /> :
                                                                                field.value}
                                                                        </Typography>
                                                                        }
                                                                    </Box>
                                                                    { field.sectionId === 7 &&
                                                                    <Box
                                                                        className='previewInputField'
                                                                        sx={{ width: '120%', ml: '-2%', mt: 2, border: '0.5px solid #dcd0d0',  borderRadius: '4px', display: 'flex', cursor: 'pointer' }}
                                                                        onClick = {() => handleAnnexture()}
                                                                    >
                                                                        <Typography sx={{ 
                                                                            // fontFamily: "Open Sauce Sans ", 
                                                                            color: 'rgb(0 0 0 / 60%)',
                                                                            fontSize: '14px', width: '90%', padding: '8px',}}>Annexture Preview
                                                                        </Typography>
                                                                        <Box sx={{width: '18%', }}>
                                                                            <img src={docIcon} style={{ width: '50px' }}  alt="DOC" />
                                                                        </Box>
                                                                    </Box> 
                                                                    }
                                                                </Box>
                                                                </FormControl>

                                                        }

                                                        {isModalOpen && 
                                                            <ModalComponent 
                                                                open={isModalOpen} 
                                                                onClose={() => setIsModalOpen(false)} 
                                                                title={titleMsg} 
                                                                content={content} 
                                                                contentIdentity={modalContentIdentity} 
                                                                modalResponse={() => {
                                                                    setModalResponse(true);
                                                                    if (modalResponseCallback) {
                                                                    modalResponseCallback();
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    </>
                                                ))}
                                            </section>
                                            {section.isMultipleFields === 1 && 
                                                <TableContainer sx={{marginTop: '2%'}} component={Paper} elevation={4}>
                                                 <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                     <TableHead>
                                                         <TableRow sx={{background: '#538a30', }}>
                                                             {section.fieldsList
                                                             .filter(field => field.multipleSequence === 0 || field.multipleSequence === 1 )
                                                            .sort((a, b) => a.sequence - b.sequence)
                                                            .map((field, index) => (
                                                                <TableCell key={field.fieldId} sx={{ color: '#fff' }}>
                                                                {field.fieldName}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tableInputValue[section.sectionId] && tableInputValue[section.sectionId].map((row, rowIndex) => (
                                                            <TableRow key={rowIndex}>
                                                                {section.fieldsList
                                                                .filter(field => field.multipleSequence === 1 )
                                                                .slice()
                                                                .sort((a, b) => a.multipleSequence - b.multipleSequence)
                                                                .map((field, cellIndex) => (
                                                                    <TableCell>
                                                                        {row[field.fieldName]}
                                                                    </TableCell>
                                                                    
                                                                ))}

                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            }
                                        </Grid>
                                        {((adminData !== null && adminData.ActionType === 'Edit' && adminData.IsEditable === 'NO') || ((loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit')) && admissionId !== '0' && !(admissionStage === 'A' || admissionStage === 'null')))? null :
                                            section.sectionId !== 14 || (section.sectionId === 14 && Insterested === '1') && admissionId === '0' ?
                                            <Grid item xs={1}>
                                                <Button sx={{
                                                    color: '#000',
                                                    textAlign: 'center',
                                                    fontFamily: "Open Sans",
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    lineHeight: 'normal',
                                                    textTransform: 'none'
                                                    }}
                                                    className='edit-btn'
                                                    onClick={() => handleEdit(section.sectionId)}
                                                >
                                                    {mobileView ? <BorderColorOutlinedIcon/> : <><BorderColorOutlinedIcon/></>} 
                                                </Button>
                                            </Grid>
                                            : null
                                        }
                                    </Grid>
                                    </Box>
                                ): null
                            )): <Loader />}
                        </React.Fragment>
                        {loading === false && ((loggedInUser !== 'admin' && (admissionStage === 'A' && admissionId !== '0') || (admissionStage === 'null')) ||
                        loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit') &&
                        <Box sx={{my: mobileView ? 7 : 10, mx: mobileView ? 2 : 18, textAlign: 'left', marginBottom: '10%', display: 'flex'}}>
                            <Button sx={{
                                    border: '1px solid #185ABD',
                                    color: '#000',
                                    background: '#FFF',
                                    marginTop:  '0%',
                                    marginLeft: mobileView? '6%' :'0%',
                                    '&:hover': {
                                        border: '1px solid #185ABD',
                                    },
                                    textTransform: 'none'
                                }} 
                                id='final_save_btn'
                                className="secondary-save-btn" 
                                variant="outlined" 
                                onClick={() => handleFinalSave()}
                                >
                                {buttonLoading ?
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                        color: 'green[500]',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                        }}
                                    />
                                    :
                                    'Save & Finalize'
                                }
                                
                            </Button>
                            <Button sx={{
                                    background: '#185ABD',
                                    marginTop: mobileView ? '3%' : '0%',
                                    marginLeft: mobileView ?? '0%',
                                    mx: 3,
                                    '&:hover': {
                                        background: '#185ABD',
                                    },
                                    textTransform: 'none',
                                    display: 'none'
                                }} 
                                id='payment_btn'
                                className="primary-save-btn" 
                                variant="contained" 
                                onClick={handlePayment}
                            >
                                Pay Now
                            </Button>
                        </Box>
                        }
                    </Box>
                    </>

                )}
            </ScreenView>

        </>
    )
}
