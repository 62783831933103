import {getAccessToken, fetchAccessToken } from '../component/getToken';
let accessToken = '';

const callAPI = async (url, method, params = {}) => {
  try {
    // const accessToken = await getAccessToken();
    let finalUrl = url;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    };

    let requestBody = null;
    if (method === 'POST') {
      requestBody = JSON.stringify(params);
    } else {
      const queryParams = new URLSearchParams(params).toString();
      if (url.includes("?")) {
        finalUrl = `${url}&${queryParams}`
      }else{
        finalUrl = `${url}?${queryParams}`;
      }
    }

    const response = await fetch(finalUrl, {
      method: method,
      headers: headers,
      body: requestBody,
    });
    
    if (response.status === 401 || response.status === 403) {
      // Token expired, fetch a new one and retry the API call
      accessToken = await getAccessToken();
      return callAPI(url, method, params);
    }

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    
    if(responseData.statusCode===401 || response.status === 403)
    {
      accessToken = await getAccessToken();
      // document.cookie = " expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      return callAPI(url, method, params);
    }

    return responseData;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export default callAPI;