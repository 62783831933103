import React, { useState, useEffect } from "react";
import Header from "../component/header";
import PreviewComponent from "../component/preview";
import { annexture } from '../util/apiUrl';
import CallAPI from '../util/apiAction';

export default function Preview(){

    return(
        <>
            <Header/>
            <PreviewComponent/>
        </>
    )
}