import React from "react";
import Modal from '@mui/material/Modal';
import { Box, Button, Typography, Divider } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ScreenView from "../util/screenView";
import success from "../assets/images/success.gif";
import errorIcon from "../assets/images/error.png";

export default function ModalComponent({ open, onClose, title, content, contentIdentity, modalResponse }) {
    let screenSize = window.innerWidth
    console.log('contentIdentity', contentIdentity)
    const style = {
        position: 'absolute',
        top: '50%',
        left: contentIdentity === 'Verification' && contentIdentity === 'documentPreview' ? '50%' : '56%',
        width: contentIdentity === 'Verification' ? '35%' : contentIdentity === 'alert' || contentIdentity === 'confirmation' ? '35%' : '50%',         
        transform: 'translate(-50%, -50%)',
        border: 'none',
    };

    const mediaQueryStyle = {
        '@media only screen and (max-width: 900px)': {
            position: 'absolute',
            top: '50%',
            left: contentIdentity === 'Verification' && contentIdentity === 'documentPreview' ? '50%' : '47%',
            width: contentIdentity === 'Verification' ? '35%' : contentIdentity === 'alert' || contentIdentity === 'confirmation' ? '75%' : '80%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
        },
    };

    const handleContinue = () => {
        onClose();
        modalResponse();
    }

    const handleCancel = () => {
        onClose();
        // modalResponse(false);
    }

    return (
        <ScreenView>
            {mobileView => (
                <Modal
                    open={open}
                    onClose={onClose}
                >
                    <Box sx={screenSize < 900 ? mediaQueryStyle : style}>
                        <Box className='modal' sx={{
                            width : contentIdentity === 'alert' || contentIdentity === 'confirmation' ? '100%' : 'auto',
                            // width: contentIdentity === 'Verification' || contentIdentity === 'documentPreview' ? '90%' : contentIdentity === 'alert' || contentIdentity === 'confirmation' ? '56%' : '50%',
                            // height: '30%',
                            height: contentIdentity === 'alert' || contentIdentity === 'confirmation' ? '30%' : 'auto',
                            borderRadius: '10px',
                            border: 'none',
                            overflowY: 'auto', // Add this property
                            scrollbarWidth: 'none',
                            background: '#fff',
                            alignItems: 'right',
                            justifyItems: 'right',
                            textAlign: 'right',
                            // padding: mobileView ? '3%' : '0%',
                            display: 'flex',          // Use Flexbox
                            flexDirection: 'column',  // Stack children vertically
                            alignItems: 'center',     // Center children horizontally
                            justifyContent: 'center', // Center children vertically
                            textAlign: 'center',      // Center text
                        }}
                        >
                            {/* {contentIdentity === 'alert' &&
                                <>
                                    {title === 'Success' ?
                                    <img src={success} style={{ width: '30%' }} alt="success"/> : <img src={errorIcon} style={{ width: '30%' }} alt="error"/>
                                    }
                                </>
                            } */}
                            <Typography sx={{textAlign: 'center', fontWeight: 600, fontSize: 22, fontFamily: 'Open Sauce Sans', 
                                color: contentIdentity === 'Verification' ? '#fff' : '#000',
                                background: contentIdentity === 'Verification' ? 'linear-gradient(119.54deg, #000046 0, #1cb5e0 100%)' : '#fff', 
                                width: '102%', 
                                borderRadius: '10px 10px 0px 0px',
                                marginTop: contentIdentity === 'alert' ? '1%' : '-2%',
                            }}>
                                <b>{title}</b>
                            </Typography>
                            {contentIdentity === 'alert' &&
                                <hr style={{border: '0.5px solid rgb(239 239 239)', width: '100%', margin: '10px 0'}}/>
                            }
                            <Typography sx={{textAlign:  contentIdentity === 'alert' ? 'left' : 'center', color: '#484848', mt: contentIdentity === 'confirmation' ? 3 : 1}}>
                                {content}
                                {/* {contentIdentity === 'documentPreview' ? 
                                <iframe src={content} width={700} height={500} frameBorder="0"></iframe>
                                 : {content}} */}
                            </Typography>
                            {contentIdentity !== 'Verification' && contentIdentity !== 'confirmation' && contentIdentity !== 'documentPreview' &&
                                <Box sx={{display: 'flex', gap: 3, mt:3, mb: contentIdentity === 'alert' ? '1%' : '0%'}}>
                                    <Button sx={{
                                         border: '1px solid #D4273C',
                                         color: '#D4273C',
                                         background: '#FFF',
                                         '&:hover': {
                                             border: '1px solid #D4273C',
                                         },
                                         textTransform: 'none',
                                        // textAlign: 'center', 
                                        // borderColor: '#D4273C',
                                        // color: '#fff',
                                        width: '30%',
                                        marginTop:  '3%',
                                        marginBottom: '2%',
                                    }}
                                        onClick={handleCancel}
                                    >Cancel</Button>
                                    <Button sx={{
                                        textAlign: 'center', 
                                        background: '#D4273C',
                                        color: '#fff',
                                        marginTop:  '3%',
                                        marginBottom: '2%',
                                        '&:hover': {
                                            background: '#D4273C',
                                        },
                                        textTransform: 'none',
                                    }}
                                        onClick={handleContinue}
                                    >Delete</Button>
                                </Box>
                            }
                            {contentIdentity === 'confirmation' && 
                                <Box sx={{display: 'flex', mt:3}}>
                                    <Button sx={{
                                        textAlign: 'center', 
                                        background: '#D4273C',
                                        color: '#fff',
                                        marginTop:  '3%',
                                        marginBottom: '2%',
                                        '&:hover': {
                                            background: '#D4273C',
                                        },
                                        textTransform: 'none',
                                    }}
                                        onClick={handleContinue}
                                    >Ok</Button>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Modal>
            )}
        </ScreenView>
    );
}
