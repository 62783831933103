import React, {useState, useEffect, useRef, createContext, useContext} from "react";
import Header from "./header";
import Navbar from "../component/sectionbar";
import { TextField, Typography, Box, InputLabel } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

import '../assets/styles/global.css';
import '../assets/styles/form.css';
import company_logo from '../assets/images/company_logo.jpg';
import CallAPI from '../util/apiAction';
import { jobList, Form } from "../util/apiUrl";
import ScreenView from "../util/screenView";


export default function JobSection({ sendDataToParent, sendDataLength, enquiry, fields }){
    const [data, setData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [inputValue, setInputValue] = useState({});
    const [selected, setSelected] = useState(false);
    const [selectedButtons, setSelectedButtons] = useState([]);

    // const toggleSelected = () => {
    //     setSelected(prevSelected => !prevSelected);
    // };   
    
    const name = sessionStorage.getItem('name');
    const dateOfBirth = sessionStorage.getItem('dateOfBirth');
    const mobileNo= sessionStorage.getItem('mobileNo');
    const CourseId = sessionStorage.getItem('CourseId');
    const CategoryId = sessionStorage.getItem('CategoryId');
    const univId = sessionStorage.getItem('univID');
    const loggedInUser = sessionStorage.getItem('loggedInUser');

    // API for job list
    useEffect(() => {
        const fetchData = async () => {
          try {
            const { opportunity,loading,error } = await CallAPI(jobList, 'POST', { 
                    "courseID": CourseId,
                    "univID": univId,
                    "candidateID": "",
                    "candidateMobile": mobileNo,
                    "candidateEmail": ""
                }
            ); 
                setData(opportunity);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
        
    }, []);

    // API for enquiry
    useEffect(() => {
        const fetchData = async () => {
          try {
           // const result = await callAPI(apiUrl, 'GET', { IsActive:1 });
           const { data,loading,error } = await CallAPI(Form, 'POST', { 
                "unvId": univId,
                "categoryId": CategoryId.parseInt(),
                "courseId": CourseId.parseInt(),
                "enquiryId": enquiry,
                "mobileNo": mobileNo,
                "dob": dateOfBirth,
                "name": name
            }
          ); 
            setFormData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
        
    }, []);

    const handleLocationClick = () => {
        if(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('ActionType') === 'Edit')){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition);
            } else {
                alert("Geolocation is not supported by this browser.");
            }
        }
    }

    function showPosition(position) {

        fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`)
            .then(response => response.json())
            .then(data => {
                const city = data.city;
                const state = data.principalSubdivision;
                const country = data.countryName;
                const pincode = data.postcode;

                setInputValue((prevInputValue) => ({
                    ...prevInputValue,
                    ['city']: city,
                    ['state']: state,
                    ['location']: city
                }));

            })
            .catch(error => {
                console.error(error);
            });
    }

    const [listToSave, setListToSave] = useState([])

    const toggleSelected = (id) => {
        if(loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('ActionType') === 'Edit')){
            setSelectedButtons(prevSelected => {
                if (prevSelected.includes(id)) {
                    return prevSelected.filter(buttonId => buttonId !== id);
                } else {
                    return [...prevSelected, id];
                }
            });
        }
    };

    useEffect(() => {
        if (fields && fields.length > 0 ) {
            const newValues = fields.map((f) => {
                // Split the comma-separated string into an array
                if(f.value.trim() !== ''){
                    return f.value.split(',').map(value => value.trim());
                }
            }).flat();
            if(newValues[0] !== undefined && newValues[0] !== ''){
                setSelectedButtons( [
                    // ...prevSelected,
                    ...newValues
                ]);
            }
        }
    }, [fields]);
    

    useEffect(() => {
        if(selectedButtons.length > 0){
            setListToSave([{
                sectionId: 14,
                fieldId: 74,
                sequenceId: 0,
                value: selectedButtons.join(', '),
                imageExtension: "",
                imageSize: "",
                fieldRefTypeId: 0,
                fieldRefType: "",
            }])
        }
    }, [selectedButtons])

    useEffect(() => {
        sendDataToParent(listToSave);
    },[listToSave])

    useEffect(() => {
        if(data !== null){
            sendDataLength(data.length)
        }else{
            sendDataLength(data)
        }
        
    },[data])


    return(
        <ScreenView>{mobileView => (
        <>
        <Typography className="title" sx={{textAlign: 'left', mt: 3}}>
            Choose your preferred work location
        </Typography>

        {/* Search filter */}
        {!mobileView ? 
            <Box sx={{textAlign: 'left', mt:2, display:'flex'}}>
                <Box>
                    <InputLabel className="formLabel" sx={{ml:1}}>Set location</InputLabel>
                    <TextField 
                        className="formInputField" 
                        name="location" 
                        value={inputValue.city || ''} 
                        // onChange={(e) => setInputValue({ ...inputValue, city: e.target.value })} 
                        InputProps={{
                            readOnly: loggedInUser === 'admin' && sessionStorage.getItem('ActionType') !== 'Edit',
                            endAdornment:(
                                <PlaceOutlinedIcon
                                    sx={{
                                        cursor: 'pointer'
                                    }} 
                                    onClick={handleLocationClick}
                                />
                            )
                        }}
                    />
                </Box>
                <Typography sx={{ml:3, mt: 3}}>or</Typography>
                <Box sx={{ml:3}}>
                    <InputLabel className="formLabel" sx={{ml:1}}>State</InputLabel>
                    <TextField 
                        className="formInputField" 
                        name='state' id='state' 
                        value={inputValue.state || ''} 
                        onChange={(e) => setInputValue({ ...inputValue, state: e.target.value })}  
                        InputProps={{readOnly: loggedInUser === 'admin' && sessionStorage.getItem('ActionType') !== 'Edit'}}
                    
                    />
                </Box>
                <Typography sx={{ml:3, mt: 3}}>or</Typography>
                <Box sx={{ml:3}}>
                    <InputLabel className="formLabel" sx={{ml:1}}>City</InputLabel>
                    <TextField 
                        className="formInputField" 
                        name='city' id='city' 
                        value={inputValue.city || ''} 
                        onChange={(e) => setInputValue({ ...inputValue, city: e.target.value })} 
                        InputProps={{readOnly: loggedInUser === 'admin' && sessionStorage.getItem('ActionType') !== 'Edit'}}
                    />
                </Box>
            </Box>
        : 
            <Box>
                <InputLabel className="formLabel" sx={{ml:1}}>Set location</InputLabel>
                <TextField 
                    className="formInputField" 
                    name="location" 
                    sx={{width: '100%'}} 
                    value={inputValue.city || ''} 
                    // onChange={(e) => setInputValue({ ...inputValue, city: e.target.value })} 
                    InputProps={{
                        readOnly: loggedInUser === 'admin' && sessionStorage.getItem('ActionType') !== 'Edit',
                        endAdornment:(
                            <PlaceOutlinedIcon
                                sx={{
                                    cursor: 'pointer'
                                }} 
                                onClick={handleLocationClick}
                            />
                        )
                    }}
                />
                <Typography sx={{textAlign: 'center', mt: 2, mb: 2}}>or</Typography>
                <InputLabel className="formLabel" sx={{ml:1}}>State</InputLabel>
                <TextField 
                    className="formInputField" 
                    name='state' id='state' 
                    value={inputValue.state || ''}
                    sx={{width: '100%'}} 
                    onChange={(e) => setInputValue({ ...inputValue, state: e.target.value })}  
                    InputProps={{readOnly: loggedInUser === 'admin' && sessionStorage.getItem('ActionType') !== 'Edit'}}
                
                />
                <Typography sx={{textAlign: 'center', mt: 2, mb: 2}}>or</Typography>
                <InputLabel className="formLabel" sx={{ml:1}}>City</InputLabel>
                <TextField 
                    className="formInputField" 
                    name='city' id='city' 
                    value={inputValue.city || ''} 
                    sx={{width: '100%'}} 
                    onChange={(e) => setInputValue({ ...inputValue, city: e.target.value })} 
                    InputProps={{readOnly: loggedInUser === 'admin' && sessionStorage.getItem('ActionType') !== 'Edit'}}
                />
            </Box>
        }
        <Box>
            {!data || data.length === 0 ?
            <Box sx={{ display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 6
                }}>
                <Typography
                    sx={{
                        fontFamily: "Open Sauce Sans",
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        mt:3,
                        textAlign: 'center',
                        color: 'red'
                    }}
                >Sorry!
                </Typography> 
                <Typography
                    sx={{
                        fontFamily: "Open Sauce Sans",
                        fontSize: '18px',
                        fontStyle: 'normal',
                        textAlign: 'center',
                        color: '#555555'
                    }}
                >We don't have any opportunities that match your preferences right now.
                </Typography> 
            </Box>
             :
             <Box>
                <Typography sx={{
                    color: '#031A1C',
                    fontFamily: "Open Sauce Sans",
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    letterSpacing: '-0.8px',
                    textAlign: 'left',
                    mt: 10
                }}>
                    Here are the list of available work opportunities
                </Typography>
                {data.filter(opportunity => 
                    (!inputValue['state'] || opportunity.location.some(loc => loc.state.toLowerCase() === inputValue['state'].toLowerCase())) 
                    &&(!inputValue['city'] || opportunity.location.some(loc => loc.city.toLowerCase() === inputValue['city'].toLowerCase()))
                )
                .map((opportunity) => (
                <Card sx={{ maxWidth: mobileView ? '317px' : '398px', mt: 3, borderRadius: '10px', display: 'inline-block', mr: 3}}>
                    <CardActionArea>
                        <CardContent>
                            <Box sx={{display: 'flex'}}>
                                <Box sx={{width: '23%'}}> 
                                    <img src={opportunity.companyLogo !== "" ? opportunity.companyLogo : company_logo} alt='logo' style={{
                                        width: mobileView ? '60px' : '100px'
                                    }}/>
                                </Box>
                                <Box key={opportunity.jobId} sx={{width: '76%'}}>
                                    <Typography sx={{
                                        textAlign: 'left', 
                                        padding: '8px',
                                        color: '#484848',
                                        fontFamily: "Open Sauce Sans",
                                        fontSize: mobileView ? '16px' : '18px',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        lineHeight: '20px',
                                        textWrap: 'balance' 
                                    }}>
                                        {opportunity.jobTitle}
                                    </Typography>
                                    <Typography color="text.secondary" sx={{
                                        display: 'flex',
                                        width: mobileView ? '218px' : '338px',
                                        height: '15px',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        flexShrink: 0,
                                        textAlign: 'left',
                                        padding: '8px',
                                        fontStyle: 'Open Sauce Sans',
                                        fontSize: mobileView ? '13px' : '15px',
                                        mt:3
                                        }}>
                                        Location: {opportunity.location.map(loc => loc.city).join(', ')}<br/>
                                        {/* Location : {opportunity.location[0]?.city || ''},{opportunity.location[0]?.state || ''}<br/> */}
                                        Salary Range: ₹ {opportunity.stipend.low ||''}-{opportunity.stipend.high ||''} /month<br/>
                                        Positions available: {opportunity.positionAvailable}<br/>
                                    </Typography>
                                    <CardActions>
                                        <Button sx={{
                                            borderRadius: '8px',
                                            border: '1px solid #FEBF00',
                                            width: '116px',
                                            height: '48px',
                                            flexShrink: 0,
                                            textTransform: 'none',
                                            color: selectedButtons.includes(opportunity.jobId)  ? '#fff' : '#111',
                                            mt:3,
                                            background: selectedButtons.includes(opportunity.jobId)  ? '#29BF12' : '#fff',
                                            '&:hover': {
                                                background: selectedButtons.includes(opportunity.jobId)  ? '#29BF12' : '#fff',
                                            }
                                        }}
                                        onClick={() => toggleSelected(opportunity.jobId)}
                                        >
                                            {selectedButtons.includes(opportunity.jobId)  ? "Selected" : "Select"}
                                        </Button>
                                    </CardActions>
                                </Box>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                    
                    
                </Card>
                ))}
                </Box>
                // data.length === 0 &&
            }
        </Box>
        </>
        )}</ScreenView>
    )
}