import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../assets/styles/loader.css'

// import CircularProgress from '@mui/material/CircularProgress';

// export default function CircularIndeterminate() {
//   return (
//     <Box sx={{ display: 'flex' }}>
//       <CircularProgress />
//     </Box>
//   );
// }


export default function LinearWithValueLabel() {
  return(
    <div class="loader-wrapper">
    <div class="loader"></div>
  </div> 
  )
}

// function LinearProgressWithLabel(props) {
//   return (
//     <>
//     <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//       <Box sx={{ width: '50%', mr: 1, marginTop: '10%' }}>
//         <Typography sx={{
//             color: '#000',
//             fontFamily: "Open Sauce Sans",
//             fontSize: '16px',
//             fontStyle: 'italic',
//             fontWeight: 400,
//         }}>Loading...</Typography>
//         <LinearProgress variant="determinate" {...props} />
//       </Box>
//       <Box sx={{ minWidth: 35 , marginTop: '10%' }}>
//         <Typography variant="body2" color="text.secondary"
//             sx={{
//                 color: '#000',
//                 fontFamily: "Open Sauce Sans",
//                 fontSize: '20px',
//                 fontStyle: 'italic',
//                 fontWeight: 400,
//             }}
//         >{`${Math.round(
//           props.value,
//         )}%`}</Typography>
//       </Box>
//     </Box>
//     </>
//   );
// }

// LinearProgressWithLabel.propTypes = {
//   /**
//    * The value of the progress indicator for the determinate and buffer variants.
//    * Value between 0 and 100.
//    */
//   value: PropTypes.number.isRequired,
// };

// export default function LinearWithValueLabel() {
//   const [progress, setProgress] = React.useState(10);

//   React.useEffect(() => {
//     const timer = setInterval(() => {
//       setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
//     }, 800);
//     return () => {
//       clearInterval(timer);
//     };
//   }, []);

//   return (
//     <Box sx={{ width: '100%' }}>
//       <LinearProgressWithLabel value={progress} />
//     </Box>
//   );
// }
