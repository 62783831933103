import React, { Children, useEffect, useState } from "react";
import * as CryptoJS from 'crypto-js';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import { createState } from 'state-pool';

import '../assets/styles/form.css';
import '../assets/styles/global.css';
import Header from "../component/header";
import Navbar from "../component/sectionbar";
import FormSection from "../component/formSection";
import ScreenView from "../util/screenView";
import { Form, debGenerateToken } from "../util/apiUrl";
import CallAPI from '../util/apiAction';

export const globalActionType = createState('');

export default function AdmissionForm (){
    const location = useLocation();
    const [lastActiveSection, setLastActiveSection] = useState(null)
    const [CourseId, setCourseId] = useState(null)
    const [CategoryId, setCategoryId] = useState(null)
    const [userName, setUserName] = useState(null)
    const [name, setName] = useState(null)
    const [decrytedString, setDecryptedString] = useState(null);
    const [actionType, setActionType] = globalActionType.useState();
    const [activeSection, setActiveSection] = useState(null);
    const [dataFromSection, setDataFromSection] = useState(true);
    const [data, setData] = useState(null);
    const [debToken, setDebToken] = useState(null);

    const loggedInUser = sessionStorage.getItem('loggedInUser')
    const univId = sessionStorage.getItem('univID')
    const dateOfBirth = sessionStorage.getItem('dateOfBirth')
    const mobileNo = sessionStorage.getItem('mobileNo')
    var enquiry = sessionStorage.getItem('enquiryId')
    const RenderFromPreview = localStorage.getItem('RenderFromPreview')

    useEffect(() => {
        if(location.state !== null){
            const { CategoryId, 
                CourseId, 
                enquiryId, 
                lastActiveSection, 
                name
            } = location.state;
            setActiveSection(lastActiveSection)
            setCourseId(CourseId)
            setCategoryId(CategoryId)
            setUserName(userName)
            setName(name)

        }
    },[location])

    useEffect(() => {
        if(sessionStorage.length > 0 && loggedInUser === 'student'){
            let lastSection = sessionStorage.getItem('lastActiveSection')
            if(lastSection !== 0 && RenderFromPreview === 0 && !isNaN(lastSection) && lastSection !== null){
                setActiveSection(lastSection)
            }
            
        }
    },[sessionStorage, loggedInUser])

    // set initial section as active section
    useEffect(() => {
        const fetchData = async () => {
          try {
           const { data,loading,error } = await CallAPI(Form, 'POST', { 
                "unvId": univId,
                "categoryId": CategoryId,
                "courseId": CourseId,
                "enquiryId": parseInt(enquiry),
                "mobileNo": mobileNo,
                "dob": dateOfBirth,
                "name": name
            }
          ); 
            let firstSection = ''
            if(data){
                firstSection = data.sections.find(section => section.isActive === 1 && section.parentSectionId === 0)
                    && data.sections.filter(section => section.isActive === 1 && section.parentSectionId === 0)
                    .reduce((minSection, currentSection) => 
                        currentSection.sequence < minSection.sequence ? currentSection : minSection, 
                        data.sections.find(section => section.isActive === 1 && section.parentSectionId === 0)
                    )
            }
            // if(RenderFromPreview !== null || RenderFromPreview !== undefined || (RenderFromPreview === 0 && lastActiveSection === 0)){
            //     console.log('in admin from preview')
                setActiveSection(firstSection.sectionId)
            // }
            
            setData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        // && sessionStorage.getItem('lastActiveSection') === '0'
        if((loggedInUser === 'student' && location.state === null ) || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')){
            fetchData();
        }
        
    }, [lastActiveSection, CourseId, CategoryId, enquiry, name, loggedInUser]);

    // API for deb token
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data,loading,error } = await CallAPI(debGenerateToken, 'POST', { 
                      "username" : "DigivUnv", 
                      "password" : "63a326d8-3943-4f27-b2f1-83770255282c"
                 }
               ); 
               setDebToken(data.token)
                 
               } catch (error) {
                 console.error('Error fetching data:', error);
                 
            }
        };

        if(loggedInUser === 'student' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')){
            fetchData();
        }
    }, [])

    var course = sessionStorage.getItem('CourseId')
    var category = sessionStorage.getItem('CategoryId')
    var nameSession = sessionStorage.getItem('name')
    var lastActive = sessionStorage.getItem('lastActiveSection')

    useEffect(()=>{
        if(loggedInUser !== 'admin'){
            setLastActiveSection(parseInt(sessionStorage.getItem('lastActiveSection')))
            setCourseId(parseInt(course))
            setCategoryId(parseInt(category))
            setName(nameSession)
        }
    },[sessionStorage.length > 0])


    // var mobileNo = sessionStorage.getItem("mobileNo");
    // var dateOfBirth = sessionStorage.getItem("dateOfBirth");
    // var univID = sessionStorage.getItem("univID");

    const [adminData, setAdminData] = useState(null);

    const deriveKey = (key) => {
        return CryptoJS.SHA256(key);
    };

    const decryptData = (base64Encrypted) => {
        var secretKey = 'LURNAPP$SGLURN@G02020';
        const key = deriveKey(secretKey);

      
        const bytes = CryptoJS.AES.decrypt(base64Encrypted, key, {
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.ECB,
          keySize: 256 / 32
        });

        return bytes.toString(CryptoJS.enc.Utf8);
    };

    

    useEffect(() => {
        var EncryptedURL = window.location.href;
        const url = new URL(EncryptedURL).searchParams.get('data');

        if(url !== null){
            const finalUrlData = url.replace(/ /g, '+')
    
            if (finalUrlData !== null) {
              try {
                const decrypted = decryptData(finalUrlData);
                if (decrypted.length > 0) {
                    setDecryptedString(decrypted);
                } else {
                  console.log('Decryption error: No output from decryption');
                }
              } catch (e) {
                console.log('Decryption error:', e.message);
              }
            } else {
              console.log('URL parameter "data" is null or undefined');
            }
        }
    },[])

    // Convert decrypted data to object
    useEffect(() => {
        if(decrytedString !== null){
            // Split the query string by '&' to get key-value pairs
            const pairs = decrytedString.split('&');

            // Initialize an empty object to hold the JSON
            const jsonObject = {};

            // Iterate over each pair
            pairs.forEach(pair => {
                // Split each pair by '=' to separate keys and values
                const [key, value] = pair.split('=');
                // Add the key-value pair to the jsonObject
                jsonObject[key] = value;
            });
            setAdminData(jsonObject);
        }
        
    }, [decrytedString])


    useEffect(() => {
        if(adminData !== null){
            sessionStorage.setItem("univID",adminData.UnvId);
            sessionStorage.setItem("CategoryId", adminData.CategoryId);
            sessionStorage.setItem("actionType",adminData.ActionType);
            sessionStorage.setItem("loggedInUser",'admin');
            localStorage.setItem('enquiryId', '')
            localStorage.setItem('lastActiveSection', '')
            setActionType(adminData.ActionType)
            setActiveSection(2)
        }
    },[adminData])

    useEffect(() => {
        if(parseInt(lastActiveSection) !== 0 && loggedInUser !== 'admin' && location.state === null){
            if(isNaN(lastActiveSection) || lastActiveSection === null){
                let last = sessionStorage.getItem('lastActiveSection')
                setActiveSection(parseInt(last))
            }else{
                setActiveSection(parseInt(lastActiveSection))
            }
            
        }
        // new 15-07-2024
        else if(parseInt(lastActiveSection) === 0 && loggedInUser !== 'admin' && location.state === null){
            setActiveSection(2)
        }
    }, [lastActiveSection])
  

    function handleDataFromSec(showFormSection){
        setDataFromSection(showFormSection)
    } 
 

    return(
        <Box>
            <Header />
            <ScreenView>
                {mobileView => (
                    mobileView ? (
                        (() => {
                            setDataFromSection(false);
                            return <Navbar setShowFormSection={handleDataFromSec} previewToken={adminData !== null ? adminData.PreviewToken : '' } />
                        })()
                    ) : (
                        <>
                            <Navbar setShowFormSection={handleDataFromSec} activeSection={parseInt(activeSection)} previewToken={adminData !== null ? adminData.PreviewToken : '' } formData={data} />
                        </>
                    )
                )}
            </ScreenView>
            {dataFromSection && (
                <FormSection activeSection={parseInt(activeSection)} setActiveSection={parseInt(activeSection)} previewToken={adminData !== null ? adminData.PreviewToken : '' } debTokenData={debToken}/>
            )}
            
        </Box>
        
    )
}