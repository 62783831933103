import appSetting from "../configuration/appsettings";

let apiRootUrl = `${appSetting.ApiServerURL[appSetting.environment]}`;
let externalApiRootUrl = `${appSetting.ExternalApiServerURL[appSetting.environment]}`;
let verificationApiRootUrl = `${appSetting.VerificationApiServerURL[appSetting.environment]}`;
let annextureApiRootUrl = `${appSetting.VerificationApiServerURL[appSetting.environment]}`;
export const ApiRootUrl = apiRootUrl;
export const ExternalApiRootUrl = externalApiRootUrl;
console.log("AppSetting.environment]", appSetting.environment);
export const REFRESH_TOKEN = `${apiRootUrl}api/Login/GenerateToken`;
export const REFRESH_ADMIN_TOKEN = `${apiRootUrl}api/Login/GenerateConfigToken`
export const TemplateList = `${apiRootUrl}api/Configuration/TemplateList/api/admission/config/template/list`;
export const GenerateOtp = `${apiRootUrl}api/Registration/GenerateOTP/api/admission/register/generateotp`;
export const SectionList = `${apiRootUrl}api/Configuration/SectionList/api/admission/config/section/list`;
export const ValidateOtp = `${apiRootUrl}api/Registration/ValidateOTP/api/admission/Register`;
export const ValidateCaptcha = `${apiRootUrl}api/Registration/ValidateCaptcha/api/admission/Register/validatecaptcha`;
export const CategoryList = `${apiRootUrl}api/Configuration/CategoryList/api/admission/config/category/list`;
export const CourseList = `${apiRootUrl}api/Configuration/CourseList/api/admission/config/course/list`;
export const FormDetail = `${apiRootUrl}api/Configuration/AdmissionFormDetails/api/admission/config/form/details`;
export const UniversityDetails = `${apiRootUrl}api/University/UniversityDetails/api/university/details`;
export const CheckListForm = `${apiRootUrl}api/CheckListForm/CheckLiistForm/api/university/get/checklistform`;
export const Enquiry = `${apiRootUrl}api/Admission/GetEnquiryDetails/api/admission/get/enquiry`;
export const NewEnquiry = `${apiRootUrl}api/Admission/GenerateEnquiry/api/admission/new/enquiry`
export const Form = `${apiRootUrl}api/Admission/GetAdmissionForm/api/admission/form/details`;
export const saveField = `${apiRootUrl}api/SaveFields/SaveFieldsDetails/api/admission/form/save/fields`;
export const CountryList = `${apiRootUrl}api/Admission/CountryList`;
export const StateList = `${apiRootUrl}api/Admission/StateList`;
export const CityList = `${apiRootUrl}api/Admission/CityList`;
export const DocumentSave = `${apiRootUrl}api/SaveFields/DocumentPartialSave/api/admission/form/document/partial/save`
export const FinalDocumentSave  = `${apiRootUrl}api/SaveFields/SaveFinalForm/api/admission/form/save/final/form`
export const jobList = `${externalApiRootUrl}api/da/tleplacements/getopportunitybycourse`
export const annexture = `${apiRootUrl}api/Annexure/GetAnnexure/api/admission/form/get/annexure`
export const formPreview = `${apiRootUrl}api/Admission/GetAdmissionFormPreview/api/admission/form/preview`
export const enterAadhaar = `${verificationApiRootUrl}api/kyc/v2/enteraadhaar`
export const enterOtp = `${verificationApiRootUrl}api/kyc/v2/enterotp`
export const debGenerateToken = `${verificationApiRootUrl}api/account/generateaccesstoken`
export const debIdDetails = `${verificationApiRootUrl}api/admission/fetchdebIddetails`