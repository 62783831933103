import React from 'react';
import {dynamicForm} from '../configuration/fieldSetting';
import { FormControl, InputLabel, Input, FormHelperText, Typography } from '@mui/material';
import { TextField, Checkbox, FormControlLabel, Select, MenuItem } from '@mui/material'; 
import Box from '@mui/material/Box';

const errors = {};

const inputString = "0=--Select--+\r\n1=test1+\r\n2=test2+\r\n3=test3+\r\n4=test4+\r\n5=test5+\r\n";

// Split the string by the '+' character
const splitByPlus = inputString.split('+');

// Trim each element by '='
const trimmedList = splitByPlus.map(item => item.trim().split('=')[1]);

// Filter out undefined values
const finalList = trimmedList.filter(item => item !== undefined);

console.log(finalList);

export default function Form(){

    const CustomInputLabel = ({ label, type }) => {
        console.log(label);
        const isTestCheckbox = label === "Test Checkbox";
        switch (type) {
            case "Text":
                return <InputLabel sx={{textAlign: 'center', position: 'relative' }}>
                {label}
                {isTestCheckbox && <span style={{ color: 'red' }}> *</span>}
                </InputLabel>;

            default:
                return null;
        }
    };
    

    const Input = ({ value, onBlur, type, ...rest }) => {
        switch (type) {
            case "Text":
                return (
                    <TextField
                        placeholder={rest?.placeholder} 
                        onBlur={(e) => onBlur(e.target.value)}
                        value={value}
                    />
                );
            // case "DropDown":
            //     return (
            //         <Select
            //             value={value}
            //             onBlur={(e) => onBlur(e.target.value)}
            //         >
            //             {rest?.fieldDataSource.map((option, index) => ( 
            //                 <MenuItem key={index} value={option}>{option}</MenuItem>
            //             ))}
            //         </Select>
            //     );
            default:
                return null;
        }
    };

const handleInputChange = (y, x) => {
    console.log(y);
    console.log(x);
}

    return(
        <>
            {dynamicForm.sections.map((section) => (
                <section key={section.sectionId}>
                    <Typography>{section.sectionName}</Typography>
                    <FormControl fullWidth variant="standard">
                        {section.fieldsList.map((field) => (
                            <div key={field.fieldId}>
                                <CustomInputLabel 
                                    label={field.fieldName}
                                    type={field.fieldType} />
                                <Input
                                    type={field.fieldType}
                                    value={field.value}
                                    onBlur={(value) => handleInputChange(field.fieldId, value)}
                                    // Assuming handleInputChange takes fieldId and value as arguments
                                />
                                {/* Render error message if field has error */}
                                {field.error && <FormHelperText>{field.errorMessage}</FormHelperText>}
                            </div>
                        ))}
                    </FormControl>
                </section>
            ))}
            
            
        </>

    );
}