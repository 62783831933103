
const environment = "Development" //(process.browser ? window.NEXT_PUBLIC_APIENV : process.env.NEXT_PUBLIC_APIENV)||"Development";

export default {
  "environment":environment,
  "ApiServerURL": {
    "Development": "https://mservicedevapi.teamleaseedtech.com/admission/",
    "QA": "",
    "UAT": "",
    "Portal":"https://admissionapi.teamleaseedtech.com/admission/"  

  },
  "ExternalApiServerURL": {
    "Development": "https://stagingapi.teamleaseedtech.com/",
    "QA": "",
    "UAT": "",    
    "Portal":"https://digvda.teamleaseedtech.com/"  
  },
  "VerificationApiServerURL": {
    "Development": "https://uatapi.teamleaseedtech.com/",
    "QA": "",
    "UAT": "",    
    "Portal":"https://api.mzuonline.in/"  
  },
  "Annexture": {
    "Development": "",
    "QA": "",
    "UAT": "",    
    "Portal":"https://d1vzxgfg1k5ls8.cloudfront.net/"  
  },
  "LoggingURL":{
    "default":""
  }
}
