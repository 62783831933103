import { React, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Fade } from '@mui/material';
import AdjustIcon from '@mui/icons-material/Adjust';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

import PropTypes from 'prop-types';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

import FormSection from './formSection';
import CallAPI from '../util/apiAction';
import { Form, formPreview } from '../util/apiUrl';
import { last, globalInstersted, globalCurrentSection } from './formSection';
import activeIcon from '../assets/images/Proggressing Animation.jpg'
import '../assets/styles/form.css';
import ScreenView from "../util/screenView";
import { newContext } from './formSection';

// const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
//     color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
//     display: 'flex',
//     height: 22,
//     alignItems: 'center',
//     ...(ownerState.active && {
//       color: '#17AA00',
//     }),
//     '& .QontoStepIcon-completedIcon': {
//       color: '#17AA00',
//       zIndex: 1,
//       fontSize: 25,
//     },
//     '& .QontoStepIcon-circle': {
//       width: 8,
//       height: 8,
//       borderRadius: '50%',
//       backgroundColor: 'currentColor',
//     },
// }));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: ownerState.active ? '#17AA00' : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#17AA00',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#17AA00',
        zIndex: 1,
        fontSize: 25,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));


function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed && !active ? ( // Completed but not active
                <CheckCircleIcon className="QontoStepIcon-completedIcon" />
            ) : (active || completed) ? ( // Active or completed and active
                <img 
                    style={{
                        borderRadius: '64px',
                        width: '23px',
                        border: '1px solid #17AA00',
                        background: 'var(--Neutral-White, #FFF)'
                    }} 
                    src={activeIcon} 
                    alt="Active step icon" // Always add alt text for accessibility
                />
            ) : ( // Default icon for incomplete and inactive steps
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}


QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,

};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#17AA00', // Color for active connector
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#17AA00', // Color for completed connector
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0', // Default color
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));


// const QontoConnector = styled(StepConnector)(({ theme }) => ({
//     [`&.${stepConnectorClasses.alternativeLabel}`]: {
//         top: 10,
//         left: 'calc(-50% + 16px)',
//         right: 'calc(50% + 16px)',
//     },
//     [`&.${stepConnectorClasses.active}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             borderColor: '#17AA00', // Active color
//         },
//     },
//     [`&.${stepConnectorClasses.completed}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             borderColor: '#17AA00', // Completed color
//         },
//     },
//     [`& .${stepConnectorClasses.line}`]: {
//         borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0', // Default color
//         borderTopWidth: 3,
//         borderRadius: 1,
//     },
// }));

export default function HorizontalLinearAlternativeLabelStepper({ setShowFormSection, activeSection, previewToken, formData }) {
    const location = useLocation();
    const [lastActiveSection, setLastActiveSec] = useState(null)
    const [CourseId, setCourseId] = useState(null)
    const [CategoryId, setCategoryId] = useState(null)
    const [userName, setUserName] = useState(null)
    const [name, setName] = useState(null)
    const [enquiryId, setEnquiryId] = useState(null)


    const [sectionId, setPage] = useState(null);
    const [sectionName, setSectionName] = useState(null);
    // const [lastSaveSection, setLastActiveSection] = useState();
    const [lastSaveSection, setLastActiveSection] = last.useState();
    const [currentSection, setCurrentSection] = globalCurrentSection.useState();
    const [Insterested, setInterested] = globalInstersted.useState();
    const [lastSaveSequenece, setLastSaveSequence] = useState(null);
    const [activeId, setActiveId] = useState(null);
    const [expanded, setExpanded] = useState(false);

    const univId = sessionStorage.getItem('univID')
    const dateOfBirth = sessionStorage.getItem('dateOfBirth')
    const mobileNo = sessionStorage.getItem('mobileNo')
    const loggedInUser = sessionStorage.getItem('loggedInUser')
    // const adminCategoryId = sessionStorage.getItem('CategoryId')

    var course = sessionStorage.getItem('CourseId')
    var category = sessionStorage.getItem('CategoryId')
    var lastActive = sessionStorage.getItem('lastActiveSection')
    var enquiry = sessionStorage.getItem('enquiryId')
    var RegisterName = sessionStorage.getItem("name");
    var RenderFromPreview = localStorage.getItem('RenderFromPreview')

    const [data, setData] = useState(null)

    useEffect(() => {
        setLastActiveSec(lastActive)
        setCourseId(course)
        setCategoryId(category)
        setName(RegisterName)
        setEnquiryId(enquiry)
    }, [sessionStorage.length > 0])

    // useEffect(() => {
    //     if(location.state !== null){
    //         const { lastActiveSection, CategoryId, CourseId, enquiryId, name } = location.state;
    //         setLastActiveSec(lastActiveSection)
    //         setCourseId(CourseId)
    //         setCategoryId(CategoryId)
    //         setUserName(userName)
    //         setName(name)
    //     }
    // },[location.state]) 
    
    useEffect(() => {
        if(formData !== null && (loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))){
            setData(formData)
            setLastActiveSec(parseInt(lastActiveSection))
        }
    },[formData, loggedInUser])

    // Api for section
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const { data, loading, error } = await CallAPI(Form, 'POST', {
    //                 "unvId": univId,
    //                 "categoryId": parseInt(CategoryId),
    //                 "courseId": parseInt(CourseId),
    //                 "enquiryId": parseInt(enquiry),
    //                 "mobileNo": mobileNo,
    //                 "dob": dateOfBirth,
    //                 "name": name
    //             }
    //             );
    //             setData(data);
    //             setLastActiveSec(parseInt(lastActiveSection))
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     if (loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit')) {
    //         fetchData();
    //     }

    // }, [CategoryId, loggedInUser]);

    // Api for preview config form
    useEffect(() => {
        const fetchPreviewData = async () => {
            try {
                const { data, loading, error } = await CallAPI(formPreview, 'POST', {
                    "unvId": univId,
                    "categoryId": CategoryId,
                    "previewToken": previewToken
                }
                );
                if (data.length !== 0) {
                    setData(data);
                    let firstSection = data.sections.find(section => section.isActive === 1 && section.parentSectionId === 0)
                        && data.sections.filter(section => section.isActive === 1 && section.parentSectionId === 0)
                            .reduce((minSection, currentSection) =>
                                currentSection.sequence < minSection.sequence ? currentSection : minSection,
                                data.sections.find(section => section.isActive === 1 && section.parentSectionId === 0)
                            )
                    if (firstSection) {
                        setCurrentSection(firstSection.sectionId)
                        setActiveId(firstSection.sectionId)
                    } else {
                        setCurrentSection(activeSection)
                        setActiveId(activeSection)
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' && CategoryId !== null && previewToken !== '') {
            fetchPreviewData();
        }
    }, [CategoryId, loggedInUser, previewToken])


    useEffect(() => {
        if (data !== null && (loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))) {
            // let sec = data.sections.find(section => section.sectionId === data.lastActiveSection || section.sectionId === lastSaveSection) 
            let section = data.sections.find(section => {
                return lastActiveSection < lastSaveSection ? section.sectionId === lastSaveSection : section.sectionId === lastActiveSection
            }
            )
            if (section) {
                setLastSaveSequence(section.sequence);
            }
        } else if (data !== null && loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit') {
            let sec = data.sections.find(section => section.sectionId === lastSaveSection)
            if (sec) {
                setLastSaveSequence(sec.sequence);
            }
        }
    }, [data, lastSaveSection])

    useEffect(() => {
        if ((data !== null && parseInt(lastActiveSection) === 0 && activeSection !== null && activeSection !== NaN) ||
            (data !== null && parseInt(RenderFromPreview) === 1 && activeSection !== null && activeSection !== NaN) ||
            (data !== null && activeSection !== null && !isNaN(activeSection)) && (loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))) {
            setCurrentSection(activeSection)
            setActiveId(activeSection)
        } else if (data !== null && parseInt(RenderFromPreview) !== 1 && parseInt(lastActiveSection) !== 0 && lastActiveSection !== undefined && lastActiveSection !== null && !isNaN(lastActiveSection) && lastActiveSection !== '') {
            // setCurrentSection(2)
            let lastSequence = data.sections.find(sec => sec.sectionId === parseInt(lastActiveSection))
            setLastSaveSequence(lastSequence.sequence)
            setCurrentSection(lastActiveSection)

            let nextSection = data.sections.find(sec => sec.sequence === lastSequence.sequence + 1)
            setActiveId(lastActiveSection)
        }
        localStorage.setItem('RenderFromPreview', 0)
    }, [data, lastActiveSection, activeSection, RenderFromPreview])

    const flag = false;
    const setSectionPage = (sectionId, sectionName, sequence) => {
        if (sequence <= lastSaveSequenece && (loggedInUser !== 'admin' || (loggedInUser === 'admin' && sessionStorage.getItem('actionType') === 'Edit'))) {
            setPage(sectionId);     // Set section Id on click of navbar
            setActiveId(sectionId);     // Set active sectionId
            setSectionName(sectionName);
            setShowFormSection(flag);
            setCurrentSection(sectionId);
        }
    }


    useEffect(() => {
        if (activeSection === 0 && data !== null) {
            let sec = data.sections.find(section => section.sectionId === activeSection)
            if (sec) {
                setCurrentSection(sec.sequence)
                setActiveId(sec.sectionId)
            }

            // setCurrentSection(sec.sectionId)
        }
        // else{
        //     setCurrentSection(activeSection)
        // }

    }, [activeSection, data])

    // useEffect(() => {
    //     if(data !== null){
    //         if(activeSection === 0){
    //             let sec = data.sections.find(section => section.sectionId === activeSection) 
    //             setCurrentSection(sec.sequence)
    //             // setCurrentSection(sec.sectionId)
    //         }else{
    //             let sec = data.sections.find(section => section.sectionId === activeSection) 
    //             setLastActiveSection(sec.sectionId)
    //         }
    //     }

    // },[data, activeSection])

const [currentSequence, setCurrentSequence] = useState(null)

useEffect(()=>{

    
    if(data!== null && currentSection !==''){
        let  sec = data.sections.find(section => section.sectionId === currentSection )
        if(sec){
            setCurrentSequence(sec.sequence)
        }   
    }
 
},[currentSection,data])


    const handleExpansion = (section) => (event, isExpanded) => {
        setActiveId(section)
        setExpanded(isExpanded ? section : false)
    };

    // Set active section for mobile
    let screenSize = window.innerWidth

    useEffect(() => {
        if (activeId !== null && screenSize < 900) {
            setPage(activeId);
        }
    })


    return (
        <>
            <ScreenView>
                {mobileView => (
                    mobileView ? (
                        <newContext.Provider value={{ activeId, setActiveId }}>
                            <Box className="box">
                                <Typography sx={{
                                    padding: '5%',
                                    textAlign: 'left',
                                    color: '#031A1C',
                                    fontFamily: "Open Sauce Sans",
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: '150%', /* 22.5px */
                                }}>Fill up the details to complete the student application form.</Typography>
                                <Box>
                                    <Box sx={{ flexGrow: 1, display: { xs: 'block', md: 'flex' } }}>
                                        <>
                                            {data !== null && data.isActive === 1 ?
                                                data.sections
                                                    .filter(section => (loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' ) || Insterested === '1' || section.sectionId !== 14)
                                                    .sort((a, b) => a.sequence - b.sequence)
                                                    .map((section) => (
                                                        section.isActive === 1 && section.parentSectionId === 0 &&
                                                        <Accordion
                                                            className="rectangle"
                                                            onClick={() => setSectionPage(section.sectionId, section.sectionName, section.sequence, mobileView)}
                                                            expanded={sectionId === activeId ? expanded === section.sectionId : expanded === activeId}
                                                            onChange={handleExpansion(section.sectionId)}
                                                            slots={{ transition: Fade }}
                                                            slotProps={{ transition: { timeout: 400 } }}
                                                            sx={{
                                                                '& .MuiAccordion-region': {
                                                                    height: sectionId === section.sectionId ? 'auto' : 0,
                                                                    opacity: 'unset !important',
                                                                },
                                                                '& .MuiAccordionDetails-root': {
                                                                    display: sectionId === section.sectionId && expanded !== false ? 'block' : 'none',
                                                                    background: '#fff',
                                                                    color: '#000',
                                                                    visibility: 'visible',
                                                                },
                                                            }}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />}
                                                                // aria-controls={"panel" + section.sectionId + "-content"} 
                                                                id={"panel1" + section.sectionId + "-header"}
                                                            >
                                                                <Typography sx={{ fontWeight: 600 }}>
                                                                    {section.sectionId === activeId ?
                                                                        <AdjustIcon sx={{ mt: 1, mr: 1, color: '#538a30' }} />
                                                                        : (section.sequence <= lastSaveSequenece ?
                                                                            <CheckCircleIcon sx={{ mt: 1, mr: 1, color: '#538a30' }} />
                                                                            : <CircleOutlinedIcon sx={{ mt: 1, mr: 1, color: '#C6C6C6' }} />)
                                                                    }
                                                                    {section.sectionName}
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography>
                                                                    {sectionId !== null && <FormSection activeSection={activeId} sectionName={sectionName} />}
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))
                                                : null}
                                        </>
                                    </Box>
                                </Box>
                                {/* {sectionId !== null && <FormSection activeSection={sectionId} sectionName={sectionName} />} */}
                            </Box>
                        </newContext.Provider>
                        // <Box></Box>
                    ) : (
                        <>
                            <Box mt={3} mb={2} sx={{ width: '100%', color: '#17AA00', width: '91%', padding: '0px 50px' }}>
                                <Stepper
                                    alternativeLabel
                                    activeStep={currentSection} // This indicates the index of the currently active step
                                    connector={<QontoConnector />}
                                >
                                    {data !== null && data.isActive === 1 ?
                                        data.sections
                                            .filter(section => (loggedInUser === 'admin' && sessionStorage.getItem('actionType') !== 'Edit' ) || Insterested === '1' || section.sectionId !== 14)
                                            .sort((a, b) => a.sequence - b.sequence)
                                            .map((section) => (
                                                section.isActive === 1 && section.parentSectionId === 0 &&
                                                <Step
                                                    key={section.sectionId}
                                                    sx={{ color: '#17AA00', '&:hover': { cursor: 'pointer' } }}
                                                    // Check if this section is completed or active
                                                    completed={section.sequence <= lastSaveSequenece}
                                                    active={section.sequence === currentSequence}
                                                    onClick={() => setSectionPage(section.sectionId, section.sectionName, section.sequence)}
                                                >
                                                    <StepLabel
                                                        StepIconComponent={(props) => (
                                                            <QontoStepIcon
                                                                {...props}
                                                                completed={section.sequence <= lastSaveSequenece}
                                                                active={section.sequence === currentSequence}
                                                            />
                                                        )}
                                                        sx={{ color: '#000' }}
                                                    >
                                                        {section.sectionName}
                                                    </StepLabel>
                                                </Step>
                                            ))
                                        : null
                                    }
                                </Stepper>

                            </Box>

                            {sectionId !== null && <FormSection activeSection={sectionId} sectionName={sectionName} showPreview={0} />}
                        </>
                    )
                )}
            </ScreenView>
        </>
    );
}
