
export const dynamicForm = {
    firstName: {
      label: "First Name",
      type: "text",
      placeholder: "Enter your first name",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    lastName: {
      label: "Last Name",
      type: "text",
      placeholder: "Enter your last name",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    gender: {
      label: "Gender",
      type: "radio",
      options: ["male", "female"],
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    profession: {
      label: "Profession",
      type: "dropdown",
      options: ["Front-end Developer", "Back-end Developer", "Devops Engineer"],
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    agree: {
      type: "checkbox",
      label: "",
      checkboxLabel: "I hereby agree to the terms.",
      defaultValue: false,
      rules: {
        required: true,
      },
    },
   };


export const data = {
    "sections": [
        {
          "sectionId": 1,
          "sectionName": "Enrollment Information",
          "isActive": 0,
          "fieldsList": []
        },
        {
          "sectionId": 2,
          "sectionName": "Personal Information",
          "isActive": 0,
          "fieldsList": [
            {
              "sectionId": 2,
              "fieldId": 1,
              "fieldDetId": 1,
              "fieldName": "Firstname",
              "dbColumnName": "Firstname",
              "isActive": 0,
              "fieldTypeId": 1,
              "fieldType": "Text",
              "fieldDataSource": "1",
              "fieldDataSourceType": "Txt"
            },
            {
              "sectionId": 2,
              "fieldId": 2,
              "fieldDetId": 2,
              "fieldName": "Middlename",
              "dbColumnName": "Middlename",
              "isActive": 0,
              "fieldTypeId": 1,
              "fieldType": "Text",
              "fieldDataSource": "1",
              "fieldDataSourceType": "Txt"
            },
            {
              "sectionId": 2,
              "fieldId": 3,
              "fieldDetId": 3,
              "fieldName": "Lastname",
              "dbColumnName": "Lastname",
              "isActive": 0,
              "fieldTypeId": 1,
              "fieldType": "Text",
              "fieldDataSource": "1",
              "fieldDataSourceType": "Txt"
            },
            {
              "sectionId": 2,
              "fieldId": 4,
              "fieldDetId": 4,
              "fieldName": "DOA",
              "dbColumnName": "DOA",
              "isActive": 0,
              "fieldTypeId": 1,
              "fieldType": "Text",
              "fieldDataSource": "1",
              "fieldDataSourceType": "Txt"
            },
            {
              "sectionId": 2,
              "fieldId": 5,
              "fieldDetId": 5,
              "fieldName": "FatherName",
              "dbColumnName": "FatherName",
              "isActive": 0,
              "fieldTypeId": 1,
              "fieldType": "Text",
              "fieldDataSource": "1",
              "fieldDataSourceType": "Txt"
            }
          ]
        },
        {
          "sectionId": 3,
          "sectionName": "Communication Information",
          "isActive": 0,
          "fieldsList": [
            {
              "sectionId": 3,
              "fieldId": 17,
              "fieldDetId": 6,
              "fieldName": "Grade",
              "dbColumnName": "C3",
              "isActive": 0,
              "fieldTypeId": 2,
              "fieldType": "DropDown",
              "fieldDataSource": "0=--Select--+\r\n1=test1+\r\n2=test2+\r\n3=test3+\r\n4=test4+\r\n5=test5+\r\n6=test6+\r\n7=test7+\r\n8=test8+\r\n9=test9+\r\n10=test10+\r\n11=test11+\r\n12=test12+\r\n13=test13+\r\n14=test14+\r\n15=test15+\r\n",
              "fieldDataSourceType": "Txt"
            }
          ]
        }
        ,
        {
          "sectionId": 4,
          "sectionName": "Upload Document List",
          "isActive": 0,
          "fieldsList": []
        },
        {
          "sectionId": 5,
          "sectionName": "Education Detail",
          "isActive": 0,
          "fieldsList": []
        },
        {
          "sectionId": 6,
          "sectionName": "Work Experience",
          "isActive": 0,
          "fieldsList": []
        },
        {
          "sectionId": 7,
          "sectionName": "Annexure Details",
          "isActive": 0,
          "fieldsList": []
        },
        {
          "sectionId": 8,
          "sectionName": "Admin Panel",
          "isActive": 0,
          "fieldsList": []
        }
      ]
}