import React, { useEffect, useState } from "react";
import Header from "../component/header";
import CourseSelection from "../component/courseSelection";
// import { useAuth } from '../util/AuthContext';
import { CategoryList, CourseList, Enquiry, Form, NewEnquiry } from "../util/apiUrl";
import CallAPI from '../util/apiAction';

export default function CourseSelectionPage(){
    
    const dateOfBirth = sessionStorage.getItem("dateOfBirth");
    const mobileNo = sessionStorage.getItem("mobileNo");
    const univID = sessionStorage.getItem("univID");
    // const dateOfBirth = "2018-01-01"
    // const mobileNo = "99999999"
    // const univID = 23

    // console.log(mobileNo, dateOfBirth, univID)

    // const { login } = useAuth();
    // useEffect(()=>{
    //     login(dateOfBirth,mobileNo,univID);
    // }, []);
    const [enquiry, setEnquiry] = useState(null);
    const [data, setData] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data,load,err } = await CallAPI(Enquiry, 'POST', { dateOfBirth:dateOfBirth, mobileNo:mobileNo, univId:univID }); 
                setEnquiry(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        // if(sessionStorage.getItem('source') !== 'digiversity'){
            fetchData(); 
        // }
        
    }, []);

    useEffect(()=>{    
        if(enquiry !== null ){
            let catId = enquiry.existingEnqDet.map((enq) => enq.categoryId).shift();
            let CourseId = enquiry.existingEnqDet.map((enq) => enq.courseId).shift();
            let enquiryId = enquiry.existingEnqDet.map((enq) => enq.enquiryId).shift();
            let userName = enquiry.existingEnqDet.map((enq) => enq.candidateName).shift();
            let admissionId = enquiry.existingEnqDet.map((enq) => enq.admissionId).shift();

            const fetchData = async () => {
            try {
                const { data,loading,error } = await CallAPI(Form, 'POST', { 
                    "unvId": univID,
                    "categoryId": catId,
                    "courseId": CourseId,
                    "enquiryId": enquiryId,
                    "mobileNo": mobileNo,
                    "dob": dateOfBirth,
                    "name": userName
                }
                ); 
                 setData(data);
                sessionStorage.setItem('lastActiveSection', parseInt(data.lastActiveSection))
                sessionStorage.setItem('admissionId', admissionId)
                setDataFetched(true)
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            
            if(enquiry.existingEnqDet.length !== 0){
                fetchData();
            }
        }
        
    }, [enquiry])


    return(
        <>
            <Header/>
            <CourseSelection enquiryData={enquiry} data={data} dataFetched={dataFetched}/>
            {/* <CourseSelection enquiryData={enquiry}/> */}
        </>
    )
}