import React, { useState, useEffect} from "react";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { InputLabel, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import '../assets/styles/form.css';
import '../assets/styles/global.css';
import univLogo from "../assets/images/univLogo.png";
import { Cookie, Image } from "@mui/icons-material";
import AdmissionForm from "../pages/admissionForm";
import { UniversityDetails } from '../util/apiUrl';
import CallAPI from '../util/apiAction';

export default function Header(){
    const [data, setData] = useState(null)
    const [state, setState] = useState({
        mobileView: false,
    });

    const { mobileView } = state;
    useEffect(() => {
        const setResponsiveness = () => {
          return window.innerWidth < 900
            ? setState((prevState) => ({ ...prevState, mobileView: true }))
            : setState((prevState) => ({ ...prevState, mobileView: false }));
        };
    
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
    
        return () => {
          window.removeEventListener("resize", () => setResponsiveness());
        }
    }, []);

    // const UniversityUrl = "https://mservicedev.teamleaseedtech.com"
    let url = window.location.origin;
    let UniversityUrl = ''

    useEffect(() => {
        if (url) {
            if(url === 'http://localhost:3000'){
                UniversityUrl = `https://mservicedev.teamleaseedtech.com`;
            }else {
                UniversityUrl = window.location.origin
            }

          fetchData();
        }
    }, [url]);

    // useEffect(() => {
    //     if(UniversityUrl === 'http://localhost:3000'){
    //         UniversityUrl = `https://mservicedev.teamleaseedtech.com`
    //     }

        const fetchData = async() => {
            try {
                const response = await fetch(`${UniversityDetails}?UmsUniversityUrl=${UniversityUrl}`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  });
                if (response.ok) {
                    const data = await response.json();
                    setData(data.data)
                } else {
                    console.log("")
                }
            } catch (error) {
                console.log(error);
            }
        }

        // const fetchData = async () => {
        //     try {
        //         const { data,loading,error } = await CallAPI(UniversityDetails, 'GET', { UmsUniversityUrl:UniversityUrl }); 
        //         setData(data);
        //     } catch (error) {
        //         console.error('Error fetching data:', error);
        //     }
        // };
    
        // fetchData();
        
    // }, []);

    useEffect(() => {
        const importCSS = async () => {
            if (mobileView) {
                const module = await import('../assets/styles/mobile/global.css');
            } else {
                const module = await import('../assets/styles/global.css');
            }
        };

        importCSS();

        // Clean up effect
        return () => {
            // Any cleanup code if needed
        };
    }, [mobileView]);


    return(
        <>
            <Box className='univ-header'>
                {data !== null? 
                <Grid container>
                    <Grid item xs={mobileView? 4 : 2} 
                        sx={{padding: '1%'}}
                    >
                        <Box>
                            <img src={data[0].UniversityLogo} alt="University Logo" style={{ maxWidth: '120px', height: '120px' }} />   
                        </Box>
                    </Grid>
                    <Grid item xs={mobileView ? 8 : 7} sx={{padding: '1%'}}>
                        <Typography className="university-name"
                            sx={{
                                fontSize: mobileView ? '20px' : '28px',
                                letterSpacing: mobileView? '0.8px' : '1.28px'
                            }}>
                                {data[0].UniversityName}
                        </Typography>
                        {/* <Typography 
                            sx={{
                                fontWeight: 700, 
                                color: '#D52B2A', 
                                fontSize: mobileView ? '20px' : '32px',
                                textAlign: 'left',
                                lineHeight: '120%', 
                                letterSpacing: mobileView? '0.8px' : '1.28px'
                            }}>
                                {data[0].UniversityName}
                        </Typography> */}
                        <Box className="header-subline" sx={{ width: '100%', textAlign: 'left'}}>
                            {data[0].AddressLine1}
                        </Box>
                        <Box className="header-subline" sx={{ width: '100%', textAlign: 'left'}}>
                            {data[0].AddressLine2}
                        </Box>
                        <Box className="header-subline" sx={{ width: '100%', textAlign: 'left'}}>
                            {data[0].url}
                        </Box>
                    </Grid>
                    {/* Right side with 30% width */}
                    {mobileView &&
                    <Grid item xs={2}></Grid>
                    }
                    <Grid item xs={mobileView ? 10 : 3} sx={{padding: '1%', textAlign: mobileView ? 'left' : 'left'}}>
                        <Box className="header-subline" sx={{ width: '100%', padding: mobileView ? '10px 0px 10px 0px' : '0px'}}>
                            <LocalPhoneOutlinedIcon sx={{marginBottom: '-7px'}}/> {data[0].TelNo}
                        </Box>
                        {/* '10px 0px 10px 40px' */}
                        {data[0].EmailId !== null &&
                            <Box className="header-subline" sx={{ width: '100%', display: 'flex', padding: mobileView ? '0px' : '8px 0px'}}>
                                <EmailOutlinedIcon /> &nbsp;{data[0].EmailId}
                            </Box>  
                        }
                    </Grid>
                </Grid>
                : null}
            </Box>
        </>
    )
}